import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@taxdown/piccolo';
import { useTranslation } from 'Hooks';
import Trans from 'Components/Trans';
import ArrowIcon from 'Assets/icons/ArrowIcon';

import {
  MediaCardWrapper,
  MediaSnippetLink,
  CustomLink,
  MediaImage,
  MediaBody,
  Rectangle,
  ArticleImage,
  MediaCardContent,
} from './styles';

function MediaCard({ Snippet, Image, Link, Interview, hasImage, isSmall }) {
  const { t } = useTranslation();
  return (
    <MediaCardWrapper isBig={hasImage}>
      <Rectangle />
      <MediaCardContent isBig={hasImage} isSmall={isSmall}>
        <MediaImage isSmall={isSmall} isBig={hasImage}>
          <img src={Image} alt="" />
        </MediaImage>
        <MediaBody isBig={hasImage}>
          <Text>{Snippet}</Text>
          <Trans i18nKey="InterviewText" />
          <MediaSnippetLink>
            <Text size="xs" color="primaryGreen" bold>
              <CustomLink href={Link} target="_blank">
                {t(Interview ? 'Media.MediaCard.interviewText' : 'Media.MediaCard.noticeText')}
              </CustomLink>
            </Text>
            <ArrowIcon />
          </MediaSnippetLink>
        </MediaBody>
      </MediaCardContent>
      {hasImage && (
        <ArticleImage>
          <img src="/images/media/article-mobile.png" alt="" />
        </ArticleImage>
      )}
    </MediaCardWrapper>
  );
}

MediaCard.propTypes = {
  Snippet: PropTypes.string.isRequired,
  Image: PropTypes.string.isRequired,
  Link: PropTypes.string.isRequired,
  Interview: PropTypes.bool,
  hasImage: PropTypes.bool,
  isSmall: PropTypes.bool,
};

MediaCard.defaultProps = {
  hasImage: null,
  isSmall: false,
  Interview: false,
};

export default MediaCard;
