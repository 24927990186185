import { useStaticQuery, graphql } from 'gatsby';

export function useLegalsQuery(name) {
  const queryData = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Legals" }, data: { Type: { ne: null } } }) {
        nodes {
          data {
            Text
            Type
            ID
          }
        }
      }
    }
  `);

  const {
    allAirtable: { nodes },
  } = queryData;

  const data = nodes.map(item => item.data);
  const text = data.find(item => item.Type === name).Text;
  return { text };
}

export default useLegalsQuery;
