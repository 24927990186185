import React from 'react';

const SquaresImage = () => {
  return (
    <svg
      width="463"
      height="85"
      viewBox="0 0 463 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="60"
        height="60"
        rx="5"
        transform="matrix(0.707105 -0.707109 0.707105 0.707109 0 42.5)"
        stroke="#3D3A62"
        strokeWidth="3.14713"
        strokeLinecap="round"
      />
      <rect
        x="106.853"
        y="12.5"
        width="60"
        height="60"
        rx="5"
        stroke="#99F1BD"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        width="60"
        height="60"
        rx="5"
        transform="matrix(0.707105 -0.707108 0.707105 0.707108 188.853 42.5)"
        stroke="#3D3A62"
        strokeWidth="3.14713"
        strokeLinecap="round"
      />
      <rect
        x="295.705"
        y="12.5"
        width="60"
        height="60"
        rx="5"
        stroke="#3D3A62"
        strokeWidth="3.14713"
        strokeLinecap="round"
      />
      <rect
        width="60"
        height="60"
        rx="5"
        transform="matrix(0.707105 -0.707108 0.707105 0.707108 377.705 42.5)"
        stroke="#3D3A62"
        strokeWidth="3.14713"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SquaresImage;
