import { createGlobalStyle } from 'styled-components';
import './global.scss';
import './buttons.scss';

const ArboriaBoldEot = '/fonts/Arboria-Bold/Arboria-Bold.eot';
const ArboriaBoldWoff2 = '/fonts/Arboria-Bold/Arboria-Bold.woff2';
const ArboriaBoldWoff = '/fonts/Arboria-Bold/Arboria-Bold.woff';
const ArboriaBoldTtf = '/fonts/Arboria-Bold/Arboria-Bold.ttf';
const ArboriaBoldSvg = '/fonts/Arboria-Bold/Arboria-Bold.svg';

const ArboriaMediumEot = '/fonts/Arboria-Medium/Arboria-Medium.eot';
const ArboriaMediumWoff2 = '/fonts/Arboria-Medium/Arboria-Medium.woff2';
const ArboriaMediumWoff = '/fonts/Arboria-Medium/Arboria-Medium.woff';
const ArboriaMediumTtf = '/fonts/Arboria-Medium/Arboria-Medium.ttf';
const ArboriaMediumSvg = '/fonts/Arboria-Medium/Arboria-Medium.svg';

const ArboriaBookEot = '/fonts/Arboria-Book/Arboria-Book.eot';
const ArboriaBookWoff2 = '/fonts/Arboria-Book/Arboria-Book.woff2';
const ArboriaBookWoff = '/fonts/Arboria-Book/Arboria-Book.woff';
const ArboriaBookTtf = '/fonts/Arboria-Book/Arboria-Book.ttf';
const ArboriaBookSvg = '/fonts/Arboria-Book/Arboria-Book.svg';

export const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: 'Arboria';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('${ArboriaBoldEot}'); /* IE9 Compat Modes */
    src:
        url('${ArboriaMediumEot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url(${ArboriaMediumWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${ArboriaMediumWoff}) format('woff'), /* Modern Browsers */
        url(${ArboriaMediumTtf}) format('truetype'), /* Safari, Android, iOS */
        url('${ArboriaMediumSvg}#OpenSans') format('svg'); /* Legacy iOS */
    }

    @font-face {
    font-family: 'Arboria-Bold';
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
    src: url('${ArboriaBoldEot}'); /* IE9 Compat Modes */
    src:
        url('${ArboriaBoldEot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url(${ArboriaBoldWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${ArboriaBoldWoff}) format('woff'), /* Modern Browsers */
        url(${ArboriaBoldTtf}) format('truetype'), /* Safari, Android, iOS */
        url('${ArboriaBoldSvg}#OpenSans') format('svg'); /* Legacy iOS */
    }

    @font-face {
    font-family: 'Arboria-Book';
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    src: url('${ArboriaBoldEot}'); /* IE9 Compat Modes */
    src:
        url('${ArboriaBookEot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url(${ArboriaBookWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${ArboriaBookWoff}) format('woff'), /* Modern Browsers */
        url(${ArboriaBookTtf}) format('truetype'), /* Safari, Android, iOS */
        url('${ArboriaBookSvg}#OpenSans') format('svg'); /* Legacy iOS */
    }

    html {
    font-size: 16px;
    box-sizing: border-box;
    height: 100%;
    scroll-behavior: smooth;
    }


    body {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: 'Arboria-Book';
    > #root  {
        height: 100%;
    }
    }

    *,
    *:before,
    *:after {
    box-sizing: inherit;
    }

    ul {
    margin: 0;
    padding: 0;
    list-style: none;
    }

    a {
    color: inherit;
    text-decoration: none;
    color: inherit;
    }

    button {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    }

    button,
    input, select {
    border: 0;
    outline: none;
    }
    textarea:focus,
    input:focus {
    outline: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
    margin: 0;
    }
`;
