import React from 'react';
import PropTypes from 'prop-types';
import { Text, Heading } from '@taxdown/piccolo';
import { Container, LinkButton } from 'Components/UI';
import { useTranslation } from 'Hooks';
import { BannerImageWrapper, ImageWrapper } from './styles';

const BannerImage = ({
  backgroundImage,
  title,
  description,
  textButton,
  align,
  cta,
  image,
  ns,
}) => {
  const { t } = useTranslation();
  return (
    <BannerImageWrapper
      backgroundImage={backgroundImage}
      align={align}
      hasImage={image}
      className="lazyload"
      data-bg={backgroundImage}
    >
      <Container>
        <div>
          <Heading type="h3" color="white">
            {t(`${ns}:${title}`)}
          </Heading>
          {description && (
            <Text type="p" color="white">
              {t(`${ns}:${description}`)}
            </Text>
          )}
          <LinkButton link={cta.route} isLink={cta.link}>
            {t(`${ns}:${textButton}`)}
          </LinkButton>
        </div>
        {image && (
          <ImageWrapper>
            <img src={image} alt="" />
          </ImageWrapper>
        )}
      </Container>
    </BannerImageWrapper>
  );
};

BannerImage.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  textButton: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
  image: PropTypes.string,
  cta: PropTypes.shape({
    route: PropTypes.string,
    link: PropTypes.bool,
  }).isRequired,
  ns: PropTypes.string.isRequired,
};

BannerImage.defaultProps = {
  description: null,
  image: null,
};

export default BannerImage;
