/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Link from 'Components/Link';
import { useTranslation } from 'Hooks';
import { FOOTER_OPTIONS_LIST } from 'Constants/footer';
import { AeatIcon, InstagramIconColor, YoutubeIcon, LinkedInIconColor } from 'Assets/icons';
import './styles.scss';

const Footer = () => {
  const { t } = useTranslation();
  // const languages = [
  //   { language: 'es', text: t('Footer.lang.es') },
  //   { language: 'en', text: t('Footer.lang.en') },
  // ];
  return (
    <footer className="general-footer">
      <div className="footer_left">
        <div className="footer_logo" title={t('Footer.Logo.alt')} />
        <div className="stores_logos">
          <a href="https://apps.apple.com/es/app/taxdown/id1503998673" className="store_logo">
            <img src="/images/app_store.svg" alt={t('Footer.appStore.alt')} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.taxdown.bave.android&hl=es_419&gl=US"
            className="store_logo"
          >
            <img src="/images/google_play.svg" alt={t('Footer.googlePlay.alt')} />
          </a>
        </div>
      </div>
      <div className="col_wr">
        {/* <div className="col">
          <div className="footer_title">{t('Footer.language')}</div>
          <ChangeLanguage languages={languages} />
        </div> */}
        {FOOTER_OPTIONS_LIST.map(({ title, options }) => (
          <div className="col" key={title}>
            <div className="footer_title">
              <p>{t(title)}</p>
            </div>
            {options.map(({ label, route, link, isContact, rel }) => {
              if (isContact) {
                return (
                  <p>
                    <a key={label} href="mailto:support@taxdown.es" className="footer_link">
                      {t(label)}
                    </a>
                  </p>
                );
              }
              if (link) {
                return (
                  <p>
                    <a key={label} href={route} className="footer_link">
                      {t(label)}
                    </a>
                  </p>
                );
              }
              return (
                <p>
                  <Link key={label} to={route} className="footer_link" rel={rel}>
                    {t(label)}
                  </Link>
                </p>
              );
            })}
          </div>
        ))}
      </div>
      <div className="end_bar">
        <div className="social_icons">
          <a
            href="https://twitter.com/Tax_Down?lang=es"
            className="social_icon twitter"
            title={t('Footer.Links.twitter.alt')}
            rel="nofollow"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
                fill="#1DA1F2"
              />
              <path
                d="M10.5577 19.9453C16.5436 19.9453 19.8173 14.9861 19.8173 10.6857C19.8173 10.5446 19.8146 10.4042 19.8079 10.2652C20.443 9.8055 20.9958 9.23242 21.4312 8.58037C20.848 8.83957 20.2203 9.01372 19.5621 9.0927C20.2338 8.68972 20.7501 8.05252 20.9931 7.29247C20.364 7.66507 19.6681 7.93642 18.927 8.08222C18.333 7.44975 17.4872 7.0542 16.5517 7.0542C14.7541 7.0542 13.2968 8.51152 13.2968 10.3084C13.2968 10.5635 13.3252 10.8119 13.3812 11.0502C10.6765 10.9145 8.27818 9.6192 6.67303 7.65022C6.39358 8.13082 6.23225 8.68972 6.23225 9.28575C6.23225 10.415 6.80668 11.4113 7.6808 11.9945C7.14688 11.9783 6.64535 11.8312 6.2066 11.5875C6.20593 11.601 6.20593 11.6145 6.20593 11.6287C6.20593 13.2048 7.32778 14.521 8.81682 14.8194C8.54345 14.8936 8.2559 14.9341 7.9589 14.9341C7.74898 14.9341 7.54513 14.9132 7.34735 14.8754C7.7618 16.168 8.9633 17.109 10.3876 17.136C9.27313 18.0088 7.8698 18.5285 6.34498 18.5285C6.0824 18.5285 5.8232 18.5137 5.56873 18.4833C7.00918 19.4067 8.71895 19.9453 10.5577 19.9453Z"
                fill="white"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/tax_down/?hl=es"
            className="social_icon instagram"
            title={t('Footer.Links.instagram.alt')}
            rel="nofollow"
          >
            <InstagramIconColor />
          </a>
          <a
            href="https://www.youtube.com/TaxDown/videos"
            className="social_icon youtube"
            title={t('Footer.Links.youtube.alt')}
            rel="nofollow"
          >
            <YoutubeIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/taxdown/"
            className="social_icon linkedin"
            title={t('Footer.Links.linkedin.alt')}
            rel="nofollow"
          >
            <LinkedInIconColor />
          </a>
        </div>
        <div className="right copyright">{t('Footer.copyright')}</div>
      </div>
      <div className="footer_body">
        <p>
          {t('Footer.proud')}
          <span role="img" aria-label="emoji">
            ❤️
          </span>
        </p>
        <div className="footer_aeat">
          <AeatIcon />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
