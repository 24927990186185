import { useEffect, useState } from 'react';
import { getRemainingTime } from 'Utils/countdown';

const setCountdownValues = remainingTime => {
  const { days, hours, minutes, seconds } = remainingTime;
  return [
    { type: 'days', name: 'Countdown.Day.text', value: days },
    { type: 'hours', name: 'Countdown.Hour.text', value: hours },
    { type: 'minutes', name: 'Countdown.Minute.text', value: minutes },
    { type: 'seconds', name: 'Countdown.Second.text', value: seconds },
  ];
};

export const useCountdownTimer = ({ deadline }) => {
  const [remainingTime, setRemainingTime] = useState(getRemainingTime(deadline));
  const { total } = remainingTime;
  const isDeadline = total <= 0;

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemainingTime(getRemainingTime(deadline));
    }, 1000);
    if (total === 0) {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [total]);

  const countdownValues = setCountdownValues(remainingTime);

  return { countdownValues, isDeadline };
};

export default useCountdownTimer;
