import React from 'react';
import { useTranslation } from 'Hooks';
import { PLANS_COMPARE_ANCHOR } from 'Constants/routes';
import ReactTooltip from 'react-tooltip';
import TooltipFullComplexIcon from 'Assets/icons/plans/TooltipFullComplexIcon';
import TooltipFullConsultantIcon from 'Assets/icons/plans/TooltipFullConsultantIcon';
import TooltipFullNotWorryIcon from 'Assets/icons/plans/TooltipFullNotWorryIcon';

import TooltipLiveCryptoIcon from 'Assets/icons/plans/TooltipLiveCryptoIcon';
import TooltipLiveFreelanceIcon from 'Assets/icons/plans/TooltipLiveFreelanceIcon';
import TooltipLivePropertyIcon from 'Assets/icons/plans/TooltipLivePropertyIcon';

import TooltipProFirstIcon from 'Assets/icons/plans/TooltipProFirstIcon';
import TooltipProSavingsIcon from 'Assets/icons/plans/TooltipProSavingsIcon';
import TooltipProYoungIcon from 'Assets/icons/plans/TooltipProYoungIcon';

import IMG1 from './images/pricing_basic.png';
import IMG2 from './images/pricing_pro.png';
import IMG3 from './images/pricing_live.png';
import IMG4 from './images/pricing_full.png';
import './styles.scss';

const PricingPlansBlock = () => {
  const { t } = useTranslation();

  function GetBasicPlan() {
    return (
      <div className="pricing-plan-box basic">
        <h3 className="color-secondary">{t('PricingPlansBlock.basic.title')}</h3>
        <p className="princing-plan-box__subtitle color-secondary">
          {t('PricingPlansBlock.basic.subtitle')}
        </p>
        <div className="pricing-plan-box__details">
          <div className="pricing-plan-box__image">
            <img src={IMG1} alt={t('PricingPlansBlock.basic.alt')} />
          </div>
          <div className="pricing-plan-box__description color-primary">
            <p>{t('PricingPlansBlock.basic.description')}</p>
          </div>
          <div className="pricing-plan-box__price color-primary">
            <span>{t('PricingPlansBlock.basic.price')}</span>
          </div>
          <div className="pricing-plan-box__cta">
            <div className="button__container">
              <a
                href={`#${PLANS_COMPARE_ANCHOR}`}
                className="solid_btn green no_sticky"
                data-testid="cta_button"
              >
                {t('PricingPlansBlock.basic.cta')}
              </a>
            </div>
          </div>
          <ul className="pricing-plan-box__features">
            <li>{t('PricingPlansBlock.basic.feature1')}</li>
            <li>{t('PricingPlansBlock.basic.feature2')}</li>
            <li>{t('PricingPlansBlock.basic.feature3')}</li>
            <li>{t('PricingPlansBlock.basic.feature4')}</li>
          </ul>
        </div>
      </div>
    );
  }

  function GetProPlan() {
    return (
      <div className="pricing-plan-box pro">
        <h3 className="color-gold">{t('PricingPlansBlock.pro.title')}</h3>
        <p className="princing-plan-box__subtitle color-gold">
          {t('PricingPlansBlock.pro.subtitle')}
        </p>
        <div className="pricing-plan-box__details">
          <div className="pricing-plan-box__image">
            <img src={IMG2} alt={t('PricingPlansBlock.pro.alt')} />
          </div>
          <div className="pricing-plan-box__description color-primary">
            <p>{t('PricingPlansBlock.pro.description')}</p>
          </div>
          <div className="pricing-plan-box__price color-primary">
            <span>
              {t('PricingPlansBlock.pro.price')}
              <small>/{t('PricingPlansBlock.year')}</small>
            </span>
          </div>
          <div className="pricing-plan-box__cta">
            <div className="button__container">
              <a
                href={`#${PLANS_COMPARE_ANCHOR}`}
                className="solid_btn pro no_sticky"
                data-testid="cta_button"
              >
                {t('PricingPlansBlock.pro.cta')}
              </a>
            </div>
          </div>
          <ul className="pricing-plan-box__features">
            <li>{t('PricingPlansBlock.pro.feature1')}</li>
            <li>{t('PricingPlansBlock.pro.feature2')}</li>
            <li>{t('PricingPlansBlock.pro.feature3')}</li>
            <li>{t('PricingPlansBlock.pro.feature4')}</li>
          </ul>
        </div>
      </div>
    );
  }

  function GetLivePlan() {
    return (
      <div className="pricing-plan-box live">
        <h3 className="color-primary">{t('PricingPlansBlock.live.title')}</h3>
        <p className="princing-plan-box__subtitle color-primary">
          {t('PricingPlansBlock.live.subtitle')}
        </p>
        <div className="pricing-plan-box__details">
          <div className="pricing-plan-box__image">
            <img src={IMG3} alt={t('PricingPlansBlock.live.alt')} />
          </div>
          <div className="pricing-plan-box__description color-primary">
            <p>{t('PricingPlansBlock.live.description')}</p>
          </div>
          <div className="pricing-plan-box__price color-primary">
            <span>
              {t('PricingPlansBlock.live.price')}
              <small>/{t('PricingPlansBlock.year')}</small>
            </span>
          </div>
          <div className="pricing-plan-box__cta">
            <div className="button__container">
              <a
                href={`#${PLANS_COMPARE_ANCHOR}`}
                className="solid_btn live no_sticky"
                data-testid="cta_button"
              >
                {t('PricingPlansBlock.live.cta')}
              </a>
            </div>
          </div>
          <ul className="pricing-plan-box__features">
            <li>{t('PricingPlansBlock.live.feature1')}</li>
            <li>{t('PricingPlansBlock.live.feature2')}</li>
            <li>{t('PricingPlansBlock.live.feature3')}</li>
            <li>{t('PricingPlansBlock.live.feature4')}</li>
          </ul>
        </div>
      </div>
    );
  }

  function GetFullPlan() {
    return (
      <div className="pricing-plan-box full">
        <div className="featured">{t('PricingPlansBlock.comingSoon')}</div>
        <h3 className="color-primary">{t('PricingPlansBlock.full.title')}</h3>
        <p className="princing-plan-box__subtitle color-primary">
          {t('PricingPlansBlock.full.subtitle')}
        </p>
        <div className="pricing-plan-box__details">
          <div className="pricing-plan-box__image">
            <img src={IMG4} alt={t('PricingPlansBlock.full.alt')} />
          </div>
          <div className="pricing-plan-box__description color-primary">
            <p>{t('PricingPlansBlock.full.description')}</p>
          </div>
          <div className="pricing-plan-box__price color-primary">
            {/* <del>{t('PricingPlansBlock.full.offer')}</del> */}
            <span>
              {t('PricingPlansBlock.full.price')}
              <small>/{t('PricingPlansBlock.year')}</small>
            </span>
          </div>
          <div className="pricing-plan-box__cta">
            <div className="button__container">
              <a
                href={`#${PLANS_COMPARE_ANCHOR}`}
                className="solid_btn gold no_sticky"
                data-testid="cta_button"
              >
                {t('PricingPlansBlock.full.cta')}
              </a>
            </div>
          </div>
          <ul className="pricing-plan-box__features">
            <li>{t('PricingPlansBlock.full.feature1')}</li>
            <li>{t('PricingPlansBlock.full.feature2')}</li>
            <li>{t('PricingPlansBlock.full.feature3')}</li>
            <li>{t('PricingPlansBlock.full.feature4')}</li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <section className="pricing-plans-block block">
      <h2 className="section_title big">{t('PricingPlansBlock.title')}</h2>
      <p className="section_subtitle">{t('PricingPlansBlock.subtitle')}</p>

      <div className="pricing-plans-block__content">
        <div className="pricing-plans-block__container">
          <GetBasicPlan />
        </div>
        <div className="pricing-plans-block__container" data-tip data-for="proTooltip">
          <GetProPlan />
        </div>
        <div className="pricing-plans-block__container" data-tip data-for="liveTooltip">
          <GetLivePlan />
        </div>
        <div className="pricing-plans-block__container" data-tip data-for="fullTooltip">
          <GetFullPlan />
        </div>
        <ReactTooltip id="proTooltip" className="tooltip" place="bottom" type="dark" effect="solid">
          <p className="princing-plan-box__subtitle">{t('PricingPlansBlock.pro.tooltip.title')}</p>
          <div className="tooltip__icons">
            <div className="tooltip__icon">
              <TooltipProFirstIcon />
              <p>{t('PricingPlansBlock.pro.tooltip.icon1')}</p>
            </div>
            <div className="tooltip__icon">
              <TooltipProSavingsIcon />
              <p>{t('PricingPlansBlock.pro.tooltip.icon2')}</p>
            </div>
            <div className="tooltip__icon">
              <TooltipProYoungIcon />
              <p>{t('PricingPlansBlock.pro.tooltip.icon3')}</p>
            </div>
          </div>
        </ReactTooltip>
        <ReactTooltip
          id="liveTooltip"
          className="tooltip"
          place="bottom"
          type="dark"
          effect="solid"
        >
          <p className="princing-plan-box__subtitle">{t('PricingPlansBlock.live.tooltip.title')}</p>
          <div className="tooltip__icons">
            <div className="tooltip__icon">
              <TooltipLiveFreelanceIcon />
              <p>{t('PricingPlansBlock.live.tooltip.icon1')}</p>
            </div>
            <div className="tooltip__icon">
              <TooltipLiveCryptoIcon />
              <p>{t('PricingPlansBlock.live.tooltip.icon2')}</p>
            </div>
            <div className="tooltip__icon">
              <TooltipLivePropertyIcon />
              <p>{t('PricingPlansBlock.live.tooltip.icon3')}</p>
            </div>
          </div>
        </ReactTooltip>
        <ReactTooltip
          id="fullTooltip"
          className="tooltip"
          place="bottom"
          type="dark"
          effect="solid"
        >
          <p className="princing-plan-box__subtitle">{t('PricingPlansBlock.full.tooltip.title')}</p>
          <div className="tooltip__icons">
            <div className="tooltip__icon">
              <TooltipFullComplexIcon />
              <p>{t('PricingPlansBlock.full.tooltip.icon1')}</p>
            </div>
            <div className="tooltip__icon">
              <TooltipFullConsultantIcon />
              <p>{t('PricingPlansBlock.full.tooltip.icon2')}</p>
            </div>
            <div className="tooltip__icon">
              <TooltipFullNotWorryIcon />
              <p>{t('PricingPlansBlock.full.tooltip.icon3')}</p>
            </div>
          </div>
        </ReactTooltip>
      </div>
    </section>
  );
};

export default PricingPlansBlock;
