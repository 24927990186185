import React from 'react';
import PropTypes from 'prop-types';

function DiffTree({ title }) {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="diff_three_svg_title"
    >
      <title id="diff_three_svg_title">{title}</title>
      <path
        d="M50.5 3L63.674 31.3675L94.7241 35.1307L71.816 56.426L77.832 87.1193L50.5 71.913L23.168 87.1193L29.184 56.426L6.27587 35.1307L37.326 31.3675L50.5 3Z"
        fill="white"
        stroke="#3D3A62"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.5 22L58.2911 38.7765L76.6541 41.002L63.1063 53.596L66.6641 71.748L50.5 62.755L34.3359 71.748L37.8937 53.596L24.3459 41.002L42.7089 38.7765L50.5 22Z"
        fill="white"
        stroke="#C9EBD6"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.5 39L53.4748 45.4056L60.4861 46.2553L55.3133 51.0639L56.6717 57.9947L50.5 54.561L44.3283 57.9947L45.6867 51.0639L40.5139 46.2553L47.5252 45.4056L50.5 39Z"
        fill="white"
        stroke="#E0F7E9"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DiffTree.propTypes = {
  title: PropTypes.string,
};

DiffTree.defaultProps = {
  title: 'Expertos en la declaración de la renta',
};

export default DiffTree;
