import styled from 'styled-components';
import { TABLET_WIDTH } from 'Styles/sizes';
import { FlexContainer } from '@taxdown/piccolo';

export const ButtonContainer = styled(FlexContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  @media screen and (max-width: ${TABLET_WIDTH}) {
    margin-bottom: 1rem;
    a {
      min-width: 100%;
      max-width: 100%;
      font-size: 14px;
      padding: 1rem;
      white-space: pre;
    }
  }
`;
