import React from 'react';
import { useTranslation } from 'Hooks';
import './styles.scss';
import LinesImage from './img/lines';
import SquaresImage from './img/squares';

const TechnologyThatScalesBlock = () => {
  const { t } = useTranslation('home');

  return (
    <section className="technology-that-scales">
      <h2>{t('TechnologyThatScalesBlock.title')}</h2>
      <div className="technology-that-scales__container">
        <div className="block">
          <div className="image-container">
            <LinesImage />
          </div>
          <h3>{t('TechnologyThatScalesBlock.integration.title')}</h3>
          <p>{t('TechnologyThatScalesBlock.integration.description')}</p>
        </div>
        <div className="block">
          <div className="image-container squares">
            <SquaresImage />
          </div>
          <h3>{t('TechnologyThatScalesBlock.security.title')}</h3>
          <p>{t('TechnologyThatScalesBlock.security.description')}</p>
        </div>
      </div>
    </section>
  );
};

export default TechnologyThatScalesBlock;
