import styled from 'styled-components';

import { PRIMARY_GREEN, BASE_GREY, WHITE } from 'Styles/colors';

export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';

export const LoaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CONTAINER_SIZE = {
  [SMALL]: 1.2,
  [MEDIUM]: 2.4,
  [LARGE]: 3.8,
};

export const SQUARE_SIZE = {
  [SMALL]: 0.3,
  [MEDIUM]: 0.6,
  [LARGE]: 1,
};

export const getTopDistanceSecond = size => {
  const squareSize = SQUARE_SIZE[size];
  const quarterSquareSize = SQUARE_SIZE[size] / 4;
  const totalDistance = squareSize + quarterSquareSize;
  return totalDistance;
};

export const getTopDistanceThird = size => {
  const topDistanceSecond = getTopDistanceSecond(size);
  return 2 * topDistanceSecond;
};

export const getSizeInRem = size => `${size}rem`;

export const Container = styled.div`
  position: relative;
  transform: rotate(315deg);
  width: ${({ size }) => getSizeInRem(CONTAINER_SIZE[size])};
  height: ${({ size }) => getSizeInRem(CONTAINER_SIZE[size])};
`;

export const Square = styled.div`
  background-color: ${({ white }) => (white ? WHITE : BASE_GREY)};

  top: 0;
  border-radius: 3px;
  position: absolute;
  width: ${({ size }) => getSizeInRem(SQUARE_SIZE[size])};
  height: ${({ size }) => getSizeInRem(SQUARE_SIZE[size])};
`;

export const FirstPiece = styled(Square)``;

export const SecondPiece = styled(Square)`
  ${({ size, stopped }) =>
    stopped
      ? `
        top: ${getSizeInRem(getTopDistanceSecond(size))};
        width: ${getSizeInRem(SQUARE_SIZE[size] * 2)};
      `
      : `
      animation-name: grow-second-${size};
      animation-duration: 1s;
      animation-iteration-count: infinite;

      @keyframes grow-second-${size} {
        0% {
          top: 0;
          width: ${getSizeInRem(SQUARE_SIZE[size])};
        }
        25% {
          top: ${getSizeInRem(getTopDistanceSecond(size))};
          width: ${getSizeInRem(SQUARE_SIZE[size])};
        }
        50% {
          top: ${getSizeInRem(getTopDistanceSecond(size))};
          width: ${getSizeInRem(SQUARE_SIZE[size])};
        }
        100% {
          top: ${getSizeInRem(getTopDistanceSecond(size))};
          width: ${getSizeInRem(SQUARE_SIZE[size] * 2)};
        }
      }
    `}
`;

export const ThirdPiece = styled(Square)`
  background-color: ${({ white }) => (white ? WHITE : PRIMARY_GREEN)};

  ${({ size, stopped }) =>
    stopped
      ? `
    top: ${getSizeInRem(getTopDistanceThird(size))};
    width: ${getSizeInRem(SQUARE_SIZE[size] * 3)};
  `
      : `
    animation-name: grow-third-${size};
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @keyframes grow-third-${size} {
      0% {
        top: 0;
        width: ${getSizeInRem(SQUARE_SIZE[size])};
      }
      25% {
        top: ${getSizeInRem(getTopDistanceSecond(size))};
        width: ${getSizeInRem(SQUARE_SIZE[size])};
      }
      50% {
        top: ${getSizeInRem(getTopDistanceThird(size))};
        width: ${getSizeInRem(SQUARE_SIZE[size])};
      }
      100% {
        top: ${getSizeInRem(getTopDistanceThird(size))};
        width: ${getSizeInRem(SQUARE_SIZE[size] * 3)};
      }
    }
  `}
`;
