import React from 'react';

function Pin() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" fill="none" viewBox="0 0 12 18">
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M5.028 17.482a1.01 1.01 0 0 0 1.763 0C8.44 14.598 11.82 8.378 11.82 5.91A5.91 5.91 0 0 0 0 5.91c0 2.468 3.38 8.688 5.028 11.572z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6.015 8.864a2.85 2.85 0 1 0 0-5.698 2.85 2.85 0 0 0 0 5.698z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Pin;
