import React from 'react';
import { Helmet } from 'react-helmet';
import { config } from 'Config';

const AnalyticsHead = () => (
  <Helmet>
    <script>
      {`
        window.segmentSnippetLoaded = false;
        window.segmentSnippetLoading = false;

        window.segmentSnippetLoader = function (callback) {
          if (!window.segmentSnippetLoaded && !window.segmentSnippetLoading) {
            window.segmentSnippetLoading = true;

            function loader() {
              window.segmentSnippetLoading = false;
              window.segmentSnippetLoaded = true;
              if (callback) {
                callback();
              }
            }

            setTimeout(function () {
              'requestIdleCallback' in window
                ? requestIdleCallback(function () {
                    loader();
                  })
                : loader();
            }, 3000);
          }
        };

          window.segmentSnippetLoader();

          !(function () {
            var analytics = (window.analytics = window.analytics || []);
            if (!analytics.initialize)
              if (analytics.invoked)
                window.console && console.error && console.error('Segment snippet included twice.');
              else {
                analytics.invoked = !0;
                analytics.methods = [
                  'trackSubmit',
                  'trackClick',
                  'trackLink',
                  'trackForm',
                  'pageview',
                  'identify',
                  'reset',
                  'group',
                  'track',
                  'ready',
                  'alias',
                  'debug',
                  'page',
                  'once',
                  'off',
                  'on',
                  'addSourceMiddleware',
                  'addIntegrationMiddleware',
                  'setAnonymousId',
                  'addDestinationMiddleware',
                ];
                analytics.factory = function (e) {
                  return function () {
                    var t = Array.prototype.slice.call(arguments);
                    t.unshift(e);
                    analytics.push(t);
                    return analytics;
                  };
                };
                for (var e = 0; e < analytics.methods.length; e++) {
                  var key = analytics.methods[e];
                  analytics[key] = analytics.factory(key);
                }
                analytics.load = function (key, e) {
                  var t = document.createElement('script');
                  t.type = 'text/javascript';
                  t.async = !0;
                  t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                  var n = document.getElementsByTagName('script')[0];
                  n.parentNode.insertBefore(t, n);
                  analytics._loadOptions = e;
                };
                analytics._writeKey = '${config.SEGMENT_KEY}';
                analytics.SNIPPET_VERSION = '4.13.2';
              }
          })();
        `}
    </script>
    <script async>
      {`
            !(function() {
              window.taxdown_web_version = '${config.VERSION}';
              if(typeof window !== 'undefined' && window.analytics) {
                window.analytics.ready(() => { if (window.Raven) window.Raven.setTagsContext({ environment: '${process.env.STAGE}' }); });
              }
            })();
        `}
    </script>
    <script>
      {` 
      // load Branch
      (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
      // init Branch
      branch.init('${config.BRANCH_IO_KEY}');`}
    </script>
    <script
      type="text/javascript"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      async
    />
  </Helmet>
);

export default AnalyticsHead;
