/* eslint-disable react/no-children-prop */
import React, { useState, useCallback, useEffect } from 'react';
import { Text, Heading, Button } from '@taxdown/piccolo';
import { Section, Container } from 'Components/UI';
import Pin from 'Assets/icons/Pin';
import { useTranslation } from 'Hooks';
import Link from 'Components/Link';
import { TEXT_GREY_LIGHT } from 'Styles/colors';
import headerOptions from 'Assets/json/careers-list/header.json';
import DropDown from 'Assets/icons/Drowpdown';
import { CAREERS_LIST_ROUTE } from 'Constants/routes';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
  ListRow,
  RowDescription,
  RowLocation,
  RowArea,
  RowHeader,
  Option,
  OptionsList,
  DropDownOption,
} from './styles';

const ALL_FILTERS = 'Todas';

function FirstSection({ jobs, categories, category, fullRemoteOptions }) {
  const { t } = useTranslation('careers');
  const [jobsFiltered, setJobsFiltered] = useState(jobs);
  const [options, setToggle] = useState([
    { id: 'area', show: false },
    { id: 'description', show: false },
    { id: 'remote', show: false },
  ]);

  const showDropDown = id => {
    const newArr = [...options];
    newArr.map(e => {
      if (e.id === id) {
        e.show = !e.show;
      }
      return e;
    });
    setToggle(newArr);
  };

  useEffect(() => {
    const newJobs = !category ? jobs : [...jobs].filter(({ Area }) => Area === category);
    setJobsFiltered(newJobs);
  }, [jobs]);

  const filterJobs = useCallback(
    (type, node) => {
      const filteredJobs =
        type !== ALL_FILTERS ? [...jobs].filter(item => item[node] === type) : jobs;
      setJobsFiltered(filteredJobs);
    },
    [jobsFiltered]
  );

  return (
    <Section>
      <Container>
        <RowHeader>
          {headerOptions.map(({ label, isSelect, id }) => (
            <React.Fragment key={label}>
              {isSelect ? (
                <Option
                  key={label}
                  onClick={() => showDropDown(id)}
                  isSelect={isSelect}
                  toggle={options.find(item => item.id === id).show}
                >
                  <Text size="xs" bold key={label}>
                    {t(label)}
                    {isSelect && <DropDown />}
                  </Text>
                  <OptionsList>
                    {id === 'area' &&
                      [...categories, ALL_FILTERS].map(item => (
                        <DropDownOption onClick={() => filterJobs(item, 'Area')} key={item}>
                          <Text size="xs" key={item}>
                            {item}
                          </Text>
                        </DropDownOption>
                      ))}
                    {id === 'remote' &&
                      [...fullRemoteOptions, ALL_FILTERS].map(item => (
                        <DropDownOption onClick={() => filterJobs(item, 'FullRemote')} key={item}>
                          <Text size="xs" key={item}>
                            {item !== ALL_FILTERS &&
                              (item !== null
                                ? t('CareersList.Header.yes')
                                : t('CareersList.Header.no'))}
                            {item === ALL_FILTERS && item}
                          </Text>
                        </DropDownOption>
                      ))}
                  </OptionsList>
                </Option>
              ) : (
                <Option key={label}>
                  <Text size="xs" bold key={label}>
                    {t(label)}
                  </Text>
                </Option>
              )}
            </React.Fragment>
          ))}
        </RowHeader>
        {jobsFiltered.map(({ Title, Area, Location, ID, ShortDescriptionESP, FullRemote }) => (
          <ListRow key={ID}>
            <Heading type="h3">{Title}</Heading>
            <RowDescription>
              <ReactMarkdown children={ShortDescriptionESP} />
            </RowDescription>
            <RowArea>
              <Text size="medium">{Area}</Text>
            </RowArea>
            <RowArea>
              <Text size="medium">
                {FullRemote ? t('CareersList.Header.yes') : t('CareersList.Header.no')}
              </Text>
            </RowArea>
            <RowLocation>
              <Text size="xs" color={TEXT_GREY_LIGHT}>
                <Pin />
                {Location}
              </Text>
            </RowLocation>
            <Link to={`${CAREERS_LIST_ROUTE}/${Title}/${Area}/${ID}`}>
              <Button secondary size="small">
                {t('CareersList.textButton')}
              </Button>
            </Link>
          </ListRow>
        ))}
      </Container>
    </Section>
  );
}

FirstSection.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Area: PropTypes.string,
      Location: PropTypes.string,
      Title: PropTypes.string,
      Short_descriptionESP: PropTypes.string,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  category: PropTypes.string,
  fullRemoteOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

FirstSection.defaultProps = {
  category: null,
};

export default FirstSection;
