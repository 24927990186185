import React from 'react';
import PropTypes from 'prop-types';

function LogoIconYellow({ title }) {
  return (
    <svg
      width="40"
      height="27"
      viewBox="0 0 40 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="plan_logo_icon"
    >
      <title id="plan_logo_icon">{title}</title>
      <path
        d="M6.3397 0.989949C6.88643 0.443215 7.77286 0.443215 8.3196 0.989949L12.2794 4.94975C12.8261 5.49648 12.8261 6.38291 12.2794 6.92965L7.32965 11.8794C6.78291 12.4261 5.89648 12.4261 5.34975 11.8794L1.38995 7.9196C0.843216 7.37286 0.843216 6.48643 1.38995 5.9397L6.3397 0.989949Z"
        fill="url(#paint0_linear_605_784)"
      />
      <path
        d="M19.5385 1.79097C20.0853 1.24424 20.9717 1.24424 21.5184 1.79097L25.4782 5.75077C26.025 6.29751 26.025 7.18394 25.4782 7.73067L14.3294 18.8795C13.7827 19.4262 12.8963 19.4262 12.3495 18.8795L8.38973 14.9197C7.843 14.3729 7.843 13.4865 8.38974 12.9398L19.5385 1.79097Z"
        fill="url(#paint1_linear_605_784)"
      />
      <path
        d="M32.8631 2.46822C33.4099 1.92149 34.2963 1.92149 34.843 2.46822L38.8028 6.42802C39.3496 6.97475 39.3496 7.86118 38.8028 8.40792L21.3314 25.8794C20.7846 26.4261 19.8982 26.4261 19.3515 25.8794L15.3917 21.9196C14.8449 21.3729 14.8449 20.4864 15.3917 19.9397L32.8631 2.46822Z"
        fill="url(#paint2_linear_605_784)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_605_784"
          x1="10.2995"
          y1="2.96985"
          x2="3.36985"
          y2="9.8995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_605_784"
          x1="23.4983"
          y1="3.77087"
          x2="10.3696"
          y2="16.8996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_605_784"
          x1="36.8229"
          y1="4.44812"
          x2="17.3716"
          y2="23.8995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
      </defs>
    </svg>
  );
}

LogoIconYellow.propTypes = {
  title: PropTypes.string,
};

LogoIconYellow.defaultProps = {
  title: 'TaxDown',
};

export default LogoIconYellow;
