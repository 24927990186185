/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'Components/Link';
import { Text, Heading, Button } from '@taxdown/piccolo';
import { useTranslation } from 'Hooks';
import { Container, Section, LinkButton } from 'Components/UI';
import { TEXT_GREY } from 'Styles/colors';
import ArrowIcon from 'Assets/icons/ArrowIcon';
import ReactMarkdown from 'react-markdown';
import { CAREERS_LIST_ROUTE } from 'Constants/routes';
import {
  JobBanner,
  JobBannerWrapper,
  JoinBannerText,
  CustomLink,
  Description,
  DescriptionText,
  ButtonWrapper,
} from './styles';

function MainSection({ currentJob }) {
  const { ID, Area, Title, DescriptionESP } = currentJob;
  const { t } = useTranslation('careers');

  return (
    <>
      <JobBanner>
        <Container>
          <CustomLink>
            <Link to={CAREERS_LIST_ROUTE}>
              <Text color={TEXT_GREY} bold>
                <ArrowIcon />
                {t('JobDescription.goBack')}
              </Text>
            </Link>
          </CustomLink>
          <JobBannerWrapper>
            <JoinBannerText>
              <Heading type="h2" mb={16}>
                {Title}
              </Heading>
              <Text color={TEXT_GREY} mb={10}>
                {Area}
              </Text>
            </JoinBannerText>
            <Link to={`${CAREERS_LIST_ROUTE}/${ID}/apply`}>
              <Button size="small">{t('JobDescription.applyTo')}</Button>
            </Link>
          </JobBannerWrapper>
        </Container>
      </JobBanner>
      <Section>
        <Container>
          <Description>
            <DescriptionText>
              <ReactMarkdown children={DescriptionESP} />
            </DescriptionText>
            <ButtonWrapper>
              <LinkButton link={`${CAREERS_LIST_ROUTE}/${ID}/apply`}>
                {t('JobDescription.applyTo')}
              </LinkButton>
            </ButtonWrapper>
          </Description>
        </Container>
      </Section>
    </>
  );
}

MainSection.propTypes = {
  currentJob: PropTypes.shape({
    ID: PropTypes.number,
    Area: PropTypes.string,
    Title: PropTypes.string,
    DescriptionESP: PropTypes.string,
  }),
};

MainSection.defaultProps = {
  currentJob: {},
};

export default MainSection;
