import React from 'react';
import withView from 'Hocs/withView';
import { Carousel, StartButton } from 'Components';
import { FirstSection, MainSection } from './components';

function Security() {
  return (
    <>
      <MainSection />
      <FirstSection />
      <StartButton />
      <Carousel />
    </>
  );
}

export default withView(Security);
