import React from 'react';
import PropTypes from 'prop-types';
import LottieView from 'react-lottie';
import { LottieContainer } from './styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Lottie = ({ className, options }) => (
  <LottieContainer className={className}>
    <LottieView
      options={{ ...defaultOptions, ...options }}
      isClickToPauseDisabled
      width="100%"
      height="100%"
    />
  </LottieContainer>
);

Lottie.propTypes = {
  options: PropTypes.shape().isRequired,
  className: PropTypes.string,
};

Lottie.defaultProps = {
  className: null,
};

export default Lottie;
