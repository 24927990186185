/* eslint-disable */
import { useEffect } from 'react';
import loadable from '@loadable/component';
import { SMART_BANNER_OPTIONS } from 'Constants/bannerOptions';
import { hasSmartBanner } from 'Utils/partners';
import 'smart-app-banner/dist/smart-app-banner.css';
import './styles.scss';

const SmartModule = loadable.lib(() => import('smart-app-banner'));

function SmartBannerComponent() {
  useEffect(() => {
    function initialize() {
      SmartModule.load().then(module => {
        const SmartBanner = module.default;
        new SmartBanner(SMART_BANNER_OPTIONS);
      });
    }
    if (hasSmartBanner()) {
      initialize();
    }
  }, []);

  return null;
}

export default SmartBannerComponent;
