import React from 'react';

function LinkedInIcon() {
  return (
    <svg width="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>LinkedIn</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="MAIN_man"
          transform="translate(-321.000000, -3059.000000)"
          fill="#5C5C5C"
          fillRule="nonzero"
        >
          <g id="footer" transform="translate(-2.000000, 2738.000000)">
            <g id="Group-11" transform="translate(163.000000, 286.000000)">
              <g id="Group-10" transform="translate(0.000000, 35.000000)">
                <g id="icons8-linkedin_filled" transform="translate(160.000000, 0.000000)">
                  <path
                    d="M20.2619048,0 L2.73809524,0 C1.22666667,0 0,1.22666667 0,2.73809524 L0,20.2619048 C0,21.7733333 1.22666667,23 2.73809524,23 L20.2619048,23 C21.7733333,23 23,21.7733333 23,20.2619048 L23,2.73809524 C23,1.22666667 21.7733333,0 20.2619048,0 Z M7.11904762,8.76190476 L7.11904762,19.1666667 L3.83333333,19.1666667 L3.83333333,8.76190476 L7.11904762,8.76190476 Z M3.83333333,5.73357143 C3.83333333,4.96690476 4.49047619,4.38095238 5.47619048,4.38095238 C6.46190476,4.38095238 7.08071429,4.96690476 7.11904762,5.73357143 C7.11904762,6.5002381 6.50571429,7.11904762 5.47619048,7.11904762 C4.49047619,7.11904762 3.83333333,6.5002381 3.83333333,5.73357143 Z M19.1666667,19.1666667 L15.8809524,19.1666667 C15.8809524,19.1666667 15.8809524,14.0957143 15.8809524,13.6904762 C15.8809524,12.5952381 15.3333333,11.5 13.9642857,11.4780952 L13.9204762,11.4780952 C12.5952381,11.4780952 12.047619,12.6061905 12.047619,13.6904762 C12.047619,14.1888095 12.047619,19.1666667 12.047619,19.1666667 L8.76190476,19.1666667 L8.76190476,8.76190476 L12.047619,8.76190476 L12.047619,10.1638095 C12.047619,10.1638095 13.1045238,8.76190476 15.2292857,8.76190476 C17.4033333,8.76190476 19.1666667,10.2569048 19.1666667,13.2852381 L19.1666667,19.1666667 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LinkedInIcon;
