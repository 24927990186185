import React from 'react';
import withView from 'Hocs/withView';
import {
  BannerSectionBlock,
  NumberStatsBlock,
  PricingPlansBlock,
  UserRatingsBlock,
  DeclarationBlock,
  PlansCompareBlock,
} from 'Blocks';
import { PLANS_COMPARE_ANCHOR } from 'Constants/routes';

const Pricing = () => {
  return (
    <>
      <BannerSectionBlock
        title="pricing:HeaderBanner.title"
        description="pricing:HeaderBanner.description"
        ctaText=""
        ctaLink=""
      />
      <PricingPlansBlock />
      <DeclarationBlock showIcons link={`#${PLANS_COMPARE_ANCHOR}`} />
      <UserRatingsBlock showLink={false} style={{ backgroundColor: 'var(--green5)' }} />
      <PlansCompareBlock />
      <NumberStatsBlock />
    </>
  );
};

export default withView(Pricing);
