import React, { useEffect } from 'react';
import { getLanguage } from 'Utils/language';

const TrustScore = () => {
  const ref = React.useRef(null);
  const currentLanguage = getLanguage();

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [currentLanguage]);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={currentLanguage}
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5cbd8e2d001a030001a82511"
      data-style-height="150px"
      data-theme="dark"
    />
  );
};
export default TrustScore;
