import React from 'react';
import { useTranslation } from 'Hooks';
import FEAT1 from './images/feature_1.png';
import FEAT2 from './images/feature_2.png';
import FEAT3 from './images/feature_3.png';
import FEAT4 from './images/feature_4.png';
import './styles.scss';

const features = [
  {
    title: 'HelpFeaturesBlock.title.1',
    description: 'HelpFeaturesBlock.description.1',
    icon: FEAT1,
  },
  {
    title: 'HelpFeaturesBlock.title.2',
    description: 'HelpFeaturesBlock.description.2',
    icon: FEAT2,
    link: 'https://help.taxdown.es/es/articles/4991164-en-que-consiste-la-revision-de-anos-anteriores',
  },
  {
    title: 'HelpFeaturesBlock.title.3',
    description: 'HelpFeaturesBlock.description.3',
    icon: FEAT3,
  },
  {
    title: 'HelpFeaturesBlock.title.4',
    description: 'HelpFeaturesBlock.description.4',
    icon: FEAT4,
  },
];

const HelpFeaturesBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="help-features-block block">
      <div className="block-container">
        <h2> {t('HelpFeaturesBlock.header')}</h2>
        <div className="help-features-block__items">
          {features.map(({ title, description, icon, link }) => (
            <div className="box" key={title}>
              <div className="icon">
                <img src={icon} alt="icon" />
              </div>
              <h4>{t(title)}</h4>
              <p>{t(description)}</p>
              {link && (
                <a className="know-more" href={link} target="_blank" rel="noreferrer">
                  + Saber más
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HelpFeaturesBlock;
