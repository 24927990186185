import React from 'react';

function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="116"
      height="85"
      fill="none"
      viewBox="0 0 116 85"
    >
      <title>Informado en todo momento</title>
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M108.693 1.697H68.796a5.44 5.44 0 0 0-5.44 5.44v18.135l-7.035 7.034c-.75.75-.219 2.033.843 2.033h6.192v1.814a5.44 5.44 0 0 0 5.44 5.44h39.897a5.44 5.44 0 0 0 5.44-5.44V7.137a5.44 5.44 0 0 0-5.44-5.44z"
        clipRule="evenodd"
      />
      <path
        fill="#00DC5A"
        d="M40.967 41.156v7.046c0 .742.452 1.409 1.14 1.684l5.648 2.26a3.627 3.627 0 0 1 2.28 3.367v5.592a1.813 1.813 0 0 1-1.814 1.813H22.833a1.814 1.814 0 0 1-1.814-1.813v-5.592c0-1.483.903-2.817 2.28-3.368l5.648-2.259a1.814 1.814 0 0 0 1.14-1.684v-7.046h10.88z"
      />
      <path
        fill="#00DC5A"
        d="M42.78 33.902c0-3.005-3.247-5.44-7.253-5.44-4.007 0-7.254 2.435-7.254 5.44v5.44a7.254 7.254 0 0 0 14.507 0v-5.44z"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M39.037 62.918c0 .937-.76 1.696-1.697 1.696H22.833a3.514 3.514 0 0 1-3.51-3.51v-5.592c0-2.19 1.313-4.13 3.346-4.943l5.648-2.259a.116.116 0 0 0 .073-.108v-3.466a8.904 8.904 0 0 1-1.814-5.394v-5.44c0-3.936 4.016-7.137 8.95-7.137 4.936 0 8.951 3.201 8.951 7.137v5.44a8.903 8.903 0 0 1-1.813 5.394v3.466c0 .048.029.09.073.108l5.648 2.26a5.298 5.298 0 0 1 3.346 4.943v5.591a3.514 3.514 0 0 1-3.51 3.51h-3.627a1.697 1.697 0 0 1 0-3.393h3.627a.117.117 0 0 0 .117-.117v-5.592c0-.794-.477-1.497-1.214-1.792l-5.647-2.259a3.493 3.493 0 0 1-2.206-3.259v-.732a8.893 8.893 0 0 1-3.744.823 8.896 8.896 0 0 1-3.744-.823v.732a3.494 3.494 0 0 1-2.206 3.26l-5.648 2.258a1.921 1.921 0 0 0-1.213 1.793v5.591c0 .065.052.117.116.117H37.34c.937 0 1.697.76 1.697 1.697zm-3.51-32.76c-3.013 0-5.557 1.715-5.557 3.744v5.44a5.564 5.564 0 0 0 5.557 5.558 5.564 5.564 0 0 0 5.557-5.558v-5.44c0-2.03-2.545-3.744-5.557-3.744z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M115.83 36.153c0 3.935-3.202 7.137-7.137 7.137h-.117v31.063h3.744a3.514 3.514 0 0 1 3.51 3.51v1.813A5.33 5.33 0 0 1 110.506 85H5.324A5.33 5.33 0 0 1 0 79.676v-1.813a3.514 3.514 0 0 1 3.51-3.51h3.744V14.39a7.145 7.145 0 0 1 7.137-7.137H61.66v-.117A7.145 7.145 0 0 1 68.796 0h39.897c3.935 0 7.137 3.202 7.137 7.137v29.016zm-7.138-32.76H68.796a3.748 3.748 0 0 0-3.744 3.744v18.135c0 .45-.178.882-.497 1.2l-6.17 6.17h4.97c.938 0 1.697.76 1.697 1.697v1.814a3.748 3.748 0 0 0 3.744 3.744h39.896a3.748 3.748 0 0 0 3.744-3.744V7.137a3.748 3.748 0 0 0-3.744-3.744zm3.744 74.47v1.813c0 1.065-.866 1.93-1.93 1.93H5.324a1.932 1.932 0 0 1-1.93-1.93v-1.813c0-.065.052-.117.116-.117h47.274l-.001.014a1.697 1.697 0 0 0 1.692 1.8h10.88a1.697 1.697 0 0 0 1.696-1.734v-.01l-.005-.07h47.274c.064 0 .116.053.116.117zM10.647 14.39v59.962h94.535V43.29H68.796a7.145 7.145 0 0 1-7.138-7.137v-.117h-7.37a1.697 1.697 0 0 1-1.2-2.896l8.57-8.57V10.646H14.391a3.748 3.748 0 0 0-3.744 3.744z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M74.85 19.217c.331.332.766.497 1.2.497.434 0 .868-.165 1.2-.497l7.253-7.253a1.697 1.697 0 1 0-2.4-2.4l-6.053 6.054-2.428-2.427a1.697 1.697 0 1 0-2.399 2.4l3.627 3.626zM90.558 16.087h14.508a1.696 1.696 0 0 0 0-3.393H90.558a1.697 1.697 0 0 0 0 3.393zM74.85 33.725c.331.331.766.497 1.2.497.434 0 .868-.166 1.2-.497l7.253-7.254a1.697 1.697 0 1 0-2.4-2.4l-6.053 6.055-2.428-2.427a1.697 1.697 0 1 0-2.399 2.4l3.627 3.626zM90.558 30.596h14.508a1.696 1.696 0 0 0 0-3.393H90.558a1.697 1.697 0 0 0 0 3.393zM84.21 63.865c1.19 0 2.154-.76 2.154-1.697S85.4 60.47 84.21 60.47H63.499c-1.19 0-2.154.76-2.154 1.697s.965 1.697 2.154 1.697H84.21zM61.345 54.913c0 .938.968 1.697 2.16 1.697H91.21c1.193 0 2.16-.76 2.16-1.697 0-.936-.967-1.696-2.16-1.696H63.505c-1.193 0-2.16.76-2.16 1.697z"
      />
    </svg>
  );
}

export default LockIcon;
