import React, { useState } from 'react';
import { AppearTransition } from 'Components';
import { useMediaQuery } from 'Hooks';
import ArrowBackIcon from 'Assets/icons/ArrowBackIcon';
import ArrowNextIcon from 'Assets/icons/ArrowNextIcon';
import MediaSnippet from './components/MediaSnippet';
import { CarrouselContainer, CarrouselBody, IconButton } from './styles';

const Carousel = () => {
  const [carrouselIndex, setCarrouselIndex] = useState(0);
  const { data } = useMediaQuery();
  const reduceCarrouselIndex = () => {
    const cIndex = !carrouselIndex ? data.length - 1 : carrouselIndex - 1;
    setCarrouselIndex(cIndex);
  };

  const increaseCarrouselIndex = () => {
    const cIndex = carrouselIndex === data.length - 1 ? 0 : carrouselIndex + 1;
    setCarrouselIndex(cIndex);
  };

  return (
    <CarrouselContainer>
      <IconButton onClick={reduceCarrouselIndex}>
        <ArrowBackIcon />
      </IconButton>
      <CarrouselBody>
        {data.map(
          (snippet, index) =>
            (index === carrouselIndex - 1 ||
              index === carrouselIndex ||
              index === carrouselIndex + 1) && (
              <AppearTransition
                key={snippet.Name}
                start={index === carrouselIndex}
                customStyles={{ height: '100%' }}
              >
                <MediaSnippet {...snippet} />
              </AppearTransition>
            )
        )}
      </CarrouselBody>
      <IconButton onClick={increaseCarrouselIndex}>
        <ArrowNextIcon />
      </IconButton>
    </CarrouselContainer>
  );
};

export default Carousel;
