import React, { useEffect } from 'react';
import { useTranslation, useTrustStats } from 'Hooks';
import { formatNumbers } from 'Utils/format';
import { loadAnimations } from './animations';
import './styles.scss';

const NumberStatsBlock = () => {
  const { t } = useTranslation();
  const { data } = useTrustStats();
  const { eurosSaved, taxReturn, failed } = data;

  useEffect(() => {
    const stArray = loadAnimations(data);
    return () => {
      stArray.forEach(st => st.kill());
    };
  });

  return (
    <section className="mod_numbers_data block">
      <div className="block-container">
        <h2>{t('GlobalData.numbers.title')}</h2>
        <div className="incenter data_columns">
          <div className="col">
            <div id="first_var_line" className="line" />
            <p id="first_var_num" className="num">
              {formatNumbers(eurosSaved)}
            </p>
            <div className="text">
              <p>{t('GlobalData.feature.1')}</p>
            </div>
          </div>
          <div className="col">
            <div id="second_var_line" className="line" />
            <p id="second_var_num" className="num">
              {formatNumbers(taxReturn)}
            </p>
            <div className="text">
              <p>{t('GlobalData.feature.2')}</p>
            </div>
          </div>
          <div className="col">
            <div id="third_var_line" className="line" />
            <p id="third_var_num" className="num">
              {formatNumbers(failed)}
            </p>
            <div className="text">
              <p>{t('GlobalData.feature.3')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NumberStatsBlock;
