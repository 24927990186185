import React from 'react';

const siteMetaData = {
  title: 'Tu declaración de la renta online, bien hecha | TaxDown',
  description:
    'Simula gratis, asegúrate el máximo ahorro, y revísala con la ayuda de expertos fiscales. Colaborador Social de la Agencia Tributaria',
  image: `https://taxdown.es/assets/social/og-image.png`,
  url: `https://taxdown.es`,
  twitterUsername: '@tax_down',
};

const SocialMetaTags = () => (
  <>
    <title>{siteMetaData.title}</title>
    {siteMetaData.description && <meta name="description" content={siteMetaData.description} />}
    {siteMetaData.url && <meta property="og:url" content={siteMetaData.url} />}
    {siteMetaData.title && <meta property="og:title" content={siteMetaData.title} />}
    {siteMetaData.description && (
      <meta property="og:description" content={siteMetaData.description} />
    )}
    {siteMetaData.image && <meta property="og:image" content={siteMetaData.image} />}
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    {siteMetaData.title && <meta property="og:site_name" content={siteMetaData.title} />}
    <meta name="twitter:card" content="summary_large_image" />
    {siteMetaData.twitterUsername && (
      <meta name="twitter:creator" content={siteMetaData.twitterUsername} />
    )}
    {siteMetaData.title && <meta name="twitter:title" content={siteMetaData.title} />}
    {siteMetaData.title && <meta name="twitter:site" content={siteMetaData.twitterUsername} />}
    {siteMetaData.description && (
      <meta name="twitter:description" content={siteMetaData.description} />
    )}
    {siteMetaData.image && <meta name="twitter:image" content={siteMetaData.image} />}
    <meta name="apple-itunes-app" content="app-id=1503998673" />
    <meta name="google-play-app" content="app-id=com.taxdown.bave.android" />
  </>
);

export default SocialMetaTags;
