import React from 'react';
import PropTypes from 'prop-types';
import { WEBAPP_APP_SIGNUP } from 'Constants/routes';
import { useTranslation } from 'Hooks';
import './styles.scss';

const BannerBlock = ({ title, description, ctaText, ctaLink, bgImage }) => {
  const { t } = useTranslation();
  return (
    <section className="mod_startnow" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="incenter">
        <h2 className="text">{t(title)}</h2>
        {description && <div className="description">{t(description)}</div>}

        <a href={ctaLink ?? WEBAPP_APP_SIGNUP} className="solid_btn green big">
          {t(ctaText)}
        </a>
      </div>
    </section>
  );
};

BannerBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  ctaLink: PropTypes.string,
};

BannerBlock.defaultProps = {
  description: null,
  ctaLink: null,
};

export default BannerBlock;
