import React from 'react';

export const formatNumbers = value => {
  const nf = Intl.NumberFormat();
  const formatted = nf.format(value);
  const parsed = formatted?.replace(/,/g, '.');
  return parsed;
};

export const replaceDashByBullet = content => {
  return content.replace(/- /g, '• ');
};

export const insertLinkIntoText = (text, link, url) => {
  const regexp = new RegExp(`(${link})`, 'ig');
  const newText = text.replace(regexp, `<a href=${url} class="link">${link}</a>`);
  // eslint-disable-next-line react/no-danger
  return <p dangerouslySetInnerHTML={{ __html: newText }} />;
};

export const insertStrongText = (text, word, className) => {
  const regexp = new RegExp(`(${word})`, 'ig');
  const newText = text.replace(regexp, `<strong class=${className}>${word}</strong>`);
  // eslint-disable-next-line react/no-danger
  return <p dangerouslySetInnerHTML={{ __html: newText }} />;
};

export const removeBacklash = text => text.replace(/\\/g, '');
