import React from 'react';

function CheckIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="10.4242" cy="10.5" rx="10.4242" ry="10.5" fill="#00DC5A" />
      <path d="M5.95654 10.5L9.92766 14.5L15.388 7.00003" stroke="white" strokeWidth="2" />
    </svg>
  );
}

export default CheckIcon;
