import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import withView from 'Hocs/withView';
import { useJobsQuery } from 'Hooks';
import BannerSmall from 'Components/BannerSmall';
import bannerOptions from 'Assets/json/careers-list/banner.json';
import { FirstSection } from './components';

function CareersList({ location }) {
  const { listing } = useJobsQuery();
  const { jobs, categories, fullRemoteOptions } = listing;
  const state = location?.state;
  const getCategory = useMemo(() => {
    if (!state) {
      return null;
    }
    const { category } = state;
    return category;
  }, [state]);
  return (
    <>
      <BannerSmall {...bannerOptions} />
      <FirstSection
        jobs={jobs}
        categories={categories}
        category={getCategory}
        fullRemoteOptions={fullRemoteOptions}
      />
    </>
  );
}

CareersList.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      category: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default withView(CareersList);
