import React from 'react';

function DottedLine() {
  return (
    <svg
      width="247"
      height="1427"
      viewBox="0 0 247 1427"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M245 2C253.965 211.035 -27.0762 218.477 43.2384 430.809C92.2219 578.726 161 607.668 161 736.372C161 941.105 -8.89835 968.715 3.00008 1122.5C14.8985 1276.29 145 1299.1 145 1425"
        stroke="#3090E9"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="1 10"
      />
    </svg>
  );
}

export default DottedLine;
