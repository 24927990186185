import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer, AppearTransition, CookiesManager } from 'Components';
import { Container } from './styles';

function withView(Component) {
  return class extends React.Component {
    render() {
      return (
        <>
          <Header />
          <Container>
            <AppearTransition start>
              <Component {...this.props} />
            </AppearTransition>
            <Footer />
            <CookiesManager />
          </Container>
        </>
      );
    }
  };
}

withView.propTypes = {
  Component: PropTypes.element,
};

export default withView;
