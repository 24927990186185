import React from 'react';
import PropTypes from 'prop-types';
import { TrustSlider } from 'Components';
import { useTranslation } from 'Hooks';
import './styles.scss';

const RatingsBlock = ({ style }) => {
  const { t } = useTranslation();
  return (
    <section className="ratings-partners-block block" style={style}>
      <div className="block-container">
        <h2> {t('RatingsBlock.title')}</h2>
        <TrustSlider />
      </div>
    </section>
  );
};

RatingsBlock.propTypes = {
  style: PropTypes.shape(),
};

RatingsBlock.defaultProps = {
  style: null,
};

export default RatingsBlock;
