import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useJobsQuery } from 'Hooks';
import withView from 'Hocs/withView';
import { MainSection } from './components';

function CareersDetail({ id }) {
  const { listing } = useJobsQuery();
  const { jobs } = listing;

  const currentJob = useMemo(() => {
    return jobs.find(({ ID: jobId }) => jobId === parseInt(id, 10));
  }, [id, jobs]);

  return (
    <>
      <MainSection currentJob={currentJob} />
    </>
  );
}

CareersDetail.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withView(CareersDetail);
