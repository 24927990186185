import React from 'react';

function CoffeeIcon() {
  return (
    <svg
      width="54px"
      height="68px"
      viewBox="0 0 54 68"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Café</title>
      <g id="trabaja-con-nosotros" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-535.000000, -556.000000)" fillRule="nonzero" id="coffee_icon">
          <g transform="translate(535.000000, 556.000000)">
            <polygon
              id="Path"
              fill="#FFC107"
              points="3 16.5 6.46643852 39.5 47.5335615 39.5 51 16.5"
            />
            <g id="Group" transform="translate(0.000000, 10.500000)">
              <path
                d="M54,7.35483871 L54,2.75806452 C54,1.2356129 52.7904,0 51.3,0 L2.7,0 C1.2096,0 0,1.2356129 0,2.75806452 L0,7.35483871 L54,7.35483871 Z"
                id="Path"
                fill="#795548"
              />
              <polygon
                id="Path"
                fill="#77574F"
                points="7.4574 33.0967742 10.8 57 43.2 57 46.5426 33.0967742"
              />
              <ellipse id="Oval" fill="#77574F" cx="28" cy="31.2580645" rx="9" ry="9.19354839" />
            </g>
            <g id="Group" transform="translate(3.000000, 0.500000)" fill="#4E342E">
              <path
                d="M16.6153846,41.36 C16.6153846,39.98384 17.0049231,38.71108 17.64,37.6 L3.16615385,37.6 L4.48430769,47 L19.1630769,47 C17.6141538,45.62196 16.6153846,43.61788 16.6153846,41.36 Z"
                id="Path"
              />
              <path
                d="M30.36,37.6 C30.9950769,38.71108 31.3846154,39.98384 31.3846154,41.36 C31.3846154,43.61788 30.3858462,45.62196 28.8369231,47 L43.5156923,47 L44.8338462,37.6 L30.36,37.6 Z"
                id="Path"
              />
              <polygon id="Path" points="46.1538462 0 1.84615385 0 0 9.4 48 9.4" />
            </g>
            <path
              d="M27,27.375 C33.7655,27.375 39.25,33.25125 39.25,40.5 C39.25,47.74875 33.7655,53.625 27,53.625 C20.2345,53.625 14.75,47.74875 14.75,40.5 C14.75,33.25125 20.2345,27.375 27,27.375 L27,27.375 Z M27,25.5 C19.28075,25.5 13,32.229375 13,40.5 C13,48.770625 19.28075,55.5 27,55.5 C34.71925,55.5 41,48.770625 41,40.5 C41,32.229375 34.71925,25.5 27,25.5 L27,25.5 Z"
              id="Shape"
              fill="#5D4037"
            />
            <path
              d="M27,26.5 C19.8202857,26.5 14,32.5441429 14,40 C14,47.4558571 19.8202857,53.5 27,53.5 C34.1797143,53.5 40,47.4558571 40,40 C40,32.5441429 34.1797143,26.5 27,26.5 Z M27,47.7142857 C22.8975714,47.7142857 19.5714286,44.2602143 19.5714286,40 C19.5714286,35.7397857 22.8975714,32.2857143 27,32.2857143 C31.1024286,32.2857143 34.4285714,35.7397857 34.4285714,40 C34.4285714,44.2602143 31.1024286,47.7142857 27,47.7142857 Z"
              id="Shape"
              fill="#FFF3E0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CoffeeIcon;
