import React from 'react';

function UserExperienceIcon() {
  return (
    <svg width="72px" viewBox="0 0 72 70" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Experiencia de usuario</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="MAIN_man" transform="translate(-286.000000, -1762.000000)" fillRule="nonzero">
          <g id="experoenmcia_icon" transform="translate(286.000000, 1762.000000)">
            <path
              d="M67.1989125,0 L4.80122813,0 C2.15395733,0 0,2.17299378 0,4.84334466 L0,44.7895185 C0,45.4576736 0.537610425,46 1.19995547,46 L70.8000445,46 C71.4623896,46 72,45.4576736 72,44.7895185 L72,4.84334466 C72.0001406,2.17299378 69.8461833,0 67.1989125,0 Z M69.6000891,43.579037 L2.40005156,43.579037 L2.40005156,4.84334466 C2.40005156,3.50689249 3.47766304,2.4211049 4.80122813,2.4211049 L67.1989125,2.4211049 C68.5224776,2.4211049 69.6000891,3.50689249 69.6000891,4.84334466 L69.6000891,43.579037 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M70.8000469,43 L1.19995312,43 C0.537609375,43 0,43.5376115 0,44.1999578 L0,50.1987625 C0,52.8460385 2.15395313,55 4.80121875,55 L67.1987812,55 C69.8460469,55 72,52.8460385 72,50.1987625 L72,44.1999578 C72,43.5376115 71.4623906,43 70.8000469,43 Z M69.5999531,50.1989031 C69.5999531,51.5237364 68.5223438,52.6000844 67.1987813,52.6000844 L4.80121875,52.6000844 C3.47765625,52.6000844 2.40004688,51.5237364 2.40004688,50.1989031 L2.40004688,45.4000563 L69.6000938,45.4000563 L69.6000938,50.1989031 L69.5999531,50.1989031 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M39.7500366,48 L32.2499634,48 C31.5600152,48 31,48.4480253 31,49 C31,49.5519747 31.5600152,50 32.2499634,50 L39.7500366,50 C40.4399848,50 41,49.5519747 41,49 C41,48.4480253 40.4399848,48 39.7500366,48 Z"
              id="Path"
              fill="#00DC5A"
            />
            <path
              d="M65.8075983,5 L6.19226201,5 C5.53416356,5 5,5.54024534 5,6.20583663 L5,44.7941634 C5,45.4597547 5.53416356,46 6.19226201,46 L65.807738,46 C66.4658364,46 67,45.4597547 67,44.7941634 L67,6.20583663 C67,5.54024534 66.4658364,5 65.8075983,5 Z M64.6153362,43.5881854 L7.38452403,43.5881854 L7.38452403,7.41167327 L64.6153362,7.41167327 L64.6153362,43.5881854 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M51.4373652,63 L20.5624957,63 C18.5983916,63 17,64.5703516 17,66.5 C17,68.4296484 18.5983916,70 20.5624957,70 L51.4375043,70 C53.4016084,70 55,68.4296484 55,66.5 C55,64.5703516 53.4014692,63 51.4373652,63 Z M51.4373652,67.6666211 L20.5624957,67.6666211 C19.9070521,67.6666211 19.3750435,67.1428516 19.3750435,66.5 C19.3750435,65.8571484 19.9070521,65.3333789 20.5624957,65.3333789 L51.4375043,65.3333789 C52.0929479,65.3333789 52.6249565,65.8571484 52.6249565,66.5 C52.6249565,67.1428516 52.0929479,67.6666211 51.4373652,67.6666211 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M49.791708,62.6000563 C45.7823817,62.6000563 43.7499646,59.7740121 43.7499646,54.1999719 C43.7499646,53.5376178 43.2086192,53 42.5416726,53 L30.4583274,53 C29.7913808,53 29.2500354,53.5376178 29.2500354,54.1999719 C29.2500354,59.7740121 27.2176183,62.6000563 23.208292,62.6000563 C22.5413454,62.6000563 22,63.137674 22,63.8000281 C22,64.4623822 22.5413454,65 23.208292,65 L49.791708,65 C50.4586546,65 51,64.4623822 51,63.8000281 C51,63.137674 50.4586546,62.6000563 49.791708,62.6000563 Z M29.0939897,62.6000563 C30.5815214,60.9632963 31.4574729,58.5259457 31.6339093,55.3999437 L41.3670819,55.3999437 C41.5422439,58.5259457 42.418337,60.9632963 43.9070015,62.6000563 L29.0939897,62.6000563 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M57.6411008,35.4173329 L54.6861313,32.4463623 L56.3300922,30.7934994 C56.6349279,30.4870131 56.7572925,30.0414901 56.6519987,29.6217841 C56.5467049,29.202078 56.2296758,28.8697747 55.8171076,28.7442956 L43.5759109,25.0520364 C43.1438332,24.9215093 42.6774705,25.0409308 42.3591503,25.3609746 C42.0396825,25.6810185 41.9221952,26.1510604 42.0518759,26.584324 L45.7242493,38.8918065 C45.847905,39.3077626 46.1795662,39.6265084 46.5970118,39.7323724 C47.0131663,39.8357845 47.4575808,39.715065 47.7624165,39.4087228 L49.4933093,37.6671592 L52.4482788,40.6394278 C52.6772283,40.8707709 52.9893801,41 53.3137252,41 C53.6380704,41 53.9502221,40.8707709 54.1791717,40.6394278 L57.6409574,37.1575985 C58.119657,36.676451 58.119657,35.8986247 57.6411008,35.4173329 Z M53.3137252,38.0290294 L50.623138,35.3225746 C50.3109862,35.0087325 49.9021478,34.8523884 49.4933093,34.8523884 C49.0831798,34.8523884 48.6743414,35.0098864 48.3634807,35.3212765 L47.4796724,36.2098706 L45.0497376,28.0659872 L53.1497595,30.5090801 L52.2022585,31.4617118 C51.9414626,31.7250737 51.7982974,32.0746845 51.7982974,32.4463623 C51.7982974,32.8193382 51.9427537,33.1712567 52.2046972,33.4321667 L55.0446181,36.2874657 L53.3137252,38.0290294 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M50.8000422,10 L17.1999578,10 C16.5376115,10 16,10.5120118 16,11.1428189 L16,16.8571811 C16,17.4879882 16.5376115,18 17.1999578,18 L50.8000422,18 C51.4623885,18 52,17.4879882 52,16.8571811 L52,11.1428189 C52,10.5120118 51.4623885,10 50.8000422,10 Z M49.6000844,15.7143622 L18.3999156,15.7143622 L18.3999156,12.2857717 L49.6000844,12.2857717 L49.6000844,15.7143622 L49.6000844,15.7143622 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M30.8000422,23 L21.1999578,23 C20.5376115,23 20,23.522686 20,24.1666439 L20,28.8333561 C20,29.477314 20.5376115,30 21.1999578,30 L30.8000422,30 C31.4623885,30 32,29.477314 32,28.8333561 L32,24.1666439 C32,23.522686 31.4623885,23 30.8000422,23 Z M29.5999437,27.6667122 L22.3999156,27.6667122 L22.3999156,25.3332878 L29.5999437,25.3332878 L29.5999437,27.6667122 L29.5999437,27.6667122 Z"
              id="Shape"
              fill="#00DC5A"
            />
            <path
              d="M50.7999062,10 L17.1999531,10 C16.5376094,10 16,10.5333456 16,11.1904363 L16,33.8095637 C16,34.4666544 16.5376094,35 17.1999531,35 L45.7672187,35 C46.1476094,35 46.5039531,34.8226832 46.7296562,34.5202261 C46.9564844,34.2179086 47.0260937,33.8274209 46.9168281,33.4666488 L45.3976562,28.4416487 L50.4556562,29.947656 C50.8193125,30.056055 51.2129219,29.9869977 51.5163906,29.7619685 C51.821125,29.538195 52,29.1834218 52,28.8071641 L52,11.1904363 C51.9998594,10.5333456 51.46225,10 50.7999062,10 Z M49.5999531,27.2072668 L43.9514687,25.5263143 C43.5279062,25.400058 43.0707344,25.5155721 42.7586875,25.8251441 C42.4455156,26.1358322 42.3303437,26.5893783 42.4574687,27.009721 L44.15425,32.6192668 L18.3999063,32.6192668 L18.3999063,12.3810122 L49.5999531,12.3810122 L49.5999531,27.2072668 L49.5999531,27.2072668 Z"
              id="Shape"
              fill="#5C5C5C"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default UserExperienceIcon;
