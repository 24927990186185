import React from 'react';
import withView from 'Hocs/withView';
import {
  PartnersHowHelpBlock,
  SuccessfulCasesBlock,
  WhoArePartnerBlock,
  WhyUseTaxDownBlock,
  TechnologyThatScalesBlock,
  PartnersHeaderBlock,
  PartnerFooterBlock,
} from 'Blocks';

const PartnersDeal = () => {
  return (
    <>
      <PartnersHeaderBlock />
      <PartnersHowHelpBlock />
      <SuccessfulCasesBlock />
      <WhoArePartnerBlock />
      <WhyUseTaxDownBlock />
      <TechnologyThatScalesBlock />
      <PartnerFooterBlock />
    </>
  );
};

export default withView(PartnersDeal);
