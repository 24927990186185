import React from 'react';

function FacebookIcon() {
  return (
    <svg width="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Facebook</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="MAIN_man"
          transform="translate(-202.000000, -3059.000000)"
          fill="#5C5C5C"
          fillRule="nonzero"
        >
          <g id="footer" transform="translate(-2.000000, 2738.000000)">
            <g id="Group-11" transform="translate(163.000000, 286.000000)">
              <g id="Group-10" transform="translate(0.000000, 35.000000)">
                <g id="icons8-facebook" transform="translate(41.000000, 0.000000)">
                  <path
                    d="M18.6875,0 L4.3125,0 C1.93164085,0 0,1.93164085 0,4.3125 L0,18.6875 C0,21.0683592 1.93164085,23 4.3125,23 L18.6875,23 C21.0683592,23 23,21.0683592 23,18.6875 L23,4.3125 C23,1.93164085 21.0683592,0 18.6875,0 Z M18.1035158,12.3880707 L15.9507212,12.3880707 L15.9507212,20.3530653 L12.3569712,20.3530653 L12.3569712,12.3880707 L10.9609375,12.3880707 L10.9609375,9.703125 L12.3569712,9.703125 L12.3569712,7.992638 C12.3569712,5.75345531 13.2865082,4.42307692 15.9299884,4.42307692 L18.6045673,4.42307692 L18.6045673,7.09765669 L17.0495798,7.09765669 C16.023287,7.09765669 15.9541765,7.47776442 15.9541765,8.19651442 L15.9507212,9.703125 L18.3972356,9.703125 L18.1035158,12.3880707 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FacebookIcon;
