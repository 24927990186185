import ProtectedSessionIcon from 'Assets/icons/ProtectedSessionIcon';
import ProtectedInfoIcon from 'Assets/icons/ProtectedInfoIcon';
import CorrectUseInfoIcon from 'Assets/icons/CorrectUseInfoIcon';
import InformIcon from 'Assets/icons/InformIcon';
import DedicatedTeam from 'Assets/icons/DedicatedTeam';

export const SECURITY_OPTION_LIST = [
  {
    Icon: ProtectedInfoIcon,
    title: 'Security.FirstSection.firstOption.title',
    options: [
      'Security.FirstSection.firstOption.firstFeature',
      'Security.FirstSection.firstOption.secondFeature',
      'Security.FirstSection.firstOption.thirdFeature',
      'Security.FirstSection.firstOption.fourthFeature',
    ],
  },
  {
    Icon: CorrectUseInfoIcon,
    title: 'Security.FirstSection.secondOption.title',
    withNo: true,
    options: [
      'Security.FirstSection.secondOption.firstFeature',
      'Security.FirstSection.secondOption.secondFeature',
      'Security.FirstSection.secondOption.thirdFeature',
    ],
  },
  {
    Icon: InformIcon,
    imageUrl: '/static/images/real_time.png',
    title: 'Security.FirstSection.thirdOption.title',
    options: [
      'Security.FirstSection.thirdOption.firstFeature',
      'Security.FirstSection.thirdOption.secondFeature',
    ],
  },
  {
    Icon: DedicatedTeam,
    imageUrl: '/static/images/security_logo.png',
    title: 'Security.FirstSection.fourthOption.title',
    options: [
      'Security.FirstSection.fourthOption.firstFeature',
      'Security.FirstSection.fourthOption.secondFeature',
    ],
  },
  {
    Icon: ProtectedSessionIcon,
    title: 'Security.FirstSection.fifthOption.title',
    options: [
      'Security.FirstSection.fifthOption.firstFeature',
      'Security.FirstSection.fifthOption.secondFeature',
    ],
  },
];
