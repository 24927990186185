import { useEffect, useState } from 'react';
import { setTrack } from 'Utils/analytics';

export function useAnalytics(pathname, data) {
  const [pastPathname, setPastPathname] = useState(null);
  return useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== 'undefined' && window.analytics) {
        if (pathname !== pastPathname) {
          setTrack(pathname, data);
          setPastPathname(pathname);
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [pathname]);
}
