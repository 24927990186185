import React from 'react';
import { Text, Heading } from '@taxdown/piccolo';
import MainBanner from 'Components/MainBanner';
import { useTranslation } from 'Hooks';
import { Trans } from 'Components';
import { TextWrapper, TitleWrapper, TextImportant } from './styles';

const BANNER_IMAGE = '/images/security/main-banner.jpg';

function MainSection() {
  const { t } = useTranslation('security');
  return (
    <MainBanner backgroundImage={BANNER_IMAGE}>
      <TitleWrapper>
        <Heading type="h1" color="white">
          <Trans
            i18nKey="Security.MainBanner.title"
            ns="security"
            components={{ bold: <TextImportant /> }}
          />
        </Heading>
      </TitleWrapper>
      <TextWrapper>
        <Text color="white">{t('Security.MainBanner.description')}</Text>
      </TextWrapper>
    </MainBanner>
  );
}

export default MainSection;
