import React from 'react';
import PropTypes from 'prop-types';

function CloseIcon({ width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#242B36"
        d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z"
      />
    </svg>
  );
}

CloseIcon.propTypes = {
  width: PropTypes.number,
};

CloseIcon.defaultProps = {
  width: 14,
};

export default CloseIcon;
