/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { useTranslation } from 'Hooks';
import './styles.scss';

const LOGOS = [
  'cobee.png',
  'jobandtalent.png',
  'abanca.png',
  'coca-cola.png',
  'nova.png',
  'aon.png',
  'evo.png',
  'pleo.png',
  'arbor.png',
  'fintonic.png',
  'bnext.png',
  'goin.png',
  'telefonica.png',
  'carrefour.png',
  'indra.png',
  'unisono.png',
];

const CONTACT_LINK = 'https://airtable.com/shrbi9cjktVdgzRrK';

const PartnersBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="mod_collab">
      <div className="incenter section_inner">
        <h2 className="section_title">{t('PartnersMedia.title')}</h2>
        <div className="collab_wrapper">
          {LOGOS.map(logo => (
            <div
              key={logo}
              className="collab_logo"
              style={{ backgroundImage: `url(/images/partners/${logo})` }}
            />
          ))}
        </div>
        <a href={CONTACT_LINK} target="_blank" className="solid_btn blue big" rel="noreferrer">
          {t('Contact.title')}
        </a>
      </div>
    </section>
  );
};

export default PartnersBlock;
