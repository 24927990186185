import React from 'react';

function ArrowIcon() {
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 13.5L7.5 7.5" stroke="#00DC5A" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1.5 1.5L7.5 7.5" stroke="#00DC5A" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}

export default ArrowIcon;
