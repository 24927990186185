// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aviso-legal-index-jsx": () => import("./../../../src/pages/aviso-legal/index.jsx" /* webpackChunkName: "component---src-pages-aviso-legal-index-jsx" */),
  "component---src-pages-careers-apply-index-jsx": () => import("./../../../src/pages/careers-apply/index.jsx" /* webpackChunkName: "component---src-pages-careers-apply-index-jsx" */),
  "component---src-pages-careers-detail-index-jsx": () => import("./../../../src/pages/careers-detail/index.jsx" /* webpackChunkName: "component---src-pages-careers-detail-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-careers-list-index-jsx": () => import("./../../../src/pages/careers-list/index.jsx" /* webpackChunkName: "component---src-pages-careers-list-index-jsx" */),
  "component---src-pages-como-funciona-index-jsx": () => import("./../../../src/pages/como-funciona/index.jsx" /* webpackChunkName: "component---src-pages-como-funciona-index-jsx" */),
  "component---src-pages-cookies-index-jsx": () => import("./../../../src/pages/cookies/index.jsx" /* webpackChunkName: "component---src-pages-cookies-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-precio-index-jsx": () => import("./../../../src/pages/precio/index.jsx" /* webpackChunkName: "component---src-pages-precio-index-jsx" */),
  "component---src-pages-privacidad-index-jsx": () => import("./../../../src/pages/privacidad/index.jsx" /* webpackChunkName: "component---src-pages-privacidad-index-jsx" */),
  "component---src-pages-quienes-somos-index-jsx": () => import("./../../../src/pages/quienes-somos/index.jsx" /* webpackChunkName: "component---src-pages-quienes-somos-index-jsx" */),
  "component---src-pages-seguridad-index-jsx": () => import("./../../../src/pages/seguridad/index.jsx" /* webpackChunkName: "component---src-pages-seguridad-index-jsx" */),
  "component---src-pages-terminos-condiciones-index-jsx": () => import("./../../../src/pages/terminos-condiciones/index.jsx" /* webpackChunkName: "component---src-pages-terminos-condiciones-index-jsx" */)
}

