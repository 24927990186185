import React from 'react';
import { useTranslation } from 'Hooks';
import DiffOne from './images/DiffOne';
import DiffTwo from './images/DiffTwo';
import DiffThree from './images/DiffThree';
import DiffFour from './images/DiffFour';
import './styles.scss';

const differences = [
  {
    title: 'DifferencesBlock.feature.1.title',
    description: 'DifferencesBlock.feature.1.description',
    DiffImage: DiffOne,
    alt: 'DifferencesBlock.feature.1.alt',
  },
  {
    title: 'DifferencesBlock.feature.2.title',
    description: 'DifferencesBlock.feature.2.description',
    DiffImage: DiffTwo,
    alt: 'DifferencesBlock.feature.2.alt',
  },
  {
    title: 'DifferencesBlock.feature.3.title',
    description: 'DifferencesBlock.feature.3.description',
    DiffImage: DiffThree,
    alt: 'DifferencesBlock.feature.3.alt',
  },
  {
    title: 'DifferencesBlock.feature.4.title',
    description: 'DifferencesBlock.feature.4.description',
    DiffImage: DiffFour,
    alt: 'DifferencesBlock.feature.4.alt',
  },
];

const DifferencesBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="differences-block block">
      <div className="block-container">
        <h2 className="section_title big">{t('DifferencesBlock.title')}</h2>
        <div className="differences-block__content">
          {differences.map(({ title, description, DiffImage, alt }) => (
            <div className="difference" key={title}>
              <div className="icon">
                <DiffImage title={t(alt)} />
              </div>
              <h3>{t(title)}</h3>
              <p>{t(description)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DifferencesBlock;
