import React from 'react';

function MaxSavingsIcon() {
  return (
    <svg
      width="184px"
      height="180px"
      viewBox="0 0 184 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Máximo ahorro</title>
      <defs>
        <polygon id="path-1" points="0 0 184 0 184 126 0 126" />
      </defs>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="MAIN_man" transform="translate(-1014.000000, -944.000000)">
          <g id="conseguimos_ilus" transform="translate(1014.000000, 944.000000)">
            <g id="Group-14">
              <g id="Group-3">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M8.16147715,0 L175.838894,0 C180.340141,0 184,3.74351841 184,8.29891143 L184,117.701842 C184,122.256859 180.340141,126 175.838894,126 L8.16147715,126 C3.65985855,126 0,122.256859 0,117.701842 L0,8.29891143 C0,3.74351841 3.65985855,0 8.16147715,0"
                  id="Fill-1"
                  fill="#4D4D4D"
                  mask="url(#mask-2)"
                />
              </g>
              <path
                d="M10.6668814,6 L173.332749,6 C176.459687,6 179,8.56107608 179,11.7148626 L179,115.284391 C179,118.438551 176.459687,121 173.332749,121 L10.6668814,121 C7.53957362,121 5,118.438551 5,115.284391 L5,11.7148626 C5,8.56107608 7.53957362,6 10.6668814,6"
                id="Fill-4"
                fill="#D8F5FA"
              />
              <path
                d="M10.6668814,5 L173.332749,5 C176.459687,5 179,7.5521053 179,10.6948449 L179,66 L5,66 L5,10.6948449 C5,7.5521053 7.53957362,5 10.6668814,5"
                id="Fill-6"
                fill="#B9E8F0"
              />
              <path
                d="M26.7169962,77.7771679 C27.1384866,77.0662048 27.8996137,76.6709766 28.6929638,76.6709766 L28.6929638,76.6545554 L75.3124661,76.6545554 L104.907165,38.8794674 C105.668662,37.9001672 107.110174,37.710577 108.098343,38.4528896 C109.102068,39.1955754 109.296887,40.6018266 108.53576,41.5654521 L78.3414189,80.1153218 C77.9206693,80.7150685 77.2239879,81.1102968 76.4295267,81.1102968 L30.0052137,81.1102968 L26.7169962,77.7771679 Z"
                id="Fill-8"
                fill="#5AC75A"
              />
              <path
                d="M31.0007343,65 C38.1878336,65 44,70.5961044 44,77.4992694 C44,84.4024344 38.1878336,90 31.0007343,90 C23.8129007,90 18,84.4024344 18,77.4992694 C18,70.5961044 23.8129007,65 31.0007343,65 M31.0007343,67.1761208 C36.9380948,67.1761208 41.7296168,71.7986878 41.7296168,77.4992694 C41.7296168,83.2002163 36.9380948,87.8231486 31.0007343,87.8231486 C25.0626394,87.8231486 20.2501906,83.2002163 20.2501906,77.4992694 C20.2501906,71.7986878 25.0626394,67.1761208 31.0007343,67.1761208 M31.0007343,72.2344965 C34.0215482,72.2344965 36.4589794,74.597288 36.4589794,77.4992694 C36.4589794,80.4016161 34.0215482,82.7658688 31.0007343,82.7658688 C27.9795532,82.7658688 25.5421221,80.4016161 25.5421221,77.4992694 C25.5421221,74.597288 27.9795532,72.2344965 31.0007343,72.2344965"
                id="Fill-10"
                fill="#EF5160"
              />
              <path
                d="M107.489196,17 C119.378316,17 129,26.4078401 129,38.000185 C129,49.5917898 119.378316,59 107.489196,59 C95.6231485,59 86,49.5917898 86,38.000185 C86,26.4078401 95.6231485,17 107.489196,17 M107.489196,20.6544104 C117.299497,20.6544104 125.258784,28.4239645 125.258784,38.000185 C125.258784,47.5760355 117.299497,55.3459597 107.489196,55.3459597 C97.7012359,55.3459597 89.7415827,47.5760355 89.7415827,38.000185 C89.7415827,28.4239645 97.7012359,20.6544104 107.489196,20.6544104 M107.489196,29.1803812 C112.477851,29.1803812 116.530743,33.1282546 116.530743,38.000185 C116.530743,42.8717454 112.477851,46.8199889 107.489196,46.8199889 C102.502006,46.8199889 98.4703553,42.8717454 98.4703553,38.000185 C98.4703553,33.1282546 102.502006,29.1803812 107.489196,29.1803812"
                id="Fill-12"
                fill="#FFCF00"
              />
            </g>
            <g
              id="Group-16"
              transform="translate(140.083261, 91.376154) rotate(-45.000000) translate(-140.083261, -91.376154) translate(99.583261, 64.876154)"
            >
              <path
                d="M38.8308853,51.6188891 L30.3136115,43.1023415 C29.2646787,42.0534048 29.2646787,40.3534301 30.3136115,39.3044934 L67.4256729,2.19229412 C68.4746057,1.14335741 70.1745741,1.14335741 71.2227491,2.19229412 L79.7407808,10.7095996 C80.7889557,11.7577784 80.7889557,13.4577531 79.7407808,14.5066898 L42.6279615,51.6188891 C41.5790287,52.6678258 39.8790602,52.6678258 38.8308853,51.6188891"
                id="Fill-15"
                fill="#00DC5A"
              />
              <path
                d="M23.9813461,36.8331025 L15.4009162,28.2526726 C14.3418302,27.1935866 14.3418302,25.4765837 15.4009162,24.4167336 L38.3966825,1.42173145 C39.4557684,0.362645515 41.1727714,0.362645515 42.2318573,1.42173145 L50.8122873,10.0021614 C51.8713732,11.0612474 51.8713732,12.7782503 50.8122873,13.8373362 L27.8172851,36.8331025 C26.757435,37.8921885 25.0404321,37.8921885 23.9813461,36.8331025"
                id="Fill-17"
                fill="#5C5C5C"
              />
              <path
                d="M9.32328475,22.2844683 L0.958172368,13.9193559 C-0.0710021212,12.8901814 -0.0710021212,11.2217728 0.958172368,10.1933424 L9.32328475,1.82823005 C10.3524592,0.799055564 12.0201238,0.799055564 13.0492982,1.82823005 L21.4144106,10.1933424 C22.4435851,11.2217728 22.4435851,12.8901814 21.4144106,13.9193559 L13.0492982,22.2844683 C12.0201238,23.3136428 10.3524592,23.3136428 9.32328475,22.2844683"
                id="Fill-19"
                fill="#5C5C5C"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MaxSavingsIcon;
