import React from 'react';
import withView from 'Hocs/withView';
import { MainSection, FirstSection } from './components';

const Media = () => {
  return (
    <>
      <MainSection />
      <FirstSection />
    </>
  );
};

export default withView(Media);
