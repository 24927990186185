import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH } from 'Styles/sizes';

export const FeatureWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${({ alignLeft }) =>
    alignLeft &&
    `
    flex-direction: row-reverse;
    text-align: left;
  `}
  margin-bottom: ${pxToRem(98)};
  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
    margin-bottom: ${pxToRem(40)};
  }
`;
export const FeatureText = styled.div`
  width: 100%;
  text-align: right;
  max-width: ${pxToRem(545)};
  flex-basis: 50%;
  h2 {
    font-size: ${pxToRem(32)};
  }
  ${({ alignLeft }) =>
    alignLeft &&
    `
    text-align: left;
  `};
  @media (max-width: ${MOBILE_WIDTH}) {
    width: 100%;
    text-align: left;
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: ${pxToRem(32)};
  }
`;
export const FeatureImage = styled.div`
  width: 100%;
  max-width: ${pxToRem(178)};
  flex-basis: 50%;

  img {
    width: 100%;
    height: auto;
  }
`;
