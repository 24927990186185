import React from 'react';
import { Text } from '@taxdown/piccolo';
import { useTranslation } from 'Hooks';
import { Section, Container } from 'Components/UI';
import { PerkBlock, PerkWrapper } from './styles';
import { perkList } from './data';

function SecondSection() {
  const { t } = useTranslation('careers');
  return (
    <Section backgroundColor="#f5f5f5">
      <Container>
        <PerkWrapper>
          {perkList.map(({ Icon, description }) => (
            <PerkBlock key={description}>
              <Icon />
              <Text>{t(description)}</Text>
            </PerkBlock>
          ))}
        </PerkWrapper>
      </Container>
    </Section>
  );
}

export default SecondSection;
