import styled from 'styled-components';
import { MOBILE_WIDTH, TABLET_WIDTH } from 'Styles/sizes';
import { pxToRem } from 'Styles/utils';

export const FirstSectionContainer = styled.div`
  text-align: center;

  h2 {
    font-size: ${pxToRem(32)};
    margin: 2rem;
  }
`;

export const OptionListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: ${TABLET_WIDTH}) {
    padding: 0;
    justify-content: space-around;
  }
`;

export const OptionContainer = styled.div`
  flex-basis: 30%;

  @media screen and (max-width: ${TABLET_WIDTH}) {
    flex-basis: 45%;
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    flex-basis: 100%;
  }
`;

export const ImageContainer = styled.div`
  height: 160px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  ${({ withTranslate }) => withTranslate && `transform: translateY(0.5rem)`};
`;

export const TextContainer = styled.div`
  padding-left: 2rem;
  text-align: left;

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    padding-left: 0;
  }
`;

export const CheckedOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToRem(24)};
  p {
    flex-basis: 95%;
    padding-left: 1rem;
  }
`;
