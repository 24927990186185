import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Airtable from 'airtable';
import View from './view';
import './styles.scss';

const LeadsHomepageId = 'tblcbE2Owmulp1QdI';
const EmailFieldId = 'fldkQQCTqBbsCfikA';
const CompanyFieldId = 'fldp5oklyrGYG3uEn';
const NameFieldId = 'fldZbXm1lm6PbesQm';
const PhoneFieldId = 'fldoy6tsPTn5QXSUT';
const EmployerFieldId = 'fld8SWRsOJ3LSym9v';

const PartnerContactForm = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          partnersTableId
          partnersTableKey
        }
      }
    }
  `);

  const [submitted, setSubmitted] = useState(false);

  const writePartner = async (partner, apiKey, baseId) => {
    const base = new Airtable({ apiKey }).base(baseId);
    try {
      await base(LeadsHomepageId).create([
        {
          fields: {
            [EmailFieldId]: partner.email,
            [CompanyFieldId]: partner.company,
            [NameFieldId]: partner.name,
            [PhoneFieldId]: partner.phone,
            [EmployerFieldId]: partner.employers,
          },
        },
      ]);
      setSubmitted(true);
    } catch (error) {
      // console.error('Cannot create the partner');
      setSubmitted(false);
    }
  };

  const onSubmit = async partner => {
    await writePartner(
      partner,
      data.site.siteMetadata.partnersTableKey,
      data.site.siteMetadata.partnersTableId
    );
  };

  return <View onSubmit={onSubmit} submitted={submitted} />;
};

export default PartnerContactForm;
