import React from 'react';
import { Heading } from '@taxdown/piccolo';
import { Container } from 'Components/UI';
import { useTranslation } from 'Hooks';
import { FourthSectionWrapper, TextWrapper } from './styles';

const BANNER_IMAGE = '/images/careers/banner-image.jpg';

function FourthSection() {
  const { t } = useTranslation('careers');
  return (
    <>
      <TextWrapper>
        <Container>
          <Heading type="h3" color="primaryGreen" bold>
            {t('Careers.FourthSection.textButton')}
          </Heading>
        </Container>
      </TextWrapper>
      <FourthSectionWrapper backgroundImage={BANNER_IMAGE} />
    </>
  );
}

export default FourthSection;
