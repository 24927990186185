import React from 'react';

function FullIcon() {
  const random = Math.floor(Math.random() * 1000000);
  const id = `full_icon_defs${random}`;
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="20"
        width="28.2843"
        height="28.2843"
        rx="3"
        transform="rotate(45 20 0)"
        fill={`url(#${id})`}
      />
      <path
        d="M13.4582 15.9299C13.7208 15.6674 14.1464 15.6674 14.409 15.9299L16.3104 17.8314C16.5729 18.0939 16.5729 18.5196 16.3104 18.7821L13.9336 21.1589C13.6711 21.4214 13.2454 21.4214 12.9829 21.1589L11.0815 19.2574C10.8189 18.9949 10.8189 18.5693 11.0815 18.3067L13.4582 15.9299Z"
        fill="#2F2E46"
      />
      <path
        d="M19.7955 16.3147C20.058 16.0522 20.4837 16.0522 20.7462 16.3147L22.6477 18.2161C22.9102 18.4787 22.9102 18.9043 22.6477 19.1669L17.2942 24.5203C17.0317 24.7829 16.606 24.7829 16.3435 24.5203L14.4421 22.6189C14.1795 22.3564 14.1795 21.9307 14.4421 21.6682L19.7955 16.3147Z"
        fill="#2F2E46"
      />
      <path
        d="M26.194 16.6397C26.4565 16.3771 26.8821 16.3771 27.1447 16.6397L29.0461 18.5411C29.3086 18.8036 29.3086 19.2293 29.0461 19.4918L20.6566 27.8813C20.3941 28.1438 19.9684 28.1438 19.7059 27.8813L17.8044 25.9799C17.5419 25.7174 17.5419 25.2917 17.8044 25.0292L26.194 16.6397Z"
        fill="#2F2E46"
      />
      <path
        d="M25.7546 28.3801L23.5781 26.2036L25.2037 24.5779L25.6068 24.981L24.4514 26.1364L24.8947 26.5798L25.8822 25.5923L26.2819 25.992L25.2944 26.9795L26.2248 27.9098L25.7546 28.3801ZM29.1387 24.566C29.1253 24.9108 28.9876 25.2142 28.7256 25.4762C28.4636 25.7382 28.1602 25.8781 27.8154 25.8961C27.4705 25.9095 27.166 25.7841 26.9018 25.5199L25.5818 24.1999L26.0453 23.7364L27.3451 25.0362C27.4862 25.1773 27.6474 25.2489 27.8288 25.2512C28.0102 25.2489 28.1669 25.1818 28.299 25.0497C28.4311 24.9175 28.4972 24.7619 28.4972 24.5828C28.495 24.4014 28.4233 24.2402 28.2822 24.0991L26.9824 22.7993L27.4493 22.3324L28.7693 23.6524C29.0312 23.9144 29.1544 24.2189 29.1387 24.566ZM30.2031 23.9316L28.0266 21.7551L28.4968 21.2849L30.2702 23.0583L31.5264 21.8021L31.9295 22.2052L30.2031 23.9316ZM32.4076 21.727L30.2312 19.5505L30.7014 19.0803L32.4748 20.8537L33.731 19.5976L34.134 20.0006L32.4076 21.727Z"
        fill="#2F2E46"
      />
      <defs>
        <linearGradient
          id={id}
          x1="34.1421"
          y1="0"
          x2="34.1421"
          y2="28.2843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FullIcon;
