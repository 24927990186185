import React from 'react';

function InstagramIcon() {
  return (
    <svg width="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Instagram</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="MAIN_man"
          transform="translate(-242.000000, -3059.000000)"
          fill="#5C5C5C"
          fillRule="nonzero"
        >
          <g id="footer" transform="translate(-2.000000, 2738.000000)">
            <g id="Group-11" transform="translate(163.000000, 286.000000)">
              <g id="Group-10" transform="translate(0.000000, 35.000000)">
                <g id="icons8-instagram_new" transform="translate(81.000000, 0.000000)">
                  <path
                    d="M6.38888889,0 C2.86094444,0 0,2.86094444 0,6.38888889 L0,16.6111111 C0,20.1390556 2.86094444,23 6.38888889,23 L16.6111111,23 C20.1390556,23 23,20.1390556 23,16.6111111 L23,6.38888889 C23,2.86094444 20.1390556,0 16.6111111,0 L6.38888889,0 Z M19.1666667,2.55555556 C19.872,2.55555556 20.4444444,3.128 20.4444444,3.83333333 C20.4444444,4.53866667 19.872,5.11111111 19.1666667,5.11111111 C18.4613333,5.11111111 17.8888889,4.53866667 17.8888889,3.83333333 C17.8888889,3.128 18.4613333,2.55555556 19.1666667,2.55555556 Z M11.5,5.11111111 C15.0279444,5.11111111 17.8888889,7.97205556 17.8888889,11.5 C17.8888889,15.0279444 15.0279444,17.8888889 11.5,17.8888889 C7.97205556,17.8888889 5.11111111,15.0279444 5.11111111,11.5 C5.11111111,7.97205556 7.97205556,5.11111111 11.5,5.11111111 Z M11.5,7.66666667 C9.38290846,7.66666667 7.66666667,9.38290846 7.66666667,11.5 C7.66666667,13.6170915 9.38290846,15.3333333 11.5,15.3333333 C13.6170915,15.3333333 15.3333333,13.6170915 15.3333333,11.5 C15.3333333,9.38290846 13.6170915,7.66666667 11.5,7.66666667 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InstagramIcon;
