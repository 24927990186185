import React from 'react';

function LikedInIconColor() {
  return (
    <svg width="30" height="30" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1398_1996)">
        <path
          d="M25.0025 0H1.99155C0.892946 0 0 0.871717 0 1.9471V25.0529C0 26.1276 0.892946 27.0007 1.99155 27.0007H25.0025C26.1031 27.0007 27 26.1276 27 25.0522V1.9471C27 0.871717 26.1037 0 25.0025 0Z"
          fill="#007BB6"
        />
        <path
          d="M4.00036 10.1225H8.00932V23.0078H4.00036V10.1225ZM6.00583 3.71729C7.28687 3.71729 8.32709 4.75751 8.32709 6.03921C8.32709 7.32157 7.28687 8.3618 6.00583 8.3618C4.71949 8.3618 3.68259 7.32157 3.68259 6.03921C3.68259 4.75751 4.72015 3.71729 6.00583 3.71729Z"
          fill="white"
        />
        <path
          d="M10.5216 10.1225H14.3601V11.8838H14.4152C14.9492 10.8708 16.2555 9.80273 18.2039 9.80273C22.2586 9.80273 23.0083 12.471 23.0083 15.9412V23.0078H19.004V16.742C19.004 15.248 18.9781 13.3254 16.9229 13.3254C14.8391 13.3254 14.5213 14.9541 14.5213 16.6352V23.0078H10.5216V10.1225Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1398_1996">
          <rect width="27" height="27.0007" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LikedInIconColor;
