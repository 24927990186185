import React from 'react';

function SaveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      fill="none"
      viewBox="0 0 110 110"
    >
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M104.251 57.092l-6.265-.784c-4.467-12.294-16.252-21.076-30.09-21.076H44.252c-17.676 0-32.005 14.33-32.005 32.005 0 12.155 6.776 22.727 16.756 28.145v8.386a4.081 4.081 0 0 0 4.081 4.081h12.462a4.081 4.081 0 0 0 4.082-4.081v-4.526h18.05v4.526a4.081 4.081 0 0 0 4.08 4.081H84.22a4.081 4.081 0 0 0 4.081-4.081V91.892a32.043 32.043 0 0 0 10.144-15.085l5.806-.727a4.11 4.11 0 0 0 3.599-4.078V61.171a4.108 4.108 0 0 0-3.599-4.079z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M45.547 26.223a6.875 6.875 0 1 0 0-13.75 6.875 6.875 0 0 0 0 13.75zM63 47.699a6.875 6.875 0 1 0 0-13.75 6.875 6.875 0 0 0 0 13.75z"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M99.528 54.338l4.99.625a6.268 6.268 0 0 1 5.481 6.21v10.832a6.268 6.268 0 0 1-5.481 6.21l-4.466.559a34.081 34.081 0 0 1-9.602 14.111v10.885a6.237 6.237 0 0 1-6.23 6.23H71.758a6.237 6.237 0 0 1-6.23-6.23v-2.377H51.775v2.377a6.237 6.237 0 0 1-6.23 6.23H33.084a6.237 6.237 0 0 1-6.23-6.23v-7.134a34.151 34.151 0 0 1-16.512-33.461h-.78C4.288 63.175 0 58.886 0 53.615c0-5.272 4.289-9.56 9.56-9.56a2.149 2.149 0 0 1 0 4.296 5.27 5.27 0 0 0-5.263 5.264 5.27 5.27 0 0 0 5.263 5.263h1.575c3.739-14.803 17.168-25.792 33.116-25.792h2.692a2.149 2.149 0 0 1 0 4.297H44.25c-16.463 0-29.856 13.394-29.857 29.857a29.86 29.86 0 0 0 15.633 26.257 2.148 2.148 0 0 1 1.124 1.888v8.385c0 1.066.867 1.933 1.933 1.933h12.462a1.935 1.935 0 0 0 1.933-1.933v-4.526c0-1.186.962-2.148 2.148-2.148h18.05c1.186 0 2.148.962 2.148 2.148v4.526c0 1.066.868 1.933 1.933 1.933h12.463a1.935 1.935 0 0 0 1.932-1.933V91.895c0-.64.286-1.246.778-1.654a29.808 29.808 0 0 0 9.464-14.073 2.148 2.148 0 0 1 1.784-1.49l5.806-.727a1.965 1.965 0 0 0 1.718-1.946V61.173c0-.987-.739-1.824-1.718-1.947l-6.266-.784c-.8-.1-1.476-.64-1.752-1.398a29.898 29.898 0 0 0-6.456-10.428 2.142 2.142 0 0 1-.521-.535 29.75 29.75 0 0 0-14.193-7.897 2.148 2.148 0 1 1 .99-4.181c1.6.379 3.17.878 4.697 1.485 2.16-5.62 7.649-9.453 13.74-9.453h.477a2.15 2.15 0 0 1 2.09 2.642l-3.67 15.545a34.316 34.316 0 0 1 6.407 10.116zM89.513 40.803l2.41-10.21c-3.452.792-6.348 3.33-7.535 6.74a34.253 34.253 0 0 1 5.125 3.47z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M85.522 57.592c.4-.4.63-.954.63-1.519s-.23-1.119-.63-1.519c-.4-.4-.954-.63-1.519-.63s-1.119.23-1.519.63c-.4.4-.63.954-.63 1.52 0 .564.23 1.119.63 1.518.4.4.954.63 1.52.63.564 0 1.119-.23 1.518-.63zM63 12.532a2.149 2.149 0 0 0-2.148 2.149v8.521a2.149 2.149 0 0 0 4.297 0V14.68a2.149 2.149 0 0 0-2.148-2.148zM63 9.033c.565 0 1.12-.23 1.519-.63.4-.4.63-.954.63-1.519s-.23-1.117-.63-1.519c-.4-.4-.954-.63-1.519-.63s-1.12.23-1.519.63c-.4.4-.63.954-.63 1.52 0 .566.23 1.118.63 1.518.4.4.954.63 1.52.63zM45.547 7.95a2.149 2.149 0 0 0 2.148-2.15V2.149a2.149 2.149 0 0 0-4.297 0v3.653c0 1.186.962 2.148 2.149 2.148zM22.344 69.489a2.149 2.149 0 0 0 2.135-2.162v-.087a2.149 2.149 0 0 0-4.297 0v.114a2.149 2.149 0 0 0 2.148 2.135h.014zM31.755 87.814a2.148 2.148 0 0 0 2.233-3.67 19.915 19.915 0 0 1-8.208-9.833 2.148 2.148 0 1 0-4.012 1.537 24.232 24.232 0 0 0 9.987 11.966z"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M54.57 19.348c0 4.976-4.048 9.023-9.023 9.023-4.976 0-9.024-4.047-9.024-9.023 0-4.975 4.048-9.023 9.024-9.023 4.975 0 9.023 4.048 9.023 9.023zm-4.297 0a4.732 4.732 0 0 0-4.726-4.726 4.732 4.732 0 0 0-4.727 4.726 4.732 4.732 0 0 0 4.727 4.727 4.732 4.732 0 0 0 4.726-4.727zM44.687 45.546h10.631a8.965 8.965 0 0 1-1.34-4.727c0-4.975 4.047-9.023 9.023-9.023 4.975 0 9.023 4.048 9.023 9.023 0 1.732-.491 3.351-1.34 4.727h1.933a2.149 2.149 0 0 1 0 4.297h-27.93a2.149 2.149 0 0 1 0-4.297zm23.04-4.727a4.732 4.732 0 0 0-4.726-4.726 4.732 4.732 0 0 0-4.727 4.726 4.732 4.732 0 0 0 4.727 4.727 4.732 4.732 0 0 0 4.726-4.727z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SaveIcon;
