import React from 'react';

function TooltipFullConsultantIcon() {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.4569" cy="18.4569" r="17.4569" stroke="#2F2E46" strokeWidth="2" />
      <path
        d="M24.1666 18.6806C25.0091 19.5241 25.0051 20.8858 24.1666 21.7232L19.2659 26.6249C18.5545 27.3361 17.6123 27.7774 16.612 27.8671L10.2953 28.337L9.10834 29.7854C8.80794 30.1518 8.26657 30.2081 7.89691 29.9057C7.52907 29.6047 7.47543 29.0627 7.77663 28.6942L9.1972 26.9601C9.3463 26.7775 9.56424 26.6651 9.79919 26.6473L16.4704 26.1511C17.0565 26.0981 17.622 25.8335 18.0484 25.4072L22.9495 20.506C23.1184 20.337 23.1158 20.0635 22.9493 19.8973C22.7818 19.7292 22.5084 19.7292 22.3407 19.8973L18.6909 23.5474C18.371 23.8672 17.9016 24.1063 17.4132 24.1602L12.8892 24.9243C12.3962 25.0015 11.9736 24.6693 11.8973 24.2182C11.8185 23.7499 12.1343 23.3052 12.6034 23.2264L17.1762 22.4555C17.4382 22.4252 17.5991 22.2455 17.5991 22.0302C17.5991 21.7392 17.3531 21.493 17.0623 21.493H11.0859C10.1657 21.493 9.3013 21.8511 8.65166 22.5019L6.46919 24.6839C6.13297 25.0202 5.58843 25.0202 5.25218 24.6839C4.91593 24.3477 4.91595 23.8032 5.25218 23.4669L7.43423 21.2849C8.40886 20.3086 9.70574 19.7717 11.0857 19.7717H17.0621C17.8673 19.7717 18.5699 20.1984 18.9698 20.8342L21.123 18.681C21.9617 17.8416 23.3272 17.8416 24.1664 18.6806L24.1666 18.6806Z"
        fill="#2F2E46"
      />
      <path
        d="M30.5697 11.4166C30.9059 11.7528 30.9059 12.2974 30.5697 12.6336L28.3876 14.8157C27.4126 15.7917 26.1157 16.3287 24.7358 16.3287H18.7594C17.9542 16.3287 17.2516 15.902 16.8517 15.2662L14.6985 17.4194C13.858 18.2599 12.4937 18.2577 11.6553 17.4194C10.8128 16.5758 10.8168 15.2141 11.6553 14.3768L16.5564 9.4755C17.2678 8.76422 18.21 8.32294 19.2103 8.23328L25.527 7.76337L26.714 6.31498C27.0158 5.94774 27.5583 5.8937 27.9254 6.1947C28.2932 6.4957 28.3469 7.03766 28.0457 7.40613L26.6251 9.1403C26.476 9.32282 26.2581 9.43529 26.0231 9.45311L19.3514 9.94904C18.7652 10.0021 18.1998 10.2666 17.7734 10.693L12.8723 15.594C12.7034 15.7629 12.706 16.0365 12.8725 16.2026C13.04 16.3707 13.3134 16.3707 13.4811 16.2026L17.1309 12.553C17.4507 12.2332 17.9202 11.994 18.4086 11.9402L22.9326 11.1761C23.4003 11.0994 23.845 11.4131 23.9245 11.8821C24.0033 12.3505 23.6875 12.7951 23.2184 12.874L18.6456 13.6449C18.3836 13.6751 18.2227 13.8548 18.2227 14.0702C18.2227 14.3612 18.4686 14.6073 18.7595 14.6073H24.7359C25.6561 14.6073 26.5205 14.2493 27.1701 13.5985L29.3526 11.4164C29.6892 11.0804 30.2338 11.0804 30.57 11.4166L30.5697 11.4166Z"
        fill="#2F2E46"
      />
    </svg>
  );
}

export default TooltipFullConsultantIcon;
