import React from 'react';
import { useTranslation } from 'Hooks';
import CheckIcon from './CheckIcon';
import './styles.scss';

const PlansBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="plans-block block">
      <div className="block-container">
        <h2>{t('PlansBlock.titlte')}</h2>
        <div className="plans-block__content">
          <div className="plan">
            <div className="plan-box basic">
              <h3>{t('PlansBlock.basic.title')}</h3>
              <h4>{t('PlansBlock.basic.description')}</h4>
              {[...Array(5).keys()].map(item => (
                <div className="plan-box__feature" key={item}>
                  <CheckIcon />
                  <p>{t(`PlansBlock.basic.feature.${item + 1}`)}</p>
                </div>
              ))}
              <div className="plan-box__price">
                <span>{t('PlansBlock.basic.price')}</span>
              </div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-box">
              <h3>{t('PlansBlock.pro.title')}</h3>
              <h4>{t('PlansBlock.pro.description')}</h4>
              {[...Array(7).keys()].map(item => (
                <div className="plan-box__feature" key={item}>
                  <CheckIcon />
                  <p>{t(`PlansBlock.pro.feature.${item + 1}`)}</p>
                </div>
              ))}
              <div className="plan-box__price">
                <span>35€</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlansBlock;
