import React from 'react';

function TwitterIcon() {
  return (
    <svg width="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Twitter</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="MAIN_man"
          transform="translate(-161.000000, -3059.000000)"
          fill="#5C5C5C"
          fillRule="nonzero"
        >
          <g id="footer" transform="translate(-2.000000, 2738.000000)">
            <g id="Group-11" transform="translate(163.000000, 286.000000)">
              <g id="Group-10" transform="translate(0.000000, 35.000000)">
                <g id="icons8-twitter">
                  <path
                    d="M20.4444444,0 L2.55555556,0 C1.14361111,0 0,1.14361111 0,2.55555556 L0,20.4444444 C0,21.8563889 1.14361111,23 2.55555556,23 L20.4444444,23 C21.8563889,23 23,21.8563889 23,20.4444444 L23,2.55555556 C23,1.14361111 21.8563889,0 20.4444444,0 Z M17.9527778,8.32344444 C17.9527778,8.43333333 17.9527778,8.54194444 17.9527778,8.76172222 C17.9527778,12.9234444 14.7762222,17.7432222 8.97127778,17.7432222 C7.21944444,17.7432222 5.57622222,17.1950556 4.15277778,16.3197778 C4.37127778,16.3197778 4.70094444,16.3197778 4.91944444,16.3197778 C6.34288889,16.3197778 7.76761111,15.7716111 8.86266667,15.0049444 C7.43922222,15.0049444 6.34416667,14.0197778 5.90588889,12.8148333 C6.12438889,12.8148333 6.34416667,12.9247222 6.45405556,12.9247222 C6.78244444,12.9247222 7.00222222,12.9247222 7.33061111,12.8148333 C5.90716667,12.4864444 4.81211111,11.2815 4.81211111,9.74816667 C5.25038889,9.96666667 5.68866667,10.0765556 6.23555556,10.1864444 C5.359,9.41977778 4.81211111,8.54322222 4.81211111,7.44816667 C4.81211111,6.9 4.922,6.35311111 5.25038889,5.91483333 C6.78372222,7.77655556 9.08372222,9.09138889 11.7121111,9.2 C11.7121111,8.9815 11.6022222,8.76172222 11.6022222,8.43333333 C11.6022222,6.6815 13.0256667,5.25677778 14.7787778,5.25677778 C15.6553333,5.25677778 16.5306111,5.58516667 17.0787778,6.24194444 C17.8454444,6.13205556 18.5022222,5.80366667 19.0503889,5.47527778 C18.8318889,6.24194444 18.2837222,6.79011111 17.6269444,7.22711111 C18.2837222,7.11722222 18.8318889,7.00861111 19.4886667,6.67894444 C19.0478333,7.33827778 18.5009444,7.88516667 17.9527778,8.32344444 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TwitterIcon;
