import React from 'react';
import { useTranslation } from 'Hooks';
import { PRICE_ROUTE } from 'Constants/routes';
import Slider from 'react-slick';
import LogoIcon from './LogoIcon';
import LogoIconYellow from './LogoIconYellow';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

const HomePlansBlock = () => {
  const { t } = useTranslation();

  function GetBasicPlan() {
    return (
      <div className="home-plan-box basic">
        <LogoIcon title={t('HomePlansBlock.Basic.alt')} />
        <h3 className="color-secondary">{t('HomePlansBlock.Basic.title')}</h3>
        <div className="home-plan-box__price color-primary">
          <p>{t('HomePlansBlock.Basic.price')}</p>
        </div>
        <div className="home-plan-box__description color-primary">
          <p>{t('HomePlansBlock.Basic.description')}</p>
        </div>
        <div className="home-plan-box__cta">
          <div className="button__container">
            <a href={PRICE_ROUTE} className="line_btn green no_sticky" data-testid="cta_button">
              {t('HomePlansBlock.Basic.cta')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  function GetProPlan() {
    return (
      <div className="home-plan-box pro">
        <LogoIconYellow title={t('HomePlansBlock.Pro.alt')} />
        <h3 className="color-gold">{t('HomePlansBlock.Pro.title')}</h3>
        <div className="home-plan-box__price color-light">
          <p>{t('HomePlansBlock.Pro.price')}</p>
        </div>
        <div className="home-plan-box__description color-light">
          <p>{t('HomePlansBlock.Pro.description')}</p>
        </div>
        <div className="home-plan-box__cta">
          <a href={PRICE_ROUTE} className="line_btn gold no_sticky" data-testid="cta_button">
            {t('HomePlansBlock.Pro.cta')}
          </a>
        </div>
      </div>
    );
  }

  function GetLivePlan() {
    return (
      <div className="home-plan-box live">
        <LogoIcon
          first="#3D3A62"
          second="#3D3A62"
          third="#3D3A62"
          title={t('HomePlansBlock.Live.alt')}
        />
        <h3 className="color-primary">{t('HomePlansBlock.Live.title')}</h3>
        <div className="home-plan-box__price color-primary">
          <p>{t('HomePlansBlock.Live.price')}</p>
        </div>
        <div className="home-plan-box__description color-primary">
          <p>{t('HomePlansBlock.Live.description')}</p>
        </div>
        <div className="home-plan-box__cta">
          <a href={PRICE_ROUTE} className="line_btn no_sticky" data-testid="cta_button">
            {t('HomePlansBlock.Live.cta')}
          </a>
        </div>
      </div>
    );
  }

  function GetFullPlan() {
    return (
      <div className="home-plan-box full">
        <div className="featured">{t('HomePlansBlock.full.featured')}</div>
        <LogoIcon
          first="#3D3A62"
          second="#3D3A62"
          third="#3D3A62"
          title={t('HomePlansBlock.full.alt')}
        />
        <h3 className="color-primary">{t('HomePlansBlock.full.title')}</h3>
        <div className="home-plan-box__price color-primary">
          <p>{t('HomePlansBlock.full.price')}</p>
        </div>
        <div className="home-plan-box__description color-primary">
          <p>{t('HomePlansBlock.full.description')}</p>
        </div>
        <div className="home-plan-box__cta">
          <a href={PRICE_ROUTE} className="line_btn no_sticky" data-testid="cta_button">
            {t('HomePlansBlock.full.cta')}
          </a>
        </div>
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    className: 'plans-slide',
    centerMode: true,
  };

  return (
    <section className="home-plans-block block">
      <div className="block-container">
        <h2 className="section_title big">{t('HomePlansBlock.title')}</h2>
        <p className="section_subtitle">{t('HomePlansBlock.subtitle')}</p>

        <div className="home-plans-block__content">
          <GetBasicPlan />
          <GetProPlan />
          <GetLivePlan />
          <GetFullPlan />
        </div>
      </div>
      <Slider {...settings}>
        <GetBasicPlan />
        <GetProPlan />
        <GetLivePlan />
        <GetFullPlan />
      </Slider>
    </section>
  );
};

export default HomePlansBlock;
