import React from 'react';
import { useJobsQuery } from 'Hooks';
import withView from 'Hocs/withView';
import {
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  MainSection,
} from './components';

const Careers = () => {
  const { listing } = useJobsQuery();
  const { jobs, categories } = listing;

  return (
    <>
      <MainSection />
      <FirstSection />
      <SecondSection />
      <ThirdSection jobs={jobs} categories={categories} />
      <FourthSection />
    </>
  );
};
export default withView(Careers);
