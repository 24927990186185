import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH, TABLET_WIDTH } from 'Styles/sizes';

export const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  padding: 0 ${pxToRem(50)};
  z-index: 1;
  @media screen and (min-width: ${TABLET_WIDTH}) {
    padding: 0 ${pxToRem(34)};
  }
  @media screen and (max-width: ${MOBILE_WIDTH}) {
    padding: 0 ${pxToRem(24)};
  }
`;
