import styled from 'styled-components';

import { TABLET_WIDTH, LARGE_DEVICE_WIDTH, DESKTOP_WIDTH } from 'Styles/sizes';
import { pxToRem } from 'Styles/utils';

export const ListRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: ${pxToRem(25)} ${pxToRem(20)};
  margin: 1rem 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  button {
    margin-left: auto;
  }

  p,
  h3 {
    font-weight: normal;
    margin: 0;
  }

  p {
    &:last-of-type {
      svg {
        margin-right: 0.75rem;
      }
    }
  }

  h3,
  a {
    flex: 1;
  }

  @media screen and (max-width: ${LARGE_DEVICE_WIDTH}) {
    h3 {
      font-weight: bold;
      font-size: 21px;
    }
    button {
      font-size: ${pxToRem(12)};
    }
  }
  @media screen and (max-width: ${TABLET_WIDTH}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    h3,
    p {
      margin: 1rem 0;
    }
    a,
    button {
      width: 100%;
    }
    button {
      font-size: ${pxToRem(14)};
    }
  }
`;

export const RowDescription = styled.div`
  flex: 2;
  max-width: ${pxToRem(355)};
  margin-right: ${pxToRem(50)};
  p {
    font-size: ${pxToRem(12)};
  }

  @media screen and (max-width: ${DESKTOP_WIDTH}) {
    max-width: ${pxToRem(250)};
  }
  @media screen and (max-width: ${LARGE_DEVICE_WIDTH}) {
    display: none;
  }
`;

export const RowLocation = styled.div`
  flex: 0.4;
  p {
    display: flex;
    align-items: center;
  }
`;

export const RowArea = styled.div`
  flex: 1;
`;

export const DropDownOption = styled.div`
  cursor: pointer;
`;

export const OptionsList = styled.div`
  display: none;
  position: absolute;
  top: 0;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid rgb(232, 235, 242);
  text-transform: capitalize;
  top: 50px;
  left: -10px;

  p {
    padding: ${pxToRem(12)} ${pxToRem(16)};
    margin: 0;
  }

  ${DropDownOption} {
    border-bottom: 1px solid rgb(232, 235, 242);
  }
  ${DropDownOption}:last-child {
    border-bottom: none;
  }
`;

export const Option = styled.div`
  position: relative;
  ${({ isSelect }) =>
    isSelect &&
    `
    cursor: pointer;
  `}
  ${({ toggle }) =>
    toggle &&
    `
    > ${OptionsList} {
      display:block;
    }
  `}
`;

export const RowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${pxToRem(20)};

  > ${Option} {
    display: flex;
    align-items: center;
    flex: 1;
    text-transform: uppercase;
    font-size: ${pxToRem(12)};

    svg {
      margin-left: ${pxToRem(8.5)};
    }
  }
  > ${Option}:nth-child(2) {
    flex: 2;
    max-width: ${pxToRem(355)};
    margin-right: ${pxToRem(50)};
  }
  > ${Option}:last-child {
    flex: 1.4;
  }

  @media screen and (max-width: ${DESKTOP_WIDTH}) {
    > ${Option}:nth-child(2) {
      max-width: ${pxToRem(250)};
    }
  }
  @media screen and (max-width: ${LARGE_DEVICE_WIDTH}) {
    > ${Option}:nth-child(2) {
      display: none;
    }
  }
  @media screen and (max-width: ${TABLET_WIDTH}) {
    > ${Option}:nth-child(1) {
      display: none;
    }
  }
`;
