import React from 'react';
import PropTypes from 'prop-types';

function DiffOne({ title }) {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="diff_one_svg_title"
    >
      <title id="diff_one_svg_title">{title}</title>
      <rect
        x="19"
        y="17"
        width="64.29"
        height="65.2217"
        rx="5.50749"
        fill="white"
        stroke="#3D3A62"
        strokeWidth="3"
      />
      <line
        x1="30.5"
        y1="28.5"
        x2="40.5"
        y2="28.5"
        stroke="#C9EBD6"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M75.5 10L78.6952 14.7214L84.1776 13.1584L83.5904 18.829L88.7949 21.1557L84.7001 25.1222L87.1913 30.25L81.5049 30.6564L80.1173 36.1859L75.5 32.842L70.8827 36.1859L69.4951 30.6564L63.8087 30.25L66.2999 25.1222L62.2051 21.1557L67.4096 18.829L66.8224 13.1584L72.3048 14.7214L75.5 10Z"
        fill="#99F1BD"
      />
      <line
        x1="30.5"
        y1="57.5"
        x2="70.5"
        y2="57.5"
        stroke="#E0F7E9"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="30.5"
        y1="67.5"
        x2="70.5"
        y2="67.5"
        stroke="#C9EBD6"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="30.5"
        y1="47.5"
        x2="70.5"
        y2="47.5"
        stroke="#C9EBD6"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}

DiffOne.propTypes = {
  title: PropTypes.string,
};

DiffOne.defaultProps = {
  title: 'Simulación gratuita de la renta',
};

export default DiffOne;
