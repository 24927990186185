import { toast, Zoom } from 'react-toastify';

export const AlertTypes = Object.freeze({
  success: Symbol('success'),
  info: Symbol('info'),
  warn: Symbol('warn'),
  error: Symbol('error'),
});

class NotificationService {
  configuration = {
    position: toast.POSITION.TOP_RIGHT,
    transition: Zoom,
  };

  sendNotification = (message, type) => {
    try {
      if (message) {
        const msg = message instanceof String ? message : message.toString();

        switch (type) {
          case AlertTypes.success:
            toast.success(msg, this.configuration);
            break;
          case AlertTypes.info:
            toast.info(msg, this.configuration);
            break;
          case AlertTypes.warn:
            toast.warn(msg, this.configuration);
            break;
          case AlertTypes.error:
            toast.error(msg, this.configuration);
            break;
          default:
            toast(msg, this.configuration);
            break;
        }
      }
    } catch (ex) {
      toast.error(ex.message, this.configuration);
    }
  };
}

const Notify = new NotificationService();

export default Notify;
