import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'Hooks';
import { Trans } from 'Components';
import { ConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';
import { config } from 'Config';
import './styles.scss';

const CookiesManager = () => {
  const { t } = useTranslation('');

  const BannerActions = ({ acceptAll, changePreferences }) => {
    return (
      <div className="cookies-banner__actions">
        <button type="button" className="line_btn" onClick={changePreferences}>
          {t('CookiesSettings.Title')}
        </button>
        <button type="button" className="solid_btn green" onClick={acceptAll}>
          {t('CookiesSettings.acceptAll')}
        </button>
      </div>
    );
  };

  BannerActions.propTypes = {
    acceptAll: PropTypes.isRequired,
    changePreferences: PropTypes.isRequired,
  };

  const bannerContent = (
    <div className="cookie-banner">
      <Trans
        i18nKey="CookiesSettings.MainText"
        ns="common"
        components={{ italic: <i />, bold: <strong /> }}
      />
    </div>
  );
  const bannerSubContent = '';
  const preferencesDialogTitle = t('CookiesSettings.Title');
  const preferencesDialogContent = t('CookiesSettings.Text');
  const cancelDialogTitle = t('CookiesSettings.CancelDialogTitle');
  const cancelDialogContent = t('CookiesSettings.CancelDialogContent');

  const preferencesDialogTemplate = {
    headings: {
      allowValue: t('CookiesSettings.allowValue'),
      categoryValue: t('CookiesSettings.categoryValue'),
      purposeValue: t('CookiesSettings.purposeValue'),
      toolsValue: t('CookiesSettings.toolsValue'),
    },
    checkboxes: {
      noValue: t('CookiesSettings.noValue'),
      yesValue: t('CookiesSettings.yesValue'),
    },
    actionButtons: {
      cancelValue: t('CookiesSettings.CancelButton'),
      saveValue: t('CookiesSettings.SaveButton'),
    },
    cancelDialogButtons: {
      cancelValue: t('CookiesSettings.CancelDialogButtons'),
      backValue: t('CookiesSettings.BackDialogButtons'),
    },
    categories: [
      {
        key: 'functional',
        name: t('CookiesSettings.BehavioralOpt'),
        description: t('CookiesSettings.BehavioralOptDescription'),
        example: t('CookiesSettings.BehavioralOptExample'),
      },
      {
        key: 'marketing',
        name: t('CookiesSettings.Marketing'),
        description: t('CookiesSettings.MarketingDescription'),
        example: t('CookiesSettings.MarketingExample'),
      },
      {
        key: 'advertising',
        name: t('CookiesSettings.AdvertisingOpt'),
        description: t('CookiesSettings.AdvertisingOptDescription'),
        example: t('CookiesSettings.AdvertisingOptExample'),
      },
      {
        key: 'essential',
        name: t('CookiesSettings.Mandatory'),
        description: t('CookiesSettings.MandatoryDescription'),
        example: t('CookiesSettings.MandatoryExample'),
      },
    ],
  };

  return (
    <div id="consent-manager">
      <ConsentManager
        writeKey={config.SEGMENT_KEY}
        shouldRequireConsent={inEU}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        preferencesDialogTemplate={preferencesDialogTemplate}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        bannerActionsBlock={BannerActions}
        bannerBackgroundColor="#ffffff"
        bannerTextColor="black"
        bannerHideCloseButton
        bannerAsModal="true"
      />
    </div>
  );
};

export default CookiesManager;
