import React from 'react';

function WeApplyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170"
      height="170"
      fill="none"
      viewBox="0 0 170 170"
    >
      <title>Aplicamos deducciones</title>
      <path
        fill="#F2F2F2"
        d="M169.221 37.69L140.828 9.296a2.661 2.661 0 0 0-1.882-.78H31.054a2.662 2.662 0 0 0-2.662 2.663v156.158A2.662 2.662 0 0 0 31.054 170h136.284a2.662 2.662 0 0 0 2.662-2.662V39.572c0-.706-.28-1.383-.779-1.883z"
      />
      <path
        fill="#E5E5E5"
        d="M169.994 39.454a2.662 2.662 0 0 0-.774-1.764L140.828 9.297c-.5-.5-1.176-.78-1.882-.78h-.118v28.275a2.661 2.661 0 0 0 2.661 2.662h28.505z"
      />
      <path
        fill="#E5E5E5"
        d="M31.054 8.518a2.662 2.662 0 0 0-2.662 2.662v156.158A2.662 2.662 0 0 0 31.054 170h124.807L45.204 8.518h-14.15z"
        opacity=".6"
      />
      <path
        fill="#5C5C5C"
        d="M71.159 0H7.985C3.582 0 0 3.582 0 7.985v85.888c0 4.403 3.582 7.985 7.985 7.985H71.16c4.403 0 7.985-3.582 7.985-7.986V7.986C79.144 3.582 75.562 0 71.159 0z"
      />
      <path
        fill="#02CD55"
        fillRule="evenodd"
        d="M118.726 82.16c-21.82 0-39.572 17.752-39.572 39.572 0 21.82 17.752 39.572 39.572 39.572 21.82 0 39.573-17.752 39.573-39.572 0-21.82-17.753-39.572-39.573-39.572z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M124.048 119.189l-3.662-.108v-13.126h8.349a2.363 2.363 0 1 0 0-4.726h-8.349v-2.678a2.363 2.363 0 1 0-4.727 0v2.678h-2.363a7.097 7.097 0 0 0-7.089 7.089v8.192c0 3.896 3.16 7.069 7.051 7.089l2.401.071v12.847h-7.089a2.363 2.363 0 0 0 0 4.727h7.089v2.678a2.363 2.363 0 0 0 4.727 0v-2.678h3.623c3.909 0 7.089-3.181 7.089-7.09v-7.876c.001-3.896-3.159-7.068-7.05-7.089zm-10.682-.315h-.069a2.366 2.366 0 0 1-2.363-2.364v-8.192a2.366 2.366 0 0 1 2.363-2.363h2.363v12.987l-2.294-.068zm10.643 17.644a2.366 2.366 0 0 0 2.364-2.363v-7.877a2.366 2.366 0 0 0-2.364-2.363l-.069-.001-3.554-.105v12.709h3.623z"
        clipRule="evenodd"
      />
      <path
        fill="#07BF52"
        fillRule="evenodd"
        d="M126.879 153.674c-21.82 0-39.572-17.752-39.572-39.572 0-8.924 2.97-17.166 7.971-23.792-9.577 7.229-15.78 18.703-15.78 31.6 0 21.82 17.753 39.572 39.573 39.572 12.896 0 24.371-6.202 31.6-15.78a39.366 39.366 0 0 1-23.792 7.972z"
        clipRule="evenodd"
      />
      <path
        fill="#F2F2F2"
        d="M67.964 8.518H11.18a2.662 2.662 0 0 0-2.662 2.662v14.196a2.662 2.662 0 0 0 2.662 2.662h56.784a2.661 2.661 0 0 0 2.662-2.662V11.179a2.662 2.662 0 0 0-2.662-2.661z"
      />
      <path
        fill="#87CEE9"
        d="M11.18 8.518a2.662 2.662 0 0 0-2.662 2.662v14.196a2.662 2.662 0 0 0 2.662 2.662h11.534l19.52-19.52H11.179zM67.964 8.518h-9.937l-19.52 19.52h29.457a2.662 2.662 0 0 0 2.662-2.663V11.18a2.662 2.662 0 0 0-2.662-2.662z"
      />
      <path
        fill="#07BF52"
        fillRule="evenodd"
        d="M23.135 35.962H12.81a2.42 2.42 0 0 0-2.42 2.42v10.324a2.42 2.42 0 0 0 2.42 2.42h10.325a2.42 2.42 0 0 0 2.42-2.42V38.382a2.42 2.42 0 0 0-2.42-2.42zM43.623 35.962H33.298a2.42 2.42 0 0 0-2.42 2.42v10.324a2.42 2.42 0 0 0 2.42 2.42h10.325a2.42 2.42 0 0 0 2.42-2.42V38.382a2.42 2.42 0 0 0-2.42-2.42zM23.135 56.61H12.81a2.42 2.42 0 0 0-2.42 2.42v10.325a2.42 2.42 0 0 0 2.42 2.42h10.325a2.42 2.42 0 0 0 2.42-2.42V59.03a2.42 2.42 0 0 0-2.42-2.42zM43.623 56.61H33.298a2.42 2.42 0 0 0-2.42 2.42v10.325a2.42 2.42 0 0 0 2.42 2.42h10.325a2.42 2.42 0 0 0 2.42-2.42V59.03a2.42 2.42 0 0 0-2.42-2.42zM23.135 77.26H12.81a2.42 2.42 0 0 0-2.42 2.42v10.324a2.42 2.42 0 0 0 2.42 2.42h10.325a2.42 2.42 0 0 0 2.42-2.42V79.68a2.42 2.42 0 0 0-2.42-2.42zM43.623 77.26H33.298a2.42 2.42 0 0 0-2.42 2.42v10.324a2.42 2.42 0 0 0 2.42 2.42h10.325a2.42 2.42 0 0 0 2.42-2.42V79.68a2.42 2.42 0 0 0-2.42-2.42zM64.272 35.962H53.947a2.42 2.42 0 0 0-2.42 2.42v10.324a2.42 2.42 0 0 0 2.42 2.42h10.325a2.42 2.42 0 0 0 2.42-2.42V38.382a2.42 2.42 0 0 0-2.42-2.42z"
        clipRule="evenodd"
      />
      <path
        fill="#02CD55"
        fillRule="evenodd"
        d="M64.433 56.61H54.108a2.42 2.42 0 0 0-2.42 2.42v30.974a2.42 2.42 0 0 0 2.42 2.42h10.325a2.42 2.42 0 0 0 2.42-2.42V59.03a2.42 2.42 0 0 0-2.42-2.42z"
        clipRule="evenodd"
      />
      <path
        fill="#666"
        d="M98.894 30.877a2.654 2.654 0 0 1-1.973-.874l-5.15-5.679a2.662 2.662 0 1 1 3.945-3.576l5.149 5.679a2.66 2.66 0 0 1-1.97 4.45z"
      />
      <path
        fill="#62C9CC"
        d="M154.099 71.097l-42.411-42.412a2.662 2.662 0 0 0-1.361-.728l-10.038-2.008a2.663 2.663 0 0 0-3.132 3.132l2.007 10.039c.103.515.357.988.728 1.36l42.412 42.412a8.038 8.038 0 0 0 6.51 2.288c2.135-.232 3.802-1.306 5.285-2.79 3.114-3.114 3.114-8.18 0-11.293z"
      />
      <path
        fill="#D1D1D1"
        d="M128.99 69.58L99.892 40.484l11.795-11.795 29.098 29.098-11.795 11.795z"
      />
      <path
        fill="#02CD55"
        fillRule="evenodd"
        d="M100.289 25.95c-1.83-.364-3.498 1.3-3.132 3.132l2.007 10.038c.103.515.357.988.728 1.36l11.796-11.795a2.662 2.662 0 0 0-1.361-.728l-10.038-2.008z"
        clipRule="evenodd"
      />
      <path
        fill="#50A9A9"
        d="M154.099 71.096l-13.313-13.313-11.795 11.796 13.313 13.313c1.667 1.667 4.05 2.483 6.391 2.27 2.178-.197 3.891-1.26 5.404-2.772 3.114-3.114 3.114-8.18 0-11.294z"
      />
      <path
        fill="#666"
        d="M149.958 73.382a2.661 2.661 0 0 1-1.882-4.544l4.091-4.09L139.65 52.86a2.661 2.661 0 1 1 3.665-3.86l14.499 13.768a2.66 2.66 0 0 1 .05 3.812l-6.023 6.023a2.662 2.662 0 0 1-1.883.78z"
      />
      <path
        fill="#5AAAE7"
        d="M65.835 127.411H48.09a2.661 2.661 0 1 1 0-5.323h17.745a2.661 2.661 0 1 1 0 5.323zM65.835 141.608H48.09a2.661 2.661 0 1 1 0-5.324h17.745a2.661 2.661 0 1 1 0 5.324z"
      />
    </svg>
  );
}

export default WeApplyIcon;
