import React from 'react';
import { useTranslation } from 'Hooks';
import './styles.scss';

import abanca from './img/abanca.png';
import aon from './img/aon.png';
import carrefour from './img/carrefour.png';
import etoro from './img/etoro.png';
import evo from './img/evo.png';
import fintonic from './img/fintonic.png';
import goin from './img/goin.png';
import indra from './img/indra.png';
import jobandtalent from './img/jobandtalent.png';
import leroy from './img/leroy.png';
import nova from './img/nova.png';
import openbank from './img/openbank.png';
import reale from './img/reale.png';
import talgo from './img/talgo.png';
import unisono from './img/unisono.png';
import verti from './img/verti.png';
import wework from './img/wework.png';

const WhoArePartnerBlock = () => {
  const { t } = useTranslation('home');

  function ClientService() {
    return (
      <div className="grid-wrapper">
        <h3 className="mobile-title">{t('WhoArePartnerBlock.client.mobileTitle')}</h3>
        <h3 className="desktop-title">{t('WhoArePartnerBlock.client.desktopTitle')}</h3>
        <div className="one">
          <img src={evo} alt="evobank" />
        </div>
        <div className="two">
          <img src={openbank} alt="openbank" />
        </div>
        <div className="three">
          <img src={abanca} alt="abanca" />
        </div>
        <div className="four">
          <img src={verti} alt="verti" />
        </div>
        <div className="five">
          <img src={reale} alt="reale" />
        </div>
        <div className="six">
          <img src={nova} alt="nova" />
        </div>
        <div className="seven">
          <img src={etoro} alt="etoro" />
        </div>
        <div className="eight">
          <img src={goin} alt="goin" />
        </div>
        <div className="nine">
          <img src={fintonic} alt="fintonic" />
        </div>
      </div>
    );
  }
  function EmployerService() {
    return (
      <div className="grid-wrapper">
        <h3 className="mobile-title">{t('WhoArePartnerBlock.employer.mobileTitle')}</h3>
        <h3 className="desktop-title">{t('WhoArePartnerBlock.employer.desktopTitle')}</h3>
        <div className="one">
          <img src={leroy} alt="leroy" />
        </div>
        <div className="two">
          <img src={aon} alt="aon" />
        </div>
        <div className="three">
          <img src={verti} alt="verti" />
        </div>
        <div className="four">
          <img src={talgo} alt="talgo" />
        </div>
        <div className="five">
          <img src={indra} alt="indra" />
        </div>
        <div className="six">
          <img src={wework} alt="wework" />
        </div>
        <div className="seven">
          <img src={jobandtalent} alt="jobandtalent" />
        </div>
        <div className="eight">
          <img src={unisono} alt="unisono" />
        </div>
        <div className="nine">
          <img src={carrefour} alt="carrefour" />
        </div>
      </div>
    );
  }
  return (
    <section className="who-are-partner-block">
      <h2>{t('WhoArePartnerBlock.title')}</h2>
      <div className="who-are-partner-block__container">
        <ClientService />
        <EmployerService />
      </div>
      <p className="much-more">{t('WhoArePartnerBlock.muchMore')}</p>
    </section>
  );
};

export default WhoArePartnerBlock;
