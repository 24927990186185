export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const required = input => {
  const re = /\S/;
  return re.test(input);
};

export const validations = {
  email: { validator: validateEmail, message: 'Email no válido' },
  required: { validator: required, message: 'Campo obligatorio' },
};
