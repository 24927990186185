import React from 'react';
import { useTranslation } from 'Hooks';
import { Lottie } from 'Components';
import HIW1 from './lotties/hiw_1.json';
import HIW2 from './lotties/hiw_2.json';
import HIW3 from './lotties/hiw_3.json';
import './styles.scss';

const features = [
  {
    title: 'HomeUspBlock.step.1.title',
    description: 'HomeUspBlock.step.1.description',
    alt: 'HomeUspBlock.step.1.alt',
    lottie: HIW1,
  },
  {
    title: 'HomeUspBlock.step.2.title',
    description: 'HomeUspBlock.step.2.description',
    alt: 'HomeUspBlock.step.2.alt',
    lottie: HIW2,
  },
  {
    title: 'HomeUspBlock.step.3.title',
    description: 'HomeUspBlock.step.3.description',
    alt: 'HomeUspBlock.step.3.alt',
    lottie: HIW3,
  },
];

const HomeUspBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="home-usp-block block">
      <div className="block-container">
        <h2>{t('HomeUspBlock.title')}</h2>
        <div className="home-usp-block__features">
          {features.map(({ title, description, alt, lottie }) => (
            <div className="box" key={title}>
              <div className="icon" title={t(alt)}>
                <Lottie className="lottie-container" options={{ animationData: lottie }} />
              </div>
              <h3>{t(title)}</h3>
              <p>{t(description)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomeUspBlock;
