import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'Hooks';
import ProIcon from 'Assets/icons/plans/ProIcon';
import LiveIcon from 'Assets/icons/plans/LiveIcon';
import './styles.scss';
import criptomoneda from '../../assets/img/criptomoneda.png';
import freelance from '../../assets/img/freelance.png';

const DeclarationBlock = ({ showIcons, link }) => {
  const { t } = useTranslation();
  return (
    <section className="declarations">
      <div className="declarations-container">
        <h2>{t('DeclarationsBlock.title')}</h2>
        <div className="declaration-block">
          {showIcons && (
            <div className="declaration-block__icons_left">
              <ProIcon /> <LiveIcon />
            </div>
          )}
          <div className="declaration-block__content">
            <h3 className="declaration-block__title">{t('DeclarationsBlock.crypto.title')}</h3>
            <p className="declaration-block__description">
              {t('DeclarationsBlock.crypto.description')}
            </p>
            <a href={link} className="declaration-block__cta solid_btn">
              {t('DeclarationsBlock.moreInfo')}
            </a>
          </div>
          <div className="declaration-block__image">
            <img src={criptomoneda} alt={t('DeclarationsBlock.crypto.alt')} />
          </div>
        </div>
        <div className="declaration-block second">
          {showIcons && (
            <div className="declaration-block__icons_right">
              <ProIcon /> <LiveIcon />
            </div>
          )}
          <div className="declaration-block__content">
            <h3 className="declaration-block__title">
              {t('DeclarationsBlock.selfEmployed.title')}
            </h3>
            <p className="declaration-block__description">
              {t('DeclarationsBlock.selfEmployed.description')}
            </p>
            <a href={link} className="declaration-block__cta solid_btn">
              {t('DeclarationsBlock.moreInfo')}
            </a>
          </div>
          <div className="declaration-block__image">
            <img src={freelance} alt={t('DeclarationsBlock.selfEmployed.alt')} />
          </div>
        </div>
      </div>
    </section>
  );
};

DeclarationBlock.propTypes = {
  showIcons: PropTypes.bool,
  link: PropTypes.string.isRequired,
};

DeclarationBlock.defaultProps = {
  showIcons: false,
};

export default DeclarationBlock;
