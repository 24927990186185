import React from 'react';

function ProtectedInfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="111"
      height="100"
      fill="none"
      viewBox="0 0 111 100"
    >
      <title>Protección de información</title>
      <path fill="#00DC5A" d="M2 75.005h106.023v8.002H2z" />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M103.568 10.367h-25.92a1.617 1.617 0 0 0 0 3.234h25.92a3.57 3.57 0 0 1 3.567 3.567v57.135H3.233V17.168c0-1.967 1.6-3.567 3.568-3.567h25.92a1.617 1.617 0 0 0 0-3.234H6.8C3.05 10.367 0 13.418 0 17.169v62.208c0 3.75 3.05 6.8 6.8 6.8H39.53l-1.324 10.59h-2.029a1.617 1.617 0 0 0 0 3.234h38.016a1.617 1.617 0 0 0 0-3.234h-2.03l-1.323-10.59h32.728c3.75 0 6.801-3.05 6.801-6.8V17.168c0-3.75-3.05-6.8-6.8-6.8zm-62.104 86.4l1.324-10.59H67.58l1.324 10.59H41.464zm62.104-13.824c1.967 0 3.567-1.6 3.567-3.567v-1.84H3.233v1.84c0 1.967 1.6 3.567 3.568 3.567h96.767z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M72.464 25.92h-.111v-8.752C72.353 7.703 64.65 0 55.184 0c-9.466 0-17.168 7.702-17.168 17.169v8.75h-.112a5.078 5.078 0 0 0-5.072 5.073v13.824c0 12.325 10.027 22.353 22.352 22.353 12.325 0 22.353-10.028 22.353-22.353V30.992a5.078 5.078 0 0 0-5.073-5.072zM55.184 3.233c7.684 0 13.935 6.252 13.935 13.935v8.752h-3.678v-8.752c0-5.655-4.601-10.256-10.257-10.256-5.655 0-10.256 4.6-10.256 10.256v8.752h-3.679v-8.752c0-7.684 6.251-13.935 13.935-13.935zm7.024 22.687v-8.752a7.031 7.031 0 0 0-7.024-7.023 7.031 7.031 0 0 0-7.023 7.024v8.75h14.047z"
        clipRule="evenodd"
      />
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M55.184 63.936c-10.542 0-19.119-8.577-19.119-19.12V30.994c0-1.014.826-1.84 1.84-1.84h34.559c1.014 0 1.84.826 1.84 1.84v13.824c0 10.542-8.578 19.119-19.12 19.119z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        stroke="#5C5C5C"
        strokeWidth=".5"
        d="M47.813 45.26c0-2.568-5.496-1.78-5.496-5.838 0-2.552 2.191-3.425 4.16-3.425 1.011 0 3.254.24 3.254 1.37 0 .394-.274 1.18-.89 1.18-.531 0-.925-.65-2.364-.65-1.232 0-1.934.497-1.934 1.319 0 2.123 5.496 1.387 5.496 5.821 0 2.449-1.712 3.716-4.075 3.716-2.243 0-3.955-1.13-3.955-2.123 0-.48.445-1.199.976-1.199.702 0 1.165 1.32 2.91 1.32 1.028-.002 1.918-.464 1.918-1.491zM57.847 45.26c0-2.568-5.496-1.78-5.496-5.838 0-2.552 2.19-3.425 4.16-3.425 1.01 0 3.254.24 3.254 1.37 0 .394-.274 1.18-.89 1.18-.532 0-.925-.65-2.364-.65-1.233 0-1.934.497-1.934 1.319 0 2.123 5.496 1.387 5.496 5.821 0 2.449-1.712 3.716-4.075 3.716-2.243 0-3.956-1.13-3.956-2.123 0-.48.445-1.199.976-1.199.703 0 1.165 1.32 2.911 1.32 1.027-.002 1.918-.464 1.918-1.491zM62.692 47.896V36.767c0-.48.548-.685 1.113-.685.548 0 1.113.205 1.113.685v9.88h4.178c.445 0 .667.479.667.975 0 .48-.222.976-.667.976h-5.428c-.497 0-.976-.24-.976-.702z"
      />
    </svg>
  );
}

export default ProtectedInfoIcon;
