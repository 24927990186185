import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH } from 'Styles/sizes';

export const BannerSmallWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.backgroundGrey};
  min-height: ${pxToRem(368)};
`;

export const BannerSmallContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BannerSmallImage = styled.div`
  width: 100%;
  max-width: ${pxToRem(142)};
  margin-bottom: ${pxToRem(30)};

  img {
    width: 100%;
    height: auto;
  }
`;

export const BannerSmallTitle = styled.div`
  display: flex;
  align-items: center;
  h2 {
    font-size: ${pxToRem(34)};
  }
  svg {
    margin: ${pxToRem(10)};
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    h2 {
      margin: ${pxToRem(10)} 0;
    }
    flex-direction: column;
  }
`;

export const BannerSmallDescription = styled.div`
  text-align: center;
  p {
    margin: 0;
  }
`;
