import React from 'react';

function BasicIcon() {
  const random = Math.floor(Math.random() * 1000000);
  const id = `basic_icon_defs${random}`;
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="20"
        width="28.2843"
        height="28.2843"
        rx="3"
        transform="rotate(45 20 0)"
        fill={`url(#${id})`}
      />
      <path
        d="M13.4582 15.9299C13.7208 15.6674 14.1464 15.6674 14.409 15.9299L16.3104 17.8314C16.5729 18.0939 16.5729 18.5196 16.3104 18.7821L13.9336 21.1589C13.6711 21.4214 13.2454 21.4214 12.9829 21.1589L11.0815 19.2574C10.8189 18.9949 10.8189 18.5693 11.0815 18.3067L13.4582 15.9299Z"
        fill="#2F2E46"
      />
      <path
        d="M19.7955 16.3147C20.058 16.0522 20.4837 16.0522 20.7462 16.3147L22.6477 18.2161C22.9102 18.4787 22.9102 18.9043 22.6477 19.1669L17.2942 24.5203C17.0317 24.7829 16.606 24.7828 16.3435 24.5203L14.4421 22.6189C14.1795 22.3564 14.1795 21.9307 14.4421 21.6682L19.7955 16.3147Z"
        fill="#2F2E46"
      />
      <path
        d="M26.194 16.6398C26.4565 16.3773 26.8821 16.3773 27.1447 16.6398L29.0461 18.5412C29.3086 18.8037 29.3086 19.2294 29.0461 19.4919L20.6566 27.8814C20.3941 28.144 19.9684 28.144 19.7059 27.8814L17.8044 25.98C17.5419 25.7175 17.5419 25.2918 17.8044 25.0293L26.194 16.6398Z"
        fill="#00DC5A"
      />
      <defs>
        <linearGradient
          id={id}
          x1="34.1421"
          y1="0"
          x2="34.1421"
          y2="28.2843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4F4F4" />
          <stop offset="1" stopColor="#F4F4F4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BasicIcon;
