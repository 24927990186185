import React from 'react';

function LiveIcon() {
  const random = Math.floor(Math.random() * 1000000);
  const id = `live_icon_defs${random}`;
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="20"
        width="28.2843"
        height="28.2843"
        rx="4.55"
        transform="rotate(45 20 0)"
        fill={`url(#${id})`}
      />
      <path
        d="M13.4582 15.9299C13.7208 15.6674 14.1464 15.6674 14.409 15.9299L16.3104 17.8314C16.5729 18.0939 16.5729 18.5196 16.3104 18.7821L13.9336 21.1589C13.6711 21.4214 13.2454 21.4214 12.9829 21.1589L11.0815 19.2574C10.8189 18.9949 10.8189 18.5693 11.0815 18.3067L13.4582 15.9299Z"
        fill="#3D3A62"
      />
      <path
        d="M19.7955 16.3147C20.058 16.0522 20.4837 16.0522 20.7462 16.3147L22.6477 18.2161C22.9102 18.4787 22.9102 18.9043 22.6477 19.1669L17.2942 24.5203C17.0317 24.7829 16.606 24.7829 16.3435 24.5203L14.4421 22.6189C14.1795 22.3564 14.1795 21.9307 14.4421 21.6682L19.7955 16.3147Z"
        fill="#3D3A62"
      />
      <path
        d="M26.194 16.6397C26.4565 16.3771 26.8821 16.3771 27.1447 16.6397L29.0461 18.5411C29.3086 18.8036 29.3086 19.2293 29.0461 19.4918L20.6566 27.8813C20.3941 28.1438 19.9684 28.1438 19.7059 27.8813L17.8044 25.9799C17.5419 25.7174 17.5419 25.2917 17.8044 25.0292L26.194 16.6397Z"
        fill="#3D3A62"
      />
      <path
        d="M25.7546 27.8841L23.5781 25.7076L24.0483 25.2374L25.8218 27.0108L27.0779 25.7546L27.481 26.1577L25.7546 27.8841ZM27.9591 25.6795L25.7827 23.503L26.2529 23.0328L28.4294 25.2093L27.9591 25.6795ZM29.6868 23.9518L26.6639 22.6218L27.1442 22.1415L28.6758 22.8132L29.398 23.1793C29.2255 22.8547 29.1046 22.6128 29.0352 22.4538L28.3635 20.9222L28.8438 20.4419L30.1738 23.4648L29.6868 23.9518ZM31.4302 22.2085L29.2537 20.032L30.97 18.3157L31.3731 18.7187L30.127 19.9648L30.5703 20.4082L31.7526 19.2259L32.1523 19.6256L30.97 20.8079L31.4974 21.3352L32.7871 20.0454L33.1902 20.4485L31.4302 22.2085Z"
        fill="#3D3A62"
      />
      <defs>
        <linearGradient
          id={id}
          x1="34.1421"
          y1="0"
          x2="34.1421"
          y2="28.2843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E8" />
          <stop offset="1" stopColor="#C2C2CA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LiveIcon;
