import React from 'react';

function CheckedIcon() {
  return (
    <svg width="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Checked icon</title>
      <g id="Precio" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="precio"
          transform="translate(-548.000000, -466.000000)"
          fill="#00DC5A"
          fillRule="nonzero"
        >
          <g id="icons8-checked" transform="translate(548.000000, 466.000000)">
            <path
              d="M10.5,0 C4.70996137,0 0,4.70996137 0,10.5 C0,16.2900395 4.70996137,21 10.5,21 C16.2900395,21 21,16.2900395 21,10.5 C21,4.70996137 16.2900395,0 10.5,0 Z M10.5,1.75 C15.3432615,1.75 19.25,5.6567385 19.25,10.5 C19.25,15.3432615 15.3432615,19.25 10.5,19.25 C5.6567385,19.25 1.75,15.3432615 1.75,10.5 C1.75,5.6567385 5.6567385,1.75 10.5,1.75 Z M14.1640625,5.3046875 C14.0341801,5.32519575 13.9248051,5.39355513 13.8359375,5.5234375 L9.54296875,11.8945312 L7.51953125,9.8984375 C7.34521462,9.63525412 7.01025412,9.61474587 6.8359375,9.7890625 L6.04296875,10.5820312 C5.86865212,10.8452146 5.86865212,11.2006834 6.04296875,11.375 L9.10546875,14.4375 C9.27978537,14.5263676 9.52587912,14.7109375 9.7890625,14.7109375 C9.96337912,14.7109375 10.2436521,14.6186521 10.4179687,14.3554687 L15.6679687,6.64453125 C15.8422854,6.38134787 15.7431645,6.13525412 15.3945312,5.9609375 L14.5195312,5.33203125 C14.4306645,5.28759788 14.2939457,5.28418013 14.1640625,5.3046875 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckedIcon;
