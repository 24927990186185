import React from 'react';
import { useTranslation } from 'Hooks';
import './styles.scss';
import { LINKS } from './links';

const MediaBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="mod_talkaboutus">
      <div className="incenter">
        <div className="compound_title">
          <div className="icon" style={{ backgroundImage: 'url(/images/talkaboutus.svg)' }} />
          <div className="text">{t('TalkAboutUs.title')}</div>
        </div>
        <div className="media_logos_wr">
          {LINKS.map(({ icon, link }) => (
            <a href={link} rel="noreferrer" target="_blank" key={link}>
              <img src={icon} alt="media logo" className="media_logo" />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MediaBlock;
