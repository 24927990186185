import React from 'react';

function PersonalDataIcon() {
  return (
    <svg width="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Datos personales</title>
      <g id="como-funciona" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="c_funciona" transform="translate(-708.000000, -670.000000)" fillRule="nonzero">
          <g id="d_perso_ico" transform="translate(708.000000, 670.000000)">
            <path
              d="M22.2036792,15.1028182 C23.1084906,14.1357273 23.6603774,12.8561818 23.6603774,11.4545455 C23.6603774,8.44690909 21.1211321,6 18,6 C16.490283,6 15.0575472,6.56227273 13.965566,7.58318182 C12.8562264,8.62027273 12.245283,9.99518182 12.245283,11.4545455 C12.245283,12.8196364 12.78,14.1106364 13.7569811,15.1206364 C10.4886792,16.6467273 8,19.967 8,23.9090909 L8,26.6363636 C8,27.3894545 8.63358491,28 9.41509434,28 L26.5849057,28 C27.3664151,28 28,27.3894545 28,26.6363636 L28,23.9090909 C28,19.9889091 25.5271698,16.6370909 22.2036792,15.1028182 Z M18,8.72727273 C19.560566,8.72727273 20.8301887,9.95072727 20.8301887,11.4545455 C20.8301887,12.9583636 19.560566,14.1818182 18,14.1818182 C16.414717,14.1818182 15.0754717,12.9329091 15.0754717,11.4545455 C15.0754717,9.97618182 16.414717,8.72727273 18,8.72727273 Z M25.1698113,25.2727273 L10.8301887,25.2727273 L10.8301887,23.9090909 C10.8301887,20.1147273 14.1135849,16.9090909 18,16.9090909 C21.8864151,16.9090909 25.1698113,20.1147273 25.1698113,23.9090909 L25.1698113,25.2727273 Z"
              id="Shape"
              fill="#00DC5A"
            />
            <path
              d="M36.75,25.5 L36.75,1.40625 C36.75,0.629625 36.120375,0 35.34375,0 L11.0086875,0 C9.8818125,0 8.8224375,0.43884375 8.02565625,1.235625 L1.235625,8.02565625 C0.43884375,8.8224375 0,9.8818125 0,11.0086875 L0,43.78125 C0,44.557875 0.629625,45.1875 1.40625,45.1875 L29.3175938,45.1875 C31.3011563,46.936875 33.9035625,48 36.75,48 C42.95325,48 48,42.95325 48,36.75 C48,30.54675 42.95325,25.5 36.75,25.5 Z M8.4375,4.80121875 L8.4375,8.4375 L4.80121875,8.4375 L8.4375,4.80121875 Z M2.8125,42.375 L2.8125,11.25 L9.84375,11.25 C10.620375,11.25 11.25,10.620375 11.25,9.84375 L11.25,2.8125 L33.9375,2.8125 L33.9375,25.8559688 C30.9855938,26.6185313 28.4998125,28.5525 27.0072188,31.1300625 C26.97375,31.1277188 26.940375,31.125 26.90625,31.125 L9.84375,31.125 C9.067125,31.125 8.4375,31.754625 8.4375,32.53125 C8.4375,33.307875 9.067125,33.928125 9.84375,33.928125 L25.8559688,33.928125 C25.6236563,34.827375 25.5,35.7792188 25.5,36.75 L9.84375,36.75 C9.067125,36.75 8.4375,37.379625 8.4375,38.15625 C8.4375,38.932875 9.067125,39.5625 9.84375,39.5625 L25.8559688,39.5625 C26.1136875,40.5600938 26.505375,41.50425 27.0102188,42.375 L2.8125,42.375 Z M36.75,45.1875 C32.0975625,45.1875 28.3125,41.4024375 28.3125,36.75 C28.3125,32.0975625 32.0975625,28.3125 36.75,28.3125 C41.4024375,28.3125 45.1875,32.0975625 45.1875,36.75 C45.1875,41.4024375 41.4024375,45.1875 36.75,45.1875 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M41.5972625,32.4393357 C41.0603708,31.8535381 40.1897208,31.8535381 39.6528292,32.4394357 L35.1250458,37.3787151 L33.3472625,35.4394232 C32.8103708,34.8536256 31.9397208,34.8536256 31.4027375,35.4394232 C30.8657542,36.0252207 30.8657542,36.9749168 31.4027375,37.5607143 L34.1527375,40.5607018 C34.4212292,40.8536006 34.7731375,41 35.1250458,41 C35.4769542,41 35.8288625,40.8536006 36.0972625,40.5606018 L41.5972625,34.5606268 C42.1342458,33.9748293 42.1342458,33.0251332 41.5972625,32.4393357 Z"
              id="Path"
              fill="#00DC5A"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PersonalDataIcon;
