import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ComparePlanCard } from 'Components';
import { useTranslation } from 'Hooks';
import CrossIcon from 'Assets/icons/plans/CrossIcon';
import CheckIcon from 'Assets/icons/plans/CheckIcon';
import { PLANS, PLANS_FEATURES } from 'Constants/plans';
import './styles.scss';

const ComparePlanTabs = () => {
  const { t } = useTranslation('pricing');

  const getFeatureIcon = (item, feature) => {
    const icon = item ? <CheckIcon /> : <CrossIcon />;
    return (
      <div key={feature}>
        <p className="plan-feature-item-inline">{t(feature)}</p>
        <div className="plan-feature-icon-inline">{icon}</div>
      </div>
    );
  };

  const getFeatureSection = (section, index) => {
    const features = section.map((_item, ix) =>
      getFeatureIcon(_item, PLANS_FEATURES[index].features[ix])
    );
    return (
      <div key={index}>
        <p className="plan-feature-section">{t(PLANS_FEATURES[index].title)}</p>
        {features}
      </div>
    );
  };

  return (
    <div className="plans-compare-tabs">
      <Tabs
        selectedTabClassName="plans-compare-tab--selected"
        selectedTabPanelClassName="plans-compare-tabpanel--selected"
      >
        <TabList className="plans-compare-tab-list">
          {PLANS.map(({ name }) => (
            <Tab className="plans-compare-tab" key={name}>
              {t(name)}
            </Tab>
          ))}
        </TabList>

        {PLANS.map(({ name, Icon, price, isPopular, isYearly, features, button }) => (
          <TabPanel className="plans-compare-tabpanel" key={name}>
            <ComparePlanCard
              name={name}
              Icon={Icon}
              price={price}
              isPopular={isPopular}
              isYearly={isYearly}
              showShadow
              button={button}
            />
            <div>{features.map((section, index) => getFeatureSection(section, index))}</div>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default ComparePlanTabs;
