import { useStaticQuery, graphql } from 'gatsby';

export function useMediaQuery() {
  const queryData = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Media" }, data: { Name: { ne: null } } }
        sort: { fields: id, order: DESC }
      ) {
        nodes {
          data {
            ID
            Name
            Snippet
            Link
            Image
            Interview
          }
        }
      }
    }
  `);

  const {
    allAirtable: { nodes },
  } = queryData;

  const data = nodes.map(item => item.data);

  return { data };
}

export default useMediaQuery;
