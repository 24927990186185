import React from 'react';
import { useTranslation } from 'Hooks';
import { PLANS_COMPARE_ANCHOR } from 'Constants/routes';
import { ComparePlanCard, ComparePlanTabs } from 'Components';
import CrossIcon from 'Assets/icons/plans/CrossIcon';
import CheckIcon from 'Assets/icons/plans/CheckIcon';
import { PLANS, PLANS_FEATURES } from 'Constants/plans';
import './styles.scss';

const PlansCompareBlock = () => {
  const { t } = useTranslation('pricing');

  const getFeatureIcon = (item, ix) => {
    const icon = item ? <CheckIcon /> : <CrossIcon />;
    return (
      <div className="plan-feature-icon" key={ix}>
        {icon}
      </div>
    );
  };

  const getFeatureSection = (section, index) => {
    const features = section.map((_item, ix) => getFeatureIcon(_item, ix));
    return (
      <div className="plan-features-section" key={index}>
        {features}
      </div>
    );
  };

  return (
    <section className="plans-compare-block block" id={PLANS_COMPARE_ANCHOR}>
      <div className="plans-compare-container">
        <h2 className="plans-compare-title">{t('ComparePlan.Section.title')}</h2>
        <p className="plans-compare-subtitle">{t('ComparePlan.Section.subtitle')}</p>

        <div className="plans-compare-block__content">
          <div>
            <div className="plans-compare-empty" />
            {PLANS_FEATURES.map(({ title, features }) => (
              <div key={title}>
                <p className="plan-feature-section">{t(title)}</p>
                {features.map(feature => (
                  <p className="plan-feature-item" key={feature}>
                    {t(feature)}
                  </p>
                ))}
              </div>
            ))}
          </div>

          {PLANS.map(({ name, Icon, price, isYearly, isPopular, features, button }) => (
            <div key={name} className={isPopular ? 'plan-feature-container' : ''}>
              {isPopular && <div className="popular">{t('ComparePlan.popular')}</div>}
              <ComparePlanCard
                name={name}
                Icon={Icon}
                price={price}
                isYearly={isYearly}
                showShadow={!isPopular}
                button={button}
              />
              <div>{features.map((section, ix) => getFeatureSection(section, ix))}</div>
            </div>
          ))}
        </div>
        <ComparePlanTabs />
      </div>
    </section>
  );
};

export default PlansCompareBlock;
