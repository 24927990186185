import React, { useState } from 'react';
import Link from 'Components/Link';
import { useTranslation, useLandingsSEO } from 'Hooks';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { config } from 'Config';
import * as routes from 'Constants/routes';
import { ArrowDownIcon, ArrowUpIcon } from 'Assets/icons';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import './styles.scss';

const Header = () => {
  const { nodes } = useLandingsSEO();
  const { t } = useTranslation();

  const handleOnClick = () => {
    document.querySelector('.responsive_menu').classList.toggle('active');
    document.querySelector('.right').classList.toggle('is-open');
  };

  const [showUp, setShowUp] = useState(false);
  const menuChanged = event => setShowUp(event.open);

  return (
    <header className="topbar">
      <div className="left">
        <Link to="/" className="logo" title={t('Footer.Logo.alt')} />
      </div>
      <div className="right">
        <nav>
          <p className="topbar_link basic">
            <Link to={routes.HOW_IT_WORKS_ROUTE} data-testid={`${routes.HOW_IT_WORKS_ROUTE}-link`}>
              {t('Footer.Links.howItWorks')}
            </Link>
          </p>
          <p className="topbar_link basic">
            <Link to={routes.PRICE_ROUTE} data-testid={`${routes.PRICE_ROUTE}-link`}>
              {t('Footer.Links.planAndPricing')}
            </Link>
          </p>
          <p className="topbar_link basic">
            <Link to={routes.SECURITY_ROUTE} data-testid={`${routes.SECURITY_ROUTE}-link`}>
              {t('Footer.Links.security')}
            </Link>
          </p>
          <Menu
            className="my-menu"
            onMenuChange={menuChanged}
            menuButton={
              <button type="button" className="topbar_link basic header-menu">
                {t('NavBar.moreInfo')}
                {showUp ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </button>
            }
            transition
          >
            <MenuItem>
              <a href={routes.BLOG_ROUTE} className="topbar_link menu">
                {t('Footer.Links.blog')}
              </a>
            </MenuItem>
            {nodes.map(({ data: { type, Path } }) => (
              <MenuItem key={Path}>
                <Link to={`/seo${Path}`} className="topbar_link menu" key={type}>
                  {t(`seo-types:${type}`)}
                </Link>
              </MenuItem>
            ))}
          </Menu>
          <a href={`${config.WEBAPP_ROUTE}/signup`} className="topbar_link solid_btn green">
            {t('NavBar.RegisterButton')}
          </a>
          <a href={`${config.WEBAPP_ROUTE}/login`} className="topbar_link line_btn loginbtn">
            {t('NavBar.LogInButton')}
          </a>
        </nav>
      </div>
      <button type="button" className="responsive_menu" onClick={handleOnClick}>
        <div className="line first" />
        <div className="line second" />
        <div className="line third" />
      </button>
    </header>
  );
};

export default Header;
