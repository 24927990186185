import styled, { css } from 'styled-components';
import Link from 'Components/Link';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH } from 'Styles/sizes';

const sharedStyle = css`
  text-align: center;
  border: 1px solid;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  padding: 1rem 2rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  min-width: ${pxToRem(272)};
  display: inline-block;
  border-color: ${({ theme: { colors } }) => colors.primaryGreen};
  background-color: ${({ theme: { colors } }) => colors.primaryGreen};
  :hover,
  :focus,
  :active {
    background-color: ${({ theme: { colors } }) => colors.shadowGreen};
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    padding: ${pxToRem(12)} ${pxToRem(24)};
    font-size: ${pxToRem(14)};
  }
`;

export const StyledLink = styled(Link)`
  ${sharedStyle}
`;

export const CustomLink = styled.a`
  ${sharedStyle}
`;
