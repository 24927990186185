import React from 'react';
import { Text, Heading } from '@taxdown/piccolo';
import { Section, Container } from 'Components/UI';
import CheckedIcon from 'Assets/icons/CheckedIcon';
import { useTranslation } from 'Hooks';
import { SECURITY_OPTION_LIST } from './data';

import {
  OptionListContainer,
  OptionContainer,
  ImageContainer,
  CheckedOptionsContainer,
  IconTextContainer,
  TextContainer,
  FirstSectionContainer,
} from './styles';

function FirstSection() {
  const { t } = useTranslation('security');
  return (
    <Section>
      <Container>
        <FirstSectionContainer>
          <Heading type="h2">{t('Security.FirstSection.title')}</Heading>
          <OptionListContainer>
            {SECURITY_OPTION_LIST.map(({ Icon, imageUrl, title, options }, index) => (
              <OptionContainer key={title}>
                <ImageContainer withTranslate={index === 0}>
                  {Icon ? <Icon /> : <img src={imageUrl} alt={title} />}
                </ImageContainer>
                <TextContainer>
                  <Text size="m" bold mb={20}>
                    {t(title)}
                  </Text>
                  <CheckedOptionsContainer>
                    {options.map(option => (
                      <IconTextContainer key={option}>
                        <CheckedIcon />
                        <Text>{t(option)}</Text>
                      </IconTextContainer>
                    ))}
                  </CheckedOptionsContainer>
                </TextContainer>
              </OptionContainer>
            ))}
          </OptionListContainer>
        </FirstSectionContainer>
      </Container>
    </Section>
  );
}

export default FirstSection;
