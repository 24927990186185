import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { config } from 'Config';

const locizeOptions = {
  projectId: config.LOCIZE_PROJECT_ID,
  referenceLng: 'es',
  version: config.LOCIZE_VERSION,
};

i18n
  .use(Backend)
  .use(LastUsed)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: ['en', 'es'],
    lng: 'es',
    fallbackLng: 'es',
    ns: [
      'common',
      'about',
      'careers',
      'home',
      'security',
      'how-it-works',
      'landing-seo',
      'landings-partners',
      'seo-types',
      'Landings',
      'pricing',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    react: {
      bindI18n: 'languageChanged editorSaved',
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
