import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FlexContainer, Text, Heading } from '@taxdown/piccolo';
import { usersSavings } from 'Constants/widget';
import { getRandomIntInRange, shuffleArray } from 'Utils/widget';
import { useCountdownTimer, useTranslation } from 'Hooks';
import './styles.scss';

const SavingWidget = () => {
  const { t } = useTranslation();
  const [savings, setSavings] = useState([]);
  const [randomTime, setRandomTime] = useState(2000);
  const [count, setCount] = useState(0);
  const users = useMemo(() => shuffleArray(usersSavings), []);

  const deadline = new Date(Date.UTC(2021, 5, 30, 21, 59, 59));
  const { isDeadline } = useCountdownTimer({ deadline });

  const stickyRef = useRef(null);

  useEffect(() => {
    const counterTimer = setInterval(() => {
      setCount(prev => prev + 1);
    }, 4000);
    return () => {
      clearInterval(counterTimer);
    };
  }, []);

  useEffect(() => {
    if (count === users.length) {
      setCount(0);
    }
  }, [count]);

  useEffect(() => {
    const currentUser = users[count];
    const dataTimer = setTimeout(() => {
      setRandomTime(getRandomIntInRange(4000, 5000));
      setSavings(prev => [currentUser, ...prev]);
    }, randomTime);
    return () => clearTimeout(dataTimer);
  }, [randomTime]);

  return (
    <>
      {!isDeadline && (
        <FlexContainer
          direction="column"
          className="savings-notifications__container"
          ref={stickyRef}
        >
          {savings.slice(0, 1).map(item => (
            <article
              key={item.name}
              className="savings-notifications__card appear"
              smooth={count === 2}
            >
              <p className="savings-notifications__timeago">{t(item.time)}</p>
              <div className="savings-notifications__wrapper">
                <Heading
                  size="small"
                  color="violet"
                  type="h2"
                  className="savings-notifications__text"
                >
                  <span role="img" aria-label="Party Icon">
                    &#127881;{' '}
                  </span>
                  {item.name}
                  <p type="h2">{t(item.text)}</p>
                </Heading>
                {item.amount && (
                  <Text
                    color="primaryGreen"
                    size="xm"
                    ml={10}
                    bold
                    className="savings-notifications__amount"
                  >
                    {item.amount}
                  </Text>
                )}
              </div>
            </article>
          ))}
        </FlexContainer>
      )}
    </>
  );
};
export default SavingWidget;
