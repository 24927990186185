import React from 'react';
import PropTypes from 'prop-types';

function DiffTwo({ title }) {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="diff_two_svg_title"
    >
      <title id="diff_two_svg_title">{title}</title>
      <rect
        x="17.5"
        y="18"
        width="64.29"
        height="65.2217"
        rx="5.50749"
        fill="white"
        stroke="#3D3A62"
        strokeWidth="3"
      />
      <circle
        cx="26"
        cy="23.5"
        r="15.5"
        fill="white"
        fillOpacity="0.6"
        stroke="#3D3A62"
        strokeWidth="3"
      />
      <line
        x1="37.6213"
        y1="35"
        x2="51.5"
        y2="48.8787"
        stroke="#3D3A62"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M15.5 23C15.5 17.4772 20.4249 13 26.5 13"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M25.5 74.5L39.5 59L62 69L73.5 37"
        stroke="#99F1BD"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DiffTwo.propTypes = {
  title: PropTypes.string,
};

DiffTwo.defaultProps = {
  title: 'Gráfico con una lupa',
};

export default DiffTwo;
