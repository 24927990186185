import { BNEXT_COUPON, FINTONIC_COUPON } from 'Constants/partners';
import * as routes from 'Constants/routes';
import { config } from 'Config';
import { i18n } from 'Services';
import invalidEmails from 'Assets/json/partners/invalidEmails.json';

export const isValidEmail = value => {
  const domain = value.split('@')[1];
  return !invalidEmails.includes(`@${domain}`);
};

export const getErrorMessage = () => i18n.t('Partners.errorEmail');

export const hasSmartBanner = () => {
  const { pathname } = window.location;
  const path = pathname.split('/')[1];
  return path !== 'partners';
};

export const setEvoTags = () => {
  if (window && window.gtag) {
    window.gtag('event', 'Formulario Validacion Cuenta', {
      event_category: 'Alianza taxdown',
      event_label: 'Alta correcta',
    });
  }
};

export const redirectTo = coupon => {
  let route;
  switch (coupon) {
    case BNEXT_COUPON:
      route = routes.BNEXT_TAXDONW_ROUTE;
      break;
    case FINTONIC_COUPON:
      route = routes.FINTONIC_TAXDONW_ROUTE;
      break;
    default:
      route = config.WEBAPP_ROUTE;
      break;
  }
  window.location.href = route;
};
