import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'Components/UI';
import { MainBannerWrapper } from './styles';

function MainBanner({ children, backgroundImage, className }) {
  const [headerHeight, setHeaderHeight] = useState();
  useLayoutEffect(() => {
    const header = document.querySelector('header');
    const height = `${header?.clientHeight}px`;
    setHeaderHeight(height);
  }, []);

  return (
    <MainBannerWrapper
      backgroundImage={backgroundImage}
      headerHeight={headerHeight}
      className={className}
    >
      <Container>{children}</Container>
    </MainBannerWrapper>
  );
}

MainBanner.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

MainBanner.defaultProps = {
  className: null,
};

export default MainBanner;
