import React from 'react';

function FiscalDataIcon() {
  return (
    <svg width="40px" viewBox="0 0 40 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Datos fiscales</title>
      <g id="como-funciona" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="c_funciona" transform="translate(-909.000000, -668.000000)" fillRule="nonzero">
          <g id="d_fiscales_ico" transform="translate(909.000000, 668.000000)">
            <path
              d="M38.4615385,2.90625 L27.6923077,2.90625 L27.6923077,1.40625 C27.6923077,0.629516625 27.0036057,0 26.1538462,0 L13.8461538,0 C12.9963943,0 12.3076923,0.629516625 12.3076923,1.40625 L12.3076923,2.90625 L1.53846154,2.90625 C0.688701949,2.90625 0,3.53576662 0,4.3125 L0,46.59375 C0,47.3704834 0.688701949,48 1.53846154,48 L38.4615385,48 C39.3112981,48 40,47.3704834 40,46.59375 L40,4.3125 C40,3.53576662 39.3112981,2.90625 38.4615385,2.90625 Z M15.3846154,2.8125 L24.6153846,2.8125 L24.6153846,5.71875 L15.3846154,5.71875 L15.3846154,2.8125 Z M36.9230769,45.1875 L3.07692308,45.1875 L3.07692308,5.71875 L12.3076923,5.71875 L12.3076923,7.125 C12.3076923,7.90173338 12.9963943,8.53125 13.8461538,8.53125 L26.1538462,8.53125 C27.0036057,8.53125 27.6923077,7.90173338 27.6923077,7.125 L27.6923077,5.71875 L36.9230769,5.71875 L36.9230769,45.1875 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M7.55555556,11 C6.69635419,11 6,11.6714844 6,12.5 C6,13.3285156 6.69635419,14 7.55555556,14 L32.4444444,14 C33.3036458,14 34,13.3285156 34,12.5 C34,11.6714844 33.3036458,11 32.4444444,11 L7.55555556,11 Z"
              id="Path"
              fill="#00DC5A"
            />
            <path
              d="M14.7979798,26.8238446 C13.8270536,26.8238446 13.0272453,27.091424 12.3985308,27.6265908 C11.7698163,28.1617575 11.3639434,28.9379275 11.1808999,29.9551239 L15.9917355,29.9551239 L15.9917355,31.9591427 L11.0018365,31.9591427 L10.9779614,32.3576691 L10.9779614,32.8928332 L11.0018365,33.2685867 L15.2396694,33.2685867 L15.2396694,35.2953784 L11.204775,35.2953784 C11.610654,37.1399958 12.8879599,38.0622907 15.0367309,38.0622907 C16.1747838,38.0622907 17.2690489,37.8459499 18.3195592,37.4132619 L18.3195592,40.3281983 C17.3963835,40.7760683 16.2304937,41 14.8218549,41 C12.8720441,41 11.2684483,40.4952049 10.0110193,39.4855995 C8.75359028,38.4759941 7.96174023,37.0792677 7.63544536,35.2953784 L6,35.2953784 L6,33.2685867 L7.40863177,33.2685867 C7.37679813,33.0939933 7.36088154,32.8586753 7.36088154,32.5626256 L7.38475666,31.9591427 L6,31.9591427 L6,29.9551239 L7.58769513,29.9551239 C7.88215635,28.1180975 8.67400641,26.6663371 9.96326905,25.5997991 C11.2525317,24.533261 12.8640858,24 14.7979798,24 C16.2941611,24 17.6948205,24.3112271 19,24.9336906 L17.8301194,27.5753516 C17.280989,27.3400301 16.7676792,27.1559507 16.2901745,27.0231078 C15.8126698,26.890265 15.3152765,26.8238446 14.7979798,26.8238446 Z"
              id="€"
              fill="#00DC5A"
            />
            <path
              d="M32.4444444,17 L7.55555556,17 C6.69635419,17 6,17.6714844 6,18.5 C6,19.3285156 6.69635419,20 7.55555556,20 L32.4444444,20 C33.3036458,20 34,19.3285156 34,18.5 C34,17.6714844 33.3036458,17 32.4444444,17 Z"
              id="Path"
              fill="#5C5C5C"
            />
            <path
              d="M31.9,22 L23.1,22 C22.4924219,22 22,22.6714844 22,23.5 C22,24.3285156 22.4924219,25 23.1,25 L31.9,25 C32.5075781,25 33,24.3285156 33,23.5 C33,22.6714844 32.5075781,22 31.9,22 Z"
              id="Path"
              fill="#5C5C5C"
            />
            <path
              d="M31.9,28 L23.1,28 C22.4924219,28 22,28.6714844 22,29.5 C22,30.3285156 22.4924219,31 23.1,31 L31.9,31 C32.5075781,31 33,30.3285156 33,29.5 C33,28.6714844 32.5075781,28 31.9,28 Z"
              id="Path"
              fill="#5C5C5C"
            />
            <path
              d="M31.9,34 L23.1,34 C22.4924219,34 22,34.6714844 22,35.5 C22,36.3285156 22.4924219,37 23.1,37 L31.9,37 C32.5075781,37 33,36.3285156 33,35.5 C33,34.6714844 32.5075781,34 31.9,34 Z"
              id="Path"
              fill="#5C5C5C"
            />
            <path
              d="M31.9,40 L23.1,40 C22.4924219,40 22,40.6714844 22,41.5 C22,42.3285156 22.4924219,43 23.1,43 L31.9,43 C32.5075781,43 33,42.3285156 33,41.5 C33,40.6714844 32.5075781,40 31.9,40 Z"
              id="Path"
              fill="#5C5C5C"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FiscalDataIcon;
