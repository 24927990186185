import React from 'react';
import './styles.scss';

const LogoLoader = () => {
  return (
    <div className="loader">
      <div className="loader__container">
        <div className="loader__square first" />
        <div className="loader__square second" />
        <div className="loader__square third" />
      </div>
    </div>
  );
};

export default LogoLoader;
