import React from 'react';

function FaqIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      fill="none"
      viewBox="0 0 110 110"
    >
      <path
        fill="#5C5C5C"
        d="M38.243 110c-.56 0-1.109-.218-1.52-.629l-8.824-8.824H15.04C6.747 100.547 0 93.8 0 85.507V52.423c0-8.293 6.747-15.04 15.04-15.04h21.698a2.149 2.149 0 0 1 0 4.298H15.04c-5.923 0-10.742 4.819-10.742 10.742v33.086c0 5.923 4.819 10.742 10.742 10.742H29c.88 0 1.639.529 1.97 1.29l5.129 5.128.003-4.271c0-1.186.962-2.147 2.148-2.147h23.196c5.924 0 10.742-4.819 10.742-10.742V70.684a2.149 2.149 0 0 1 4.297 0v14.824c0 8.292-6.746 15.039-15.039 15.039h-21.05l-.004 7.306A2.148 2.148 0 0 1 38.243 110z"
      />
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M78.773 2.148h-5.437c-16.06 0-29.078 13.02-29.078 29.079s13.018 29.078 29.078 29.078h5.438a29.07 29.07 0 0 0 8.551-1.279l8.925 8.92V54.467c7.046-5.307 11.602-13.742 11.602-23.242 0-16.059-13.02-29.078-29.079-29.078z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M94.731 69.465a2.148 2.148 0 0 0 3.667-1.52v-12.43a31.533 31.533 0 0 0 8.209-10.12c2.252-4.413 3.393-9.18 3.393-14.168C110 14.008 95.992 0 78.773 0h-5.437C56.118 0 42.11 14.008 42.11 31.227c0 17.218 14.009 31.226 31.227 31.226h5.437c2.688 0 5.348-.341 7.926-1.016l8.032 8.028zm-5.887-11.959a2.149 2.149 0 0 0-2.15-.534 26.937 26.937 0 0 1-7.92 1.184h-5.438c-14.85 0-26.93-12.08-26.93-26.93 0-14.848 12.08-26.93 26.93-26.93h5.438c14.849 0 26.929 12.082 26.929 26.93 0 8.537-3.916 16.382-10.746 21.526a2.149 2.149 0 0 0-.855 1.716v8.293l-5.258-5.255z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M76.656 40.125a2.149 2.149 0 0 1-2.148-2.148v-5.162a4.066 4.066 0 0 1 3.203-3.985c2.327-.52 3.897-2.635 3.732-5.028a4.802 4.802 0 0 0-4.442-4.442 4.765 4.765 0 0 0-3.618 1.278 4.753 4.753 0 0 0-1.526 3.509 2.149 2.149 0 0 1-4.297 0 9.129 9.129 0 0 1 2.89-6.65 9.026 9.026 0 0 1 6.846-2.424c4.498.31 8.124 3.936 8.434 8.434.308 4.473-2.596 8.434-6.925 9.48v4.99a2.148 2.148 0 0 1-2.149 2.148zM76.656 48.625c-.565 0-1.12-.228-1.519-.627-.4-.4-.63-.954-.63-1.521 0-.566.23-1.12.63-1.52.4-.399.954-.629 1.52-.629.564 0 1.119.23 1.518.63.4.4.63.953.63 1.519 0 .567-.23 1.119-.63 1.52-.4.4-.954.628-1.519.628zM59.082 70.898H13.75a2.148 2.148 0 0 1 0-4.296h45.332a2.149 2.149 0 0 1 0 4.296zM59.082 82.93c-.565 0-1.12-.23-1.519-.63-.4-.4-.63-.954-.63-1.519s.23-1.12.63-1.519c.4-.4.954-.63 1.519-.63s1.12.23 1.519.63c.4.4.63.954.63 1.52 0 .566-.23 1.119-.63 1.518-.4.4-.954.63-1.519.63zM50.632 82.93H13.75a2.149 2.149 0 0 1 0-4.297h36.882a2.149 2.149 0 0 1 0 4.297zM45.117 58.867H13.75a2.149 2.149 0 0 1 0-4.297h31.367a2.149 2.149 0 0 1 0 4.297z"
      />
    </svg>
  );
}

export default FaqIcon;
