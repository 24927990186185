import React from 'react';

function LogoFullGreyIcon() {
  return (
    <svg width="138px" viewBox="0 0 1402 216" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Logo full dark</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="logo_taxdown_color" transform="translate(-166,-505)" fill="#5c5c5c">
          <g id="logo_taxdown_mono_r" transform="translate(166,505)">
            <path
              d="M 393.8 85.9 L 378 85.9 L 378 53.5 L 395 53.5 L 395 18 L 435.4 18 L 435.4 53.5 L 461.3 53.5 L 461.3 85.9 L 435.4 85.9 L 435.4 123.7 C 435.4 137.6 449.6 139.6 457.5 139.6 C 460.8 139.6 463 139.3 463 139.3 L 463 175.3 C 463 175.3 458.4 176 451.7 176 C 432 176 393.8 170.7 393.8 129.2 L 393.8 85.9 Z"
              id="Fill-1"
            />
            <path
              d="M 529.5 145.4 C 540.3 145.4 549.9 133.7 549.9 123.6 L 549.9 121 L 546.1 121 C 532.9 121 517.8 124.8 517.8 135.1 C 517.8 140.9 521.4 145.4 529.5 145.4 M 547.8 97.3 L 549.5 97.3 L 549.5 95.1 C 549.5 84.9 541.5 81.5 531.2 81.5 C 515.6 81.5 497.8 93.2 497.8 93.2 L 483.4 64 C 483.4 64 504.8 48 535.3 48 C 570.1 48 591 66.9 591 98.3 L 591 174.1 L 553.1 174.1 L 553.1 166.7 C 553.1 162.6 553.5 159.1 553.5 159.1 L 553.1 159.1 C 553.1 159.1 543.9 177 518 177 C 495 177 476 161.7 476 137.3 C 476 101.4 528.6 97.3 547.8 97.3"
              id="Fill-3"
            />
            <path
              d="M 639 113.6 L 599 51 L 645.9 51 L 659.4 78.7 C 661.1 81.8 663.3 86.9 663.3 86.9 L 663.7 86.9 C 663.7 86.9 666.1 81.8 667.6 78.7 L 681.1 51 L 728 51 L 688.3 113.6 L 727.3 175 L 681.5 175 L 666.6 147.1 C 665.4 144.9 663.7 140.8 663.7 140.8 L 663.3 140.8 C 663.3 140.8 661.6 144.9 660.4 147.1 L 645.7 175 L 600 175 L 639 113.6 Z"
              id="Fill-5"
            />
            <path
              d="M 790.2 143.6 C 802.7 143.6 814.5 134.4 814.5 113 C 814.5 98.1 806.6 83.4 790.2 83.4 C 777.2 83.4 765.9 94 765.9 113 C 765.9 131.5 775.8 143.6 790.2 143.6 M 780.6 48.3 C 805.6 48.3 813.1 58.1 813.1 58.1 L 813.6 58.1 C 813.6 58.1 813.3 55.7 813.3 52.6 L 813.3 3 L 855 3 L 855 175.1 L 816 175.1 L 816 166.9 C 816 163.1 816.2 159.9 816.2 159.9 L 815.7 159.9 C 815.7 159.9 805.4 178 779.9 178 C 746.2 178 724 151.5 724 113 C 724 73.8 747.6 48.3 780.6 48.3"
              id="Fill-7"
            />
            <path
              d="M 938 142.5 C 953.1 142.5 966 131.3 966 112.9 C 966 94.2 953.1 82.5 938 82.5 C 922.9 82.5 910 94.2 910 112.9 C 910 131.3 922.9 142.5 938 142.5 M 937.8 48 C 976.8 48 1008 74.1 1008 112.9 C 1008 151.4 976.8 177 938 177 C 899.2 177 868 151.4 868 112.9 C 868 74.1 899.2 48 937.8 48"
              id="Fill-9"
            />
            <path
              d="M 1009 51 L 1052.8 51 L 1068.2 124.2 C 1069.7 130.9 1069.7 137.2 1069.7 137.2 L 1070.2 137.2 C 1070.2 137.2 1070.6 130.9 1072.3 124.2 L 1089.4 51 L 1125.1 51 L 1142.2 124.2 C 1143.8 130.9 1144.1 137.2 1144.1 137.2 L 1144.6 137.2 C 1144.6 137.2 1144.8 130.9 1146.2 124.2 L 1161.7 51 L 1205 51 L 1170.3 175 L 1123.9 175 L 1109.9 117.7 C 1108.2 111 1107.5 104.5 1107.5 104.5 L 1107 104.5 C 1107 104.5 1106.3 111 1104.6 117.7 L 1090.6 175 L 1043.7 175 L 1009 51 Z"
              id="Fill-11"
            />
            <path
              d="M 1215 50.9 L 1255 50.9 L 1255 61.7 C 1255 65.8 1254.5 69.4 1254.5 69.4 L 1255 69.4 C 1260.7 59.8 1272.5 48 1294.5 48 C 1319.5 48 1339 59.6 1339 94.5 L 1339 175 L 1297.3 175 L 1297.3 102.2 C 1297.3 90.7 1292.8 85.8 1283.6 85.8 C 1270.6 85.8 1262.7 93.1 1259.1 103.7 C 1257.4 108.5 1256.7 114 1256.7 120.1 L 1256.7 175 L 1215 175 L 1215 50.9 Z"
              id="Fill-13"
            />
            <path
              d="M 161.9 212.8 L 127.2 178.1 C 122.9 173.8 122.9 166.8 127.2 162.6 L 278.6 11.2 C 282.8 6.9 289.8 6.9 294.1 11.2 L 328.8 45.9 C 333.1 50.2 333.1 57.2 328.8 61.4 L 177.4 212.8 C 173.2 217.1 166.2 217.1 161.9 212.8"
              id="Fill-15"
            />
            <path
              d="M 100 149.8 L 65.2 115.2 C 60.9 111 60.9 104.1 65.2 99.8 L 158.4 7.2 C 162.7 2.9 169.7 2.9 174 7.2 L 208.8 41.8 C 213.1 46 213.1 52.9 208.8 57.2 L 115.6 149.8 C 111.3 154.1 104.3 154.1 100 149.8"
              id="Fill-17"
            />
            <path
              d="M 37.8 87.8 L 3.2 53.2 C -1.1 48.9 -1.1 42 3.2 37.8 L 37.8 3.2 C 42.1 -1.1 48.9 -1.1 53.2 3.2 L 87.8 37.8 C 92.1 42 92.1 48.9 87.8 53.2 L 53.2 87.8 C 48.9 92.1 42.1 92.1 37.8 87.8"
              id="Fill-19"
            />
            <g id="icons8-registered_trademark" transform="translate(1362,135)" fillRule="nonzero">
              <path
                d="M 20 0 C 9 0 0 9 0 20 C 0 31 9 40 20 40 C 31 40 40 31 40 20 C 40 9 31 0 20 0 Z M 20 3.1 C 29.4 3.1 36.9 10.6 36.9 20 C 36.9 29.4 29.4 36.9 20 36.9 C 10.6 36.9 3.1 29.4 3.1 20 C 3.1 10.6 10.6 3.1 20 3.1 Z M 13.8 10.8 L 13.8 29.2 L 16.9 29.2 L 16.9 22.5 L 20.9 22.5 L 25.2 29.2 L 28.3 29.2 L 23.8 22.2 C 24.4 22.1 24.8 22 25.2 21.8 C 26.2 21.5 26.8 21.1 27.4 20.5 C 27.9 20 28.3 19.4 28.5 18.6 C 28.6 18 28.8 17.2 28.8 16.6 C 28.8 16 28.6 15.4 28.5 14.6 C 28.3 14 28 13.4 27.4 12.8 C 26.9 12.2 26.2 11.7 25.2 11.4 C 24.3 10.9 23.1 10.8 21.7 10.8 L 13.8 10.8 Z M 16.9 13.2 L 20.9 13.2 C 21.4 13.2 22 13.2 22.6 13.4 C 23.2 13.4 23.7 13.5 24.1 13.8 C 24.6 14.2 25.1 14.5 25.2 14.8 C 25.5 15.2 25.7 15.7 25.7 16.4 C 25.7 17.1 25.5 17.7 25.2 18.2 C 24.9 18.6 24.6 18.9 24.1 19.2 C 23.7 19.4 23.1 19.6 22.6 19.7 C 22.1 19.9 21.5 19.9 20.9 19.9 L 16.9 19.9 L 16.9 13.2 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LogoFullGreyIcon;
