import React from 'react';
import { useTranslation, useMediaQuery } from 'Hooks';
import { Text, Heading } from '@taxdown/piccolo';
import { MainBanner } from 'Components';
import SimpleLogo from 'Assets/icons/SimpleLogo';
import ArrowIcon from 'Assets/icons/ArrowIcon';

import { LogoWrapper, MainSectionWrapper, CustomLink, MediaSnippetLink } from './styles';

const BANNER_IMAGE = '/images/media/main-banner.jpg';

function MainSection() {
  const { t } = useTranslation();
  const { data } = useMediaQuery();
  const MEDIA = data[0];
  return (
    <MainBanner backgroundImage={BANNER_IMAGE}>
      <MainSectionWrapper>
        <SimpleLogo />
        <Heading type="h2" color="white" mb={20}>
          {t('Media.MainBanner.title')}
        </Heading>
        <LogoWrapper>
          <img src={MEDIA.Image} alt="" />
        </LogoWrapper>
        <Text type="p" color="white">
          {MEDIA.Snippet}
        </Text>
        <MediaSnippetLink>
          <Text type="p" color="primaryGreen" bold>
            <CustomLink href={MEDIA.Link} target="_blank">
              {t('Media.MainBanner.textLink')}
            </CustomLink>
          </Text>
          <ArrowIcon />
        </MediaSnippetLink>
      </MainSectionWrapper>
    </MainBanner>
  );
}

export default MainSection;
