import React from 'react';
import withView from 'Hocs/withView';

const Page404 = () => {
  return (
    <div className="error-section">
      <h1>Oops!</h1>
      <h2>Lo sentimos 😔, no podemos encontrar lo que estás buscando.</h2>
    </div>
  );
};

export default withView(Page404);
