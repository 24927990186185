import React from 'react';
import ThemeProvider from '@taxdown/piccolo';
import PropTypes from 'prop-types';
import { GlobalStyle } from './GlobalStyle';

const Theme = ({ children }) => (
  <ThemeProvider>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
