import React from 'react';
import { useTranslation } from 'Hooks';
import './styles.scss';

import check from './img/check.png';
import clipping from './img/clipping.png';
import process from './img/process.png';
import star from './img/star.png';

const WhyUseTaxDownBlock = () => {
  const { t } = useTranslation('home');

  function ReasonBlock(image, title, description, style) {
    return (
      <div className="reason-block">
        <div className="image-container">
          <img src={image} alt={title} style={style} />
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    );
  }

  const defaultImgStyle = {
    width: 'auto',
    height: '60px',
  };

  return (
    <section className="why-use-tax-down">
      <h2>{t('WhyUseTaxDownBlock.title')}</h2>
      <div className="why-use-tax-down__container">
        {ReasonBlock(
          check,
          t('WhyUseTaxDownBlock.check.title'),
          t('WhyUseTaxDownBlock.check.description'),
          defaultImgStyle
        )}
        {ReasonBlock(
          clipping,
          t('WhyUseTaxDownBlock.clipping.title'),
          t('WhyUseTaxDownBlock.clipping.description'),
          defaultImgStyle
        )}
        {ReasonBlock(
          process,
          t('WhyUseTaxDownBlock.process.title'),
          t('WhyUseTaxDownBlock.process.description'),
          defaultImgStyle
        )}
        {ReasonBlock(
          star,
          t('WhyUseTaxDownBlock.star.title'),
          t('WhyUseTaxDownBlock.star.description'),
          {
            width: '68px',
            height: '80px',
          }
        )}
      </div>
    </section>
  );
};

export default WhyUseTaxDownBlock;
