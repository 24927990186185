import React from 'react';

function EmailIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="none" viewBox="0 0 27 30">
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M5.14 1.714h17.13v13.17l-4.525 4.83H9.447l-4.308-3.55V1.715z"
        clipRule="evenodd"
      />
      <path
        fill="#4F4F4F"
        fillRule="evenodd"
        d="M7.026 9.668c0-3.392 2.758-6.152 6.148-6.152 3.39 0 6.149 2.76 6.149 6.152a2.639 2.639 0 0 1-2.635 2.637c-.675 0-1.29-.255-1.757-.674a2.623 2.623 0 0 1-1.757.674 2.639 2.639 0 0 1-2.634-2.637 2.639 2.639 0 0 1 2.634-2.637 2.639 2.639 0 0 1 2.635 2.637v.013a.879.879 0 0 0 1.757-.013 4.398 4.398 0 0 0-4.392-4.395 4.398 4.398 0 0 0-4.391 4.395c0 1.504.759 2.889 2.03 3.704 1.303.835 2.918.914 4.433.217a.878.878 0 1 1 .734 1.597c-2.07.953-4.3.83-6.115-.334a6.134 6.134 0 0 1-2.839-5.184zm6.148.879a.88.88 0 0 0 .866-.738.88.88 0 0 1 .007-.242.88.88 0 0 0-.873-.778.88.88 0 0 0 0 1.758z"
        clipRule="evenodd"
      />
      <path
        fill="#4F4F4F"
        fillRule="evenodd"
        d="M26.349 11.542a.885.885 0 0 0-.257-.62l-3.256-3.259V.88A.879.879 0 0 0 21.957 0H4.391a.879.879 0 0 0-.878.879v6.784L.257 10.921a.068.068 0 0 0-.004.006l-.004.004a.883.883 0 0 0-.249.61V27.364A2.639 2.639 0 0 0 2.635 30h21.079a2.639 2.639 0 0 0 2.635-2.637V11.542zm-3.513-1.393l1.393 1.394-1.393 1.394v-2.788zM5.27 8.027v6.668l4.755 4.758h6.299l4.755-4.758V1.757H5.27v6.27zm-1.757 2.122v2.788L2.12 11.543l1.393-1.394zM8.42 20.332l-6.662-6.667v13.334l6.662-6.667zM3 28.242l7.026-7.031h6.299l7.026 7.031H3zm14.93-7.91l6.663 6.667V13.665l-6.662 6.667z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default EmailIcon;
