import React from 'react';
import { Text, Heading } from '@taxdown/piccolo';
import { Container, Section } from 'Components/UI';
import SimpleLogo from 'Assets/icons/SimpleLogo';
import { useTranslation } from 'Hooks';
import { FirstSectionWrapper, DescriptionWrapper, TextWithLogo } from './styles';

function FirstSection() {
  const { t } = useTranslation('careers');
  return (
    <Section testId="firstSection">
      <Container>
        <FirstSectionWrapper>
          <Heading type="h2" mb={24}>
            {t('Careers.FirstSection.title')}
          </Heading>
          <DescriptionWrapper>
            <Text>{t('Careers.FirstSection.description')}</Text>
          </DescriptionWrapper>
          <TextWithLogo>
            <SimpleLogo />
            <Heading type="h3" bold color="primaryGreen">
              {t('Careers.FirstSection.feature')}
            </Heading>
          </TextWithLogo>
        </FirstSectionWrapper>
      </Container>
    </Section>
  );
}

export default FirstSection;
