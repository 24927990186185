import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH, LARGE_DEVICE_WIDTH } from 'Styles/sizes';

export const SectionWrapper = styled.section`
  margin: ${pxToRem(72)} 0;
  background-color: ${props => props.backgroundColor};
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    padding: ${pxToRem(72)} 0;
  `}
  @media (max-width: ${LARGE_DEVICE_WIDTH}) {
    margin: ${pxToRem(60)} 0;
    ${({ backgroundColor }) =>
      backgroundColor &&
      `
    padding: ${pxToRem(60)} 0;
  `}
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    margin: ${pxToRem(40)} 0;
    ${({ backgroundColor }) =>
      backgroundColor &&
      `
    padding: ${pxToRem(40)} 0;
  `}
  }
`;
