import React from 'react';
import { Heading } from '@taxdown/piccolo';
import { useTranslation } from 'Hooks';
import { MainBanner } from 'Components';
import { TextImportant, MainSectionWrapper } from './styles';

const BANNER_IMAGE = '/images/careers/main-banner.jpg';

function MainSection() {
  const { t } = useTranslation('careers');
  return (
    <MainBanner backgroundImage={BANNER_IMAGE}>
      <MainSectionWrapper>
        <Heading type="h1" color="white">
          {t('Careers.MainSection.title')}
          <TextImportant>{t('Careers.MainSection.textImportant')}</TextImportant>
        </Heading>
      </MainSectionWrapper>
    </MainBanner>
  );
}

export default MainSection;
