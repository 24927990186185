import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH } from 'Styles/sizes';

export const StyledContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${pxToRem(30)};

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    padding: 0 ${pxToRem(30)};
  }
`;
