/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PageWrapper from 'Components/PageWrapper';
import { setTrack, setPage } from 'Utils/analytics';
import { trackData } from 'Constants/analytics';

export const wrapRootElement = ({ element }) => <PageWrapper>{element}</PageWrapper>;

export const onRouteUpdate = () => {
  setPage();
  setTrack(window.location.href, trackData.VIEW);
};
