import React from 'react';

function HolidaysIcon() {
  return (
    <svg width="57px" viewBox="0 0 57 80" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Vacaciones</title>
      <g id="trabaja-con-nosotros" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1133.000000, -864.000000)" fillRule="nonzero" id="vacaciones_icon">
          <g transform="translate(1133.000000, 864.000000)">
            <path
              d="M28.5,80 C12.825,80 0,66.7272727 0,50.3636364 L0,29.6363636 C0,13.2727273 12.825,0 28.5,0 C44.175,0 57,13.2727273 57,29.6363636 L57,50.5454545 C57,66.7272727 44.175,80 28.5,80 Z"
              id="Path"
              fill="#D7CCC8"
            />
            <path
              d="M29.5,9 C18.6863636,9 10,18.15 10,29.2735294 L10,49.5470588 C10,60.85 18.6863636,70 29.5,70 C40.3136364,70 49,60.85 49,49.7264706 L49,29.2735294 C49,18.15 40.3136364,9 29.5,9 Z"
              id="Path"
              fill="#448AFF"
            />
            <path
              d="M49,26 C47.1944444,16.9333333 39.0694444,10 29.5,10 C19.9305556,10 11.9861111,16.9333333 10,26 L49,26 Z"
              id="Path"
              fill="#BCAAA4"
            />
            <path
              d="M33.375,20 L23.625,20 C22.65,20 22,19.2 22,18 C22,16.8 22.65,16 23.625,16 L33.375,16 C34.35,16 35,16.8 35,18 C35,19.2 34.35,20 33.375,20 Z"
              id="Path"
              fill="#EFEBE9"
            />
            <path
              d="M44.6666667,42.2727273 C44.4888889,42.2727273 44.4888889,42.2727273 44.3111111,42.2727273 C43.7777778,40.6363636 42.5333333,39.3636364 40.9333333,38.8181818 C40.2222222,35.5454545 37.3777778,33 34,33 C30.6222222,33 27.7777778,35.3636364 27.0666667,38.6363636 C26.7111111,38.6363636 26.3555556,38.4545455 26,38.4545455 C23.6888889,38.4545455 21.9111111,40.0909091 21.5555556,42.4545455 C19.4222222,43.1818182 18,45.1818182 18,47.5454545 C18,50.6363636 20.3111111,53 23.3333333,53 L44.6666667,53 C47.6888889,53 50,50.6363636 50,47.5454545 C50,44.4545455 47.6888889,42.2727273 44.6666667,42.2727273 Z"
              id="Path"
              fill="#FFFFFF"
            />
            <path
              d="M28.5,73 C16.675,73 7,62.7333333 7,50.2666667 L7,29.55 C7,17.0833333 16.675,7 28.5,7 C40.325,7 50,17.0833333 50,29.55 L50,50.45 C50,62.9166667 40.325,73 28.5,73 Z M28.5,10.6666667 C18.6458333,10.6666667 10.5833333,19.1 10.5833333,29.55 L10.5833333,50.2666667 C10.5833333,60.7166667 18.6458333,69.3333333 28.5,69.3333333 C38.3541667,69.3333333 46.4166667,60.9 46.4166667,50.45 L46.4166667,29.55 C46.4166667,19.1 38.3541667,10.6666667 28.5,10.6666667 Z"
              id="Shape"
              fill="#A1887F"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HolidaysIcon;
