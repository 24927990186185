import React from 'react';

function ArrowDownIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 8L10.2941 12L5 8" stroke="#2F2E46" strokeWidth="2" />
    </svg>
  );
}

export default ArrowDownIcon;
