import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'Hooks';
import RatingStars from '../RatingStars';
import './styles.scss';

const Rating = ({ name, subtitle, review, rating }) => {
  const { t } = useTranslation();

  return (
    <div className="rating-box">
      <p className="rating-box__name">{t(name)}</p>
      <RatingStars rating={rating} />
      <p className="rating-box__subtitle">{t(subtitle)}</p>
      <p className="rating-box__review">{t(review)}</p>
    </div>
  );
};

Rating.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  rating: PropTypes.number,
};

Rating.defaultProps = {
  rating: 5,
};

export default Rating;
