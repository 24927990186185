import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { TABLET_WIDTH, MOBILE_WIDTH } from 'Styles/sizes';

export const PriceListWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.backgroundGrey};
`;

export const ThirdSectionContainer = styled.div`
  text-align: center;
  margin: 4rem 0;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: ${pxToRem(32)} 0;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 220, 90, 0.2);
  border-radius: 10px;
  padding: ${pxToRem(20)} 0;
  margin-left: 25%;
  p {
    margin: 0;
  }

  @media screen and (max-width: ${TABLET_WIDTH}) {
    margin-left: 0;
    div:last-child {
      display: none;
    }
    div {
      p {
        font-size: ${pxToRem(10)};
      }
    }
  }
`;

export const HeaderCell = styled.div`
  padding-left: 0.5rem;
  text-transform: uppercase;
  text-align: right;
  color: ${({ theme: { colors } }) => colors.baseGrey};
  text-align: center;
`;

export const SubText = styled.div`
  font-size: 10px;
`;

export const GraphRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: ${TABLET_WIDTH}) {
    flex-direction: column;
    margin: 0.25rem 0;
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    :nth-child(even) {
      background-color: #eeeeee;
    }
    padding: 0 0.75rem;
  }
`;

export const PersonCell = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  align-items: center;
  p {
    width: 10rem;
    align-self: center;
    font-style: italic;
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    background-color: transparent;
    width: 100%;
  }
`;

export const PersonImage = styled.div`
  max-width: 70px;
  margin-right: 20px;
  img {
    width: 100%;
    height: auto;
  }
`;

export const GraphCell = styled.div`
  flex-basis: 55%;

  img {
    width: 100%;
  }

  @media screen and (max-width: ${TABLET_WIDTH}) {
    margin: 1rem 0.25rem;
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    margin: 0;
    margin-top: ${pxToRem(32)};
    margin-bottom: 0.75rem;
  }
`;

export const PriceCell = styled.div`
  flex-basis: 10%;
  text-align: center;

  h3 {
    text-transform: uppercase;
    margin: 0;
  }
`;

export const PriceListTitle = styled.div`
  padding-top: ${pxToRem(60)};
  svg {
    margin-bottom: ${pxToRem(20)};
    g {
      g {
        path {
          fill: ${({ theme: { colors } }) => colors.primaryGreen};
        }
      }
    }
  }

  text-align: center;
  text-transform: uppercase;
`;

export const PriceFeatures = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin-bottom: ${pxToRem(60)};
  p {
    width: 100%;
    max-width: ${pxToRem(700)};
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
    text-align: center;
    p {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const PriceImage = styled.div`
  width: 100%;
  max-width: ${pxToRem(450)};
  img {
    width: 100%;
    height: auto;
  }
`;

export const PriceText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  h2 {
    font-size: ${pxToRem(32)};
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    text-align: right;
    h2 {
      font-size: ${pxToRem(21)};
    }
  }
`;

export const TextImportant = styled.span`
  color: ${({ theme: { colors } }) => colors.primaryGreen};
  font-weight: 700;
`;
