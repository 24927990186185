import React from 'react';

function ProIcon() {
  const random = Math.floor(Math.random() * 1000000);
  const id = `pro_icon_defs${random}`;
  const id2 = `pro_icon_defs1${random}`;
  const id3 = `pro_icon_defs2${random}`;
  const id4 = `pro_icon_defs3${random}`;
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="20"
        width="28.2843"
        height="28.2843"
        rx="3"
        transform="rotate(45 20 0)"
        fill="#2F2E46"
      />
      <path
        d="M13.4582 15.9299C13.7208 15.6674 14.1464 15.6674 14.409 15.9299L16.3104 17.8314C16.5729 18.0939 16.5729 18.5196 16.3104 18.7821L13.9336 21.1589C13.6711 21.4214 13.2454 21.4214 12.9829 21.1589L11.0815 19.2574C10.8189 18.9949 10.8189 18.5693 11.0815 18.3067L13.4582 15.9299Z"
        fill={`url(#${id})`}
      />
      <path
        d="M19.7955 16.3147C20.058 16.0522 20.4837 16.0522 20.7462 16.3147L22.6477 18.2161C22.9102 18.4787 22.9102 18.9043 22.6477 19.1669L17.2942 24.5203C17.0317 24.7829 16.606 24.7829 16.3435 24.5203L14.4421 22.6189C14.1795 22.3564 14.1795 21.9307 14.4421 21.6682L19.7955 16.3147Z"
        fill={`url(#${id2})`}
      />
      <path
        d="M26.194 16.6397C26.4565 16.3771 26.8821 16.3771 27.1447 16.6397L29.0461 18.5411C29.3086 18.8036 29.3086 19.2293 29.0461 19.4918L20.6566 27.8813C20.3941 28.1438 19.9684 28.1438 19.7059 27.8813L17.8044 25.9799C17.5419 25.7174 17.5419 25.2917 17.8044 25.0292L26.194 16.6397Z"
        fill={`url(#${id3})`}
      />
      <path
        d="M26.1862 27.8311L24.0097 25.6546L24.9401 24.7243C25.2491 24.4153 25.5682 24.2552 25.8974 24.244C26.2243 24.2305 26.5098 24.3458 26.7539 24.5899C26.9979 24.834 27.1144 25.1206 27.1032 25.4497C27.0897 25.7767 26.9274 26.0957 26.6161 26.407L26.1594 26.8638L26.6564 27.3609L26.1862 27.8311ZM25.7563 26.4607L26.24 25.9771C26.381 25.836 26.4516 25.6826 26.4516 25.5169C26.4516 25.3512 26.3889 25.2057 26.2635 25.0803C26.1381 24.9549 25.9925 24.8922 25.8268 24.8922C25.6611 24.8922 25.5089 24.9616 25.37 25.1004L24.883 25.5875L25.7563 26.4607ZM28.6269 25.3904L26.4505 23.2139L27.3809 22.2835C27.6899 21.9745 28.0089 21.8144 28.3381 21.8032C28.665 21.7898 28.9505 21.9051 29.1946 22.1492C29.3424 22.297 29.442 22.4683 29.4935 22.6631C29.545 22.8579 29.5439 23.0583 29.4902 23.2643L30.575 23.4423L30.0007 24.0166L29.161 23.8487C29.0916 23.9271 28.9886 24.0346 28.852 24.1712L28.6001 24.4231L29.0972 24.9202L28.6269 25.3904ZM28.197 24.02L28.6773 23.5397C28.8184 23.3986 28.8889 23.2453 28.8889 23.0796C28.8912 22.9116 28.8296 22.765 28.7042 22.6396C28.5788 22.5142 28.4333 22.4515 28.2676 22.4515C28.1019 22.4515 27.9485 22.522 27.8074 22.6631L27.3238 23.1467L28.197 24.02ZM30.3691 21.4718C30.5616 21.6644 30.7889 21.7663 31.0509 21.7774C31.3151 21.7864 31.5424 21.6957 31.7327 21.5054C31.9208 21.3173 32.0092 21.0923 31.9981 20.8303C31.9869 20.5683 31.885 20.341 31.6924 20.1485C31.5021 19.9581 31.2759 19.8574 31.0139 19.8462C30.752 19.835 30.5269 19.9234 30.3388 20.1115C30.1485 20.3018 30.0578 20.5291 30.0668 20.7933C30.078 21.0553 30.1787 21.2815 30.3691 21.4718ZM29.8787 21.9622C29.5652 21.6487 29.4085 21.2748 29.4085 20.8404C29.4107 20.4037 29.5775 20.0197 29.9089 19.6883C30.2403 19.3569 30.6232 19.1912 31.0576 19.1912C31.4942 19.189 31.8693 19.3446 32.1828 19.6581C32.4985 19.9738 32.6553 20.35 32.653 20.7866C32.653 21.221 32.4873 21.6039 32.1559 21.9353C31.8245 22.2667 31.4405 22.4335 31.0039 22.4358C30.5695 22.4358 30.1944 22.2779 29.8787 21.9622Z"
        fill={`url(#${id4})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1="15.3597"
          y1="16.8807"
          x2="12.0322"
          y2="20.2082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
        <linearGradient
          id={id2}
          x1="21.6969"
          y1="17.2654"
          x2="15.3928"
          y2="23.5696"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
        <linearGradient
          id={id3}
          x1="28.0954"
          y1="17.5904"
          x2="18.7552"
          y2="26.9306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
        <linearGradient
          id={id4}
          x1="27.0118"
          y1="21.3487"
          x2="30.5473"
          y2="24.8842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ProIcon;
