import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

const DEFAULT_TRANSLATE_TIMEOUT = 150;

const defaultTranslateYStyle = {
  transition: `transform ${DEFAULT_TRANSLATE_TIMEOUT}ms ease-in-out`,
  transform: 'translateY(0)',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  background: '#5E5E5Ed1',
  position: 'fixed',
  top: 0,
  zIndex: 9,
  alignItems: 'center',
  justifyContent: 'center',
};

const transitionTranslateYStyles = {
  entering: {
    transform: 'translateY(1rem)',
  },
  entered: {
    transform: 'translateY(0)',
  },
  exiting: {
    transform: 'translateY(1rem)',
  },
};

const TranslateYTransition = ({ start, children }) => (
  <Transition mountOnEnter unmountOnExit appear in={start} timeout={DEFAULT_TRANSLATE_TIMEOUT}>
    {state => (
      <div
        style={{
          ...defaultTranslateYStyle,
          ...transitionTranslateYStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

TranslateYTransition.propTypes = {
  start: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default TranslateYTransition;
