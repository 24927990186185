import React from 'react';

function SportsIcon() {
  return (
    <svg width="72px" viewBox="0 0 72 66" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Deporte</title>
      <g id="trabaja-con-nosotros" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-533.000000, -871.000000)" fillRule="nonzero" id="sport_icon">
          <g transform="translate(533.000000, 870.500000)">
            <path
              d="M68.2939848,60.5 C67.9191651,60.5 67.5443454,60.5 67.1695257,60.2985237 L52.3641479,54.8586635 L30.0623763,50.4261848 C28.000868,50.0232321 26.6889991,47.8069928 27.0638188,45.7922297 C27.4386385,43.5759904 29.5001468,42.1656563 31.3742453,42.5686089 L54.4256562,47.4040402 L69.4184439,52.8439004 C71.2925423,53.6498056 72.4170014,55.866045 71.8547718,57.880808 C71.2925423,59.4926185 69.7932635,60.5 68.2939848,60.5 Z"
              id="Path"
              fill="#FFBA57"
            />
            <path
              d="M68.4817293,23.684 C67.5487358,22.34 65.8693473,21.956 64.5631563,22.916 L55.6064178,29.444 L46.6496794,22.916 C46.4630807,22.724 46.276482,22.724 45.9032845,22.532 C42.917705,20.804 37.5063422,18.5 36.3867499,18.5 L23.3248397,18.5 C22.5784448,18.5 21.83205,18.884 21.2722538,19.268 L11.9423179,28.868 C10.8227256,30.02 10.8227256,31.748 11.9423179,32.9 C12.5021141,33.476 13.2485089,33.668 13.9949038,33.668 C14.7412987,33.668 15.4876936,33.476 16.0474897,32.9 L24.6310307,24.068 L31.721782,25.22 L18.6598718,58.82 L3.73197435,58.82 C1.67938846,58.82 0,60.548 0,62.66 C0,64.772 1.67938846,66.5 3.73197435,66.5 L20.7124576,66.5 C22.0186487,66.5 23.138241,65.924 23.8846358,64.772 L39.1857307,43.46 L43.8506986,30.212 L54.1136281,35.204 C54.6734242,35.588 55.2332204,35.78 55.7930166,35.78 C56.3528127,35.78 56.9126089,35.588 57.2858063,35.204 L67.7353345,27.524 C69.0415255,26.756 69.4147229,25.028 68.4817293,23.684 Z"
              id="Path"
              fill="#FFBA57"
            />
            <path
              d="M45.5,0.5 C41.375,0.5 38,3.875 38,8 C38,12.125 41.375,15.5 45.5,15.5 C49.625,15.5 53,12.125 53,8 C53,3.875 49.625,0.5 45.5,0.5"
              id="Path"
              fill="#FFBA57"
            />
            <polygon
              id="Path"
              fill="#2D4C5C"
              points="25.6363636 40.5 18 59.199187 24.3 63.5 39 44.2398374"
            />
            <path
              d="M26.32,38.5 L24,44.8157895 L24,45.4473684 C24.3866667,49.4473684 27.6733333,51.5526316 31.7333333,52.1842105 L51.0666667,54.5 L53,46.5 L26.32,38.5 Z"
              id="Path"
              fill="#546E7A"
            />
            <path
              d="M26,38.8225806 L39.8055556,42.5 L47,22.7580645 C46.8055556,22.5645161 46.6111111,22.5645161 46.4166667,22.5645161 C43.5,20.8225806 37.6666667,18.5 36.5,18.5 L33.9722222,18.5 L26,38.8225806 Z"
              id="Path"
              fill="#00ACC1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SportsIcon;
