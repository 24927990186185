import BannerBlock from './BannerBlock';
import BannerSectionBlock from './BannerSectionBlock';
import DifferencesBlock from './DifferencesBlock';
import HomeFeaturesBlock from './HomeFeaturesBlock';
import GeneralFeaturesBlock from './GeneralFeaturesBlock';
import HelpFeaturesBlock from './HelpFeaturesBlock';
import MediaBlock from './MediaBlock';
import MediaGridBlock from './MediaGridBlock';
import NumberStatsBlock from './NumberStatsBlock';
import PartnersBlock from './PartnersBlock';
import PlansBlock from './PlansBlock';
import PlansCompareBlock from './PlansCompareBlock';
import RatingsBlock from './RatingsBlock';
import TrustStatsBlock from './TrustStatsBlock';
import UserRatingsBlock from './UserRatingsBlock';
import UspBlock from './UspBlock';
import HomeUspBlock from './HomeUspBlock';
import HomePlansBlock from './HomePlansBlock';
import PricingPlansBlock from './PricingPlansBlock';
import DeclarationBlock from './DeclarationBlock';
import ExpertsTeamBlock from './ExpertsTeamBlock';
import PartnersHowHelpBlock from './PartnersHowHelpBlock';
import SuccessfulCasesBlock from './SuccessfulCasesBlock';
import WhoArePartnerBlock from './WhoArePartnerBlock';
import WhyUseTaxDownBlock from './WhyUseTaxDownBlock';
import TechnologyThatScalesBlock from './TechnologyThatScalesBlock';
import PartnersHeaderBlock from './PartnersHeaderBlock';
import PartnerFooterBlock from './PartnerFooterBlock';

export {
  BannerBlock,
  BannerSectionBlock,
  DifferencesBlock,
  HomeFeaturesBlock,
  GeneralFeaturesBlock,
  HelpFeaturesBlock,
  MediaBlock,
  MediaGridBlock,
  NumberStatsBlock,
  PartnersBlock,
  PlansBlock,
  PlansCompareBlock,
  RatingsBlock,
  TrustStatsBlock,
  HomeUspBlock,
  UserRatingsBlock,
  UspBlock,
  HomePlansBlock,
  PricingPlansBlock,
  DeclarationBlock,
  ExpertsTeamBlock,
  PartnersHowHelpBlock,
  SuccessfulCasesBlock,
  WhoArePartnerBlock,
  WhyUseTaxDownBlock,
  TechnologyThatScalesBlock,
  PartnersHeaderBlock,
  PartnerFooterBlock,
};
