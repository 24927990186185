import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

const DEFAULT_TRANSLATE_TIMEOUT = 250;

const defaultOpacityStyle = {
  transition: `opacity ${DEFAULT_TRANSLATE_TIMEOUT}ms ease-in-out`,
  opacity: 0,
};

const transitionOpacityStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
};

const AppearTransition = ({ start, children, timeout, customStyles }) => (
  <Transition mountOnEnter unmountOnExit appear in={start} timeout={timeout}>
    {state => (
      <div
        style={{
          ...defaultOpacityStyle,
          ...customStyles,
          ...transitionOpacityStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

AppearTransition.propTypes = {
  start: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  timeout: PropTypes.number,
  customStyles: PropTypes.oneOfType([PropTypes.object]),
};

AppearTransition.defaultProps = {
  timeout: DEFAULT_TRANSLATE_TIMEOUT,
  customStyles: {},
};

export default AppearTransition;
