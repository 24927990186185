import * as React from 'react';

function LineArrowIcon(props) {
  return (
    <svg
      width={6}
      height={6}
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.728 3h3.636M3.4 1.393L5.007 3 3.4 4.607"
        stroke="#397451"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LineArrowIcon;
