import React from 'react';
import { useTranslation } from 'Hooks';
import { FAQ_ROUTE, WEBAPP_APP_SIGNUP } from 'Constants/routes';
import { Trans } from 'Components';
import './styles.scss';
import dashboardExperts from './dashboard-experts.jpg';
import mobileExperts from './mobile-experts.jpg';

const ExpertsTeamBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="experts-team">
      <h2>{t('ExpertsTeamBlock.title')}</h2>
      <div className="experts-team-container">
        <div className="experts-team-container__picture">
          <img className="dashboard" src={dashboardExperts} alt={t('ExpertsTeamBlock.alt')} />
          <img className="mobile" src={mobileExperts} alt={t('ExpertsTeamBlock.alt')} />
        </div>
        <div className="experts-team-container__card">
          <a className="cta" href={WEBAPP_APP_SIGNUP}>
            {t('ExpertsTeamBlock.CTA')}
          </a>
          <h3 className="title">{t('ExpertsTeamBlock.subtitle')}</h3>
          <a className="description" href={FAQ_ROUTE}>
            <p className="description">
              <Trans i18nKey="ExpertsTeamBlock.description" components={{ u: <u /> }} />
            </p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ExpertsTeamBlock;
