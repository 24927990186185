import React from 'react';

function ArrowNextIcon() {
  return (
    <svg width="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Siguiente</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="MAIN_man"
          transform="translate(-1147.000000, -2587.000000)"
          fill="#00DC5A"
          fillRule="nonzero"
        >
          <g id="siguiente_icon" transform="translate(1147.000000, 2587.000000)">
            <path
              d="M19,0 C8.51579504,0 0,8.51579504 0,19 C0,29.484205 8.51579504,38 19,38 C29.484205,38 38,29.484205 38,19 C38,8.51579504 29.484205,0 19,0 Z M19,1.65217391 C28.5903533,1.65217391 36.3478261,9.40964674 36.3478261,19 C36.3478261,28.5903533 28.5903533,36.3478261 19,36.3478261 C9.40964674,36.3478261 1.65217391,28.5903533 1.65217391,19 C1.65217391,9.40964674 9.40964674,1.65217391 19,1.65217391 Z M16.4442935,9.88722826 C16.4087973,9.89368248 16.373302,9.90336257 16.3410326,9.91304348 C16.03125,9.96790061 15.7827787,10.1937842 15.6956522,10.4938859 C15.6085256,10.7972151 15.6988789,11.1199053 15.9279891,11.3328804 L23.5951087,19 L15.9279891,26.6671196 C15.5988455,26.996264 15.5988455,27.525476 15.9279891,27.8546196 C16.2571336,28.183764 16.7863455,28.183764 17.1154891,27.8546196 L25.3763587,19.59375 C25.5377042,19.4388587 25.6280575,19.222656 25.6280575,19 C25.6280575,18.777344 25.5377042,18.5611413 25.3763587,18.40625 L17.1154891,10.1453804 C16.9444635,9.96144722 16.6959923,9.86786726 16.4442935,9.88722826 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ArrowNextIcon;
