import React from 'react';

function WeAchieveIcon() {
  return (
    <svg width="311px" viewBox="0 0 311 261" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Conseguimos ahorro</title>
      <defs>
        <polygon id="path-1" points="0 0.0104790783 20.8544229 0.0104790783 20.8544229 20 0 20" />
        <polygon
          id="path-3"
          points="0.0554030941 0.2368 31.8208323 0.2368 31.8208323 31.9163827 0.0554030941 31.9163827"
        />
        <polygon
          id="path-5"
          points="0.0684909571 0.288917639 25.856025 0.288917639 25.856025 25.9273576 0.0684909571 25.9273576"
        />
        <polygon
          id="path-7"
          points="6.54545455e-05 0.0706100629 71.6053745 0.0706100629 71.6053745 103 6.54545455e-05 103"
        />
        <polygon
          id="path-9"
          points="0.413485714 0.0159538462 26.9138571 0.0159538462 26.9138571 50.6772615 0.413485714 50.6772615"
        />
        <polygon
          id="path-11"
          points="0.0809691677 0.26658288 9.55241072 0.26658288 9.55241072 12.0440535 0.0809691677 12.0440535"
        />
        <polygon
          id="path-13"
          points="0.333671013 0.199298509 8.93276717 0.199298509 8.93276717 4.71619426 0.333671013 4.71619426"
        />
        <polygon
          id="path-15"
          points="0 0.308855073 10.9573827 0.308855073 10.9573827 13.0260356 0 13.0260356"
        />
        <polygon
          id="path-17"
          points="0.0488936709 0.0927518204 6.47921133 0.0927518204 6.47921133 11.1900748 0.0488936709 11.1900748"
        />
      </defs>
      <g id="como-funciona" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="c_funciona" transform="translate(-187.000000, -1655.000000)">
          <g id="conseguimos_ilus" transform="translate(187.000000, 1639.485876)">
            <path
              d="M0.188472105,178.88719 C-5.10644319,50.277667 102.703915,127.990655 129.707983,61.2231582 C156.712052,-5.54433862 311,85.6693045 311,195.305427 C311,304.941549 5.4833874,307.496712 0.188472105,178.88719 Z"
              id="Path-2-Copy-3"
              fill="#FFFFFF"
            />
            <g
              id="Group-10"
              transform="translate(212.790368, 108.304492) rotate(-315.000000) translate(-212.790368, -108.304492) translate(167.290368, 84.804492)"
            >
              <g id="Group-3" transform="translate(0.158640, 25.152543)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M20.6248113,7.2679322 C20.417521,7.01877966 20.1062468,6.8940339 19.8152952,6.8940339 L13.8821177,6.68657627 L13.8404565,6.68657627 L11.3919242,0.644542373 C11.1846339,0.125220339 10.6037468,-0.123932203 10.085521,0.0835254237 C9.79423065,0.18759322 9.60794032,0.416067797 9.50463387,0.685898305 L7.45035968,6.31301695 L7.43613387,6.3560678 C7.43681129,6.35471186 7.43782742,6.3540339 7.42969839,6.3540339 L0.935617742,6.81132203 C0.375730645,6.85267797 -0.03885,7.33030508 0.00281129032,7.89064407 C0.0234725806,8.22284746 0.189101613,8.49301695 0.438053226,8.65911864 L5.08515,12.3133559 C5.10615,12.3133559 5.12647258,12.3343729 5.14781129,12.3550508 L3.57044032,18.6669153 C3.42581129,19.2279322 3.75740806,19.7675932 4.29697258,19.912678 C4.58724677,19.9750508 4.89852097,19.912678 5.12647258,19.7675932 L10.1265048,16.3828475 L15.645779,19.8503051 C16.1220048,20.1414915 16.7452306,19.9960678 17.0561661,19.5181017 C17.2014726,19.2692881 17.2431339,18.9577627 17.16015,18.7086102 L15.5001339,12.8947119 L20.5001661,8.70047458 C20.9150855,8.3479322 20.9770694,7.70386441 20.6248113,7.2679322"
                  id="Fill-1"
                  fill="#F3CD3E"
                  mask="url(#mask-2)"
                />
              </g>
              <g id="Group-6" transform="translate(24.710678, 0.414214)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3" />
                </mask>
                <g id="Clip-5" />
                <path
                  d="M31.4598141,11.7427537 C31.1430465,11.3510063 30.6876121,11.1441853 30.232501,11.1650695 L21.1849051,10.8349642 L21.1247838,10.8349642 L17.4221576,1.24069053 C17.1063596,0.415764211 16.2148848,0.0233431579 15.4232889,0.353785263 C14.9875717,0.539722105 14.6908444,0.890374737 14.5324606,1.30300632 L11.4236121,10.2367326 L11.4035717,10.3189221 L11.4035717,10.2987116 L1.50458182,11.0209011 C0.653187879,11.0825432 0.000258585859,11.8458274 0.0590868687,12.7330695 C0.0991676768,13.2491116 0.355490909,13.6822905 0.732379798,13.9507537 L7.81989495,19.7272589 C7.84058182,19.74848 7.88001616,19.7690274 7.90005657,19.7895747 L5.50393535,29.8170274 C5.2864,30.6833853 5.80098586,31.5497432 6.63201616,31.7767747 C7.0674101,31.9007326 7.52316768,31.7976589 7.85997576,31.5497432 L15.4827636,26.1851958 L23.8971475,31.6737011 C24.6292687,32.1483116 25.5805414,31.9007326 26.0152889,31.1579958 C26.2729051,30.7453642 26.3123394,30.2913011 26.1933899,29.8581221 L23.6592485,20.6559326 L31.2623192,14.0123958 C31.9155717,13.4343747 32.0144808,12.4238484 31.4598141,11.7427537"
                  id="Fill-4"
                  fill="#F3CD3E"
                  mask="url(#mask-4)"
                />
              </g>
              <g id="Group-9" transform="translate(64.480231, 7.656854)">
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5" />
                </mask>
                <g id="Clip-8" />
                <path
                  d="M25.5717475,9.58923896 C25.3130475,9.27926494 24.9344225,9.11313506 24.5762725,9.13373247 L17.2302975,8.86529091 L17.1909725,8.86529091 L14.1648975,1.10952468 C13.9058725,0.426771429 13.1898975,0.11645974 12.5327475,0.385238961 C12.1940975,0.509498701 11.9353975,0.798875325 11.8362725,1.15071948 L9.2876225,8.36858961 L9.2677975,8.43105714 L1.2458225,9.00981039 C0.5490225,9.05134286 0.0309725,9.67162857 0.0706225,10.3959143 C0.0910975,10.8095506 0.3094975,11.1610571 0.6081725,11.3883039 L6.3811475,16.0625636 C6.4009725,16.083161 6.4211225,16.1037584 6.4415975,16.1037584 L4.4906225,24.2326416 C4.3115475,24.9356545 4.7285225,25.6386675 5.4058225,25.8041221 C5.7649475,25.9077844 6.1224475,25.8250571 6.4009725,25.6180701 L12.5925475,21.2953169 L19.4204725,25.7213948 C20.0178225,26.1140961 20.7945725,25.9283818 21.1517475,25.3077584 C21.3512975,24.9765117 21.3915975,24.6044078 21.2911725,24.253239 L19.2417225,16.8071091 L25.4124975,11.4294987 C25.9305475,10.9746675 26.0098475,10.1473948 25.5717475,9.58923896"
                  id="Fill-7"
                  fill="#F3CD3E"
                  mask="url(#mask-6)"
                />
              </g>
            </g>
            <g
              id="Group-9"
              transform="translate(191.237845, 189.812629) scale(-1, 1) rotate(-345.000000) translate(-191.237845, -189.812629) translate(140.737845, 137.812629)"
            >
              <g id="Group-3" transform="translate(0.635826, 0.526837)">
                <mask id="mask-8" fill="white">
                  <use xlinkHref="#path-7" />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M59.6978836,14.8715157 C58.5746836,23.8409371 60.6194836,34.2374591 62.9450836,41.7752453 C65.2301018,49.1951321 66.6733745,50.1078805 67.3554109,53.7187107 L67.7160655,53.7187107 L69.6810109,53.7187107 L71.6053745,53.7187107 L71.6053745,97.5630252 L69.6810109,97.5630252 L66.0718473,97.5630252 C64.6698109,99.2699748 62.4646473,100.38095 60.0585382,100.539013 L41.0145382,101.76983 C40.77432,101.809994 40.5334473,101.849509 40.2533018,101.849509 C30.2295927,102.801126 19.3242109,103 8.17926545,103 C3.68842909,103 6.54545455e-05,99.3483585 6.54545455e-05,94.9051069 C6.54545455e-05,90.4605597 3.68842909,86.8497296 8.17926545,86.8497296 C3.68842909,86.8497296 6.54545455e-05,83.2006792 6.54545455e-05,78.7567799 C6.54545455e-05,74.3122327 3.68842909,70.7020503 8.17926545,70.7020503 C3.68842909,70.7020503 6.54545455e-05,67.0510566 6.54545455e-05,62.6065094 C6.54545455e-05,58.1626101 3.68842909,54.5517799 8.17926545,54.5517799 C3.68842909,54.5517799 6.54545455e-05,50.9007862 6.54545455e-05,46.4568868 C6.54545455e-05,42.0531509 3.68842909,38.4015094 8.17926545,38.4015094 L44.86392,38.4015094 C44.1419564,30.9816226 42.7778836,15.7052327 47.1083564,9.51551572 C50.6775927,4.35774214 55.6076291,0.350459119 58.7350473,0.0706100629 C62.4234109,0.866106918 60.5802109,8.48357233 59.6978836,14.8715157"
                  id="Fill-1"
                  fill="#F9D8BC"
                  mask="url(#mask-8)"
                />
              </g>
              <path
                d="M87.0936183,52.1632021 L72.5803534,52.1632021 C71.3734278,52.1632021 70.3554497,53.2533173 70.3554497,54.5451104 L70.3554497,97.7819529 C70.3554497,99.1139498 71.3734278,100.163202 72.5803534,100.163202 L87.0936183,100.163202 C88.3374717,100.163202 89.3554497,99.1139498 89.3554497,97.7819529 L89.3554497,54.5451104 C89.3554497,53.2533173 88.3374717,52.1632021 87.0936183,52.1632021"
                id="Fill-4"
                fill="#FFFFFF"
              />
              <g id="Group-8" transform="translate(73.505159, 50.300358)">
                <mask id="mask-10" fill="white">
                  <use xlinkHref="#path-9" />
                </mask>
                <g id="Clip-7" />
                <path
                  d="M26.9139857,0.0159538462 L4.58755714,0.0159538462 C2.30284286,0.0159538462 0.413485714,1.93826154 0.413485714,4.26006923 L0.413485714,46.4338 C0.413485714,48.7562615 2.30284286,50.6772615 4.58755714,50.6772615 L26.9139857,50.6772615 L26.9139857,0.0159538462 Z"
                  id="Fill-6"
                  fill="#00DC5A"
                  mask="url(#mask-10)"
                />
              </g>
            </g>
            <g
              id="Group-57"
              transform="translate(96.400089, 93.500000) rotate(-345.000000) translate(-96.400089, -93.500000) translate(18.900089, 18.000000)"
            >
              <polygon
                id="Fill-1"
                fill="#EF5160"
                points="153 146 126.364896 90.5007842 99.7294012 35 82.8647006 47.0798776 66 59.183672 109.500195 102.604186"
              />
              <g id="Group-56">
                <path
                  d="M108.34459,52.6688461 L105.11792,45.9278325 C101.126432,49.996336 96.2983285,54.1607356 90.825203,58.0846092 C85.3516873,62.0088758 79.8543686,65.2583337 74.7394584,67.7138988 L80.0213797,73.010185 C84.8260706,70.6273278 89.8925943,67.5696619 94.9595082,63.9346552 C100.027203,60.2996484 104.544697,56.4473038 108.34459,52.6688461 M117.618388,72.0229283 L114.415911,65.3062816 C110.973844,68.5565255 107.053765,71.8063764 102.727866,74.9119903 C98.401966,78.0168182 94.0752859,80.6889353 89.9167875,82.9043675 L95.1749057,88.1519197 C98.9751887,86.0583224 102.91907,83.5783903 106.862561,80.7620363 C110.806443,77.9205292 114.415911,74.9839123 117.618388,72.0229283 M126.820778,91.2575336 L126.43837,90.4156929 L123.642104,84.5896209 C120.893055,87.0204261 117.881002,89.4040692 114.630529,91.7389784 C111.404249,94.0746736 108.153776,96.1446899 104.998905,97.9741802 L109.564005,102.524522 L110.209417,103.197758 C113.029485,101.513291 115.897159,99.6354597 118.766004,97.5894174 C121.634069,95.5182221 124.335122,93.3998648 126.820778,91.2575336 M135.974782,110.371876 L132.819911,103.751518 C130.836068,105.364457 128.732431,106.977788 126.557384,108.566753 C124.358535,110.130957 122.159686,111.599265 119.984639,112.947311 L125.170958,118.146915 C126.987789,116.967867 128.828423,115.740084 130.69247,114.415619 C132.533104,113.092334 134.301549,111.743895 135.974782,110.371876 M145.105372,129.389929 L141.926308,122.793546 C140.803275,123.659753 139.655269,124.526354 138.460828,125.392955 C137.265215,126.235974 136.070384,127.078208 134.875162,127.84852 L140.038068,133.024936 C140.898487,132.446416 141.735104,131.844708 142.595523,131.243001 C143.431749,130.617319 144.268756,130.015218 145.105372,129.389929"
                  id="Fill-2"
                  fill="#FFCB9D"
                />
                <g id="Group-6" transform="translate(144.378757, 134.804553)">
                  <mask id="mask-12" fill="white">
                    <use xlinkHref="#path-11" />
                  </mask>
                  <g id="Clip-5" />
                  <path
                    d="M9.45076027,10.6422103 L3.42704441,0.26658288 C5.7456892,7.51183631 6.10429482,7.17580747 0.0809691677,2.57712505 L7.87312969,11.7489438 C8.15915572,12.0857587 8.71013627,12.1344927 9.09176444,11.8939668 L9.11556743,11.8695998 L9.13976063,11.8695998 L9.16356361,11.8456258 L9.18775681,11.8212588 C9.52216922,11.5567589 9.66576756,11.0026061 9.45076027,10.6422103"
                    id="Fill-4"
                    fill="#FFCB9D"
                    mask="url(#mask-12)"
                  />
                </g>
                <path
                  d="M99.7164761,34.6854865 C98.401849,32.8559961 89.7972653,36.7798698 80.4754704,43.4721494 C71.1540657,50.1644291 64.6531195,57.0732606 65.9677466,58.927118 C67.2577903,60.7566083 75.862374,56.8331277 85.1841689,50.1404551 C94.5055736,43.4481754 101.00691,36.5393438 99.7164761,34.6854865"
                  id="Fill-7"
                  fill="#71262B"
                />
                <polygon
                  id="Fill-9"
                  fill="#45B431"
                  points="148.49917 149.514323 112.240592 129.725958 75.9586005 109.914405 71.4887122 123.202675 67.0192141 136.515706 107.747291 143.015014"
                />
                <path
                  d="M87.6221408,116.293726 L83.2240517,113.885716 C82.650829,117.690112 81.6226181,121.878486 80.1647829,126.187515 C78.7311409,130.496938 76.9857187,134.444393 75.1696681,137.815292 L80.1171769,138.609185 C81.7904096,135.407675 83.36765,131.724721 84.7060801,127.728925 C86.0687034,123.732343 87.0251151,119.856418 87.6221408,116.293726 M100.242405,123.178977 L95.8681189,120.79494 C95.270703,123.900554 94.3860904,127.246694 93.2388646,130.641175 C92.0916388,134.05963 90.7774019,137.237166 89.3671727,140.07828 L94.2908785,140.871779 C95.5575094,138.224029 96.7285382,135.311779 97.7805521,132.205772 C98.8317855,129.100551 99.6449891,126.043672 100.242405,123.178977 M112.766677,130.0151 L112.24067,129.726233 L108.440777,127.632243 C107.890187,130.063441 107.173756,132.566561 106.313337,135.118808 C105.452527,137.671055 104.496506,140.07828 103.492878,142.340874 L107.747369,143.014897 L108.368588,143.111186 C109.2532,141.089117 110.089817,138.922026 110.854244,136.659432 C111.619451,134.396445 112.264083,132.182191 112.766677,130.0151 M125.242952,136.828036 L120.916663,134.46876 C120.486258,136.130039 119.961031,137.838873 119.387028,139.572468 C118.813805,141.305669 118.168003,142.966949 117.522981,144.58028 L122.375278,145.349806 C122.924698,143.977786 123.426512,142.581007 123.928716,141.113484 C124.406726,139.668757 124.860544,138.224029 125.242952,136.828036 M137.647819,143.591844 L133.345722,141.233354 C133.058916,142.17227 132.771719,143.087212 132.46072,144.050494 C132.1505,144.989017 131.816088,145.903959 131.481285,146.79414 L136.309389,147.564452 C136.548199,146.914796 136.763597,146.264354 137.003188,145.591118 C137.217805,144.917095 137.432422,144.242286 137.647819,143.591844"
                  id="Fill-11"
                  fill="#A7FFA7"
                />
                <g id="Group-15" transform="translate(140.086415, 145.415990)">
                  <mask id="mask-14" fill="white">
                    <use xlinkHref="#path-13" />
                  </mask>
                  <g id="Clip-14" />
                  <path
                    d="M8.58042952,3.47233732 L1.24208654,0.199298509 C5.35297898,3.23181142 5.42477815,2.91936355 0.333671013,2.79910059 L8.17382772,4.70090593 C8.45985374,4.77322091 8.77085339,4.58024996 8.89103895,4.31535705 L8.89103895,4.29099004 L8.89103895,4.26701606 L8.91445172,4.24264905 L8.91445172,4.21906808 C8.98586067,3.93020118 8.84304277,3.59299329 8.58042952,3.47233732"
                    id="Fill-13"
                    fill="#A7FFA7"
                    mask="url(#mask-14)"
                  />
                </g>
                <path
                  d="M75.8868013,109.890313 C74.4523788,109.408082 71.2971177,114.970047 68.811852,122.31159 C66.3499991,129.653918 65.5133827,135.985016 66.9232218,136.491614 C68.3576442,136.972666 71.5129054,131.41188 73.9981711,124.069551 C76.4604142,116.727223 77.3208335,110.372151 75.8868013,109.890313"
                  id="Fill-16"
                  fill="#1B4513"
                />
                <path
                  d="M57.4347669,111.479278 C53.4908856,107.218196 51.6030358,98.960926 43.4998756,101.753699 C36.9267401,104.016293 28.3463496,97.3000396 32.9114498,102.234751 C36.4967255,106.086702 47.7305634,101.127231 48.3283695,103.703452 C49.0451905,106.737144 57.1717635,111.190018 57.4347669,111.479278"
                  id="Fill-18"
                  fill="#FFA758"
                />
                <path
                  d="M30.3065839,113.260506 C15.0332625,113.260506 23.566047,113.934135 18.641951,117.087697 C18.2123266,117.376564 11.8303953,117.424905 18.1171147,118.195217 C22.5386165,118.74937 18.6185382,114.368025 29.6611718,114.82471 C29.8761791,114.82471 31.8362183,113.260506 30.3065839,113.260506"
                  id="Fill-20"
                  fill="#EF5160"
                />
                <polygon
                  id="Fill-22"
                  fill="#A7FFA7"
                  points="33.3658136 91.5697849 30.5449649 88.0801942 22.08476 87.3098825 24.7850329 90.2704734"
                />
                <polygon
                  id="Fill-24"
                  fill="#A7FFA7"
                  points="58.5101546 131.483919 54.9248788 130.953348 46.7027037 135.985134 50.1685743 136.37029"
                />
                <g id="Group-28" transform="translate(0.000000, 131.660423)">
                  <mask id="mask-16" fill="white">
                    <use xlinkHref="#path-15" />
                  </mask>
                  <g id="Clip-27" />
                  <path
                    d="M8.84257451,1.31652563 C2.67604075,-1.18777352 6.73971743,14.507721 0.429975546,10.9690032 C-1.55464704,9.83790262 3.87126273,16.1929744 5.66390059,10.8723212 C6.14230155,9.40479878 3.75185759,1.84552542 9.79898622,2.08683736 C10.4443983,2.11081134 12.2846421,-1.25969549 9.32058526,1.21984365 C9.12977118,1.3644736 9.12977118,1.43639556 8.84257451,1.31652563"
                    id="Fill-26"
                    fill="#FFA758"
                    mask="url(#mask-16)"
                  />
                </g>
                <polygon
                  id="Fill-29"
                  fill="#FFF0E6"
                  points="64.7966007 102.283681 64.2705938 105.508772 58.3904763 98.2870997 59.6574974 94.4115671"
                />
                <polygon
                  id="Fill-31"
                  fill="#FFF0E6"
                  points="21.1281922 73.7562476 23.2318297 74.0930625 18.4993282 78.0169361 15.9418731 77.1743094"
                />
                <polygon
                  id="Fill-33"
                  fill="#FFF0E6"
                  points="32.4813572 145.976391 32.9351747 141.497972 27.4858522 134.926741 27.3192313 138.945725"
                />
                <polygon
                  id="Fill-35"
                  fill="#F3CD3E"
                  points="64.8678536 115.547192 68.0949139 115.06614 63.0038068 122.913101 58.9643233 122.841179"
                />
                <polygon
                  id="Fill-37"
                  fill="#F3CD3E"
                  points="47.7785206 85.6487996 49.9055709 85.9848284 45.1730693 89.9098811 42.6160045 89.0432804"
                />
                <polygon
                  id="Fill-39"
                  fill="#F3CD3E"
                  points="17.5667584 95.8791288 19.6945892 96.2167297 14.9378944 100.140603 12.4042424 99.2975836"
                />
                <polygon
                  id="Fill-41"
                  fill="#A7FFA7"
                  points="50.3835036 28.9804242 52.5105539 29.3172391 47.7550298 33.2415057 45.2213777 32.398486"
                />
                <polygon
                  id="Fill-43"
                  fill="#F3CD3E"
                  points="66.8993408 44.1226698 66.397527 47.3481537 60.4939967 40.1264812 61.7848208 36.2501625"
                />
                <polygon
                  id="Fill-45"
                  fill="#FFA758"
                  points="80.76255 27.5841163 77.9420914 24.0937395 69.4811061 23.3226418 72.1575761 26.3079927"
                />
                <path
                  d="M56.9565221,54.7619324 C51.9372141,51.8261015 47.7783255,44.4590131 40.8231715,49.4907993 C35.1584515,53.5593029 25.0484266,49.6114553 30.8325518,53.0055431 C35.3734588,55.6780532 44.6952537,47.637335 46.0098808,49.9478771 C47.5637084,52.6439682 56.6217194,54.5461665 56.9565221,54.7619324"
                  id="Fill-47"
                  fill="#EF5160"
                />
                <polygon
                  id="Fill-49"
                  fill="#FFF0E6"
                  points="49.1412219 17.2804895 51.2674918 17.6176974 46.5119677 21.541178 43.9779254 20.6993373"
                />
                <g id="Group-53" transform="translate(57.361290, 0.000000)">
                  <mask id="mask-18" fill="white">
                    <use xlinkHref="#path-17" />
                  </mask>
                  <g id="Clip-52" />
                  <polygon
                    id="Fill-51"
                    fill="#EF5160"
                    mask="url(#mask-18)"
                    points="6.47921133 7.96419794 5.95281419 11.1900748 0.0488936709 3.96840236 1.34010801 0.0924767091"
                  />
                </g>
                <polygon
                  id="Fill-54"
                  fill="#F3CD3E"
                  points="80.1170208 5.38888077 82.2206583 5.74927661 77.4881568 9.67354325 74.9303115 8.80694256"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WeAchieveIcon;
