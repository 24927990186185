import React from 'react';

function WeRetrieveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="224"
      height="220"
      fill="none"
      viewBox="0 0 224 220"
    >
      <title>Recogemos tú información</title>
      <path
        fill="#3090E9"
        d="M36.066 43.278c19.908 0 36.065 16.158 36.065 36.066S55.974 115.41 36.066 115.41C16.157 115.41 0 99.252 0 79.344s16.157-36.066 36.066-36.066z"
      />
      <path
        fill="#2482D8"
        d="M36.066 43.278c-2.471 0-4.884.249-7.214.726 16.457 3.346 28.853 17.902 28.853 35.34 0 17.438-12.4 31.994-28.852 35.34 2.33.477 4.742.726 7.213.726 19.908 0 36.065-16.158 36.065-36.066S55.974 43.278 36.066 43.278z"
      />
      <path
        fill="#3090E9"
        d="M111.803 0c19.909 0 36.066 16.157 36.066 36.066 0 19.908-16.157 36.065-36.066 36.065-19.908 0-36.065-16.157-36.065-36.065C75.738 16.157 91.895 0 111.803 0z"
      />
      <path
        fill="#2482D8"
        d="M111.803 0c-2.47 0-4.883.248-7.213.726 16.457 3.345 28.853 17.902 28.853 35.34 0 17.437-12.399 31.994-28.853 35.34 2.33.477 4.743.725 7.213.725 19.909 0 36.066-16.157 36.066-36.065C147.869 16.157 131.712 0 111.803 0z"
      />
      <path
        fill="#3090E9"
        d="M187.541 43.278c19.908 0 36.066 16.158 36.066 36.066 0 19.944-16.158 36.066-36.066 36.066-19.943 0-36.065-16.123-36.065-36.066 0-19.908 16.122-36.066 36.065-36.066z"
      />
      <path
        fill="#2482D8"
        d="M187.541 43.278c-2.47 0-4.883.249-7.213.726 16.457 3.346 28.853 17.902 28.853 35.34 0 17.438-12.4 31.994-28.853 35.34a35.94 35.94 0 0 0 7.213.726c19.909 0 36.066-16.158 36.066-36.066s-16.157-36.066-36.066-36.066z"
      />
      <path
        fill="#656D78"
        d="M194.754 57.705h-14.426a3.602 3.602 0 0 0-3.606 3.607v10.82a3.602 3.602 0 0 0 3.606 3.606 3.603 3.603 0 0 0 3.607-3.607v-7.213h7.213v7.213a3.602 3.602 0 0 0 3.606 3.607 3.602 3.602 0 0 0 3.607-3.607v-10.82a3.602 3.602 0 0 0-3.607-3.606z"
      />
      <path
        fill="#EAC6BB"
        d="M151.476 137.039v14.426c0 15.4-8.8 28.78-21.64 35.343-5.41 2.781-11.542 4.329-18.033 4.329-6.491 0-12.623-1.551-18.032-4.329-12.84-6.559-21.64-19.943-21.64-35.343v-14.426l10.82-10.82s13.38 7.213 28.852 7.213c15.473 0 28.853-7.213 28.853-7.213l10.82 10.82z"
      />
      <path
        fill="#656D78"
        d="M82.95 126.219l-10.819 10.82v-7.213c0-21.893 17.781-39.673 39.672-39.673 21.892 0 39.673 17.78 39.673 39.673v7.213l-10.82-10.82s-13.38 7.213-28.853 7.213c-15.472 0-28.852-7.213-28.852-7.213z"
      />
      <path
        fill="#DABAB1"
        d="M136.967 127.943c.024.623.082 1.243.082 1.882v21.64c0 15.4-8.799 28.78-21.639 35.343-3.351 1.725-7 2.904-10.82 3.607.007 0 .014.004.021.004 1.101.202 2.212.364 3.338.491 1.273.121 2.55.227 3.854.227 6.492 0 12.623-1.551 18.033-4.329 12.84-6.559 21.64-19.943 21.64-35.343v-14.426l-10.82-10.82s-1.356.729-3.689 1.724z"
      />
      <path
        fill="#5A616C"
        d="M111.803 90.153c-1.245 0-2.467.076-3.682.187a41.559 41.559 0 0 0-3.43.488c-.032.007-.069.007-.097.014 17.843 3.293 31.492 18.528 32.373 37.101 2.333-.995 3.689-1.724 3.689-1.724l10.82 10.82v-7.213c0-21.893-17.781-39.673-39.673-39.673z"
      />
      <path
        fill="#CCD1D9"
        d="M18.033 64.312a3 3 0 0 1 3-3h30.066a3 3 0 0 1 3 3v7.82H18.032v-7.82z"
      />
      <path
        fill="#F2F2F2"
        d="M18.033 72.131h36.066v22.246a3 3 0 0 1-3 3H21.033a3 3 0 0 1-3-3V72.13zM209.181 72.131v21.64a3.618 3.618 0 0 1-3.607 3.606h-36.066a3.618 3.618 0 0 1-3.606-3.607V72.131a3.618 3.618 0 0 1 3.606-3.606h36.066a3.618 3.618 0 0 1 3.607 3.606z"
      />
      <path
        fill="#E5E5E5"
        d="M93.77 186.82c5.41 2.78 11.542 4.328 18.033 4.328 6.492 0 12.623-1.551 18.033-4.328v11.541l-7.213 7.213-10.82-3.606-10.819 3.606-7.213-7.213V186.82z"
      />
      <path
        fill="#F2F2F2"
        d="M100.984 205.574l10.819-3.607 10.82 3.607 7.213-7.213h14.427c11.973 0 21.639 9.666 21.639 21.639H57.705c0-11.973 9.666-21.639 21.64-21.639H93.77l7.213 7.213z"
      />
      <path
        fill="#E5E5E5"
        d="M144.262 198.361h-14.426c11.973 0 21.64 9.666 21.64 21.639h14.426c0-11.973-9.666-21.639-21.64-21.639z"
        opacity=".6"
      />
      <path
        fill="#F2F2F2"
        d="M126.23 39.672v14.426h-21.64a7.211 7.211 0 0 1-7.213-7.213c0-3.968 3.246-7.213 7.213-7.213h21.64z"
      />
      <path
        fill="#8CC152"
        d="M126.23 50.492h-21.64a3.608 3.608 0 0 1-3.606-3.607 3.608 3.608 0 0 1 3.606-3.606 3.602 3.602 0 0 0 3.607-3.607 3.602 3.602 0 0 0-3.607-3.606c-5.964 0-10.82 4.855-10.82 10.82 0 5.964 4.856 10.819 10.82 10.819h21.64a3.602 3.602 0 0 0 3.606-3.607 3.602 3.602 0 0 0-3.606-3.606z"
      />
      <path
        fill="#A0D468"
        d="M126.23 14.426h-21.64c-5.964 0-10.82 4.855-10.82 10.82v21.639h7.214a3.608 3.608 0 0 1 3.606-3.607h21.64a3.602 3.602 0 0 0 3.606-3.606v-21.64a3.602 3.602 0 0 0-3.606-3.606z"
      />
    </svg>
  );
}

export default WeRetrieveIcon;
