import React from 'react';
import scrollToElement from 'scroll-to-element';
import { useTranslation } from 'Hooks';
import './styles.scss';

const PartnerFooterBlock = () => {
  const { t } = useTranslation('home');

  function scrollToForm() {
    scrollToElement('#partner-contact-form', {
      offset: '74px',
      duration: 100,
    });
    document.getElementById('partners-contact-form-name').focus();
  }

  return (
    <section className="partner-footer-block">
      <h2>{t('PartnersFooterBlock.title')}</h2>
      <div className="cta">
        <button type="button" className="solid_btn green" onClick={() => scrollToForm()}>
          {t('PartnersFooterBlock.cta')}
        </button>
      </div>
    </section>
  );
};

export default PartnerFooterBlock;
