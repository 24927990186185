import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';

export const LogoContainer = styled.div`
  margin: ${pxToRem(65)} 0;
`;

export const FormApply = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  iframe {
    overflow: hidden;
  }
`;
