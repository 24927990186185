import React from 'react';
import PropTypes from 'prop-types';
import { ContainerWrapper } from './styles';

function Container({ children, className }) {
  return <ContainerWrapper className={className}>{children}</ContainerWrapper>;
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Container.defaultProps = {
  className: null,
};

export default Container;
