import React from 'react';
import PropTypes from 'prop-types';

import {
  LoaderContainer,
  SMALL,
  MEDIUM,
  LARGE,
  Container,
  FirstPiece,
  SecondPiece,
  ThirdPiece,
} from './styles';

const LogoLoader = ({ white, size, stopped }) => {
  return (
    <LoaderContainer>
      <Container size={size}>
        <FirstPiece white={white} size={size} />
        <SecondPiece white={white} size={size} stopped={stopped} />
        <ThirdPiece white={white} size={size} stopped={stopped} />
      </Container>
    </LoaderContainer>
  );
};

LogoLoader.propTypes = {
  white: PropTypes.bool,
  stopped: PropTypes.bool,
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
};

LogoLoader.defaultProps = {
  white: false,
  stopped: false,
  size: MEDIUM,
};

export default LogoLoader;
