import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';

export const StyledLegalText = styled.div`
  h3 {
    font-size: ${pxToRem(24)};
    line-height: 28px;
    margin-bottom: ${pxToRem(24)};
  }

  a {
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.linkInfoBlue};
  }

  p {
    margin: 1rem 0;
    line-height: 24px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin: 1rem 0;
    padding-inline-start: 40px;
    li:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;
