import styled from 'styled-components';

import { MOBILE_WIDTH } from 'Styles/sizes';
import { HIGH_LIGHT_GREY, TEXT_GREY, DARK_GREEN, BLACK_LIGHT } from 'Styles/colors';
import { pxToRem } from 'Styles/utils';

export const JobBanner = styled.div`
  padding: ${pxToRem(30)} 0;
  > div {
    display: flex;
    flex-direction: column;
  }

  background-color: ${HIGH_LIGHT_GREY};

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const JobBannerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const JoinBannerText = styled.div`
  h2 {
    font-size: ${pxToRem(32)};
  }
  p {
    text-transform: uppercase;
    line-height: 1.38;
  }
`;

export const CustomLink = styled.div`
  margin-bottom: ${pxToRem(40)};

  svg {
    transform: rotate(-180deg);
    margin-right: ${pxToRem(10)};
    path {
      stroke: ${TEXT_GREY};
    }
  }
`;

export const Description = styled.div`
  > button {
    margin: 0 auto;
  }
`;

export const DescriptionText = styled.div`
  font-family: 'Open Sans', sans-serif;

  h1,
  h2,
  h3 {
    color: ${DARK_GREEN};
    text-transform: uppercase;
  }

  h2,
  h3 {
    margin-top: ${pxToRem(50)};
    font-size: ${pxToRem(15)};
    margin-bottom: ${pxToRem(17)};
  }

  a {
    text-decoration: underline;
    color: ${BLACK_LIGHT};
  }

  p {
    line-height: 1.88;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
    margin: 1em;
    padding: 1em;
    li:not(:last-child) {
      margin-bottom: ${pxToRem(15)};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${pxToRem(100)};
`;
