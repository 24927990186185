import React from 'react';
import { LinkButton } from 'Components/UI';
import { config } from 'Config';
import { SIGNUP } from 'Constants/routes';
import { useTranslation } from 'Hooks';
import { ButtonContainer } from './styles';

function StartButton() {
  const { t } = useTranslation();
  return (
    <ButtonContainer align="center" justify="center">
      <LinkButton isLink link={`${config.WEBAPP_ROUTE}${SIGNUP}`}>
        {t('StartButton.text')}
      </LinkButton>
    </ButtonContainer>
  );
}

export default StartButton;
