import React from 'react';

function Networking() {
  return (
    <svg width="75px" viewBox="0 0 75 68" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Networking</title>
      <g id="trabaja-con-nosotros" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-222.000000, -863.000000)" fillRule="nonzero" id="formacion_icon">
          <g transform="translate(222.000000, 863.000000)">
            <rect id="Rectangle" fill="#3BC0B3" x="7" y="4" width="64" height="37" />
            <polygon id="Path" fill="#FFFFFF" points="59.125 39 46 39 46 35 59.125 35 61 37" />
            <g id="Group" transform="translate(3.000000, 0.000000)" fill="#FFB74D">
              <path
                d="M8.54451613,15.0740741 C4.38300387,15.0740741 0.998709677,11.6918287 0.998709677,7.53703704 C0.998709677,3.38036111 4.38300387,0 8.54451613,0 C12.7060284,0 16.0903226,3.38036111 16.0903226,7.53703704 C16.0903226,11.6918287 12.7060284,15.0740741 8.54451613,15.0740741"
                id="Path"
              />
              <polygon
                id="Path"
                points="42.3434039 20.625 42.7225806 27.4083333 36.684049 31.9305556 33.2903226 27.4083333"
              />
            </g>
            <path
              d="M6.08921805,68 L20.3100241,68 L21.8124354,36.4512134 L21.8124354,31.4610207 L31.937134,38.0511519 C32.350155,38.2126841 34.0571822,38.2876812 34.9609025,37.5915542 L44,29.9418484 L39.3298312,23.5228645 L32.5680331,28.7630476 C32.5680331,28.7630476 22.3561833,20.607592 20.4862212,19.4730203 C20.4331726,19.4422522 17.3468825,18 13.4099208,18 C13.3777127,18 13.3587668,18.003846 13.3284533,18.003846 C13.3057182,18.003846 13.2848777,18 13.2621426,18 C3.7891836,18 0,21.8460059 0,29.5380178 L0,44.9220415 L4.97330348,48.2872966 L6.08921805,68 Z"
              id="Path"
              fill="#455A64"
            />
            <g id="Group" transform="translate(27.000000, 48.000000)" fill="#FFA726">
              <path
                d="M9.96875,5 C9.96875,7.76 7.85314063,10 5.2421875,10 C2.63123438,10 0.515625,7.76 0.515625,5 C0.515625,2.238 2.63123438,0 5.2421875,0 C7.85314063,0 9.96875,2.238 9.96875,5"
                id="Path"
              />
              <path
                d="M26.984375,5 C26.984375,7.76 24.8687656,10 22.2578125,10 C19.6468594,10 17.53125,7.76 17.53125,5 C17.53125,2.238 19.6468594,0 22.2578125,0 C24.8687656,0 26.984375,2.238 26.984375,5"
                id="Path"
              />
              <path
                d="M44,5 C44,7.76 41.8825,10 39.2734375,10 C36.664375,10 34.546875,7.76 34.546875,5 C34.546875,2.238 36.664375,0 39.2734375,0 C41.8825,0 44,2.238 44,5"
                id="Path"
              />
            </g>
            <g id="Group" transform="translate(22.000000, 60.000000)" fill="#607D8B">
              <path
                d="M18.1315789,6 C18.1315789,6 15.7179094,0 9.41447368,0 C3.11103801,0 0.697368421,6 0.697368421,6 L0.697368421,8 L18.1315789,8 L18.1315789,6 Z"
                id="Path"
              />
              <path
                d="M35.5657895,6 C35.5657895,6 33.1521199,0 26.8486842,0 C20.5452485,0 18.1315789,6 18.1315789,6 L18.1315789,8 L35.5657895,8 L35.5657895,6 Z"
                id="Path"
              />
              <path
                d="M53,6 C53,6 50.5882675,0 44.2828947,0 C37.9794591,0 35.5657895,6 35.5657895,6 L35.5657895,8 L53,8 L53,6 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Networking;
