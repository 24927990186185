import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH } from 'Styles/sizes';

export const BannerContainer = styled.div`
  min-height: ${pxToRem(368)};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  white-space: pre-line;
  background: linear-gradient(rgba(92, 92, 92, 0.65), rgba(92, 92, 92, 0.65)),
    url(${({ backgroundImage }) => backgroundImage});
  background-position: center center;
  background-size: cover;

  h3 {
    margin: 0 1rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    background: linear-gradient(rgba(92, 92, 92, 0.65), rgba(92, 92, 92, 0.65)),
      url(${({ backgroundImageMobile }) => backgroundImageMobile});
    ${({ noCenter }) => !noCenter && `background-position: center center`};
    background-size: cover;

    h3 {
      font-size: 1rem;
      margin: 1rem;
    }

    h2 {
      margin: 0;
    }
  }
`;
