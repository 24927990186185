import React from 'react';

function LogoText() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="157"
      height="29"
      fill="none"
      viewBox="0 0 157 29"
    >
      <mask id="a" width="14" height="27" x="0" y="2" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M2.588 13.54H0V8.26h2.785V2.473h6.588V8.26h4.236v5.28H9.373v6.178c0 2.268 2.314 2.58 3.608 2.58.55 0 .903-.038.903-.038v5.866s-.746.117-1.844.117c-3.216 0-9.452-.86-9.452-7.625v-7.079z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#5C5C5C"
          fillRule="evenodd"
          d="M-.004 28.243h13.888V2.473H-.004v25.77z"
          clipRule="evenodd"
        />
      </g>
      <mask id="b" width="20" height="22" x="15" y="7" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M24.741 23.263c1.77 0 3.341-1.92 3.341-3.564v-.431h-.628c-2.162 0-4.639.627-4.639 2.31 0 .94.59 1.685 1.926 1.685zm2.988-7.872h.275v-.353c0-1.684-1.297-2.232-2.987-2.232-2.556 0-5.464 1.919-5.464 1.919l-2.359-4.779s3.498-2.624 8.491-2.624c5.7 0 9.12 3.094 9.12 8.225v12.416h-6.211v-1.214c0-.666.078-1.253.078-1.253h-.078s-1.494 2.937-5.74 2.937c-3.773 0-6.878-2.507-6.878-6.502 0-5.874 8.608-6.54 11.753-6.54z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#5C5C5C"
          fillRule="evenodd"
          d="M15.976 28.433h18.828V7.323H15.976v21.11z"
          clipRule="evenodd"
        />
      </g>
      <mask id="c" width="22" height="22" x="36" y="7" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M42.674 18.119L36.136 7.893h7.68l2.205 4.523c.276.51.63 1.337.63 1.337h.08s.393-.826.63-1.337l2.205-4.523h7.68l-6.498 10.226 6.38 10.029h-7.483l-2.442-4.563a13.102 13.102 0 0 1-.472-1.022h-.08s-.275.668-.472 1.022l-2.403 4.563h-7.482l6.38-10.03z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#c)">
        <path
          fill="#5C5C5C"
          fillRule="evenodd"
          d="M36.132 28.148h21.114V7.893H36.132v20.255z"
          clipRule="evenodd"
        />
      </g>
      <mask id="d" width="22" height="29" x="56" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M67.397 22.993c2.045 0 3.972-1.496 3.972-5 0-2.441-1.298-4.843-3.972-4.843-2.124 0-3.973 1.732-3.973 4.843 0 3.032 1.613 5 3.973 5zM65.823 7.402c4.09 0 5.31 1.614 5.31 1.614h.079s-.04-.393-.04-.905V0h6.805v28.15h-6.372v-1.338c0-.63.04-1.141.04-1.141h-.08s-1.69 2.952-5.86 2.952c-5.506 0-9.124-4.33-9.124-10.63 0-6.417 3.854-10.591 9.242-10.591z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#d)">
        <path
          fill="#2EDB57"
          fillRule="evenodd"
          d="M56.577 28.623h21.4V0h-21.4v28.623z"
          clipRule="evenodd"
        />
      </g>
      <mask id="e" width="23" height="22" x="80" y="7" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M91.48 22.793c2.462 0 4.573-1.84 4.573-4.856 0-3.056-2.11-4.975-4.572-4.975s-4.573 1.92-4.573 4.975c0 3.015 2.11 4.856 4.573 4.856zm-.039-15.47c6.37 0 11.451 4.268 11.451 10.614 0 6.305-5.08 10.496-11.411 10.496-6.331 0-11.412-4.19-11.412-10.496 0-6.346 5.081-10.615 11.372-10.615z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#e)">
        <path
          fill="#2EDB57"
          fillRule="evenodd"
          d="M80.07 28.433h22.822V7.323H80.069v21.11z"
          clipRule="evenodd"
        />
      </g>
      <mask id="f" width="33" height="22" x="103" y="7" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M103.082 7.893h7.165l2.52 11.956c.235 1.102.235 2.124.235 2.124h.079s.079-1.022.355-2.124l2.795-11.956h5.827l2.794 11.956c.276 1.102.316 2.124.316 2.124h.079s.039-1.022.275-2.124l2.52-11.956h7.086l-5.669 20.255h-7.599l-2.283-9.36c-.275-1.102-.393-2.164-.393-2.164h-.079s-.118 1.063-.394 2.163l-2.283 9.36h-7.678l-5.668-20.254z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#f)">
        <path
          fill="#2EDB57"
          fillRule="evenodd"
          d="M103.082 28.148h32.046V7.893h-32.046v20.255z"
          clipRule="evenodd"
        />
      </g>
      <mask id="g" width="21" height="22" x="136" y="7" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M136.745 7.89h6.529v1.77c0 .668-.079 1.258-.079 1.258h.079c.944-1.573 2.871-3.5 6.45-3.5 4.091 0 7.276 1.888 7.276 7.592v13.138h-6.804v-11.88c0-1.888-.747-2.675-2.242-2.675-2.124 0-3.422 1.18-4.012 2.911-.275.787-.393 1.692-.393 2.675v8.969h-6.804V7.889z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#g)">
        <path
          fill="#2EDB57"
          fillRule="evenodd"
          d="M136.741 28.148H157V7.418h-20.259v20.73z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default LogoText;
