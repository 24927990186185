import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';

export const MainSectionWrapper = styled.div`
  max-width: ${pxToRem(590)};
`;

export const TextImportant = styled.span`
  margin-top: ${pxToRem(20)};
  color: ${({ theme: { colors } }) => colors.primaryGreen};
  font-weight: 700;
  display: block;
  font-size: ${pxToRem(60)};
`;
