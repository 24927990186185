/* eslint-disable react/no-children-prop */
import React from 'react';
import Helmet from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { useLegalsQuery } from 'Hooks';
import withView from 'Hocs/withView';
import { Banner } from 'Components';
import { COOKIES_BANNER_OPTIONS } from 'Constants/bannerOptions';
import { Section, Container, LegalText } from 'Components/UI';
import './styles.scss';

function Cookies() {
  const { text } = useLegalsQuery('Cookies');
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <Banner {...COOKIES_BANNER_OPTIONS} />
      <Section>
        <Container>
          <LegalText>
            <ReactMarkdown children={text} />
          </LegalText>
        </Container>
      </Section>
    </>
  );
}

export default withView(Cookies);
