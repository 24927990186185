import { useCallback, useEffect, useState } from 'react';
import { setTopProportion } from 'Utils/element';
import { viewportHeight } from 'Constants/window';

export const useStickyElement = ({
  percentage = 80,
  bottomThreshold = 30,
  stickyRef,
  unstickRef,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const [refTopDistance, setRefTopDistance] = useState(0);

  const topProportion = setTopProportion({ percentage });

  const toggleSticky = useCallback(
    ({ top, bottom }) => {
      const bottomDistance = viewportHeight - bottom;
      if (top === topProportion || bottomDistance > bottomThreshold) {
        if (!isSticky) {
          setIsSticky(true);
        }
      }
    },
    [isSticky]
  );

  useEffect(() => {
    const inRange = refTopDistance > viewportHeight - 20;
    if (inRange) {
      setIsSticky(false);
    }
  }, [refTopDistance]);

  useEffect(() => {
    const handleScroll = () => {
      toggleSticky(stickyRef.current.getBoundingClientRect());
      setRefTopDistance(unstickRef.current.getBoundingClientRect().top);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isSticky };
};

export default useStickyElement;
