import React from 'react';

function LockIcon() {
  return (
    <svg width="9px" viewBox="0 0 9 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Candado</title>
      <g id="quienes-somos" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="quienes_somos_mobile"
          transform="translate(-374.000000, -64.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Group-4-Copy" transform="translate(209.000000, 55.000000)">
            <g id="candado" transform="translate(165.000000, 9.000000)">
              <path
                d="M4.5,0 C2.8496095,0 1.5,1.3496095 1.5,3 L1.5,5.5 L0,5.5 L0,12 L9,12 L9,5.5 L7.5,5.5 L7.5,3 C7.5,1.3496095 6.1503905,0 4.5,0 Z M4.5,1 C5.609375,1 6.5,1.890625 6.5,3 L6.5,5.5 L2.5,5.5 L2.5,3 C2.5,1.890625 3.390625,1 4.5,1 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LockIcon;
