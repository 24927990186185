import React from 'react';

function TooltipLivePropertyIcon() {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.4569" cy="18.4569" r="17.4569" stroke="#3D3A62" strokeWidth="2" />
      <path
        d="M17.9405 6.00253C17.8342 6.01547 17.732 6.06058 17.6507 6.13042L6.19255 15.6789C6.0228 15.8216 5.95506 16.0741 6.03079 16.2827C6.10634 16.4913 6.32024 16.6416 6.54213 16.6422H8.45177V29.4645C8.45177 29.7501 8.71172 30.01 8.99735 30.01H27.0031C27.2887 30.01 27.5486 29.7501 27.5486 29.4645V16.6422H29.4583C29.6802 16.6416 29.8939 16.4913 29.9696 16.2827C30.0453 16.0742 29.9776 15.8216 29.8079 15.6789L26.1845 12.6607V6.54826C26.1845 6.26262 25.9246 6.00268 25.639 6.00268H21.8195C21.5338 6.00268 21.2739 6.26263 21.2739 6.54826V8.56888L18.3498 6.13056C18.2381 6.03522 18.0866 5.98784 17.9404 6.00268L17.9405 6.00253ZM22.3654 7.09369H25.0935V12.9163C25.0939 13.0752 25.1677 13.2324 25.2895 13.334L27.9496 15.5508H27.0032C26.7176 15.5508 26.4576 15.8108 26.4576 16.0964V28.9186H21.2742V20.7342C21.2742 20.4486 21.0142 20.1887 20.7286 20.1887H15.2723C15.2552 20.1879 15.2382 20.1879 15.2211 20.1887C14.953 20.214 14.7254 20.4648 14.7267 20.7342V28.9186H9.54328V16.0964C9.54328 15.8108 9.28333 15.5508 8.9977 15.5508H8.05094L18.0004 7.25534L21.4703 10.1454C21.6284 10.2773 21.864 10.3079 22.0505 10.2208C22.237 10.1338 22.3649 9.93361 22.3655 9.72771L22.3654 7.09369ZM15.8179 21.28H20.183V28.9188H15.8179V21.28Z"
        fill="#3D3A62"
      />
    </svg>
  );
}

export default TooltipLivePropertyIcon;
