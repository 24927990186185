import React from 'react';
import PropTypes from 'prop-types';
import StarIcon from 'assets/icons/StarIcon';
import './styles.scss';

const RatingStars = ({ rating }) => {
  const getRatingStars = () => {
    const content = [];
    for (let i = 0; i < rating; i += 1) {
      content.push(<StarIcon key={i} />);
    }
    return content;
  };

  return <div className="stars-container">{getRatingStars()}</div>;
};

RatingStars.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default RatingStars;
