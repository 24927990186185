import React, { useState } from 'react';
import Slider from 'react-slick';
import scrollToElement from 'scroll-to-element';
import { useTranslation } from 'Hooks';
import { Lottie } from 'Components';
import IncomeStatementLottie from './lotties/partners_1.json';
import ModelsAndFormsLottie from './lotties/partners_2.json';
import TaxOptimizationLottie from './lotties/partners_3.json';
import CounsellingLottie from './lotties/partners_4.json';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

const PartnersHowHelpBlock = () => {
  const { t } = useTranslation('home');

  const [lottieShown, setLottieShown] = useState('incomeStatement');

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    className: 'partners-how-help-slide',
    centerMode: false,
  };

  function IncomeStatement() {
    return (
      <div
        role="button"
        tabIndex="0"
        className="partners-help-block"
        onMouseEnter={() => setLottieShown('incomeStatement')}
        onClick={() => setLottieShown('incomeStatement')}
        onKeyUp={() => setLottieShown('incomeStatement')}
      >
        <h3>{t('PartnersHowHelpBlock.IncomeStatement.title')}</h3>
        <p>{t('PartnersHowHelpBlock.IncomeStatement.description')}</p>
      </div>
    );
  }

  function ModelsAndForms() {
    return (
      <div
        role="button"
        tabIndex="0"
        className="partners-help-block"
        onMouseEnter={() => setLottieShown('modelsAndForms')}
        onClick={() => setLottieShown('modelsAndForms')}
        onKeyUp={() => setLottieShown('modelsAndForms')}
      >
        <h3>{t('PartnersHowHelpBlock.ModelsAndForms.title')}</h3>
        <p>{t('PartnersHowHelpBlock.ModelsAndForms.description')}</p>
      </div>
    );
  }

  function TaxOptimization() {
    return (
      <div
        role="button"
        tabIndex="0"
        className="partners-help-block"
        onMouseEnter={() => setLottieShown('taxOptimization')}
        onClick={() => setLottieShown('taxOptimization')}
        onKeyUp={() => setLottieShown('taxOptimization')}
      >
        <h3>{t('PartnersHowHelpBlock.TaxOptimization.title')}</h3>
        <p>{t('PartnersHowHelpBlock.TaxOptimization.description')}</p>
      </div>
    );
  }

  function Counselling() {
    return (
      <div
        role="button"
        tabIndex="0"
        className="partners-help-block"
        onMouseEnter={() => setLottieShown('counselling')}
        onClick={() => setLottieShown('counselling')}
        onKeyUp={() => setLottieShown('counselling')}
      >
        <h3>{t('PartnersHowHelpBlock.Counselling.title')}</h3>
        <p>{t('PartnersHowHelpBlock.Counselling.description')}</p>
      </div>
    );
  }

  function HowHelpLotties() {
    if (lottieShown === 'incomeStatement') {
      return (
        <Lottie className="lottie-container" options={{ animationData: IncomeStatementLottie }} />
      );
    }
    if (lottieShown === 'modelsAndForms') {
      return (
        <Lottie className="lottie-container" options={{ animationData: ModelsAndFormsLottie }} />
      );
    }
    if (lottieShown === 'taxOptimization') {
      return (
        <Lottie className="lottie-container" options={{ animationData: TaxOptimizationLottie }} />
      );
    }
    if (lottieShown === 'counselling') {
      return <Lottie className="lottie-container" options={{ animationData: CounsellingLottie }} />;
    }
  }

  function scrollToForm() {
    scrollToElement('#partner-contact-form', {
      offset: '74px',
      duration: 100,
    });
    document.getElementById('partners-contact-form-name').focus();
  }

  return (
    <section className="partners-how-help-block block">
      <h2 className="partners-how-help-block__title mobile big">
        {t('PartnersHowHelpBlock.MobileTitle')}
      </h2>
      <h2 className="partners-how-help-block__title desktop big">
        {t('PartnersHowHelpBlock.DesktopTitle')}
      </h2>
      <div className="block-container">
        <HowHelpLotties />
        <div className="partners-how-help-block__desktop-content">
          <IncomeStatement />
          <ModelsAndForms />
          <TaxOptimization />
          <Counselling />
        </div>
      </div>
      <Slider {...settings}>
        <div>
          <Lottie className="lottie-container" options={{ animationData: IncomeStatementLottie }} />
          <IncomeStatement />
        </div>
        <div>
          <Lottie className="lottie-container" options={{ animationData: ModelsAndFormsLottie }} />
          <ModelsAndForms />
        </div>
        <div>
          <Lottie className="lottie-container" options={{ animationData: TaxOptimizationLottie }} />
          <TaxOptimization />
        </div>
        <div>
          <Lottie className="lottie-container" options={{ animationData: CounsellingLottie }} />
          <Counselling />
        </div>
      </Slider>
      <div className="cta">
        <button className="cta solid_btn green" type="button" onClick={() => scrollToForm()}>
          {t('PartnersHowHelpBlock.cta')}
        </button>
      </div>
    </section>
  );
};

export default PartnersHowHelpBlock;
