export const REVIEWS = [
  {
    name: 'RatingsBlock.review1.name',
    subtitle: 'RatingsBlock.review1.subtitle',
    review: 'RatingsBlock.review1.content',
    rating: 5,
  },
  {
    name: 'RatingsBlock.review2.name',
    subtitle: 'RatingsBlock.review2.subtitle',
    review: 'RatingsBlock.review2.content',
    rating: 5,
  },
  {
    name: 'RatingsBlock.review3.name',
    subtitle: 'RatingsBlock.review3.subtitle',
    review: 'RatingsBlock.review3.content',
    rating: 5,
  },
  {
    name: 'RatingsBlock.review4.name',
    subtitle: 'RatingsBlock.review4.subtitle',
    review: 'RatingsBlock.review4.content',
    rating: 5,
  },
];
