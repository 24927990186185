import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@taxdown/piccolo';
import { config } from 'Config';
import { FormApply, LogoContainer } from './styles';

function FirstSection({ currentJob }) {
  const { Area, Title } = currentJob;
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef();

  const handleOnLoad = () => {
    setIsLoading(false);
    iframeRef.current.style.opacity = '1';
  };

  useEffect(() => {
    iframeRef.current.addEventListener('load', () => handleOnLoad());
  }, []);

  return (
    <FormApply>
      {isLoading && (
        <LogoContainer>
          <Spinner width={65} height={65} color="#02cd55" />
        </LogoContainer>
      )}
      <iframe
        scrolling="no"
        ref={iframeRef}
        title="form"
        className="airtable-embed airtable-dynamic-height"
        src={`https://airtable.com/embed/${config.AIRTABLE_FORM_ID}?prefill_Area=${Area}&prefill_Posicion=${Title}`}
        frameBorder="0"
        onmousewheel=""
        width="100%"
        height="1180px"
        style={{
          opacity: '0',
          background: 'transparent; border: 1px solid #ccc;',
          transition: 'opacity 0.3s linear;',
          overflow: 'hidden',
        }}
      />
    </FormApply>
  );
}

FirstSection.propTypes = {
  currentJob: PropTypes.shape({
    Area: PropTypes.string,
    Title: PropTypes.string,
  }),
};

FirstSection.defaultProps = {
  currentJob: {},
};

export default FirstSection;
