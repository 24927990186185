import React from 'react';
import { Text, Heading } from '@taxdown/piccolo';
import { Section, Container } from 'Components/UI';
import featuresList from 'Assets/json/about-us/features.json';
import { useTranslation } from 'Hooks';
import { FeatureWrapper, FeatureText, FeatureImage } from './styles';

function FirstSection() {
  const { t } = useTranslation('about');
  return (
    <Section>
      <Container>
        {featuresList.map(({ title, text, image }, index) => (
          <FeatureWrapper alignLeft={index % 2 !== 0} key={title}>
            <FeatureText alignLeft={index % 2 !== 0}>
              <Heading type="h2" mb={24}>
                {t(title)}
              </Heading>
              {text.map(item => (
                <Text type="p" key={item} mb={20}>
                  {t(item)}
                </Text>
              ))}
            </FeatureText>
            <FeatureImage>
              <img src={image} alt="" />
            </FeatureImage>
          </FeatureWrapper>
        ))}
      </Container>
    </Section>
  );
}

export default FirstSection;
