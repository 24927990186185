import { config } from 'Config';

export const BASE = 'https://www.taxdown.es';
export const BNEXT_TAXDONW_ROUTE = 'https://bpartnership.app.taxdown.es';
export const FINTONIC_TAXDONW_ROUTE = 'https://fpartnership.app.taxdown.es';
export const HOME_ROUTE = '/';
export const HOW_IT_WORKS_ROUTE = '/como-funciona';
export const PRICE_ROUTE = '/precio';
export const WHO_WE_ARE_ROUTE = '/quienes-somos';
export const FAQ_ROUTE = 'https://help.taxdown.es/es';
export const SECURITY_ROUTE = '/seguridad';
export const BLOG_ROUTE = '/blog/';
export const BLOG_LINK = 'https://taxdown.es/blog/';
export const MEDIA_ROUTE = '/media';
export const LANDING_ROUTE = '/landing';
export const CAREERS_ROUTE = '/careers';
export const CAREERS_LIST_ROUTE = '/careers-list';
export const CAREERS_APPLY = '/careers-apply';
export const PARTNERS_BNEXT = '/partners/bnext/premium/register';
export const ACCOUNT_VALIDATION = '/account-validation';
export const SIGNUP = '/signup';
export const SIGN_UP_SUCCESSFUL = '/signup-successful';
export const HELP_ROUTE = FAQ_ROUTE;
export const TERMS_AND_CONDITIONS_ROUTE = '/terminos-condiciones';
export const COOKIES_ROUTE = '/cookies';
export const PRIVACY_ROUTE = '/privacidad';
export const LEGAL_ADVISE_ROUTE = '/aviso-legal';
export const PLANS_COMPARE_ANCHOR = 'plans-compare-section';
export const PARTNERS = '/partners';

export const WEBAPP_APP_SIGNUP = `${config.WEBAPP_ROUTE}/signup`;
