import React from 'react';
import PropTypes from 'prop-types';
import Theme from 'Styles/Theme';
import LogoLoader from '../LogoLoader';
import AnalyticsHead from '../AnalyticsHead';

const PrageWrapper = ({ children }) => {
  const isSSR = typeof window === 'undefined';
  return (
    <>
      <AnalyticsHead />
      {!isSSR && (
        <>
          <React.Suspense fallback={<LogoLoader />}>
            <Theme>{children}</Theme>
          </React.Suspense>
        </>
      )}
    </>
  );
};

PrageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrageWrapper;
