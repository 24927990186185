import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';

export const FirstSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    font-size: ${pxToRem(32)};
  }
`;

export const DescriptionWrapper = styled.div`
  max-width: ${pxToRem(817)};
  width: 100%;
  p {
    line-height: 1.38;
  }
`;

export const TextWithLogo = styled.div`
  svg {
    margin: ${pxToRem(20)} 0;
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};

    #Group {
      path {
        fill: ${({ theme: { colors } }) => colors.primaryGreen};
      }
    }
  }

  h3 {
    font-size: ${pxToRem(20)};
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
`;
