import React from 'react';
import classNames from 'classnames';
import { Trans } from 'Components';
import { useCountdownTimer } from 'Hooks';
import { deadline } from 'Constants/countdown';
import { FAQ_ROUTE } from 'Constants/routes';
import { LineArrowIcon } from 'Assets/icons';
import './styles.scss';

const deadlineArticle = `${FAQ_ROUTE}/articles/5358849-cual-es-el-plazo-para-presentar-la-declaracion-de-la-renta`;
const outTimeArticle = `${FAQ_ROUTE}/articles/4963124-que-sucede-si-presento-mi-declaracion-fuera-de-plazo-30-de-junio`;

const CountdownWidget = () => {
  const { countdownValues, isDeadline } = useCountdownTimer({ deadline });
  const article = isDeadline ? outTimeArticle : deadlineArticle;
  const containerClass = classNames({
    'countdown-container': !isDeadline,
    'end-session__container': isDeadline,
  });

  return (
    <aside className={containerClass} data-testid="countdown">
      {isDeadline ? (
        <>
          <Trans
            i18nKey="Countdown.EndSession.title"
            ns="home"
            components={{
              title: <p className="countdown-title" />,
            }}
          />
          <div className="end-session__content">
            <Trans
              i18nKey="Countdown.EndSession.content"
              ns="home"
              components={{ bold: <span />, p: <p /> }}
            />
          </div>
          <div className="countdown-text">
            <Trans
              i18nKey="Countdown.EndSession.info"
              ns="home"
              components={{ bold: <span />, title: <p /> }}
            />
            <a className="know-more" href={article} target="_blank" rel="noreferrer">
              <LineArrowIcon />
              <Trans i18nKey="KnowMore" components={{ p: <p className="know-more__text" /> }} />
            </a>
          </div>
        </>
      ) : (
        <>
          <Trans
            i18nKey="Home.Countdown.title"
            ns="home"
            components={{
              icon: <span role="img" aria-label="Rocket icon" />,
              title: <p className="countdown-title" />,
            }}
          />
          <div>
            <Trans i18nKey="Countdown.End.text" ns="home" components={{ p: <p /> }} />
          </div>
          <div className="countdown-time">
            {countdownValues.map(item => (
              <div className="countdown-time__value" key={item.type}>
                <p className={item.type} data-testid="timeValue">
                  {item.value}
                </p>
                <div>
                  <Trans i18nKey={item.name} ns="home" components={{ p: <p /> }} />
                </div>
              </div>
            ))}
          </div>
          <div className="countdown-text">
            <Trans
              i18nKey="Countdown.Final.text"
              ns="home"
              components={{ bold: <span />, title: <p /> }}
            />
            <a className="know-more" href={article} target="_blank" rel="noreferrer">
              <LineArrowIcon />
              <Trans i18nKey="KnowMore" components={{ p: <p className="know-more__text" /> }} />
            </a>
          </div>
        </>
      )}
    </aside>
  );
};

export default CountdownWidget;
