import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH, LARGE_DEVICE_WIDTH } from 'Styles/sizes';

export const BannerImageWrapper = styled.section`
  position: relative;
  width: 100%;
  min-height: ${pxToRem(388)};
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;

  ${({ align }) =>
    align === 'right' &&
    `
    text-align: right;
    > div {
    h3,
    a, p {
      margin-left: auto;
    }
  }
  `}
  > div {
    display: ${props => (props.hasImage ? 'flex' : 'block')};
    justify-content: ${props => (props.hasImage ? 'space-between' : 'block')};
    align-items: ${props => (props.hasImage ? 'center' : '')};
  }
  h3 {
    font-size: ${pxToRem(30)};
    font-weight: bold;
    line-height: 1.35;
    margin: ${pxToRem(20)} 0;
    margin-top: 0;
  }

  a {
    margin-top: ${pxToRem(20)};
  }

  p {
    max-width: ${pxToRem(395)};
  }

  @media (max-width: ${LARGE_DEVICE_WIDTH}) {
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    text-align: left;
    h3 {
      font-size: ${pxToRem(24)};
    }
    a {
      width: 100%;
    }
  }
`;

export const CustomLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
`;

export const ImageWrapper = styled.div`
  display: flex;
  max-width: ${pxToRem(328)};
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${LARGE_DEVICE_WIDTH}) {
    display: none;
  }
`;
