import React from 'react';
import PropTypes from 'prop-types';
import { CustomLink, StyledLink } from './styles';

const LinkButton = ({ link, children, isLink }) => {
  return !isLink ? (
    <StyledLink to={link}>{children}</StyledLink>
  ) : (
    <CustomLink href={link} type="button" role="button">
      {children}
    </CustomLink>
  );
};

LinkButton.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLink: PropTypes.bool,
};

LinkButton.defaultProps = {
  isLink: false,
};
export default LinkButton;
