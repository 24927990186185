import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'Hooks';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

import EvoImage from './img/evo_image.png';
import EvoAvatar from './img/evo_avatar.png';
import EvoLogo from './img/evo_logo.png';
import AbancaImage from './img/abanca_image.png';
import AbancaAvatar from './img/abanca_avatar.png';
import AbancaLogo from './img/abanca_logo.png';
import VertiImage from './img/verti_image.png';
import VertiAvatar from './img/verti_avatar.png';
import VertiLogo from './img/verti_logo.png';
import RealeImage from './img/reale_image.png';
import RealeAvatar from './img/reale_avatar.png';
import RealeLogo from './img/reale_logo.png';
import LeroyImage from './img/leroy_image.png';
import LeroyAvatar from './img/leroy_avatar.png';
import LeroyLogo from './img/leroy_logo.png';
import WeworkImage from './img/we_work_image.png';
import WeworkAvatar from './img/we_work_avatar.png';
import WeworkLogo from './img/we_work_logo.png';
import AonImage from './img/aon_image.png';
import AonAvatar from './img/aon_avatar.png';
import AonLogo from './img/aon_logo.png';
import CarrefourImage from './img/carrefour_image.png';
import CarrefourAvatar from './img/carrefour_avatar.png';
import CarrefourLogo from './img/carrefour_logo.png';

const SuccessfulCasesBlock = () => {
  const { t } = useTranslation('home');

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    className: 'successful-cases-block-slide',
    centerMode: false,
  };

  function SlideBlock(img, imgAlt, avatar, logo, name, position, quote) {
    return (
      <div className="slide-block">
        <img src={img} alt={imgAlt} className="slide-block__img" />
        <div className="slide-block-card">
          <div className="slide-block-card__avatar">
            <img src={avatar} alt={imgAlt} />
          </div>
          <div className="slide-block-card__logo">
            <img src={logo} alt={imgAlt} />
          </div>
          <h3 className="slide-block-card__name">{name}</h3>
          <p className="slide-block-card__position">{position}</p>
          <p className="slide-block-card__quote">{quote}</p>
        </div>
      </div>
    );
  }

  return (
    <section className="successful-cases-block block">
      <h2>{t('SuccessfulCasesBlock.title')}</h2>
      <Slider {...settings}>
        {SlideBlock(
          EvoImage,
          t('SuccessfulCasesBlock.evo.alt'),
          EvoAvatar,
          EvoLogo,
          t('SuccessfulCasesBlock.evo.name'),
          t('SuccessfulCasesBlock.evo.position'),
          t('SuccessfulCasesBlock.evo.quote')
        )}
        {SlideBlock(
          AbancaImage,
          t('SuccessfulCasesBlock.abanca.alt'),
          AbancaAvatar,
          AbancaLogo,
          t('SuccessfulCasesBlock.abanca.name'),
          t('SuccessfulCasesBlock.abanca.position'),
          t('SuccessfulCasesBlock.abanca.quote')
        )}
        {SlideBlock(
          VertiImage,
          t('SuccessfulCasesBlock.verti.alt'),
          VertiAvatar,
          VertiLogo,
          t('SuccessfulCasesBlock.verti.name'),
          t('SuccessfulCasesBlock.verti.position'),
          t('SuccessfulCasesBlock.verti.quote')
        )}
        {SlideBlock(
          RealeImage,
          t('SuccessfulCasesBlock.reale.alt'),
          RealeAvatar,
          RealeLogo,
          t('SuccessfulCasesBlock.reale.name'),
          t('SuccessfulCasesBlock.reale.position'),
          t('SuccessfulCasesBlock.reale.quote')
        )}
        {SlideBlock(
          LeroyImage,
          t('SuccessfulCasesBlock.leroy.alt'),
          LeroyAvatar,
          LeroyLogo,
          t('SuccessfulCasesBlock.leroy.name'),
          t('SuccessfulCasesBlock.leroy.position'),
          t('SuccessfulCasesBlock.leroy.quote')
        )}
        {SlideBlock(
          WeworkImage,
          t('SuccessfulCasesBlock.wework.alt'),
          WeworkAvatar,
          WeworkLogo,
          t('SuccessfulCasesBlock.wework.name'),
          t('SuccessfulCasesBlock.wework.position'),
          t('SuccessfulCasesBlock.wework.quote')
        )}
        {SlideBlock(
          AonImage,
          t('SuccessfulCasesBlock.aon.alt'),
          AonAvatar,
          AonLogo,
          t('SuccessfulCasesBlock.aon.name'),
          t('SuccessfulCasesBlock.aon.position'),
          t('SuccessfulCasesBlock.aon.quote')
        )}
        {SlideBlock(
          CarrefourImage,
          t('SuccessfulCasesBlock.carrefour.alt'),
          CarrefourAvatar,
          CarrefourLogo,
          t('SuccessfulCasesBlock.carrefour.name'),
          t('SuccessfulCasesBlock.carrefour.position'),
          t('SuccessfulCasesBlock.carrefour.quote')
        )}
      </Slider>
    </section>
  );
};

export default SuccessfulCasesBlock;
