import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';

export const TitleWrapper = styled.div`
  margin-bottom: ${pxToRem(27)};
`;

export const TextWrapper = styled.div`
  max-width: ${pxToRem(655)};
  p {
    margin: ${pxToRem(5)} 0;
  }
`;

export const TextImportant = styled.span`
  color: ${({ theme: { colors } }) => colors.primaryGreen};
  font-size: ${pxToRem(60)};
  display: block;
`;
