import React from 'react';
import { useTranslation } from 'Hooks';
import './styles.scss';
import { MEDIA } from './media';

const MediaGridBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="media-block block">
      <div className="media-block-container">
        <h2>{t('TalkAboutUs.title')}</h2>
        <div className="media-block__items">
          {MEDIA.map(({ name, icon, alt }) => {
            let imgClass = 'media_logo';
            if (name === 'TalkAboutUs.tve') {
              imgClass = 'media_logo tve';
            } else if (name === 'TalkAboutUs.lasexta') {
              imgClass = 'media_logo sexta';
            }
            return (
              <div className="box" key={name}>
                <div className="icon-container">
                  <img src={icon} alt={t(alt)} className={imgClass} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default MediaGridBlock;
