import styled from 'styled-components';
import { MOBILE_WIDTH } from 'Styles/sizes';
import { pxToRem } from 'Styles/utils';

export const FeatureBannerWrapper = styled.section`
  width: 100%;
  padding: ${pxToRem(86)} 0;
  background: url(${({ backgroundImage }) => backgroundImage}) no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  ${({ align }) =>
    align === 'right' &&
    `
    text-align: right;
    > div {
    flex-direction: column;
    h3,
    button, p {
      margin-left: auto;
    }
  }
  `}
  > div {
    flex-direction: column;
  }

  button {
    min-width: ${pxToRem(234)};
    font-size: ${pxToRem(14)};
    a {
      color: ${({ theme: { colors } }) => colors.white};
    }
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    background: url(${({ backgroundImageMobile }) => backgroundImageMobile}) no-repeat;
    text-align: left;
    h3 {
      font-size: ${pxToRem(28)};
    }
    button {
      width: 100%;
    }
  }
`;

export const TitleWrapper = styled.div`
  max-width: ${pxToRem(636)};
  h2 {
    font-size: ${pxToRem(34)};
  }
`;

export const TextWrapper = styled.div`
  max-width: ${pxToRem(562)};
  p {
    margin: 0;
  }
  margin-bottom: ${pxToRem(60)};

  @media (max-width: ${MOBILE_WIDTH}) {
    p {
      margin: 1rem 0;
    }
  }
`;

export const CustomLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.white};
`;
