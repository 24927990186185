import React from 'react';
import { Heading } from '@taxdown/piccolo';
import { Section, Container } from 'Components/UI';
import { useTranslation, useMediaQuery } from 'Hooks';
import { MediaListWrapper, MediaTwoColumns, MediaTwoRows } from './styles';
import MediaCard from './MediaCard';

function FirstSection() {
  const { t } = useTranslation();
  const { data } = useMediaQuery();
  const MEDIA_CARD_TWO_COLUMNS = data.slice(1, 3);
  const MEDIA_CARD_THREE_COLUMNS = data.slice(3);
  return (
    <Section>
      <Container>
        <MediaListWrapper>
          <Heading type="h2">{t('Media.FirstSection.title')}</Heading>
          <MediaTwoColumns>
            {MEDIA_CARD_TWO_COLUMNS.map(mediaOptions => (
              <MediaCard key={mediaOptions.link} {...mediaOptions} />
            ))}
            <MediaCard {...MEDIA_CARD_THREE_COLUMNS[0]} hasImage />
            <MediaTwoRows>
              <MediaCard {...MEDIA_CARD_THREE_COLUMNS[1]} isSmall />
              <MediaCard {...MEDIA_CARD_THREE_COLUMNS[2]} isSmall />
            </MediaTwoRows>
          </MediaTwoColumns>
        </MediaListWrapper>
      </Container>
    </Section>
  );
}
export default FirstSection;
