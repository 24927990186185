import React from 'react';
import { useTranslation } from 'Hooks';
import { AeatIcon } from 'Assets/icons';
import { GoogleRating } from 'Components';
import { WEBAPP_APP_SIGNUP } from 'Constants/routes';
import './styles.scss';

function MainSection() {
  const { t } = useTranslation('home');

  return (
    <div className="header">
      <div className="banner__submission-home">
        <p>{t('Home.BannerTop.title')}</p>
      </div>
      {/* <div className="header__language">
        <ChangeLanguage languages={languages} className="lang" />
      </div> */}
      <div className="header_img" title={t('Home.Banner.background.alt')} />
      <div className="header_info">
        <h1 className="title">{t('Home.Banner.title')}</h1>
        <p className="subtitle">{t('Home.Banner.subtitle')}</p>
        <div className="button__container">
          <a
            href={WEBAPP_APP_SIGNUP}
            className="solid_btn green big no_sticky"
            data-testid="cta_button"
          >
            {t('Home.Banner.CTA')}
          </a>
        </div>
        <GoogleRating rating={5} />
      </div>
      <div className="mod_hacienda">
        <div className="hacienda_top">
          <AeatIcon />
          <p className="hacienda_top_text">{t('Home.AeatBanner.text')}</p>
        </div>
      </div>
    </div>
  );
}

export default MainSection;
