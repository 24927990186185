import React from 'react';

function CoworkingIcon() {
  return (
    <svg width="103px" viewBox="0 0 103 65" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Coworking</title>
      <g id="trabaja-con-nosotros" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-809.000000, -558.000000)" fillRule="nonzero" id="coworking_icon">
          <g transform="translate(809.000000, 558.500000)">
            <g id="icons8-potted_plant" transform="translate(54.000000, 0.000000)">
              <path
                d="M24.3128635,25.5 C24.3128635,25.5 24.3128635,25.5 24.3128635,25.5 C23.3927653,25.5 22.7793666,24.7903607 22.7793666,24.0807215 C22.7793666,23.9387936 22.9327163,16.1327617 17.7188267,13.1522768 C16.9520782,12.7264932 16.7987285,11.8749261 17.2587776,11.1652868 C17.7188267,10.4556475 18.6389248,10.3137197 19.4056733,10.7395033 C26.1530598,14.7134831 25.9997101,23.7968658 25.9997101,24.2226493 C25.8463604,24.9322886 25.079612,25.5 24.3128635,25.5 Z"
                id="Path"
                fill="#8BC34A"
              />
              <path
                d="M19.2349078,0.736519952 C12.7329671,-2.30781144 2.57368484,1.11706138 0,2.00499137 C3.79279872,4.16139277 3.25097033,15.8313298 10.1592823,17.9877312 C15.0357378,19.5098969 18.8285365,17.7340369 19.2349078,11.3916798 C24.6531917,8.47419557 23.8404491,2.89292135 19.2349078,0.736519952 Z"
                id="Path"
                fill="#8BC34A"
              />
              <path
                d="M24.5679732,29.5 C23.6271893,29.5 23,28.977543 23,28.1938576 L23,24.2754304 C23,14.0875196 32.0942444,12.5201487 32.0942444,12.5201487 C32.878231,12.3895345 33.8190149,12.9119914 33.9758123,13.5650626 C34.1326096,14.2181338 33.5054203,15.0018193 32.7214337,15.1324335 C32.4078391,15.1324335 26.1359464,16.3079617 26.1359464,24.2754304 L26.1359464,28.1938576 C26.1359464,28.977543 25.5087571,29.5 24.5679732,29.5 Z"
                id="Path"
                fill="#8BC34A"
              />
              <path
                d="M32.5082024,1.8928688 C38.6576863,-0.014650766 47.043346,5.57165655 49,6.93417053 C45.3662141,8.29668451 42.9902771,19.6055505 36.5612713,20.4230589 C32.0889194,20.9680645 29.7129825,18.6517907 30.8310704,12.3842264 C25.7996746,8.5691873 28.1756115,3.11913139 32.5082024,1.8928688 Z"
                id="Path"
                fill="#8BC34A"
              />
              <path
                d="M10.0352113,19.9720482 C3.21126761,17.749629 3.74647887,5.7224192 0,3.5 C3.34507042,3.76146108 8.16197183,6.63753298 19,13.17406 C18.5985915,19.7105871 14.9859155,21.5408147 10.0352113,19.9720482 Z"
                id="Path"
                fill="#689F38"
              />
              <path
                d="M37.1757455,20.4067295 C43.1543011,19.6883464 45.4128666,9.75071422 49,8.55340914 C46.2100074,8.19421761 41.8257332,9.63098371 31.728617,13.3426295 C29.4700515,18.7305023 32.7914713,21.005382 37.1757455,20.4067295 Z"
                id="Path"
                fill="#689F38"
              />
              <polygon
                id="Path"
                fill="#E64A19"
                points="10 27.5 14.0909091 48.5 35.9090909 48.5 40 27.5"
              />
              <path
                d="M39.6666667,30.5 L10.3333333,30.5 C9.53333333,30.5 9,29.8 9,29.1 L9,23.5 L41,23.5 L41,29.1 C41,29.8 40.3333333,30.5 39.6666667,30.5 Z"
                id="Path"
                fill="#BF360C"
              />
            </g>
            <g id="icons8-laptop" transform="translate(0.000000, 8.000000)">
              <path
                d="M70,41.6666667 C70,42.7265833 69.161875,45.5 68.125,45.5 L11.875,45.5 C10.84,45.5 10,42.7265833 10,41.6666667 L10,1.41666667 C10,0.35675 10.84,-0.5 11.875,-0.5 L68.125,-0.5 C69.161875,-0.5 70,0.35675 70,1.41666667 L70,41.6666667 Z"
                id="Path"
                fill="#455A64"
              />
              <rect id="Rectangle" fill="#BBDEFB" x="14" y="3.5" width="53" height="33" />
              <g id="Group" transform="translate(0.000000, 40.500000)" fill="#CFD8DC">
                <rect id="Rectangle" x="0" y="12" width="79" height="4" />
                <polygon id="Path" points="69.5952381 0 9.4047619 0 0 12 79 12" />
              </g>
              <polygon id="Path" fill="#546E7A" points="13.75 43.5 66.25 43.5 70 48.5 10 48.5" />
              <polygon id="Path" fill="#90A4AE" points="47.1 48.5 31.9 48.5 30 52.5 49 52.5" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CoworkingIcon;
