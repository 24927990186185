import React from 'react';
import { Text, Heading, Button } from '@taxdown/piccolo';
import { Container } from 'Components/UI';
import PropTypes from 'prop-types';
import Link from 'Components/Link';
import { useTranslation } from 'Hooks';
import { FeatureBannerWrapper, TextWrapper, TitleWrapper } from './styles';

function SecondSection({
  backgroundImage,
  backgroundImageMobile,
  title,
  description,
  textButton,
  align,
  cta,
}) {
  const { t } = useTranslation('about');
  return (
    <FeatureBannerWrapper
      backgroundImage={backgroundImage}
      align={align}
      backgroundImageMobile={backgroundImageMobile}
    >
      <Container>
        <TitleWrapper>
          <Heading type="h2" color="white" bold mb={24}>
            {t(title)}
          </Heading>
        </TitleWrapper>
        <TextWrapper>
          {description.map(item => (
            <Text type="p" color="white" key={item}>
              {t(item)}
            </Text>
          ))}
        </TextWrapper>
        <Link to={cta.route}>
          <Button size="medium">{t(textButton)}</Button>
        </Link>
      </Container>
    </FeatureBannerWrapper>
  );
}

SecondSection.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  backgroundImageMobile: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  textButton: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
  cta: PropTypes.shape().isRequired,
};

export default SecondSection;
