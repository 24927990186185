import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from './styles';

const ContainerLanding = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

ContainerLanding.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ContainerLanding.defaultProps = {
  className: null,
};

export default ContainerLanding;
