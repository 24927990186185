import React from 'react';
import PropTypes from 'prop-types';
import { SectionWrapper } from './styles';

function Section({ children, backgroundColor, className }) {
  return (
    <SectionWrapper className={className} backgroundColor={backgroundColor}>
      {children}
    </SectionWrapper>
  );
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};
Section.defaultProps = {
  className: null,
  backgroundColor: null,
};

export default Section;
