import styled from 'styled-components';
import { MOBILE_WIDTH, TABLET_WIDTH } from 'Styles/sizes';
import { pxToRem } from 'Styles/utils';
import { BASE_GREEN } from 'Styles/colors';

export const ThirdSectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${TABLET_WIDTH}) {
    flex-direction: column;
  }
`;

export const TextButtonWrapper = styled.div`
  flex-basis: 30%;
  h2 {
    font-size: ${pxToRem(32)};
    max-width: ${pxToRem(340)};
  }
  button {
    max-width: ${pxToRem(233)};
    width: 100%;
  }

  @media screen and (max-width: ${TABLET_WIDTH}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
    width: 100%;
    margin-bottom: ${pxToRem(48)};
    text-align: center;
    h2 {
      max-width: 100%;
    }
    button {
      max-width: ${pxToRem(148)};
      width: 100%;
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    a {
      width: 100%;
    }
    button {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export const JobsWrapper = styled.div`
  flex-basis: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${pxToRem(60)};
`;

export const JobBlock = styled.div`
  text-align: center;
  flex-basis: 50%;
  margin-bottom: ${pxToRem(60)};

  a {
    text-decoration: none;
  }

  h1 {
    font-size: 6rem;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    transition: transform 300ms linear;

    :hover {
      color: ${BASE_GREEN};
      transform: translateY(-0.5rem);
    }
  }

  h3 {
    text-transform: capitalize;
    margin-bottom: 0;
  }

  p {
    svg {
      margin-right: 0.75rem;
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    flex-basis: 100%;
  }
`;
