import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH } from 'Styles/sizes';

export const LogoWrapper = styled.div`
  width: 100%;
  max-width: ${pxToRem(170)};
  margin-bottom: ${pxToRem(20)};
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    margin-bottom: 0;
  }
`;

export const MainSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    font-size: ${pxToRem(32)};
  }
  svg {
    margin: ${pxToRem(20)} 0;
    g {
      g {
        path {
          fill: ${({ theme: { colors } }) => colors.primaryGreen};
        }
      }
    }
  }
`;

export const CustomLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.primaryGreen};
`;

export const MediaSnippetLink = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: ${pxToRem(18)};
    margin-left: ${pxToRem(10)};
  }
`;
