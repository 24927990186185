import React from 'react';

function ProtectedSessionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="90"
      fill="none"
      viewBox="0 0 104 90"
    >
      <title>Sesión protegida</title>
      <path fill="#00DC5A" d="M81 82h21v6H81z" />
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M3.462 82l35.953 1.077L38.078 88H3.462v-6z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M96.923 45h1.73a5.192 5.192 0 0 1 5.193 5.192v34.616A5.192 5.192 0 0 1 98.654 90H84.808a5.192 5.192 0 0 1-5.193-5.192V69.23H58.846v10.384h5.193a8.664 8.664 0 0 1 8.653 8.654A1.73 1.73 0 0 1 70.962 90H5.192A5.192 5.192 0 0 1 0 84.808v-45a5.192 5.192 0 0 1 5.192-5.193h1.731V8.654A8.664 8.664 0 0 1 15.577 0h72.692a8.664 8.664 0 0 1 8.654 8.654V45zM36.346 86.538a1.73 1.73 0 0 0 1.73-1.73v-1.731H3.463v1.73c0 .956.774 1.731 1.73 1.731h31.154zm1.73-6.923H3.463V39.808c0-.956.774-1.731 1.73-1.731h31.154c.956 0 1.73.775 1.73 1.73v39.808zm30.859 6.924a5.202 5.202 0 0 0-4.897-3.462h-22.5v1.73a5.134 5.134 0 0 1-.318 1.731h27.715zm-27.397-6.924V69.231H45v10.384h-3.462zm6.924-10.384v10.384h6.923V69.231h-6.923zm31.153-3.462H41.54V55.385h38.076v10.384zm0-13.846v-1.73A5.192 5.192 0 0 1 84.808 45h8.653V8.654a5.192 5.192 0 0 0-5.192-5.192H15.577a5.192 5.192 0 0 0-5.192 5.192v25.961h25.961c.74.002 1.471.165 2.143.476a.451.451 0 0 0 .04-.064c.016-.028.032-.055.055-.08l2.447 2.448c-.012.014-.028.022-.044.03a.185.185 0 0 0-.04.027 5.13 5.13 0 0 1 .591 2.356v12.115h38.077zm20.77 32.885c0 .955-.775 1.73-1.731 1.73H84.808a1.73 1.73 0 0 1-1.731-1.73v-1.731h17.308v1.73zm-17.308-5.193h17.308V50.192c0-.956-.775-1.73-1.731-1.73H84.808a1.73 1.73 0 0 0-1.731 1.73v29.423z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M51.958 58.846h-.018a1.73 1.73 0 0 0 .018 3.461 1.73 1.73 0 0 0 0-3.461z"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M70.624 33.914h-.111v-8.75C70.513 15.7 62.813 8 53.348 8s-17.165 7.7-17.165 17.165v8.75h-.111A5.077 5.077 0 0 0 31 38.984v13.822c0 12.322 10.025 22.347 22.348 22.347 12.322 0 22.347-10.025 22.347-22.347V38.986a5.077 5.077 0 0 0-5.071-5.072zM53.348 11.233c7.682 0 13.932 6.25 13.932 13.932v8.749h-3.678v-8.75c0-5.654-4.6-10.254-10.254-10.254-5.655 0-10.255 4.6-10.255 10.255v8.749h-3.678v-8.75c0-7.682 6.25-13.932 13.933-13.932zm7.022 22.681v-8.75a7.03 7.03 0 0 0-7.022-7.02 7.03 7.03 0 0 0-7.022 7.02v8.75H60.37z"
        clipRule="evenodd"
      />
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M53.348 71.922c-10.54 0-19.115-8.575-19.115-19.115v-13.82a1.84 1.84 0 0 1 1.839-1.84h34.552a1.84 1.84 0 0 1 1.839 1.84v13.82c0 10.54-8.575 19.115-19.115 19.115z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M50.703 61.37a1.61 1.61 0 0 0 1.143-.474L62.983 49.76a1.616 1.616 0 1 0-2.286-2.286l-9.994 9.994-3.944-3.944a1.616 1.616 0 0 0-2.286 2.286l5.087 5.087c.316.316.73.474 1.143.474z"
      />
    </svg>
  );
}

export default ProtectedSessionIcon;
