import React from 'react';

function FaqListIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="148"
      height="130"
      fill="none"
      viewBox="0 0 148 130"
    >
      <path
        fill="#E4EAF8"
        d="M130.001 36.4H2.6A2.6 2.6 0 0 1 0 33.8V2.6A2.6 2.6 0 0 1 2.6 0H130a2.6 2.6 0 0 1 2.6 2.6v31.2a2.6 2.6 0 0 1-2.6 2.6z"
      />
      <path
        fill="#FF5050"
        d="M31.2 36.4H2.6A2.6 2.6 0 0 1 0 33.8V2.6A2.6 2.6 0 0 1 2.6 0h28.6a2.6 2.6 0 0 1 2.6 2.6v31.2a2.6 2.6 0 0 1-2.6 2.6z"
      />
      <path
        fill="#707487"
        d="M88.4 15.601H44.2a2.598 2.598 0 0 1-2.6-2.6c0-1.436 1.163-2.6 2.6-2.6h44.2c1.438 0 2.6 1.164 2.6 2.6 0 1.438-1.162 2.6-2.6 2.6z"
      />
      <path
        fill="#7F8499"
        d="M119.601 26h-49.4a2.598 2.598 0 0 1-2.6-2.6c0-1.438 1.163-2.6 2.6-2.6h49.4c1.437 0 2.6 1.162 2.6 2.6 0 1.437-1.163 2.6-2.6 2.6zM59.8 26H44.2a2.598 2.598 0 0 1-2.6-2.6c0-1.438 1.163-2.6 2.6-2.6h15.6c1.438 0 2.6 1.162 2.6 2.6 0 1.437-1.162 2.6-2.6 2.6z"
      />
      <path
        fill="#fff"
        d="M16.9 23.399a2.598 2.598 0 0 1-2.6-2.6c0-1.437 1.163-2.6 2.6-2.6 2.15 0 3.9-1.75 3.9-3.9s-1.75-3.9-3.9-3.9-3.9 1.75-3.9 3.9c0 1.437-1.163 2.6-2.6 2.6a2.598 2.598 0 0 1-2.6-2.6c0-5.018 4.083-9.1 9.1-9.1 5.018 0 9.1 4.083 9.1 9.1 0 5.017-4.082 9.1-9.1 9.1zM16.925 31.198a2.61 2.61 0 0 1-2.612-2.6c0-1.437 1.15-2.6 2.587-2.6h.026c1.437 0 2.6 1.163 2.6 2.6 0 1.438-1.163 2.6-2.6 2.6z"
      />
      <path
        fill="#E4EAF8"
        d="M130.001 83.202H2.6a2.6 2.6 0 0 1-2.6-2.6v-31.2a2.6 2.6 0 0 1 2.6-2.6H130a2.6 2.6 0 0 1 2.6 2.6v31.2a2.6 2.6 0 0 1-2.6 2.6z"
      />
      <path
        fill="#2EDB57"
        fillRule="evenodd"
        d="M31.2 83.202H2.6a2.6 2.6 0 0 1-2.6-2.6v-31.2a2.6 2.6 0 0 1 2.6-2.6h28.6a2.6 2.6 0 0 1 2.6 2.6v31.2a2.6 2.6 0 0 1-2.6 2.6z"
        clipRule="evenodd"
      />
      <path
        fill="#707487"
        d="M88.4 62.4H44.2a2.598 2.598 0 0 1-2.6-2.6c0-1.437 1.163-2.6 2.6-2.6h44.2c1.438 0 2.6 1.163 2.6 2.6s-1.162 2.6-2.6 2.6z"
      />
      <path
        fill="#7F8499"
        d="M59.8 72.801H44.2a2.598 2.598 0 0 1-2.6-2.6c0-1.437 1.163-2.6 2.6-2.6h15.6c1.438 0 2.6 1.163 2.6 2.6s-1.162 2.6-2.6 2.6z"
      />
      <path
        fill="#fff"
        d="M16.9 70.2a2.598 2.598 0 0 1-2.6-2.6c0-1.436 1.163-2.6 2.6-2.6 2.15 0 3.9-1.749 3.9-3.9 0-2.15-1.75-3.899-3.9-3.899s-3.9 1.75-3.9 3.9c0 1.437-1.163 2.6-2.6 2.6a2.598 2.598 0 0 1-2.6-2.6c0-5.017 4.083-9.1 9.1-9.1 5.018 0 9.1 4.083 9.1 9.1 0 5.017-4.082 9.1-9.1 9.1zM16.925 78a2.61 2.61 0 0 1-2.612-2.6c0-1.437 1.15-2.6 2.587-2.6h.026c1.437 0 2.6 1.163 2.6 2.6 0 1.438-1.163 2.6-2.6 2.6z"
      />
      <path
        fill="#E4EAF8"
        d="M130.001 130H2.6a2.6 2.6 0 0 1-2.6-2.6V96.2a2.6 2.6 0 0 1 2.6-2.6H130a2.6 2.6 0 0 1 2.6 2.6v31.2a2.6 2.6 0 0 1-2.6 2.6z"
      />
      <path
        fill="#7DF5A5"
        d="M31.2 130H2.6a2.6 2.6 0 0 1-2.6-2.6V96.2a2.6 2.6 0 0 1 2.6-2.6h28.6a2.6 2.6 0 0 1 2.6 2.6v31.2a2.6 2.6 0 0 1-2.6 2.6z"
      />
      <path
        fill="#707487"
        d="M88.4 109.202H44.2a2.598 2.598 0 0 1-2.6-2.6c0-1.437 1.163-2.6 2.6-2.6h44.2c1.438 0 2.6 1.163 2.6 2.6s-1.162 2.6-2.6 2.6z"
      />
      <path
        fill="#7F8499"
        d="M59.8 119.599H44.2a2.598 2.598 0 0 1-2.6-2.6 2.598 2.598 0 0 1 2.6-2.599h15.6c1.438 0 2.6 1.162 2.6 2.599 0 1.438-1.162 2.6-2.6 2.6z"
      />
      <path
        fill="#fff"
        d="M16.9 117.003a2.598 2.598 0 0 1-2.6-2.6c0-1.437 1.163-2.6 2.6-2.6 2.15 0 3.9-1.75 3.9-3.9 0-2.151-1.75-3.9-3.9-3.9s-3.9 1.749-3.9 3.9c0 1.437-1.163 2.6-2.6 2.6a2.598 2.598 0 0 1-2.6-2.6c0-5.018 4.083-9.1 9.1-9.1 5.018 0 9.1 4.083 9.1 9.1 0 5.017-4.082 9.1-9.1 9.1zM16.925 124.802a2.61 2.61 0 0 1-2.612-2.6c0-1.437 1.15-2.6 2.587-2.6h.026c1.437 0 2.6 1.163 2.6 2.6s-1.163 2.6-2.6 2.6z"
      />
      <path
        fill="#D5DCED"
        d="M130.001 93.6h-13v15.424a6.51 6.51 0 0 0 6.503 6.502 6.475 6.475 0 0 0 3.559-1.065l1.043-.684 4.495 10.852v-28.43a2.6 2.6 0 0 0-2.6-2.599zM128.098 72.106a6.45 6.45 0 0 0-4.588-1.907c-3.589 0-6.509 2.917-6.509 6.503v6.497h13a2.6 2.6 0 0 0 2.6-2.6v-3.99l-4.503-4.503z"
      />
      <path
        fill="#4F4F4F"
        d="M122.201 76.705v32.321a1.3 1.3 0 0 0 2.013 1.087l6.294-4.125 8.061 19.463a2.6 2.6 0 0 0 3.397 1.407l3.942-1.632a2.601 2.601 0 0 0 1.407-3.397l-8.061-19.463 7.395-1.497a1.3 1.3 0 0 0 .662-2.194l-22.89-22.89c-.82-.819-2.22-.239-2.22.92z"
      />
      <path
        fill="#7F8499"
        d="M114.401 72.801h-44.2a2.6 2.6 0 0 1 0-5.2h44.2a2.6 2.6 0 0 1 0 5.2zM114.401 119.603h-44.2a2.6 2.6 0 1 1 0-5.199h44.2a2.6 2.6 0 1 1 0 5.199z"
      />
    </svg>
  );
}

export default FaqListIcon;
