import React from 'react';

export default () => (
  <svg
    width="101px"
    height="79px"
    viewBox="0 0 101 79"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Careers-homepage"
        transform="translate(-100.000000, -1198.000000)"
        stroke="#5C5C5C"
        strokeWidth="3"
      >
        <g id="Group-13" transform="translate(34.000000, 935.000000)">
          <g id="Group-11" transform="translate(17.000000, 260.000000)">
            <g id="Group-36" transform="translate(17.000000, 5.000000)">
              <g id="madrid" transform="translate(33.000000, 0.000000)">
                <g id="Group-2">
                  <path
                    d="M0.654545719,72.4942627 C13.2861562,24.1647542 20.0159573,0 20.843949,0 C27.1990674,0 33.5541858,0 39.9093041,0 C41.0138736,6.85272294e-16 41.9093041,0.8954305 41.9093041,2 C41.9093041,2.17085889 41.8874099,2.34101353 41.8441564,2.50630688 L23.2650694,73.5063069 C23.0348084,74.3862497 22.2397882,75 21.3302172,75 L2.58953536,75 C1.48497205,74.9999888 0.589546577,74.1045633 0.589546577,73 C0.589546577,72.8293381 0.611390441,72.6593781 0.654545719,72.4942627 Z"
                    id="Path-2"
                    fill="#2EDB57"
                  />
                  <path d="M20.859375,2.5 L20.8333333,72.5" id="Line" strokeLinecap="square" />
                  <path d="M11.3137837,37.5 L32.3046374,37.5" id="Line-2" strokeLinecap="square" />
                  <path
                    d="M16.905889,18.1818181 L35.8333333,18.3333333"
                    id="Line-2"
                    strokeLinecap="square"
                  />
                  <path
                    d="M6.84009949,55.6818181 L26.6666667,55.8333333"
                    id="Line-2-Copy"
                    strokeLinecap="square"
                  />
                </g>
                <g
                  id="Group-2-Copy"
                  transform="translate(77.250000, 37.500000) scale(-1, 1) translate(-77.250000, -37.500000) translate(55.750000, 0.000000)"
                >
                  <path
                    d="M0.654545719,72.4942627 C13.2861562,24.1647542 20.0159573,0 20.843949,0 C27.1990674,0 33.5541858,0 39.9093041,0 C41.0138736,1.1293615e-15 41.9093041,0.8954305 41.9093041,2 C41.9093041,2.17085889 41.8874099,2.34101353 41.8441564,2.50630688 L23.2650694,73.5063069 C23.0348084,74.3862497 22.2397882,75 21.3302172,75 L2.58953536,75 C1.48497205,74.9999888 0.589546577,74.1045633 0.589546577,73 C0.589546577,72.8293381 0.611390441,72.6593781 0.654545719,72.4942627 Z"
                    id="Path-2"
                    fill="#FFFFFF"
                  />
                  <path d="M21.25,2.5 L21.25,72.5" id="Line" strokeLinecap="square" />
                  <path d="M11.3137837,37.5 L32.3046374,37.5" id="Line-2" strokeLinecap="square" />
                  <path
                    d="M16.905889,18.1818181 L36.25,18.3333333"
                    id="Line-2"
                    strokeLinecap="square"
                  />
                  <path
                    d="M6.84009949,55.6818181 L26.25,55.8333333"
                    id="Line-2-Copy"
                    strokeLinecap="square"
                  />
                </g>
                <path
                  d="M47.2818848,75 L51.5948917,75 C52.1149206,75 52.5481578,74.6014264 52.5914245,74.0832005 L55.4166667,40.2439024 L55.4166667,40.2439024 L48.9434524,37.5 L42.9166667,40.2439024 C44.0400454,51.5289537 45.1634242,62.8140049 46.286803,74.0990562 C46.3377103,74.6104522 46.7679612,75 47.2818848,75 Z"
                  id="Path-3"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
