import { useStaticQuery, graphql } from 'gatsby';

export function useTrustStats() {
  const queryData = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Trust-Stats" } }) {
        nodes {
          data {
            euros_saved
            filed_tax_return
            failures
          }
        }
      }
    }
  `);

  const {
    allAirtable: { nodes },
  } = queryData;

  const stats = nodes.find(item => item.data);

  const data = {
    eurosSaved: stats.data.euros_saved,
    taxReturn: stats.data.filed_tax_return,
    failed: stats.data.failures,
  };

  return { data };
}

export default useTrustStats;
