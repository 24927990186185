import React from 'react';

function MenuIcon() {
  return (
    <svg width="28px" viewBox="0 0 28 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Menu Icon</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="home_mobile"
          transform="translate(-23.000000, -60.000000)"
          fill="#5C5C5C"
          fillRule="nonzero"
        >
          <g id="icons8-menu_filled" transform="translate(23.000000, 60.000000)">
            <path
              d="M0,0 L0,2.85714286 L28,2.85714286 L28,0 L0,0 Z M0,8.57142857 L0,11.4285714 L28,11.4285714 L28,8.57142857 L0,8.57142857 Z M0,17.1428571 L0,20 L28,20 L28,17.1428571 L0,17.1428571 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MenuIcon;
