import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'Hooks';
import './styles.scss';

const BannerSectionBlock = ({ title, description, ctaLink, ctaText }) => {
  const { t } = useTranslation();
  return (
    <div className="header_hiw">
      <div className="incenter">
        <h1 className="title">{t(title)}</h1>
        <div className="subtitle">
          <p>{t(description)}</p>
        </div>
      </div>
      {ctaLink && (
        <a href={ctaLink} className="solid_btn hiw_start_btn big">
          {t(ctaText)}
        </a>
      )}
    </div>
  );
};

BannerSectionBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
};

export default BannerSectionBlock;
