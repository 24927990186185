// GREENS
export const PRIMARY_GREEN = '#02cd55';
export const SHADOW_GREEN = '#3ae780';
export const LIGHT_GREEN = '#C0ECD4';
export const HIGH_LIGHT_GREEN = '#F4FFF9';

// GREYS
export const BASE_GREY = '#5c5c5c';
export const LIGHT_GREY = '#B4BCC8';
export const HIGH_LIGHT_GREY = '#E8EBF2';
export const BACKGROUND_GREY = '#F6F7F9';
export const MEDIUM_GREY = '#5c6b7c';
export const FOOTER_GREY = '#f7f7f7';
export const TEXT_GREY_LIGHT = '#898989';

// WHITE
export const WHITE = '#FFFFFF';

// BLACK
export const DARK_BLACK = '#242b36';
export const LIGHT_BLACK = '#2b3647';
export const BLACK_LIGHT = '#4f4f4f';

// MISCELANIUS
export const SUCCESS = '#39c878';
export const RED_ERROR = '#E13250';
export const DARK_WARNING = '#FFA800';
export const LIGHT_WARNING = '#FFE17F';
export const HIGH_LIGHT_WARNING = '#FFEECC';

export const LINK_HOVER = '#a7cdfa';

export const LINK_INFO_BLUE = '#0075db';
export const LIGHT_BLUE = '#ecf4fe';
export const MEDIUM_BLUE = '#3090e9';

// Grey
export const DARK_GREY = '#1f1f1f';
export const SHADOW_GREY = '#858585';
export const PLACEHOLDER_GREY = '#b8b8b8';
export const NAVBAR_GREY = '#383838';
export const HEADER_GREY = '#E4E4E4';
export const TEXT_GREY = '#5c6b7c';
export const SLOW_LIGHT_GREY = '#b4bcc8';
// Green
export const DARK_GREEN = '#02cd55';
export const BASE_GREEN = '#00dc5a';
export const PLACEHOLDER_GREEN = '#66f09e';
export const BACKGROUND_GREEN = '#E4FFEF';

// Blue
export const DARK_BLUE = '#4a90e2';
export const BASE_BLUE = '#a7cdfa';
export const LINK_BLUE = '#C0DDFF';
