export const trackData = {
  VIEW: {
    name: 'MarketingView',
    contentName: 'LlegadasWeb',
    currency: 'EUR',
    status: '',
    value: 0,
  },
  SIGN_UP_START: {
    name: 'MarketingRegisterStart',
    contentName: '1_Registro_Llegada',
    currency: 'EUR',
    status: 'Si',
    value: 0,
  },
  SIGN_UP_END: {
    name: 'MarketingRegisterEnd',
    contentName: '1_Registro_Completado',
    currency: 'EUR',
    status: 'Si',
    value: 1,
  },
};
