import styled from 'styled-components';

import { pxToRem } from 'Styles/utils';
import { TABLET_WIDTH, MOBILE_WIDTH, DESKTOP_WIDTH, LARGE_DEVICE_WIDTH } from 'Styles/sizes';

export const CarrouselContainer = styled.div`
  background: linear-gradient(180deg, #fafffd 0%, #e5f9ed 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 2rem;

  @media screen and (min-width: ${LARGE_DEVICE_WIDTH}) {
    padding: 0 4rem;
  }

  @media screen and (min-width: ${DESKTOP_WIDTH}) {
    padding: 0 3.125rem;
  }
  height: ${pxToRem(288)};
  overflow: hidden;

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    padding: 0 1rem;
    height: ${pxToRem(400)};
  }
`;

export const CarrouselBody = styled.div`
  flex-basis: 80%;
  height: 100%;

  padding: 0 1rem;
`;

export const IconButton = styled.button`
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 0;
  flex-basis: 10%;

  :hover,
  :focus {
    transform: translateY(-0.25rem);
    transition: transform 150ms linear;
  }
`;

export const SubTextContainer = styled.div`
  position: absolute;
  bottom: 0;

  @media screen and (max-width: ${TABLET_WIDTH}) {
    right: 0;
    left: 0;
    text-align: center;
    background: rgba(244, 255, 249, 0.8);
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.19);
  }
`;
