import React from 'react';

function CrossIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.41187 1.23706L15.9347 16.1814"
        stroke="#2F2E46"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.41195 15.8528L16.3323 1.23706"
        stroke="#2F2E46"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CrossIcon;
