/* eslint-disable no-undef */
export const config = {
  VERSION,
  LOCIZE_PROJECT_ID,
  SEGMENT_KEY,
  BRANCH_IO_KEY,
  STAGE,
  WEBAPP_ROUTE,
  AIRTABLE_FORM_ID,
  LOCIZE_VERSION,
};
