import React from 'react';
import PropTypes from 'prop-types';

function DiffFour({ title }) {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="diff_four_svg_title"
    >
      <title id="diff_four_svg_title">{title}</title>
      <circle
        cx="50.75"
        cy="50"
        r="46"
        stroke="#E0F7E9"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51 91C73.6437 91 92 72.6437 92 50C92 27.3563 73.6437 9 51 9C28.3563 9 10 27.3563 10 50C10 72.6437 28.3563 91 51 91ZM51 71C62.598 71 72 61.598 72 50C72 38.402 62.598 29 51 29C39.402 29 30 38.402 30 50C30 61.598 39.402 71 51 71Z"
        fill="white"
      />
      <path
        d="M90.5 50C90.5 71.8152 72.8152 89.5 51 89.5V92.5C74.4721 92.5 93.5 73.4721 93.5 50H90.5ZM51 10.5C72.8152 10.5 90.5 28.1848 90.5 50H93.5C93.5 26.5279 74.4721 7.5 51 7.5V10.5ZM11.5 50C11.5 28.1848 29.1848 10.5 51 10.5V7.5C27.5279 7.5 8.5 26.5279 8.5 50H11.5ZM51 89.5C29.1848 89.5 11.5 71.8152 11.5 50H8.5C8.5 73.4721 27.5279 92.5 51 92.5V89.5ZM70.5 50C70.5 60.7696 61.7696 69.5 51 69.5V72.5C63.4264 72.5 73.5 62.4264 73.5 50H70.5ZM51 30.5C61.7696 30.5 70.5 39.2304 70.5 50H73.5C73.5 37.5736 63.4264 27.5 51 27.5V30.5ZM31.5 50C31.5 39.2304 40.2304 30.5 51 30.5V27.5C38.5736 27.5 28.5 37.5736 28.5 50H31.5ZM51 69.5C40.2304 69.5 31.5 60.7696 31.5 50H28.5C28.5 62.4264 38.5736 72.5 51 72.5V69.5Z"
        fill="#3D3A62"
      />
      <path
        d="M24.7637 23.6604L33.249 32.1457"
        stroke="#C9EBD6"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M68.251 67.8542L76.7363 76.3395"
        stroke="#C9EBD6"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M77.0898 24.0139L68.6046 32.4992"
        stroke="#E0F7E9"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M32.8955 67.5007L24.4102 75.986"
        stroke="#E0F7E9"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}

DiffFour.propTypes = {
  title: PropTypes.string,
};

DiffFour.defaultProps = {
  title: 'Ayuda fiscal',
};

export default DiffFour;
