import Office from 'Assets/icons/perks/Office';
import Coffee from 'Assets/icons/perks/Coffee';
import Coworking from 'Assets/icons/perks/Coworking';
import Hours from 'Assets/icons/perks/Hours';
import Meetups from 'Assets/icons/perks/Meetups';
import Sports from 'Assets/icons/perks/Sports';
import Karaoke from 'Assets/icons/perks/Karaoke';
import Holidays from 'Assets/icons/perks/Holidays';

export const perkList = [
  {
    Icon: Office,
    description: 'Careers.SecondSection.PerkList.firstDescription',
  },
  {
    Icon: Coffee,
    description: 'Careers.SecondSection.PerkList.secondDescription',
  },
  {
    Icon: Coworking,
    description: 'Careers.SecondSection.PerkList.thirdDescription',
  },
  {
    Icon: Hours,
    description: 'Careers.SecondSection.PerkList.fourthDescription',
  },
  {
    Icon: Meetups,
    description: 'Careers.SecondSection.PerkList.fifthDescription',
  },
  {
    Icon: Sports,
    description: 'Careers.SecondSection.PerkList.sixthDescription',
  },
  {
    Icon: Karaoke,
    description: 'Careers.SecondSection.PerkList.seventhDescription',
  },
  {
    Icon: Holidays,
    description: 'Careers.SecondSection.PerkList.eighthDescription',
  },
];
