import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@taxdown/piccolo';
import { useTranslation } from 'Hooks';
import { MEDIA_ROUTE } from 'Constants/routes';
import ArrowIcon from 'Assets/icons/ArrowIcon';
import LogoFullColorIcon from 'Assets/icons/LogoFullColorIcon';
import {
  MediaSnippetWrapper,
  MediaSnippetImage,
  MediaSnippetText,
  MediaSnippetLink,
  CustomLink,
} from './styles';

function MediaSnippet({ Image, Snippet }) {
  const { t } = useTranslation();
  return (
    <MediaSnippetWrapper>
      <MediaSnippetImage>
        <img src={Image} alt="" />
      </MediaSnippetImage>
      <MediaSnippetText>
        <Text color="mediumGrey" size="xs" mt={20} mb={20}>
          {Snippet}
        </Text>
      </MediaSnippetText>
      <MediaSnippetLink>
        <LogoFullColorIcon />
        <Text color="primaryGreen" bold>
          <CustomLink to={MEDIA_ROUTE}>
            {t('Carrousel.linkText')}
            <ArrowIcon />
          </CustomLink>
        </Text>
      </MediaSnippetLink>
    </MediaSnippetWrapper>
  );
}

MediaSnippet.propTypes = {
  Image: PropTypes.string.isRequired,
  Snippet: PropTypes.string.isRequired,
};

export default MediaSnippet;
