import { useStaticQuery, graphql } from 'gatsby';

export function useUsersQuery() {
  const queryData = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Users" }, data: { Name: { ne: null } } }) {
        nodes {
          data {
            ID
            Name
            Job
            Starts
            Opinion
          }
        }
      }
    }
  `);

  const {
    allAirtable: { nodes },
  } = queryData;

  const data = nodes.map(item => item.data);
  return { data };
}

export default useUsersQuery;
