import React from 'react';

let counter = 0;

function StarIcon() {
  const prefix = () => {
    counter += 1;
    return `paint-${counter}_linear_292_12953`;
  };
  const id = prefix();
  const urlId = `url(#${id})`;
  return (
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7143 0L15.5688 8.78536H24.8063L17.333 14.215L20.1876 23.0004L12.7143 17.5707L5.24102 23.0004L8.09555 14.215L0.622281 8.78536H9.85975L12.7143 0Z"
        fill={urlId}
      />
      <defs>
        <linearGradient
          id={id}
          x1="12.7143"
          y1="0"
          x2="12.7143"
          y2="25.4286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF38A" />
          <stop offset="1" stopColor="#FFC700" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default StarIcon;
