import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { TweenLite } from 'gsap/gsap-core';

const setValue = (ref, value) => {
  const element = document.getElementById(ref);
  if (element) {
    element.innerHTML = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
};

export const loadAnimations = data => {
  const { eurosSaved, taxReturn, failed } = data;

  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.refresh();

  let stArray = [];

  function first_num_increase() {
    const Cont = { val: 0 };
    const NewVal = eurosSaved;
    TweenLite.to(Cont, 2, {
      val: NewVal,
      roundProps: 'val',
      onUpdate() {
        setValue('first_var_num', Cont.val);
      },
    });
  }

  function first_num_decrease() {
    setValue('first_var_num', 0);
  }

  stArray.push(
    ScrollTrigger.create({
      trigger: '#first_var_num',
      onEnter: first_num_increase,
      onLeave: first_num_decrease,
      onEnterBack: first_num_increase,
      onLeaveBack: first_num_decrease,
    })
  );

  // Second number

  function second_num_increase() {
    const Cont = { val: 0 };
    const NewVal = taxReturn;
    TweenLite.to(Cont, 2, {
      val: NewVal,
      roundProps: 'val',
      onUpdate() {
        setValue('second_var_num', Cont.val);
      },
    });
  }

  function second_num_decrease() {
    setValue('#second_var_num', 0);
  }

  stArray.push(
    ScrollTrigger.create({
      trigger: '#second_var_num',
      onEnter: second_num_increase,
      onLeave: second_num_decrease,
      onEnterBack: second_num_increase,
      onLeaveBack: second_num_decrease,
    })
  );

  // Third number

  function third_num_increase() {
    const Cont = { val: 1000 };
    const NewVal = failed;
    TweenLite.to(Cont, 2, {
      val: NewVal,
      roundProps: 'val',
      onUpdate() {
        setValue('third_var_num', Cont.val);
      },
    });
  }

  function third_num_decrease() {
    setValue('#third_var_num', 0);
  }

  stArray.push(
    ScrollTrigger.create({
      trigger: '#third_var_num',
      onEnter: third_num_increase,
      onLeave: third_num_decrease,
      onEnterBack: third_num_increase,
      onLeaveBack: third_num_decrease,
    })
  );

  // First line
  function first_line_increase() {
    TweenLite.fromTo(
      '#first_var_line',
      2,
      { borderLeftWidth: '10px' },
      { borderLeftWidth: '250px' }
    );
  }

  function first_line_decrease() {
    TweenLite.fromTo(
      '#first_var_line',
      2,
      { borderLeftWidth: '250px' },
      { borderLeftWidth: '10px' }
    );
  }

  stArray.push(
    ScrollTrigger.create({
      trigger: '#first_var_line',
      onEnter: first_line_increase,
      onLeave: first_line_decrease,
      onEnterBack: first_line_increase,
      onLeaveBack: first_line_decrease,
    })
  );

  // Second line
  function second_line_increase() {
    TweenLite.fromTo(
      '#second_var_line',
      2,
      { borderLeftWidth: '10px' },
      { borderLeftWidth: '260px' }
    );
  }

  function second_line_decrease() {
    TweenLite.fromTo(
      '#second_var_line',
      2,
      { borderLeftWidth: '260px' },
      { borderLeftWidth: '10px' }
    );
  }

  stArray.push(
    ScrollTrigger.create({
      trigger: '#second_var_line',
      onEnter: second_line_increase,
      onLeave: second_line_decrease,
      onEnterBack: second_line_increase,
      onLeaveBack: second_line_decrease,
    })
  );

  // Third line
  function third_line_increase() {
    TweenLite.fromTo(
      '#third_var_line',
      2,
      { borderLeftWidth: '10px' },
      { borderLeftWidth: '270px' }
    );
  }

  function third_line_decrease() {
    TweenLite.fromTo(
      '#third_var_line',
      2,
      { borderLeftWidth: '270px' },
      { borderLeftWidth: '10px' }
    );
  }

  stArray.push(
    ScrollTrigger.create({
      trigger: '#third_var_line',
      onEnter: third_line_decrease,
      onLeave: third_line_increase,
      onEnterBack: third_line_decrease,
      onLeaveBack: third_line_increase,
    })
  );

  stArray = stArray.concat(
    ScrollTrigger.batch('.mod_numbers_data .col', {
      onEnter: elements => {
        gsap.to(elements, {
          opacity: 1,
          stagger: 0.2,
          ease: 'power4.out',
          duration: 1.5,
          delay: 0.25,
        });
      },
      onLeave: elements => {
        gsap.to(elements, { opacity: 0, duration: 1, ease: 'power4.out' });
      },
      onEnterBack: elements => {
        gsap.to(elements, {
          opacity: 1,
          stagger: 0.2,
          ease: 'power4.out',
          duration: 1.5,
          delay: 0.25,
        });
      },
      onLeaveBack: elements => {
        gsap.to(elements, { opacity: 0, duration: 1, ease: 'power4.out' });
      },
    })
  );

  return stArray;
};
