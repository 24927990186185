import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH } from 'Styles/sizes';
import Link from 'Components/Link';

export const MediaSnippetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

export const MediaSnippetImage = styled.div`
  width: 100%;
  max-width: ${pxToRem(170)};
  margin-bottom: ${pxToRem(10)};
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    max-width: ${pxToRem(147)};
  }
`;

export const MediaSnippetText = styled.div`
  text-align: center;
`;

export const CustomLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.primaryGreen};
  display: flex;
  align-items: center;
`;

export const MediaSnippetLink = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: ${pxToRem(18)};
    margin-left: ${pxToRem(10)};
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
    a {
      white-space: nowrap;
      font-size: ${pxToRem(14)};
    }
    svg {
      margin-left: 0;
    }
    svg:last-child {
      margin-left: ${pxToRem(5)};
    }
  }
`;
