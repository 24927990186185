import React from 'react';

function SecureDataIcon() {
  return (
    <svg
      width="77px"
      height="77px"
      viewBox="0 0 77 77"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Secure Data</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="MAIN_man" transform="translate(-682.000000, -1760.000000)" fillRule="nonzero">
          <g id="acceso_icon" transform="translate(682.000000, 1760.000000)">
            <path
              d="M47.8347741,1.04568947 C44.8962132,0.351770179 41.8609233,0 38.8128979,0 C28.445609,0 18.6988716,4.00311457 11.3679339,11.271829 C4.03729944,18.5405433 0,28.2047525 0,38.4842588 C0,46.6095487 2.50358353,54.3265445 7.24936997,60.8814962 C5.41667345,63.371097 5.63257019,66.8912041 7.89781827,69.1375723 C9.15014068,70.3791407 10.7949888,71 12.4399886,71 C14.0849884,71 15.7298365,70.3791407 16.982159,69.1375723 C19.4866522,66.654135 19.4866522,62.6135885 16.982159,60.1301512 C15.7689527,58.9270671 14.1557916,58.2645666 12.4401402,58.2645666 C11.4275178,58.2645666 10.4515857,58.4969754 9.57162449,58.9333809 C5.28931863,52.9343464 3.03225765,45.893531 3.03225765,38.4842588 C3.03225765,18.921778 19.0833619,3.00658272 38.8128979,3.00658272 C41.6245588,3.00658272 44.4236358,3.33084267 47.1323516,3.97049314 C47.9459063,4.16276411 48.7644643,3.66412236 48.9583771,2.85625359 C49.1524416,2.04853514 48.6493901,1.23796044 47.8347741,1.04568947 Z M9.02403229,62.8789371 C9.68543051,62.3121622 10.5647379,62 11.4999315,62 C12.4351251,62 13.3145891,62.3121622 13.9758307,62.8789371 C15.3413637,64.0489752 15.3413637,65.9526681 13.9759873,67.1225721 C12.6104543,68.292476 10.3892521,68.292476 9.02403229,67.1225721 C7.6586559,65.952534 7.6586559,64.048841 9.02403229,62.8789371 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M69.7550053,16.1182354 C71.5864825,13.6286359 71.3707116,10.108681 69.1067833,7.86246426 C66.6035978,5.37917858 62.5309211,5.37917858 60.0277356,7.86246426 C57.5247016,10.3459003 57.5247016,14.3864447 60.0277356,16.8698807 C61.2403865,18.0728138 62.8526077,18.7353139 64.5672595,18.7353139 C65.5789889,18.7353139 66.5540493,18.5030555 67.4333463,18.0668006 C71.712551,24.0668843 73.9695091,31.1110032 73.9695091,38.5157615 C73.9695091,58.078232 57.9277569,73.9934189 38.2097162,73.9934189 C35.3901474,73.9934189 32.5834582,73.6670545 29.8678353,73.0231951 C29.0547546,72.8306236 28.2365221,73.3283631 28.041813,74.1359308 C27.847407,74.9434985 28.3494079,75.7545238 29.1633977,75.9473959 C32.1094895,76.6458247 35.153163,77 38.2097162,77 C48.5709646,77 58.3120231,72.9968876 65.6386895,65.728177 C72.9650529,58.4594665 77,48.7952624 77,38.5157615 C77,30.3954369 74.4974206,22.6742359 69.7550053,16.1182354 Z M66.1222409,14.1210678 C65.555317,14.6878396 64.8016102,15 64,15 C63.1983898,15 62.4445488,14.6877054 61.8777591,14.1209337 C60.7074136,12.9510363 60.7074136,11.0473539 61.8777591,9.87745657 C62.4629319,9.29244081 63.2313989,9 64,9 C64.7686011,9 65.5372023,9.29257495 66.1222409,9.87745657 C67.2925864,11.0474881 67.2925864,12.9511704 66.1222409,14.1210678 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M25.0815886,72.0800457 C25.0750251,72.0782488 25.0577215,72.073557 25.0511581,72.0717601 C24.2865168,71.8672166 23.4135788,72.1146853 23.1077819,72.6266931 C22.8016868,73.1385012 23.1693888,73.7182909 23.9343284,73.9230341 C24.121834,73.975343 24.3162015,74 24.5074364,74 C25.0915829,74 25.6463431,73.7689027 25.8848646,73.3854709 C26.2017,72.8765577 25.8422022,72.2920762 25.0815886,72.0800457 Z"
              id="Path"
              fill="#5C5C5C"
            />
            <path
              d="M53.0752445,3.08097666 L53.0423724,3.07210798 C52.2800677,2.86693252 51.4139408,3.11455807 51.1075315,3.62525465 C50.8014197,4.13595122 51.1711933,4.71620218 51.9333492,4.92137764 L51.9489672,4.92556286 C52.133259,4.97608445 52.3239467,5 52.5115109,5 C53.0975559,5 53.6532574,4.76642483 53.8888653,4.38058734 C54.1998857,3.87128584 53.8357643,3.28934086 53.0752445,3.08097666 Z"
              id="Path"
              fill="#5C5C5C"
            />
            <path
              d="M58.2987277,32.0617792 C57.6358812,32.0617792 56.9804332,32.1208645 56.3361584,32.2384443 C55.4244802,22.5847855 47.0991881,15 36.9974975,15 C31.897052,15 27.0786559,16.9195351 23.4299802,20.4051272 C20.1320545,23.5555577 18.1176955,27.6690793 17.6709158,32.109786 C12.2517302,32.6188062 8,37.0982138 8,42.5308157 C8,48.3036015 12.8005792,53 18.7012723,53 L20.5884975,53 C21.4222649,53 22.0983985,52.3386873 22.0983985,51.5228664 C22.0983985,50.7070455 21.4224158,50.0457328 20.5884975,50.0457328 L18.7012723,50.0457328 C14.465698,50.0457328 11.019802,46.6746186 11.019802,42.5308157 C11.019802,38.3870129 14.465698,35.0160463 18.7012723,35.0160463 C18.8110421,35.0160463 18.919755,35.0198869 19.0283168,35.024466 C19.0776906,35.026534 19.1266114,35.0260908 19.1753812,35.023432 C19.2149406,35.0294883 19.2545,35.0353968 19.2951163,35.0384988 C21.1190767,35.1758722 22.8371931,35.9495948 24.1328391,37.2172708 C24.4277228,37.505755 24.8141064,37.6499232 25.2004901,37.6499232 C25.5868738,37.6499232 25.9734084,37.5056073 26.2681411,37.2172708 C26.8577574,36.6404501 26.8577574,35.7051292 26.2681411,35.1283085 C24.7419332,33.6353696 22.7938589,32.6365318 20.6920767,32.2470117 C21.5758218,24.1316398 28.4919233,17.9545626 36.9973465,17.9545626 C46.0412005,17.9545626 53.398948,25.1526345 53.398948,34.0002216 L53.3968342,34.1594566 C53.3918515,34.6516375 53.6376634,35.1138326 54.0525842,35.3922723 C54.467354,35.6704165 54.9965743,35.7283201 55.4643416,35.5467804 C56.3696782,35.1947795 57.3233317,35.016194 58.2985767,35.016194 C62.534151,35.016194 65.980047,38.3873083 65.980047,42.5309634 C65.980047,46.6746186 62.534302,50.0457328 58.2987277,50.0457328 L56.4095396,50.0457328 C55.5757723,50.0457328 54.8996386,50.7070455 54.8996386,51.5228664 C54.8996386,52.3386873 55.5757723,53 56.4095396,53 L58.2987277,53 C64.1994208,53 69,48.3036015 69,42.5308157 C69,36.7581777 64.1994208,32.0617792 58.2987277,32.0617792 Z"
              id="Path"
              fill="#00DC5A"
            />
            <path
              d="M46.9331845,38.5983558 L46.8324405,38.5983558 L46.8324405,36.4157402 C46.8324405,31.7753148 43.094494,28 38.5,28 C33.905506,28 30.1675595,31.7753148 30.1675595,36.4157402 L30.1675595,38.5983558 L30.0668155,38.5983558 C27.8244048,38.5983558 26,40.4409992 26,42.7058274 L26,56.8925284 C26,59.1573566 27.8244048,61 30.0668155,61 L46.9331845,61 C49.1755952,61 51,59.1573566 51,56.8925284 L51,42.7058274 C51,40.4409992 49.1755952,38.5983558 46.9331845,38.5983558 Z M34,35.9876871 C34,33.2374891 36.2428738,31 39,31 C41.7571262,31 44,33.2374891 44,35.9876871 L44,38 L34,38 L34,35.9876871 L34,35.9876871 Z M48,56.8574586 C48,57.487579 47.5119375,58 46.9121016,58 L30.0878984,58 C29.4880625,58 29,57.4874231 29,56.8574586 L29,42.1425414 C29,41.512421 29.4880625,41 30.0878984,41 L46.9121016,41 C47.5119375,41 48,41.5125769 48,42.1425414 L48,56.8574586 Z"
              id="Shape"
              fill="#5C5C5C"
            />
            <path
              d="M41.931909,51.103688 C42.6130967,50.3319915 43,49.3448681 43,48.3148394 C43,45.935692 40.9813128,44 38.5,44 C36.0186872,44 34,45.935692 34,48.3148394 C34,49.3631704 34.3976984,50.3624453 35.0978166,51.1380423 L34.3421583,54.1486186 C34.229983,54.5955244 34.3377777,55.0671834 34.6342523,55.4281285 C34.9308834,55.7892237 35.3838091,56 35.8631749,56 L41.1754685,56 C41.6548343,56 42.10776,55.7892237 42.4043911,55.4281285 C42.7010221,55.0670334 42.8086604,54.5955244 42.6964851,54.1486186 L41.931909,51.103688 Z M39.5777702,49.3869467 C39.1979916,49.7420855 39.0205409,50.3651913 39.1295219,50.9608402 L39.50291,53 L38.5250485,53 L38.8943284,50.9831958 C39.0046788,50.380195 38.8215223,49.7500375 38.4334132,49.3975994 C38.162045,49.1510878 38,48.7464366 38,48.3147787 C38,47.5896474 38.4485907,47 39,47 C39.5514093,47 40,47.5897974 40,48.3146287 C40,48.7452363 39.8460573,49.136084 39.5777702,49.3869467 Z"
              id="Shape"
              fill="#5C5C5C"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SecureDataIcon;
