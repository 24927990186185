import React, { useEffect } from 'react';
import Link from 'Components/Link';
import { useTranslation } from 'Hooks';
import { Lottie } from 'Components';
import Feature1 from 'Assets/lotties/how-it-works/feature_1.json';
import Feature2 from 'Assets/lotties/how-it-works/feature_2.json';
import Feature3 from 'Assets/lotties/how-it-works/feature_3.json';
import Feature4 from 'Assets/lotties/how-it-works/feature_4.json';
import { SECURITY_ROUTE } from 'Constants/routes';
import { loadAnimations } from './animations';
import './styles.scss';

const Features = () => {
  const { t } = useTranslation('how-it-works');

  useEffect(() => {
    const stArray = loadAnimations();
    return () => {
      stArray.forEach(st => st.kill());
    };
  });

  return (
    <section className="hiw_content">
      <h2 className="section_title big">{t('HowItWorks.Features.title')}</h2>
      <div className="incenter">
        <div className="hiw_block">
          <div className="image_wr left">
            <Lottie className="lottie" options={{ animationData: Feature1 }} />
          </div>
          <div className="info">
            <h2 className="title">{t('HowItWorks.Features.feature.1.title')}</h2>
            <p className="subtitle">{t('HowItWorks.Features.feature.1.description')}</p>
            <Link to={SECURITY_ROUTE} className="solid_btn green">
              {t('HowItWorks.Features.feature.1.cta')}
            </Link>
          </div>
        </div>
        <div className="hiw_block reverse">
          <div className="image_wr right">
            <Lottie className="lottie" options={{ animationData: Feature2 }} />
          </div>
          <div className="info">
            <h2 className="title">{t('HowItWorks.Features.feature.2.title')}</h2>
            <p className="subtitle">{t('HowItWorks.Features.feature.2.description')}</p>
          </div>
        </div>
        <div className="hiw_block">
          <div className="image_wr left">
            <Lottie className="lottie" options={{ animationData: Feature3 }} />
          </div>
          <div className="info">
            <h2 className="title">{t('HowItWorks.Features.feature.3.title')}</h2>
            <p className="subtitle">{t('HowItWorks.Features.feature.3.description')}</p>
          </div>
        </div>
        <div className="hiw_block reverse">
          <div className="image_wr right">
            <Lottie className="lottie" options={{ animationData: Feature4 }} />
          </div>
          <div className="info">
            <h2 className="title">{t('HowItWorks.Features.feature.4.title')}</h2>
            <p className="subtitle">{t('HowItWorks.Features.feature.4.description')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
