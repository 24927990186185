import BasicIcon from 'Assets/icons/plans/BasicIcon';
import ProIcon from 'Assets/icons/plans/ProIcon';
import LiveIcon from 'Assets/icons/plans/LiveIcon';
import FullIcon from 'Assets/icons/plans/FullIcon';

export const PLANS = [
  {
    name: 'ComparePlan.Basic.title',
    button: 'line_btn green',
    Icon: BasicIcon,
    price: 'ComparePlan.Basic.price',
    isYearly: false,
    isPopular: false,
    features: [
      [true, true, false, false, false],
      [true, false, false, false, false],
      [false, false, false, false, false],
    ],
  },
  {
    name: 'ComparePlan.Pro.title',
    button: 'solid_btn pro',
    Icon: ProIcon,
    price: 'ComparePlan.Pro.price',
    isYearly: true,
    isPopular: true,
    features: [
      [true, true, true, true, true],
      [true, true, false, false, false],
      [true, true, true, false, false],
    ],
  },
  {
    name: 'ComparePlan.Live.title',
    button: 'solid_btn live',
    Icon: LiveIcon,
    price: 'ComparePlan.Live.price',
    isYearly: true,
    isPopular: false,
    features: [
      [true, true, true, true, true],
      [true, true, true, true, false],
      [true, true, true, false, false],
    ],
  },
  {
    name: 'ComparePlan.Full.title',
    button: 'solid_btn full',
    Icon: FullIcon,
    price: 'ComparePlan.Full.price',
    isYearly: true,
    isPopular: false,
    features: [
      [true, true, true, true, true],
      [true, true, true, true, true],
      [true, true, true, true, true],
    ],
  },
];

export const PLANS_FEATURES = [
  {
    title: 'ComparePlan.Features.firstSection',
    features: [
      'ComparePlan.Features.one',
      'ComparePlan.Features.two',
      'ComparePlan.Features.three',
      'ComparePlan.Features.four',
      'ComparePlan.Features.five',
    ],
  },
  {
    title: 'ComparePlan.Features.secondSection',
    features: [
      'ComparePlan.Features.six',
      'ComparePlan.Features.seven',
      'ComparePlan.Features.eight',
      'ComparePlan.Features.nine',
      'ComparePlan.Features.ten',
    ],
  },
  {
    title: 'ComparePlan.Features.thirdSection',
    features: [
      'ComparePlan.Features.eleven',
      'ComparePlan.Features.twelve',
      'ComparePlan.Features.thirteen',
      'ComparePlan.Features.fourteen',
      'ComparePlan.Features.fifteen',
    ],
  },
];
