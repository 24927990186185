import React from 'react';

function OnlyNecessaryIcon() {
  return (
    <svg
      width="219px"
      height="180px"
      viewBox="0 0 219 110"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Pregunta solo lo necesario</title>
      <defs>
        <polygon id="path-1" points="0 0.396200814 219 0.396200814 219 15.6499322 0 15.6499322" />
        <polygon
          id="path-3"
          points="0.0725385541 0.0648962259 165.38549 0.0648962259 165.38549 116.770667 0.0725385541 116.770667"
        />
      </defs>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="MAIN_man" transform="translate(-606.000000, -935.000000)">
          <g id="preguntamos_ilus" transform="translate(606.000000, 935.000000)">
            <g id="Group-40">
              <g id="Group-3" transform="translate(0.000000, 129.260516)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M4.58227913,0.396200814 L214.444482,0.396200814 C216.955608,0.396200814 219,1.28325907 219,2.37307349 L219,13.6728847 C219,14.7621747 216.955608,15.6499322 214.444482,15.6499322 L4.58227913,15.6499322 C2.07115321,15.6499322 0,14.7621747 0,13.6728847 L0,2.37307349 C0,1.28325907 2.07115321,0.396200814 4.58227913,0.396200814"
                  id="Fill-1"
                  fill="#CA6637"
                  mask="url(#mask-2)"
                />
              </g>
              <g id="Group-15" transform="translate(24.333333, 0.000000)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3" />
                </mask>
                <g id="Clip-14" />
                <path
                  d="M8.27663631,0.0648637778 L157.201083,0.0648637778 C161.71124,0.0648637778 165.38549,3.8389038 165.38549,8.44686032 L165.38549,108.388346 C165.38549,112.996627 161.71124,116.770667 157.201083,116.770667 L8.27663631,116.770667 C3.76647867,116.770667 0.0725385541,112.996627 0.0725385541,108.388346 L0.0725385541,8.44686032 C0.0725385541,3.8389038 3.76647867,0.0648637778 8.27663631,0.0648637778"
                  id="Fill-13"
                  fill="#333333"
                  mask="url(#mask-4)"
                />
              </g>
              <path
                d="M86.0604094,124.473089 L125.173627,124.473089 C126.463248,124.473089 127.506667,125.752469 127.506667,127.357843 C127.506667,128.939011 126.463248,130.218001 125.173627,130.218001 L86.0604094,130.218001 C84.7504035,130.218001 83.7066667,128.939011 83.7066667,127.357843 C83.7066667,125.752469 84.7504035,124.473089 86.0604094,124.473089"
                id="Fill-16"
                fill="#333333"
              />
              <path
                d="M106.092832,116.813207 L106.092832,116.813207 C107.703452,116.813207 109.013333,118.106562 109.013333,119.671314 L109.013333,123.552028 C109.013333,125.116455 107.703452,126.38806 106.092832,126.38806 C104.482547,126.38806 103.173333,125.116455 103.173333,123.552028 L103.173333,119.671314 C103.173333,118.106562 104.482547,116.813207 106.092832,116.813207"
                id="Fill-18"
                fill="#333333"
              />
              <path
                d="M178.174656,4.52329263 C180.825466,4.52329263 182.986667,6.77666559 182.986667,9.51480053 L182.986667,105.832974 C182.986667,108.571437 180.825466,110.804161 178.174656,110.804161 L34.0123283,110.804161 C31.3615179,110.804161 29.2,108.571437 29.2,105.832974 L29.2,9.51480053 C29.2,6.77666559 31.3615179,4.52329263 34.0123283,4.52329263 L178.174656,4.52329263 Z"
                id="Fill-20"
                fill="#E8E8E8"
              />
              <path
                d="M110.182514,4.52329263 L179.086786,4.52329263 C181.771135,4.52329263 183.96,6.77666559 183.96,9.51480053 L183.96,105.832974 C183.96,108.571437 181.771135,110.804161 179.086786,110.804161 L110.182514,110.804161 C107.931954,110.804161 106.093333,108.929355 106.093333,106.633373 L106.093333,8.71440246 C106.093333,6.41874802 107.931954,4.52329263 110.182514,4.52329263"
                id="Fill-22"
                fill="#E8E8E8"
              />
              <path
                d="M79.7366234,107.040757 C81.6402969,107.040757 83.1712186,108.631455 83.1712186,110.609541 C83.1712186,112.586957 81.6402969,114.1994 79.7366234,114.1994 C77.8342374,114.1994 76.2830365,112.586957 76.2830365,110.609541 C76.2830365,108.631455 77.8342374,107.040757 79.7366234,107.040757 L79.7366234,107.040757 Z M74.9999721,103.408412 L74.9999721,104.117954 C74.9999721,105.02052 74.9587698,105.901677 74.8554423,106.783168 C75.951487,105.32093 77.6687846,104.374874 79.5920935,104.374874 C82.9429967,104.374874 85.6533333,107.190962 85.6533333,110.652695 C85.6533333,114.135169 82.9429967,116.929178 79.5920935,116.929178 C76.6548225,116.929178 74.2139101,114.758402 73.6760274,111.856005 C70.6354288,120.348091 62.7748087,126.38806 53.5702866,126.38806 C41.7175531,126.38806 32.12,116.413999 32.12,104.117954 C32.12,103.881106 32.12,103.644592 32.1406011,103.408412 L74.9999721,103.408412 Z"
                id="Fill-24"
                fill="#FFFFFF"
              />
              <path
                d="M78.7564752,107.040757 C80.6623413,107.040757 82.1950264,108.631455 82.1950264,110.609541 C82.1950264,112.586957 80.6623413,114.1994 78.7564752,114.1994 C76.8518981,114.1994 75.2989104,112.586957 75.2989104,110.609541 C75.2989104,108.631455 76.8518981,107.040757 78.7564752,107.040757 L78.7564752,107.040757 Z M74.0143682,103.408412 L74.0143682,104.117954 C74.0143682,105.02052 73.9731185,105.901677 73.8696719,106.783168 C74.966979,105.32093 76.6862546,104.374874 78.6117789,104.374874 C81.9665416,104.374874 84.68,107.190962 84.68,110.652695 C84.68,114.135169 81.9665416,116.929178 78.6117789,116.929178 C75.6711247,116.929178 73.2274008,114.758402 72.6888986,111.856005 C69.6447978,120.348091 61.7751239,126.38806 52.56,126.38806 L52.56,103.408412 L74.0143682,103.408412 Z"
                id="Fill-26"
                fill="#F5F5F5"
              />
              <path
                d="M34.9988304,129.260516 L73.0202113,129.260516 C73.5383666,129.260516 73.9733333,128.597037 73.9733333,127.839757 C73.9733333,127.05203 73.5383666,126.38806 73.0202113,126.38806 L34.9988304,126.38806 C34.501311,126.38806 34.0666667,127.05203 34.0666667,127.839757 C34.0666667,128.597037 34.501311,129.260516 34.9988304,129.260516"
                id="Fill-28"
                fill="#FFFFFF"
              />
              <path
                d="M33.0933333,104.365898 L71.0533333,104.365898 L71.0533333,107.227669 C71.0533333,108.806896 69.9069555,110.11081 68.5199759,110.11081 L35.6063538,110.11081 C34.22,110.11081 33.0933333,108.806896 33.0933333,107.227669 L33.0933333,104.365898 Z"
                id="Fill-30"
                fill="#E6E6E6"
              />
              <path
                d="M53.5333333,104.365898 L73,104.365898 L73,107.227669 C73,108.806896 71.829188,110.11081 70.4123265,110.11081 L53.5333333,110.11081 L53.5333333,104.365898 Z"
                id="Fill-32"
                fill="#D9D9D9"
              />
              <path
                d="M53.0365155,98.620986 L53.0365155,98.620986 C53.8437774,98.620986 54.5066667,97.9707708 54.5066667,97.1524527 L54.5066667,67.535019 C54.5066667,66.7382442 53.8437774,66.0664858 53.0365155,66.0664858 C52.2286091,66.0664858 51.5866667,66.7382442 51.5866667,67.535019 L51.5866667,97.1524527 C51.5866667,97.9707708 52.2286091,98.620986 53.0365155,98.620986"
                id="Fill-34"
                fill="#FFFFFF"
              />
              <path
                d="M46.2228599,92.8760742 L46.2228599,92.8760742 C47.0307663,92.8760742 47.6933333,92.2104955 47.6933333,91.3999584 L47.6933333,75.2021603 C47.6933333,74.3912997 47.0307663,73.7263682 46.2228599,73.7263682 C45.4152757,73.7263682 44.7733333,74.3912997 44.7733333,75.2021603 L44.7733333,91.3999584 C44.7733333,92.2104955 45.4152757,92.8760742 46.2228599,92.8760742"
                id="Fill-36"
                fill="#FFFFFF"
              />
              <path
                d="M60.8436445,90.0036183 L60.8436445,90.0036183 C61.6511396,90.0036183 62.2933333,89.3699158 62.2933333,88.6176059 L62.2933333,80.8576004 C62.2933333,80.0852659 61.6511396,79.47128 60.8436445,79.47128 C60.0358273,79.47128 59.3733333,80.0852659 59.3733333,80.8576004 L59.3733333,88.6176059 C59.3733333,89.3699158 60.0358273,90.0036183 60.8436445,90.0036183"
                id="Fill-38"
                fill="#FFFFFF"
              />
              <g id="icons8-form" transform="translate(71.053333, 26.809588)" fillRule="nonzero">
                <path
                  d="M81.76,60.4060579 C81.76,61.418317 80.7982442,62.2365445 79.6084211,62.2365445 L2.15157895,62.2365445 C0.961755789,62.2365445 0,61.418317 0,60.4060579 L0,1.8304866 C0,0.818227512 0.961755789,0 2.15157895,0 L79.6084211,0 C80.7982442,0 81.76,0.818227512 81.76,1.8304866 L81.76,60.4060579 Z"
                  id="Path"
                  fill="#E8E8E8"
                />
                <polygon
                  id="Path"
                  fill="#5C5C5C"
                  points="23.8466667 40.6266332 8.51666667 40.6266332 8.51666667 53.59258 23.8466667 53.59258"
                />
                <g id="Group" transform="translate(8.516667, 14.694740)" fill="#00DC5A">
                  <rect id="Rectangle" x="0" y="0" width="17.2604444" height="3.64967391" />
                  <rect
                    id="Rectangle"
                    x="0"
                    y="12.7738587"
                    width="17.2604444"
                    height="3.64967391"
                  />
                  <rect
                    id="Rectangle"
                    x="21.5755556"
                    y="29.1973913"
                    width="43.1511111"
                    height="3.64967391"
                  />
                </g>
                <g id="Group" transform="translate(13.626667, 12.965947)">
                  <path
                    d="M55.3583333,5.18637871 C55.3583333,6.1406724 54.470045,6.91517162 53.3711111,6.91517162 L21.5755556,6.91517162 C20.4786089,6.91517162 19.5883333,6.1406724 19.5883333,5.18637871 L19.5883333,1.7287929 C19.5883333,0.774499221 20.4786089,0 21.5755556,0 L53.3711111,0 C54.470045,0 55.3583333,0.774499221 55.3583333,1.7287929 L55.3583333,5.18637871 Z"
                    id="Path"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M55.3583333,18.1523255 C55.3583333,19.1066192 54.470045,19.8811184 53.3711111,19.8811184 L21.5755556,19.8811184 C20.4786089,19.8811184 19.5883333,19.1066192 19.5883333,18.1523255 L19.5883333,14.6947397 C19.5883333,13.740446 20.4786089,12.9659468 21.5755556,12.9659468 L53.3711111,12.9659468 C54.470045,12.9659468 55.3583333,13.740446 55.3583333,14.6947397 L55.3583333,18.1523255 Z"
                    id="Path"
                    fill="#FFFFFF"
                  />
                  <rect
                    id="Rectangle"
                    fill="#FAFAFA"
                    x="0"
                    y="31.1182723"
                    width="5.96166667"
                    height="5.18637871"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OnlyNecessaryIcon;
