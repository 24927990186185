import React, { useEffect } from 'react';
import withView from 'Hocs/withView';
import {
  HomeFeaturesBlock,
  MediaGridBlock,
  NumberStatsBlock,
  DifferencesBlock,
  HomeUspBlock,
  HomePlansBlock,
  UserRatingsBlock,
  DeclarationBlock,
  ExpertsTeamBlock,
} from 'Blocks';
import { PRICE_ROUTE } from 'Constants/routes';
import { MainSection } from './components';
import { loadAnimations } from './animations';

const Home = () => {
  useEffect(() => {
    loadAnimations();
  });

  return (
    <>
      <MainSection />
      <HomeFeaturesBlock />
      <HomeUspBlock />
      <DeclarationBlock link={PRICE_ROUTE} />
      <HomePlansBlock />
      <UserRatingsBlock showLink />
      <DifferencesBlock />
      <NumberStatsBlock />
      <MediaGridBlock />
      <ExpertsTeamBlock />
    </>
  );
};

export default withView(Home);
