import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Services';

function LanguageButton({ language, text, className }) {
  return (
    <button type="button" className={className} onClick={() => i18n.changeLanguage(language)}>
      {text}
    </button>
  );
}

LanguageButton.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

LanguageButton.defaultProps = {
  className: null,
};

export default LanguageButton;
