import React from 'react';

function FridayLunchIcon() {
  return (
    <svg width="47px" viewBox="0 0 47 78" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Viernes</title>
      <g id="trabaja-con-nosotros" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-843.000000, -865.000000)" fillRule="nonzero" id="friday_icon">
          <g transform="translate(843.000000, 865.000000)">
            <g id="Group" transform="translate(0.000000, 47.000000)" fill="#E65100">
              <rect id="Rectangle" x="0" y="0.295238095" width="47" height="30.7047619" />
            </g>
            <polygon id="Path" fill="#FFAB91" points="47 47 0 47 3.91666667 39 43.0833333 39" />
            <rect id="Rectangle" fill="#616161" x="8" y="9" width="32" height="30" />
            <path
              d="M32.6666667,37 L15.3333333,37 C12.95,37 11,34.9545455 11,32.4545455 L11,16.5454545 C11,14.0454545 12.95,12 15.3333333,12 L32.6666667,12 C35.05,12 37,14.0454545 37,16.5454545 L37,32.4545455 C37,34.9545455 35.05,37 32.6666667,37 Z"
              id="Path"
              fill="#E3F2FD"
            />
            <g id="Group" transform="translate(20.000000, 31.000000)" fill="#424242">
              <rect id="Rectangle" x="2" y="0" width="4" height="11.1428571" />
              <ellipse id="Oval" cx="4" cy="11.1428571" rx="4" ry="1.85714286" />
            </g>
            <g id="Group" transform="translate(5.000000, 9.000000)" fill="#BF360C">
              <rect id="Rectangle" x="25.84" y="43.8372093" width="10.64" height="21.1627907" />
              <g id="Rectangle">
                <rect x="34.2" y="0" width="3.8" height="31.2481831" />
                <rect x="0" y="0" width="3.8" height="31.2481831" />
              </g>
              <ellipse id="Oval" cx="19" cy="24.9418605" rx="3.8" ry="3.77906977" />
            </g>
            <path
              d="M36,69 C34.8,69 34,68.2 34,67 L34,59 C34,57.8 34.8,57 36,57 C37.2,57 38,57.8 38,59 L38,67 C38,68.2 37.2,69 36,69 Z"
              id="Path"
              fill="#424242"
            />
            <rect id="Rectangle" fill="#E65100" x="0" y="0" width="47" height="9" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FridayLunchIcon;
