import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

export const loadAnimations = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.refresh();

  let stArray = [];
  stArray = stArray.concat(
    ScrollTrigger.batch('.hiw_block .image_wr.left .lottie', {
      onEnter: elements => {
        gsap.to(elements, {
          opacity: 1,
          rotateY: '30deg',
          rotateX: '10deg',
          x: '0',
          ease: 'power4.out',
          duration: 2.5,
          delay: 0.5,
        });
      },
      onLeave: elements => {
        gsap.to(elements, {
          opacity: 0,
          rotateY: '0deg',
          rotateX: '-40deg',
          x: '50%',
          duration: 1,
          ease: 'power4.out',
        });
      },
      onEnterBack: elements => {
        gsap.to(elements, {
          opacity: 1,
          rotateY: '30deg',
          rotateX: '10deg',
          x: '0',
          ease: 'power4.out',
          duration: 2.5,
          delay: 0.5,
        });
      },
      onLeaveBack: elements => {
        gsap.to(elements, {
          opacity: 0,
          rotateY: '0deg',
          rotateX: '-40deg',
          x: '50%',
          duration: 1,
          ease: 'power4.out',
        });
      },
    })
  );

  stArray = stArray.concat(
    ScrollTrigger.batch('.hiw_block .image_wr.right .lottie', {
      onEnter: elements => {
        gsap.to(elements, {
          opacity: 1,
          rotateY: '-30deg',
          rotateX: '10deg',
          x: '0',
          ease: 'power4.out',
          duration: 2.5,
          delay: 0.5,
        });
      },
      onLeave: elements => {
        gsap.to(elements, {
          opacity: 0,
          rotateY: '0deg',
          rotateX: '-40deg',
          x: '-50%',
          duration: 1,
          ease: 'power4.out',
        });
      },
      onEnterBack: elements => {
        gsap.to(elements, {
          opacity: 1,
          rotateY: '-30deg',
          rotateX: '10deg',
          x: '0',
          ease: 'power4.out',
          duration: 2.5,
          delay: 0.5,
        });
      },
      onLeaveBack: elements => {
        gsap.to(elements, {
          opacity: 0,
          rotateY: '0deg',
          rotateX: '-40deg',
          x: '-50%',
          duration: 1,
          ease: 'power4.out',
        });
      },
    })
  );

  return stArray;
};
