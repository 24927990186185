import React from 'react';

function RSSIcon() {
  return (
    <svg width="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>RSS</title>
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="MAIN_man"
          transform="translate(-281.000000, -3059.000000)"
          fill="#5C5C5C"
          fillRule="nonzero"
        >
          <g id="footer" transform="translate(-2.000000, 2738.000000)">
            <g id="Group-11" transform="translate(163.000000, 286.000000)">
              <g id="Group-10" transform="translate(0.000000, 35.000000)">
                <g id="icons8-rss" transform="translate(120.000000, 0.000000)">
                  <path
                    d="M18.6875,0 L4.3125,0 C1.93164085,0 0,1.93164085 0,4.3125 L0,18.6875 C0,21.0683592 1.93164085,23 4.3125,23 L18.6875,23 C21.0683592,23 23,21.0683592 23,18.6875 L23,4.3125 C23,1.93164085 21.0683592,0 18.6875,0 Z M5.30769231,19.4615385 C4.32977742,19.4615385 3.53846154,18.6702226 3.53846154,17.6923077 C3.53846154,16.7143928 4.32977742,15.9230769 5.30769231,15.9230769 C6.28560719,15.9230769 7.07692308,16.7143928 7.07692308,17.6923077 C7.07692308,18.6702226 6.28560719,19.4615385 5.30769231,19.4615385 Z M12.3846154,19.9038462 C11.6520433,19.9038462 11.0576923,19.3094952 11.0576923,18.5769231 C11.0576923,14.9175178 8.08248219,11.9423077 4.42307692,11.9423077 C3.69050481,11.9423077 3.09615385,11.3479567 3.09615385,10.6153846 C3.09615385,9.8828125 3.69050481,9.28846154 4.42307692,9.28846154 C9.54417112,9.28846154 13.7115385,13.4558298 13.7115385,18.5769231 C13.7115385,19.3094952 13.1171875,19.9038462 12.3846154,19.9038462 Z M18.5769231,19.9038462 C17.844351,19.9038462 17.25,19.3094952 17.25,18.5769231 C17.25,11.5034553 11.4965447,5.75 4.42307692,5.75 C3.69050481,5.75 3.09615385,5.15564904 3.09615385,4.42307692 C3.09615385,3.69050481 3.69050481,3.09615385 4.42307692,3.09615385 C12.9582336,3.09615385 19.9038462,10.0417673 19.9038462,18.5769231 C19.9038462,19.3094952 19.3094952,19.9038462 18.5769231,19.9038462 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RSSIcon;
