export const MEDIA = [
  {
    name: 'TalkAboutUs.techcrunch',
    icon: '/images/media/TechCrunch.svg',
    alt: 'TalkAboutUs.techcrunch',
  },
  {
    name: 'TalkAboutUs.tve',
    icon: '/images/media/TVE.png',
    alt: 'TalkAboutUs.tve',
  },
  {
    name: 'TalkAboutUs.a3',
    icon: '/images/media/Antena3.png',
    alt: 'TalkAboutUs.a3',
  },
  {
    name: 'TalkAboutUs.elpais',
    icon: '/images/media/ElPais.svg',
    alt: 'TalkAboutUs.elpais',
  },
  {
    name: 'TalkAboutUs.eleconomista',
    icon: '/images/media/ElEconomista.svg',
    alt: 'TalkAboutUs.eleconomista',
  },
  {
    name: 'TalkAboutUs.lasexta',
    icon: '/images/media/LaSexta.svg',
    alt: 'TalkAboutUs.lasexta',
  },
];
