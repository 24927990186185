import React from 'react';
import { Text, Heading, Button } from '@taxdown/piccolo';
import { Section, Container } from 'Components/UI';
import PropTypes from 'prop-types';
import { useTranslation } from 'Hooks';
import Link from 'Components/Link';
import Pin from 'Assets/icons/Pin';
import { CAREERS_LIST_ROUTE } from 'Constants/routes';
import { TEXT_GREY_LIGHT } from 'Styles/colors';
import { ThirdSectionWrapper, TextButtonWrapper, JobsWrapper, JobBlock } from './styles';

function ThirdSection({ categories, jobs }) {
  const { t } = useTranslation('careers');
  const getJobCount = category => {
    const jobsPerCategory = jobs.filter(({ Area }) => Area === category);
    return jobsPerCategory;
  };

  const getLocations = category => {
    const jobsPerCategory = getJobCount(category);
    const locations = jobsPerCategory.map(({ Location }) => Location);
    const nonRepeatedLocation = [...new Set(locations)].join(' / ');
    return nonRepeatedLocation;
  };
  return (
    <Section>
      <Container>
        <ThirdSectionWrapper>
          <TextButtonWrapper>
            <Heading type="h2" mb={24}>
              {t('Careers.ThirdSection.title')}
            </Heading>
            <Link to={CAREERS_LIST_ROUTE}>
              <Button secondary size="small">
                {t('Careers.ThirdSection.textButton')}
              </Button>
            </Link>
          </TextButtonWrapper>
          {categories && (
            <JobsWrapper>
              {categories.map(category => (
                <JobBlock key={category} data-testid="jobBlock">
                  <Link to={CAREERS_LIST_ROUTE} state={{ category }}>
                    <Heading type="h1" color="primaryGreen">
                      {getJobCount(category).length}
                    </Heading>
                  </Link>
                  <Text size="xm" mb={10}>
                    {category}
                  </Text>
                  <Text size="xs" color={TEXT_GREY_LIGHT}>
                    <Pin />
                    {getLocations(category)}
                  </Text>
                </JobBlock>
              ))}
            </JobsWrapper>
          )}
        </ThirdSectionWrapper>
      </Container>
    </Section>
  );
}

ThirdSection.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ThirdSection;
