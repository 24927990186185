import { useStaticQuery, graphql } from 'gatsby';

export function useJobsQuery() {
  const queryData = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Jobs" }, data: { Title: { ne: null }, Status: { eq: "open" } } }
      ) {
        nodes {
          data {
            Area
            Title
            Location
            DescriptionESP
            ShortDescriptionESP
            Status
            ID
            FullRemote
          }
        }
      }
    }
  `);
  const {
    allAirtable: { nodes },
  } = queryData;

  const data = nodes.map(item => item.data);
  const listing = {
    categories: [...new Set(data.map(item => item.Area))],
    fullRemoteOptions: [...new Set(data.map(item => item.FullRemote))],
    jobs: data.filter(({ Status }) => Status === 'open'),
  };

  return { listing };
}

export default useJobsQuery;
