import React from 'react';

function DropDown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" fill="none" viewBox="0 0 9 5">
      <path fill="#242B36" fillRule="evenodd" d="M9 0L4.5 4.5 0 0h9z" clipRule="evenodd" />
    </svg>
  );
}

export default DropDown;
