import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

const CustomLink = ({ to, children, ...rest }) => {
  return (
    <Location>
      {({ location }) => {
        const params = location.search;
        const route = `${to}${params}`;
        return (
          <Link to={route} {...rest}>
            {children}
          </Link>
        );
      }}
    </Location>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
};

CustomLink.defaultProps = {
  children: null,
};
export default CustomLink;
