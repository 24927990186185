import React from 'react';
import { useTranslation } from 'Hooks';
import { Lottie } from 'Components';
import USP1 from './lotties/usp_1.json';
import USP2 from './lotties/usp_2.json';
import USP3 from './lotties/usp_3.json';
import './styles.scss';

const features = [
  {
    title: 'UspBlock.features.1',
    description: 'UspBlockHome.description.1',
    lottie: USP1,
  },
  {
    title: 'UspBlock.features.2',
    description: 'UspBlockHome.description.2',
    lottie: USP2,
  },
  {
    title: 'UspBlockHome.features.3',
    description: 'UspBlockHome.description.3',
    lottie: USP3,
  },
];

const UspBlock = () => {
  const { t } = useTranslation();
  return (
    <section className="usp-block block">
      <div className="block-container">
        <h2> {t('UspBlock.title')}</h2>
        <div className="usp-block__features">
          {features.map(({ title, description, lottie }) => (
            <div className="box" key={title}>
              <div className="icon">
                <Lottie className="lottie-container" options={{ animationData: lottie }} />
              </div>
              <h4>{t(title)}</h4>
              <p>{t(description)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UspBlock;
