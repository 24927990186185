import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'Hooks';
import { StarIcon } from 'Assets/icons';
import './styles.scss';

const GoogleRating = ({ rating }) => {
  const { t } = useTranslation();

  const getRatingStars = () => {
    const content = [];
    for (let i = 0; i < rating; i += 1) {
      content.push(<StarIcon key={i} />);
    }
    return content;
  };

  return (
    <div
      className="google_rating"
      itemProp="aggregateRating"
      itemScope
      itemType="https://schema.org/AggregateRating"
    >
      <p className="rating_content">
        <span itemProp="reviewCount">{t('GoogleRating.reviewCount')}</span>
        {t('GoogleRating.afterReviews')}
        <span itemProp="ratingValue">{t('GoogleRating.ratingValue')}</span>/
        <span itemProp="bestRating">{t('GoogleRating.bestRating')}</span>
        {t('GoogleRating.afterRating')}
      </p>
      <div className="rating_stars">{getRatingStars()}</div>
    </div>
  );
};

GoogleRating.propTypes = {
  rating: PropTypes.number,
};

GoogleRating.defaultProps = {
  rating: 5,
};

export default GoogleRating;
