export const ABANCA = 'abanca';
export const BNEXT_COUPON = 'bnext_10';
export const BNEXT_FREE_COUPON = 'bnext_free';
export const EVO = 'evo_cliente_b';
export const EVOPRO = 'evo_cliente_a';
export const EVO_COUPON = 'evo_free';
export const FINTONIC_COUPON = 'fintonic_6';
export const INDRA_COUPON = 'indra_10';
export const VERTI = 'verti';
export const TALGO = 'talgo';
export const ETORO_BRONZE = 'etoro_bronze';
export const ETORO_GOLD = 'etoro_gold';
export const ETORO_DIAMONDS = 'etoro_diamonds';
export const ETORO_PLATINUM = 'etoro_platinum';
export const ETORO_SILVER = 'etoro_silver';
export const ETORO_PLATINUM_PLUS = 'etoro_platinum+';

export const partner = {
  [ABANCA]: 'abanca',
  [EVO]: 'evo',
  [EVOPRO]: 'evo',
  [VERTI]: 'verti',
  [TALGO]: 'talgo',
  [ETORO_BRONZE]: 'etoro',
  [ETORO_GOLD]: 'etoro',
  [ETORO_DIAMONDS]: 'etoro',
  [ETORO_PLATINUM]: 'etoro',
  [ETORO_PLATINUM_PLUS]: 'etoro',
  [ETORO_SILVER]: 'etoro',
};
