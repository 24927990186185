import React from 'react';

function ArrowUpIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 12L9.70588 8L15 12" stroke="#00B94B" strokeWidth="2" />
    </svg>
  );
}

export default ArrowUpIcon;
