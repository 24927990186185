import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH, DESKTOP_EXTRA_SMALL_WIDTH } from 'Styles/sizes';

export const MainBannerWrapper = styled.div`
  height: calc(100vh - (${({ headerHeight }) => headerHeight}));
  background: url(${({ backgroundImage }) => backgroundImage}) no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  position: relative;
  &::before {
    content: '';
    display: block;
    height: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.37);
  }

  h1 {
    font-size: ${pxToRem(40)};
    margin: 0;
  }

  h3 {
    font-size: ${pxToRem(60)};
    font-weight: 700;
    margin: ${pxToRem(15)} 0;
    line-height: 0.92;
  }

  a {
    margin-top: ${pxToRem(24)};
    min-width: ${pxToRem(272.8)};
    padding: ${pxToRem(16)};
    font-size: ${pxToRem(16)};
    max-width: ${pxToRem(227)};
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    h1 {
      font-size: ${pxToRem(34)};
      font-weight: bold;
      line-height: 1.35;
    }
    p {
      font-size: ${pxToRem(16)};
    }
    a {
      width: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: ${DESKTOP_EXTRA_SMALL_WIDTH}) {
    h1 {
      font-size: ${pxToRem(30)};
      font-weight: bold;
      line-height: 1.37;
    }
    p {
      font-size: ${pxToRem(18)};
    }
  }
`;
