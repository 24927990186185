import React from 'react';

function CheckIcon() {
  return (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.9082 7.90099L9.18093 15.1737L19.1809 1.53735"
        stroke="#00B94B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckIcon;
