import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH, TABLET_WIDTH } from 'Styles/sizes';

export const PerkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

export const PerkBlock = styled.div`
  flex-basis: calc(100% / 4 - 60px);
  margin-bottom: ${pxToRem(50)};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: baseline;
  flex-wrap: wrap;

  svg {
    margin-bottom: ${pxToRem(34)};
  }

  p {
    margin: 0;
    max-width: ${pxToRem(228)};
  }

  @media screen and (max-width: ${TABLET_WIDTH}) {
    flex-basis: calc(100% / 3 - 60px);
    margin-bottom: ${pxToRem(40)};
  }

  @media screen and (max-width: ${MOBILE_WIDTH}) {
    flex-basis: 100%;
  }
`;
