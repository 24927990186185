export const LINKS = [
  {
    icon: '/images/media/media_1.png',
    link: 'https://www.europapress.es/economia/fiscal-00347/noticia-lanzan-plataforma-online-taxdown-conseguir-maximo-ahorro-declaracion-renta-20190606185456.html',
  },
  {
    icon: '/images/media/media_2.png',
    link: 'https://twitter.com/La1_tve/status/1138032934085058560?s=20',
  },
  {
    icon: '/images/media/media_3.png',
    link: 'https://www.expansion.com/pymes/2019/06/21/5d0374bde5fdea76798b4765.html',
  },
  {
    icon: '/images/media/media_4.png',
    link: 'https://www.abc.es/economia/declaracion-renta/abci-taxdown-aplicacion-ayuda-presentar-declaracion-renta-menos-15-minutos-201906120205_noticia.html?ref=https:%2F%2Ftaxdown.es%2F',
  },
  {
    icon: '/images/media/media_5.png',
    link: 'https://techcrunch.com/2021/03/12/taxdown-banks-3m-for-tech-that-helps-people-get-their-taxes-done/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAACA9Dq-Y6pA90nXGnTv4nLtL1xSQ8QVo3gt0B9XDzMPnfybQ63fyVDiU5mDMuVvU-ML314iuDutiU_bF6JINMwtYx_UZxGaGVDQIuBhrBeAjMaANm9Qr7aIXfsKPHTEa6iOZ20N0r1fcV4aWsw8pm0nUqNqVsT-Y1zkmzGSYBzFM',
  },
];
