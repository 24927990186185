import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'Hooks';
import Feature1 from 'Assets/lotties/home/feature_1.json';
import Feature2 from 'Assets/lotties/home/feature_2.json';
import Feature3 from 'Assets/lotties/home/feature_3.json';
import { Lottie } from 'Components';
import './styles.scss';

const GeneralFeaturesBlock = ({ data }) => {
  const { Features_Title } = data;
  const { t } = useTranslation('home');
  return (
    <section className="mod_details">
      <div className="incenter section_inner">
        <h2 className="section_title big">{Features_Title ?? t('Home.GeneralFutures.title')}</h2>
        <div className="details_wrapper">
          <div id="detail_1" className="detail_item">
            <div className="detail_item_anim_wr">
              <div className="detail_item_anim_item rot1">
                <div className="lottie-container">
                  <Lottie className="lottie" options={{ animationData: Feature1 }} />
                </div>
              </div>
            </div>
            <div className="info_wr">
              <div className="info_inner">
                <div className="title">{t('Home.GeneralFutures.feature.1.title')}</div>
                <div className="detail_list">
                  <div className="detail_list_item">
                    {t('Home.GeneralFutures.feature.1.description.1')}
                  </div>
                  <div className="detail_list_item">
                    {t('Home.GeneralFutures.feature.1.description.2')}
                  </div>
                  <div className="detail_list_item">
                    {t('Home.GeneralFutures.feature.1.description.3')}
                  </div>
                  <div className="detail_list_item">
                    {t('Home.GeneralFutures.feature.1.description.4')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="detail_2" className="detail_item reverse">
            <div className="detail_item_anim_wr">
              <div className="detail_item_anim_item rot2">
                <div className="lottie-container">
                  <Lottie className="lottie" options={{ animationData: Feature2 }} />
                </div>
              </div>
            </div>
            <div className="info_wr">
              <div className="title">{t('Home.GeneralFutures.feature.2.title')}</div>
              <div className="detail_list">
                <div className="detail_list_item">
                  {t('Home.GeneralFutures.feature.2.description.1')}
                </div>
                <div className="detail_list_item">
                  {t('Home.GeneralFutures.feature.2.description.2')}
                </div>
                <div className="detail_list_item">
                  {t('Home.GeneralFutures.feature.2.description.3')}
                </div>
              </div>
            </div>
          </div>
          <div id="detail_3" className="detail_item">
            <div className="detail_item_anim_wr">
              <div className="detail_item_anim_item rot3">
                <div className="lottie-container">
                  <Lottie className="lottie" options={{ animationData: Feature3 }} />
                </div>
              </div>
            </div>
            <div className="info_wr">
              <div className="title">{t('Home.GeneralFutures.feature.3.title')}</div>
              <div className="detail_list">
                <div className="detail_list_item">
                  {t('Home.GeneralFutures.feature.3.description.1')}
                </div>
                <div className="detail_list_item">
                  {t('Home.GeneralFutures.feature.3.description.2')}
                </div>
                <div className="detail_list_item">
                  {t('Home.GeneralFutures.feature.3.description.3')}
                </div>
                <div className="detail_list_item">
                  {t('Home.GeneralFutures.feature.3.description.4')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

GeneralFeaturesBlock.propTypes = {
  data: PropTypes.shape(),
};
GeneralFeaturesBlock.defaultProps = {
  data: {},
};

export default GeneralFeaturesBlock;
