export const getRemainingTime = endtime => {
  const total = endtime - new Date();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  const remainingTime = {
    total,
    days: days >= 0 ? days : 0,
    hours: hours >= 0 ? `0${hours}`.slice(-2) : '00',
    minutes: minutes >= 0 ? `0${minutes}`.slice(-2) : '00',
    seconds: seconds >= 0 ? `0${seconds}`.slice(-2) : '00',
  };

  return remainingTime;
};
