import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { TABLET_WIDTH, MOBILE_WIDTH } from 'Styles/sizes';

export const Rectangle = styled.div`
  height: 15px;
  width: 15px;
  background-color: ${({ theme: { colors } }) => colors.primaryGreen};
  position: absolute;
  top: -6px;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  margin: 0;
  padding: 0;
  transform-origin: top left;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    width: 100%;
    height: 100%;
    background: ${({ theme: { colors } }) => colors.primaryGreen};
  }
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }
`;

export const MediaCardWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  flex: 0 1 calc(100% / 2 - 20px);
  padding: ${pxToRem(30)};
  position: relative;
  overflow: hidden;
  margin-bottom: ${pxToRem(32)};

  ${({ isBig }) =>
    isBig &&
    `
    flex: 0 1 calc(60% - 20px);
    display: flex;
   
  `}

  @media (max-width: ${TABLET_WIDTH}) {
    padding: ${pxToRem(24)};
    flex: 0 1 calc(100% / 2 - 12px);
    ${({ isBig }) =>
      isBig &&
      `
      flex-direction: column;
      flex: 0 1 calc(100% / 2 - 12px);
   
    `}
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MediaCardContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  ${({ isBig }) =>
    isBig &&
    `
    flex-direction: column;
    align-items: flex-start;
   
  `}

  ${({ isSmall }) =>
    isSmall &&
    `
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const MediaImage = styled.div`
  width: 100%;
  max-width: ${pxToRem(174)};
  margin-right: ${pxToRem(40)};

  ${({ isBig }) =>
    isBig &&
    `
    margin-right: 0;
    margin-bottom: ${pxToRem(40)};
   
  `}

  ${({ isSmall }) =>
    isSmall &&
    `
    margin-right: 0;
    margin-bottom: ${pxToRem(40)};
  `}

  img {
    width: 100%;
    height: auto;
  }
`;

export const ArticleImage = styled.div`
  width: 100%;
  max-width: ${pxToRem(342)};
  margin-left: ${pxToRem(32)};
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${TABLET_WIDTH}) {
    margin-left: 0;
  }
`;

export const MediaBody = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  ${({ isBig }) =>
    isBig &&
    `
    height: auto;
   
  `}
  > p {
    margin: 0;
    margin-bottom: ${pxToRem(10)};
  }
`;

export const CustomLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.primaryGreen};
`;

export const MediaSnippetLink = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  svg {
    height: ${pxToRem(18)};
    margin-left: ${pxToRem(10)};
  }
`;
