import React from 'react';

function SimpleLogo() {
  return (
    <svg
      width="33px"
      height="21px"
      viewBox="0 0 33 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Simple Logo</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(0.000000, 0.000000)" fillRule="nonzero">
          <path
            d="M16.0970041,20.6880613 L12.6442184,17.3111296 C12.2189955,16.8952114 12.2189955,16.2211472 12.6442184,15.805229 L27.6889379,1.08971647 C28.1141607,0.673798213 28.8033046,0.673798213 29.2282203,1.08971647 L32.6813132,4.46694869 C33.1062289,4.88256643 33.1062289,5.55663049 32.6813132,5.97254875 L17.6362865,20.6880613 C17.2110636,21.1039796 16.5219198,21.1039796 16.0970041,20.6880613"
            id="Fill-15"
            fill="#00DC5A"
          />
          <path
            d="M9.94032769,14.5640127 L6.4827301,11.2046327 C6.05595744,10.7899831 6.05595744,10.1177481 6.4827301,9.70279926 L15.7491777,0.699876115 C16.1759504,0.28522648 16.8678395,0.28522648 17.2946121,0.699876115 L20.7522096,4.05925617 C21.1789823,4.47390581 21.1789823,5.14614082 20.7522096,5.56079045 L11.48607,14.5640127 C11.0589893,14.9786624 10.3671003,14.9786624 9.94032769,14.5640127"
            id="Fill-17"
            fill="#5C5C5C"
          />
          <path
            d="M3.75662523,8.53681307 L0.317354633,5.17281269 C-0.105784878,4.7589338 -0.105784878,4.08798915 0.317354633,3.67440953 L3.75662523,0.310409162 C4.17976475,-0.103469721 4.86541597,-0.103469721 5.28855549,0.310409162 L8.72782609,3.67440953 C9.1509656,4.08798915 9.1509656,4.7589338 8.72782609,5.17281269 L5.28855549,8.53681307 C4.86541597,8.95069194 4.17976475,8.95069194 3.75662523,8.53681307"
            id="Fill-19"
            fill="#5C5C5C"
          />
        </g>
      </g>
    </svg>
  );
}

export default SimpleLogo;
