import React from 'react';

function TimeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="100"
      fill="none"
      viewBox="0 0 105 100"
    >
      <title>Horario</title>
      <path fill="#D5F5FB" d="M56.085 11.802h13.92v8.032h-13.92z" />
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M63.076 88.43c16.309 0 29.53-13.22 29.53-29.529 0-16.309-13.221-29.53-29.53-29.53s-29.53 13.221-29.53 29.53 13.221 29.53 29.53 29.53z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M63.076 63.19a4.288 4.288 0 1 0 0-8.577 4.288 4.288 0 0 0 0 8.576z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M103.975 54.812a41.183 41.183 0 0 0-11.836-24.975A40.858 40.858 0 0 0 72.04 18.801v-5.17c3.01-.749 5.248-3.474 5.248-6.713A6.926 6.926 0 0 0 70.37 0H55.72a6.926 6.926 0 0 0-6.918 6.918c0 3.239 2.238 5.964 5.248 6.713v5.184a40.855 40.855 0 0 0-20.037 11.022 41.305 41.305 0 0 0-3.454 3.92 2.012 2.012 0 0 0-.155-.007h-14.5a2.035 2.035 0 0 0 0 4.07H27.79a40.786 40.786 0 0 0-3.61 7.82H2.035a2.035 2.035 0 0 0 0 4.07H23.03a41.366 41.366 0 0 0-1.01 7.82h-8.998a2.035 2.035 0 0 0 0 4.07h9.063a41.31 41.31 0 0 0 1.27 7.82H10.174a2.035 2.035 0 0 0 0 4.07h14.48a40.862 40.862 0 0 0 9.36 14.474 41.177 41.177 0 0 0 25 11.838c1.343.132 2.689.198 4.032.198 7.94 0 15.779-2.298 22.43-6.63a2.035 2.035 0 1 0-2.22-3.41c-14.56 9.482-34.06 7.432-46.365-4.874-14.439-14.439-14.439-37.932 0-52.371 14.438-14.439 37.932-14.439 52.37 0 12.29 12.29 14.351 31.772 4.901 46.325a2.035 2.035 0 1 0 3.413 2.216c5.049-7.776 7.322-17.167 6.4-26.444zM52.872 6.918A2.852 2.852 0 0 1 55.72 4.07h14.65a2.852 2.852 0 0 1 2.849 2.848 2.852 2.852 0 0 1-2.849 2.849H55.72a2.852 2.852 0 0 1-2.848-2.849zm5.248 6.918v4.28c3.27-.394 6.58-.396 9.85-.008v-4.272h-9.85z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M90.701 84.49c-.537 0-1.06.217-1.438.596-.38.378-.596.903-.596 1.438a2.038 2.038 0 0 0 3.473 1.439 2.05 2.05 0 0 0 .596-1.439 2.05 2.05 0 0 0-.596-1.438 2.054 2.054 0 0 0-1.439-.596z"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M31.512 58.898c0-17.404 14.16-31.564 31.564-31.564 17.405 0 31.565 14.16 31.565 31.564 0 17.405-14.16 31.565-31.565 31.565-17.404 0-31.564-14.16-31.564-31.565zm4.07 0c0 15.161 12.333 27.495 27.494 27.495S90.571 74.06 90.571 58.898c0-15.16-12.334-27.494-27.495-27.494-15.16 0-27.495 12.334-27.495 27.494z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M65.87 53.231l10.077-10.078a2.035 2.035 0 1 1 2.878 2.878l-.296.296-9.782 9.782c.417.843.652 1.79.652 2.792a6.33 6.33 0 0 1-6.323 6.323 6.33 6.33 0 0 1-6.322-6.323c0-1.002.235-1.95.652-2.792l-4.51-4.51a2.035 2.035 0 0 1 2.877-2.878l4.51 4.51a6.283 6.283 0 0 1 2.793-.652c1.002 0 1.95.236 2.793.652zm-5.047 5.67a2.256 2.256 0 0 0 2.253 2.254 2.256 2.256 0 0 0 2.253-2.253 2.256 2.256 0 0 0-2.253-2.254 2.256 2.256 0 0 0-2.253 2.254z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M84.831 56.89h-1.885a2.035 2.035 0 0 0 0 4.069h1.885a2.035 2.035 0 0 0 0-4.07zM43.206 56.839h-1.885a2.035 2.035 0 0 0 0 4.069h1.885a2.035 2.035 0 0 0 0-4.07zM63.101 41.066c1.124 0 2.035-.91 2.035-2.034v-1.885a2.035 2.035 0 0 0-4.07 0v1.885c0 1.124.911 2.034 2.035 2.034zM63.051 76.735c-1.123 0-2.034.91-2.034 2.035v1.885a2.035 2.035 0 0 0 4.07 0V78.77a2.035 2.035 0 0 0-2.036-2.035zM2.035 69.418a2.05 2.05 0 0 0-1.439.597A2.05 2.05 0 0 0 0 71.453c0 .537.218 1.06.596 1.439a2.05 2.05 0 0 0 1.439.596 2.05 2.05 0 0 0 1.438-.596 2.05 2.05 0 0 0 .596-1.439 2.05 2.05 0 0 0-.596-1.438 2.05 2.05 0 0 0-1.438-.597z"
      />
    </svg>
  );
}

export default TimeIcon;
