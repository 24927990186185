import React from 'react';
import PropTypes from 'prop-types';
import { Trans as TransI18 } from 'react-i18next';
import { useTranslation } from 'Hooks';

const Trans = ({ i18nKey, ...rest }) => {
  const { t } = useTranslation();
  return <TransI18 t={t} i18nKey={i18nKey} {...rest} />;
};

Trans.propTypes = {
  i18nKey: PropTypes.string.isRequired,
};

export default Trans;
