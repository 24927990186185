import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { TABLET_WIDTH, MOBILE_WIDTH } from 'Styles/sizes';

export const FourthSectionWrapper = styled.div`
  background: url(${({ backgroundImage }) => backgroundImage}) no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: ${pxToRem(700)};
  position: relative;
  @media screen and (max-width: ${TABLET_WIDTH}) {
    min-height: ${pxToRem(374)};
  }
  @media screen and (max-width: ${MOBILE_WIDTH}) {
    min-height: ${pxToRem(256)};
  }
`;

export const TextWrapper = styled.div`
  h3 {
    text-transform: uppercase;
    font-size: ${pxToRem(20)};
    letter-spacing: 3px;
    text-align: center;
    margin: 0;
  }
`;
