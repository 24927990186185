import React from 'react';
import { Text, Heading } from '@taxdown/piccolo';
import { Container } from 'Components/UI';
import PropTypes from 'prop-types';
import LogoText from 'Assets/icons/LogoText';
import { useTranslation } from 'Hooks';
import {
  BannerSmallWrapper,
  BannerSmallImage,
  BannerSmallTitle,
  BannerSmallDescription,
  BannerSmallContent,
} from './styles';

function BannerSmall({ title, image, hasLogo, description, ns }) {
  const { t } = useTranslation();
  return (
    <BannerSmallWrapper>
      <Container>
        <BannerSmallContent>
          <BannerSmallImage>
            <img src={image} alt="" />
          </BannerSmallImage>
          <BannerSmallTitle>
            <Heading type="h2" bold>
              {t(`${ns}:${title}`)}
            </Heading>
            {hasLogo && <LogoText />}
          </BannerSmallTitle>
          {description && (
            <BannerSmallDescription>
              <Text type="p"> {t(`${ns}:${description}`)}</Text>
            </BannerSmallDescription>
          )}
        </BannerSmallContent>
      </Container>
    </BannerSmallWrapper>
  );
}

BannerSmall.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  hasLogo: PropTypes.bool,
  description: PropTypes.string,
  ns: PropTypes.string.isRequired,
};

BannerSmall.defaultProps = {
  image: null,
  description: null,
  hasLogo: null,
};

export default BannerSmall;
