import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'Utils/language';

const TrustSlider = ({ mode }) => {
  const ref = React.useRef(null);

  const currentLanguage = getLanguage();

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [currentLanguage]);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={currentLanguage}
      data-template-id="54ad5defc6454f065c28af8b"
      data-businessunit-id="5cbd8e2d001a030001a82511"
      data-style-height="240px"
      data-style-width="100%"
      data-theme={mode}
      data-stars="4,5"
      data-review-languages={currentLanguage}
      data-font-family="Open Sans"
    />
  );
};

TrustSlider.propTypes = {
  mode: PropTypes.string,
};
TrustSlider.defaultProps = {
  mode: 'dark',
};

export default TrustSlider;
