import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '@taxdown/piccolo';
import { useTranslation } from 'Hooks/useTranslation';
import { BannerContainer } from './styles';

function Banner({ title, description, image, imageMobile, noCenter }) {
  const { t } = useTranslation();
  return (
    <BannerContainer
      backgroundImage={image}
      backgroundImageMobile={imageMobile}
      noCenter={noCenter}
    >
      <Heading type="h2" color="white">
        {t(title)}
      </Heading>
      {description && (
        <Heading type="h3" color="white">
          {description}
        </Heading>
      )}
    </BannerContainer>
  );
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageMobile: PropTypes.string,
  noCenter: PropTypes.bool,
};

Banner.defaultProps = {
  image: '',
  imageMobile: '',
  noCenter: false,
};

export default Banner;
