import { getFingerPrintId } from 'Services/branch';

export const setTrack = (pathname, { name, contentName, status, value }) => {
  setTimeout(() => {
    if (typeof window !== 'undefined' && window.analytics && window.branch) {
      const fingerprint = getFingerPrintId();
      const integrations = {
        All: true,
        Intercom: false,
      };
      window.analytics.track(
        name,
        {
          browser_fingerprint_id: fingerprint, // add the browser fingerprint to the Segment track event
          content_name: contentName,
          status,
          value,
          content_category: pathname,
        },
        { integrations }
      );
    }
  }, 1000);
};

export const setPage = () => {
  setTimeout(() => {
    if (typeof window !== 'undefined' && window.analytics) {
      const integrations = {
        All: true,
        Intercom: false,
      };
      window.analytics.page({}, { integrations });
    }
  }, 1000);
};
