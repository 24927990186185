import React from 'react';

function PastDeclarationsIcon() {
  return (
    <svg width="46px" viewBox="0 0 46 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Declaraciones pasadas</title>
      <g id="como-funciona" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="c_funciona" transform="translate(-1101.000000, -672.000000)" fillRule="nonzero">
          <g id="decl_pas_ico" transform="translate(1101.000000, 672.000000)">
            <path
              d="M13.1276596,31 L11,32.8830202 C11.6774823,33.644984 12.4134752,34.3528004 13.2014184,35 L15,32.8021978 C14.3291667,32.2519497 13.7031028,31.6493265 13.1276596,31 Z"
              id="Path"
              fill="#00DC5A"
            />
            <path
              d="M11.727003,27 L9,28.1701891 C9.42164318,29.1523915 9.92229228,30.0987208 10.4970326,31 L13,29.4100853 C12.5118694,28.6420096 12.0861461,27.8361142 11.727003,27 Z"
              id="Path"
              fill="#00DC5A"
            />
            <path
              d="M36.2345017,2.69756258 C33.2511933,0.931499765 29.8640955,0.000925852068 26.4156087,0 C16.6074157,0.016109826 8.32098203,7.43246264 7.02065857,17.3586152 L2.05150056,12.2849459 L1.13686838e-13,14.3795936 L8.27999554,22.833734 L16.5599911,14.3795936 L14.5084905,12.2849459 L10.0210144,16.8608772 C11.7260341,7.62476212 20.441375,1.54876534 29.4872375,3.28964498 C38.5331,5.03052462 44.4839387,13.9291666 42.7789191,23.1651891 C41.0738994,32.4012115 32.3585585,38.4773935 23.312696,36.7364213 C21.4584202,36.3795979 19.6769589,35.7038185 18.0442097,34.737877 L16.5861064,37.2991542 C25.9442641,42.83899 37.9290027,39.5840645 43.3547248,30.029086 C48.7805375,20.4742 45.5926594,8.23739843 36.2345017,2.69756258 Z"
              id="Path"
              fill="#5C5C5C"
            />
            <polygon
              id="Path"
              fill="#00DC5A"
              points="24 7 24 23.1538462 31.0714286 28 33 25.6503497 27.2142857 21.6853147 27.2142857 7"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PastDeclarationsIcon;
