import React from 'react';
import { useTranslation } from 'Hooks';
import { PartnerContactForm } from 'Components';
import './styles.scss';

const PartnersHeaderBlock = () => {
  const { t } = useTranslation('home');

  return (
    <section className="partners-header-block">
      <h2>{t('PartnersHeaderBlock.title')}</h2>
      <div className="form" id="partner-contact-form">
        <PartnerContactForm />
      </div>
    </section>
  );
};

export default PartnersHeaderBlock;
