import React from 'react';
import { Text, Heading } from '@taxdown/piccolo';
import { Section, Container } from 'Components/UI';
import { Trans } from 'Components';

import { useTranslation } from 'Hooks';
import { TEXT_GREY_LIGHT } from 'Styles/colors';
import SimpleLogo from 'Assets/icons/SimpleLogo';
import priceList from 'Assets/json/prices.json';

import {
  TableContainer,
  HeaderRow,
  HeaderCell,
  GraphRow,
  PersonCell,
  GraphCell,
  PriceCell,
  PriceListWrapper,
  PersonImage,
  SubText,
  PriceListTitle,
  PriceFeatures,
  PriceImage,
  PriceText,
  TextImportant,
} from './styles';

const PRICE_IMAGE = '/images/how-it-works/price.png';

function PriceList() {
  const { t } = useTranslation();
  return (
    <Section>
      <PriceListWrapper id="price">
        <Container>
          <PriceListTitle>
            <SimpleLogo />
            <Heading type="h3" color="primaryGreen" bold mb={40}>
              {t('PriceList.title')}
            </Heading>
          </PriceListTitle>
          <PriceFeatures>
            <PriceImage>
              <img src={PRICE_IMAGE} alt="" />
            </PriceImage>
            <PriceText>
              <Heading type="h2" mb={24}>
                <Trans i18nKey="PriceList.Feature.title" components={{ bold: <TextImportant /> }} />
              </Heading>
              <Text type="p">{t('PriceList.Feature.firstDescription')}</Text>
              <Text type="p">{t('PriceList.Feature.secondDescription')}</Text>
            </PriceText>
          </PriceFeatures>
          <TableContainer>
            <HeaderRow>
              <HeaderCell>
                <Text size="xs" color="baseGrey" bold>
                  {t('PriceList.Feature.drafTitle')}
                </Text>
                <SubText>
                  <Text size="xs" color={TEXT_GREY_LIGHT}>
                    {t('PriceList.Feature.beforeTitle')}
                  </Text>
                </SubText>
              </HeaderCell>
              <HeaderCell>
                <Text size="xs" color="baseGrey" bold>
                  {t('PriceList.Feature.taxdownTitle')}
                </Text>
              </HeaderCell>
              <HeaderCell>
                <Text size="xs" color="baseGrey" bold>
                  {t('PriceList.Feature.savingTitle')}
                </Text>
              </HeaderCell>
              <HeaderCell>
                <Text size="xs" color="baseGrey" bold>
                  {t('PriceList.Feature.priceTitle')}
                </Text>
              </HeaderCell>
            </HeaderRow>
            {priceList.map(({ title, price, image, imageAlt, graph, graphAlt, priceSub }) => (
              <GraphRow key={title}>
                <PersonCell>
                  <PersonImage>
                    <img src={image} alt={imageAlt} />
                  </PersonImage>
                  <Text bold>{title && t(title)}</Text>
                </PersonCell>
                <GraphCell>
                  <img src={graph} alt={graphAlt} />
                </GraphCell>
                <PriceCell>
                  <Heading type="h3" bold color={priceSub ? 'baseGrey' : 'primaryGreen'}>
                    {t(price)}
                    {priceSub && <SubText>{t(priceSub)}</SubText>}
                  </Heading>
                </PriceCell>
              </GraphRow>
            ))}
          </TableContainer>
        </Container>
      </PriceListWrapper>
    </Section>
  );
}

export default PriceList;
