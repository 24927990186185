/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { WEBAPP_APP_SIGNUP } from 'Constants/routes';
import { useTranslation } from 'Hooks';
import './styles.scss';

const ComparePlanCard = ({ name, Icon, price, isYearly, showShadow, isPopular, button }) => {
  const { t } = useTranslation('pricing');
  const isFullPlan = button.includes('full');

  const getCardStyles = () => {
    let cardClass = 'plan-card';
    cardClass += showShadow ? ' shadow' : '';
    cardClass += isPopular ? ' popular' : '';
    return cardClass;
  };

  return (
    <div className={getCardStyles()}>
      {isPopular && <div className="popular-tag">{t('ComparePlan.popular')}</div>}
      <div className="plan-card-title__container">
        <Icon />
        <p className="plan-card-title">
          <span>{t(name)}</span>
        </p>
      </div>

      <div className="plan-card-line" />

      <div className="plan-card-price__container">
        <p className="plan-card-price">{t(price)}</p>
        {isYearly && <span className="plan-card-year">{`/${t('ComparePlan.year')}`}</span>}
      </div>

      <div className="plan-card__cta">
        <div className="button__container">
          <a href={isFullPlan ? null : WEBAPP_APP_SIGNUP} className={button}>
            {t('ComparePlan.continue')}
          </a>
        </div>
      </div>
    </div>
  );
};

ComparePlanCard.propTypes = {
  name: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired,
  isYearly: PropTypes.bool.isRequired,
  showShadow: PropTypes.bool.isRequired,
  button: PropTypes.string.isRequired,
  isPopular: PropTypes.bool,
};

ComparePlanCard.defaultProps = {
  isPopular: false,
};

export default ComparePlanCard;
