import React from 'react';

function YoutubeIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1398_1987)">
        <path
          d="M27 13.4996C27 20.9562 20.9551 26.9982 13.4996 26.9982C6.04377 26.9982 0 20.9566 0 13.4996C0 6.04269 6.04377 0 13.4996 0C20.9555 0 27 6.04305 27 13.4996Z"
          fill="#FF0000"
        />
        <path
          d="M21.283 16.4194C21.283 17.7759 20.1835 18.8764 18.8253 18.8764H8.17403C6.8176 18.8764 5.71597 17.7762 5.71597 16.4194V10.5795C5.71597 9.2231 6.81724 8.12256 8.17403 8.12256H18.8256C20.1842 8.12256 21.2833 9.22274 21.2833 10.5795V16.4194H21.283Z"
          fill="white"
        />
        <path d="M17.6889 13.4995L12.0561 17.5966V9.40234L17.6889 13.4995Z" fill="#FF0000" />
      </g>
      <defs>
        <clipPath id="clip0_1398_1987">
          <rect width="27" height="26.9982" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default YoutubeIcon;
