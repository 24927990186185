import React, { useEffect } from 'react';
import { getLanguage } from 'Utils/language';
import './styles.scss';

const TrustStarts = () => {
  const ref = React.useRef(null);
  const currentLanguage = getLanguage();

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [currentLanguage]);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={currentLanguage}
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="5cbd8e2d001a030001a82511"
      data-style-height="20px"
      data-theme="dark"
      data-font-family="Open Sans"
      data-text-color="#ffffff"
    />
  );
};
export default TrustStarts;
