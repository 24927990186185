import React from 'react';
import { useTranslation, useTrustStats } from 'Hooks';
import { formatNumbers } from 'Utils/format';
import './styles.scss';

const TrustStatsBlock = () => {
  const { t } = useTranslation();
  const { data } = useTrustStats();
  const { eurosSaved, taxReturn, failed } = data;
  return (
    <section className="mod_global_data block">
      <div className="block-container">
        <h2 className="section_title">{t('GlobalData.title')}</h2>
        <div className="incenter data_columns">
          <div className="col">
            <div id="first_var_num" className="num">
              {formatNumbers(eurosSaved)}
            </div>
            <div className="text">{t('GlobalData.feature.1')}</div>
          </div>
          <div className="col">
            <div id="second_var_num" className="num">
              {formatNumbers(taxReturn)}
            </div>
            <div className="text">{t('GlobalData.feature.2')}</div>
          </div>
          <div className="col">
            <div id="third_var_num" className="num">
              {formatNumbers(failed)}
            </div>
            <div className="text">{t('GlobalData.feature.3')}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustStatsBlock;
