import React from 'react';
import Helmet from 'react-helmet';
import {
  BannerBlock,
  BannerSectionBlock,
  HomePlansBlock,
  MediaGridBlock,
  UserRatingsBlock,
} from 'Blocks';
import withView from 'Hocs/withView';
import { useTranslation } from 'Hooks';
import { WEBAPP_APP_SIGNUP } from 'Constants/routes';
import Features from './components/Features';

const HowItWorks = () => {
  const { t } = useTranslation('how-it-works');
  return (
    <>
      <Helmet>
        <title>{t('HowItWorks.Page.title')}</title>
      </Helmet>
      <BannerSectionBlock
        title="how-it-works:HowItWorks.title"
        description="how-it-works:HowItWorks.description"
        ctaText="how-it-works:HowItWorks.ctaText"
        ctaLink={WEBAPP_APP_SIGNUP}
      />
      <Features />
      <HomePlansBlock />
      <BannerBlock
        title="how-it-works:HowItWorks.Banner.title"
        ctaText="how-it-works:HowItWorks.Banner.ctaText"
        bgImage="/images/startnow.jpg"
      />
      <UserRatingsBlock showLink />
      <MediaGridBlock />
    </>
  );
};

export default withView(HowItWorks);
