import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useTranslation } from 'Hooks';
import { Rating } from 'Components';
import { REVIEWS } from './reviews';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

const UserRatingsBlock = ({ style, showLink }) => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: false,
    className: 'ratings-slide',
    centerMode: true,
  };

  return (
    <section className="user-ratings-block block" style={style}>
      <div className="block-container">
        <h2> {t('RatingsBlock.title')}</h2>
        <div className="user-ratings-block__content">
          {REVIEWS.map(({ name, subtitle, review, rating }) => (
            <Rating key={name} name={name} subtitle={subtitle} review={review} rating={rating} />
          ))}
        </div>
        <Slider {...settings}>
          {REVIEWS.map(({ name, subtitle, review, rating }) => (
            <Rating key={name} name={name} subtitle={subtitle} review={review} rating={rating} />
          ))}
        </Slider>
        {showLink && (
          <div className="link-container">
            <a href="https://g.page/r/CXdvnJ1vZTUpEAE" target="_blank" rel="noreferrer">
              {t('RatingsBlock.CTA')}
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

UserRatingsBlock.propTypes = {
  style: PropTypes.shape(),
  showLink: PropTypes.bool,
};

UserRatingsBlock.defaultProps = {
  style: null,
  showLink: true,
};

export default UserRatingsBlock;
