import React from 'react';

function CityCenterIcon() {
  return (
    <svg width="83px" viewBox="0 0 83 62" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Centro</title>
      <g id="trabaja-con-nosotros" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-220.000000, -567.000000)" id="madrid_icon">
          <g transform="translate(222.000000, 568.000000)">
            <g id="Group-2" stroke="#C0C0C5" strokeWidth="2">
              <path
                d="M0,60 C10.1061571,20 15.6645435,4.45326109e-16 16.6751592,0 C17.6857749,0 23.4607219,0 34,0 L18.2993631,60 L0,60 Z"
                id="Path-2"
                fill="#96948C"
              />
              <path d="M17,0 L17,60" id="Line" strokeLinecap="square" />
              <path d="M9.05102696,30 L25.8437099,30" id="Line-2" strokeLinecap="square" />
              <path
                d="M13.5247112,14.5454545 L30.3173941,14.5454545"
                id="Line-2"
                strokeLinecap="square"
              />
              <path
                d="M5.47207959,44.5454545 L22.2647625,44.5454545"
                id="Line-2-Copy"
                strokeLinecap="square"
              />
            </g>
            <g
              id="Group-2-Copy"
              transform="translate(62.000000, 30.000000) scale(-1, 1) translate(-62.000000, -30.000000) translate(45.000000, 0.000000)"
              stroke="#C0C0C5"
              strokeWidth="2"
            >
              <path
                d="M0,60 C10.1061571,20 15.6645435,4.45326109e-16 16.6751592,0 C17.6857749,0 23.4607219,0 34,0 L18.2993631,60 L0,60 Z"
                id="Path-2"
                fill="#96948C"
              />
              <path d="M17,0 L17,60" id="Line" strokeLinecap="square" />
              <path d="M9.05102696,30 L25.8437099,30" id="Line-2" strokeLinecap="square" />
              <path
                d="M13.5247112,14.5454545 L30.3173941,14.5454545"
                id="Line-2"
                strokeLinecap="square"
              />
              <path
                d="M5.47207959,44.5454545 L22.2647625,44.5454545"
                id="Line-2-Copy"
                strokeLinecap="square"
              />
            </g>
            <path
              d="M36.3214286,60 C36.3214286,60 38.2857143,60 42.2142857,60 L45,26.6341463 L38.7857143,24 L33,26.6341463 C35.2142857,48.8780488 36.3214286,60 36.3214286,60 Z"
              id="Path-3"
              fill="#98958E"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CityCenterIcon;
