import * as routes from 'Constants/routes';
import { config } from 'Config';

export const PRODUCT_OPTIONS_LIST = [
  {
    label: 'Footer.Links.howItWorks',
    route: routes.HOW_IT_WORKS_ROUTE,
  },
  {
    label: 'Footer.Links.makeDeclaration',
    route: `${config.WEBAPP_ROUTE}/signup`,
    link: true,
  },
  {
    label: 'Footer.Links.blog',
    route: routes.BLOG_ROUTE,
    link: true,
  },
  {
    label: 'Footer.Links.partners',
    route: routes.PARTNERS,
  },
];

export const HELP_OPTIONS_LIST = [
  {
    label: 'Footer.Links.helpCenter',
    route: routes.FAQ_ROUTE,
    link: true,
  },
  {
    label: 'Footer.Links.security',
    route: routes.SECURITY_ROUTE,
  },
];

// Taxdown options

export const TAXDOWN_OPTIONS_LIST = [
  {
    label: 'Footer.Links.joinTheTeam',
    route: routes.CAREERS_ROUTE,
  },
  {
    label: 'Footer.Links.aboutUs',
    route: routes.WHO_WE_ARE_ROUTE,
  },
  {
    label: 'Footer.Links.media',
    route: routes.MEDIA_ROUTE,
  },
  {
    label: 'Footer.Links.contactUs',
    isContact: true,
  },
];

export const LEGAL_OPTIONS_LIST = [
  {
    label: 'Footer.Links.termsAndConditions',
    route: routes.TERMS_AND_CONDITIONS_ROUTE,
    rel: 'nofollow',
  },
  {
    label: 'Footer.Links.privacy',
    route: routes.PRIVACY_ROUTE,
    rel: 'nofollow',
  },
  {
    label: 'Footer.Links.cookies',
    route: routes.COOKIES_ROUTE,
    rel: 'nofollow',
  },
  {
    label: 'Footer.Links.legalAdvise',
    route: routes.LEGAL_ADVISE_ROUTE,
    rel: 'nofollow',
  },
];

export const FOOTER_OPTIONS_LIST = [
  {
    title: 'Footer.Links.taxdown',
    options: PRODUCT_OPTIONS_LIST,
  },
  {
    title: 'Footer.Links.faq',
    options: HELP_OPTIONS_LIST,
  },
  {
    title: 'Footer.Links.knowUs',
    options: TAXDOWN_OPTIONS_LIST,
  },
  {
    title: 'Footer.Links.legal',
    options: LEGAL_OPTIONS_LIST,
  },
];

export const FOOTER_OPTIONS_LIST_SEO = [
  {
    title: 'Footer.Links.taxdown',
    options: PRODUCT_OPTIONS_LIST,
  },
  {
    title: 'Footer.Links.faq',
    options: LEGAL_OPTIONS_LIST,
  },
  {
    title: 'Footer.Links.knowUs',
    options: TAXDOWN_OPTIONS_LIST,
  },
  {
    title: 'Legal',
    options: LEGAL_OPTIONS_LIST,
  },
];
