import { useStaticQuery, graphql } from 'gatsby';

export function useLandingsSEO() {
  const queryData = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: {
          table: { eq: "Landings-SEO" }
          data: { parent: { eq: true }, Status: { in: ["Production", "Open"] } }
        }
      ) {
        nodes {
          data {
            Path
            type
          }
        }
      }
    }
  `);
  const {
    allAirtable: { nodes },
  } = queryData;

  return { nodes };
}

export default useLandingsSEO;
