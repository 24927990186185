export const usersSavings = [
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'Samuel Z.',
    amount: '525,03€',
  },
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'Irene G.',
    amount: '362,56€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Alvaro F.',
    amount: null,
  },
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'David R.',
    amount: '50,67€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡María I.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Saving.text',
    name: 'Willmer T.',
    amount: '437,82€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Noelia D.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Saving.text',
    name: 'Miguel E.',
    amount: '1.124,03€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Sebastián A.',
    amount: null,
  },
  {
    time: 'Widget.Time.less',
    text: 'Widget.Saving.text',
    name: 'Nancy O.',
    amount: '866,12€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Yolanda A.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: 'Vicente R.',
    amount: null,
  },
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'Carla P.',
    amount: '1.058,03€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Daniel T.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Thomas T.',
    amount: null,
  },
  {
    time: 'Widget.Time.less',
    text: 'Widget.Saving.text',
    name: 'Santiago L.',
    amount: '1.398,03€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Daniela R.',
    amount: null,
  },
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'Carmen S.',
    amount: '824,03€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Enrique G.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: 'Victor F.',
    amount: null,
  },
  {
    time: 'Widget.Time.less',
    text: 'Widget.Saving.text',
    name: 'Jose María J.',
    amount: '992,57€',
  },
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'Clara R.',
    amount: '655,32€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Borja M.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Paula I.',
    amount: null,
  },
  {
    time: 'Widget.Twominutes.ago',
    text: 'Widget.Saving.text',
    name: 'Margarita W.',
    amount: '728,23€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: 'Iñaki U.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Matías F.',
    amount: null,
  },
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'Rodrigo D.',
    amount: '230,40€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Amparo F.',
    amount: null,
  },
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'Salvador G.',
    amount: '37,45€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Isabel R.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Eduardo L.',
    amount: null,
  },
  {
    time: 'Widget.Oneminute.ago',
    text: 'Widget.Saving.text',
    name: 'Jaime C.',
    amount: '1.344,03€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Hector R.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Ángeles J.',
    amount: null,
  },
  {
    time: 'Widget.Twominutes.ago',
    text: 'Widget.Saving.text',
    name: 'Jorge L.',
    amount: '1.524,03€',
  },
  {
    time: 'Widget.Time.less',
    text: 'Widget.Saving.text',
    name: 'Humberto C.',
    amount: '2.390,17€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Ericka A.',
    amount: null,
  },
  {
    time: 'Widget.Twominutes.ago',
    text: 'Widget.Saving.text',
    name: 'Juan L.',
    amount: '174,03€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Justino L.',
    amount: null,
  },
  {
    time: 'Widget.Time.less',
    text: 'Widget.Saving.text',
    name: 'Agustín S.',
    amount: '3.560,03€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Mónica O.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Rafael R.',
    amount: null,
  },
  {
    time: 'Widget.Time.less',
    text: 'Widget.Saving.text',
    name: 'Patricia H.',
    amount: '624,83€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Carolina A.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Saving.text',
    name: 'Lucas D.',
    amount: '940,30€',
  },
  {
    time: 'Widget.Time.less',
    text: 'Widget.Saving.text',
    name: 'Leonardo G.',
    amount: '665,07€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: 'Eva T.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Marcos G.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: '¡Juan P.',
    amount: null,
  },
  {
    time: 'Widget.Twominutes.ago',
    text: 'Widget.Saving.text',
    name: 'Sonia M.',
    amount: '65,15€',
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Submit.text',
    name: 'Carlos M.',
    amount: null,
  },
  {
    time: 'Widget.Time.now',
    text: 'Widget.Saving.text',
    name: 'Daniel S.',
    amount: '297,13€',
  },
];
