import React from 'react';

function CorrectUseInfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="111"
      height="100"
      fill="none"
      viewBox="0 0 111 100"
    >
      <title>Uso correcto de información</title>
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M50.266 64.222l-8.506-3.544a3.456 3.456 0 0 1-2.127-3.19v-5.76H25.81v5.76a3.456 3.456 0 0 1-2.127 3.19l-8.506 3.544a5.184 5.184 0 0 0-3.19 4.785v12.096h41.469V69.007a5.184 5.184 0 0 0-3.19-4.785z"
        clipRule="evenodd"
      />
      <path
        fill="#00DC5A"
        fillRule="evenodd"
        d="M32.721 30.993c-5.725 0-10.367 3.868-10.367 8.64v6.911c0 5.726 4.642 10.368 10.367 10.368 5.726 0 10.368-4.642 10.368-10.368v-6.911c0-4.772-4.642-8.64-10.368-8.64zM84.49 52.123l-20.947 7.285a1.73 1.73 0 0 0-1.152 1.801c.492 5.238 3.55 24.34 22.277 36.562a1.77 1.77 0 0 0 1.913 0c18.727-12.222 21.785-31.324 22.277-36.562a1.73 1.73 0 0 0-1.152-1.8L86.76 52.122a3.454 3.454 0 0 0-2.27 0z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M61.123 79.483h-6.048V69.007a6.784 6.784 0 0 0-4.186-6.28l-8.507-3.544a1.832 1.832 0 0 1-1.13-1.695V54.96a12.027 12.027 0 0 0 3.456-8.416v-6.911c0-5.657-5.377-10.259-11.987-10.259s-11.986 4.602-11.986 10.259v6.911c0 3.18 1.276 6.199 3.455 8.416v2.528c0 .743-.443 1.409-1.13 1.695l-8.506 3.544a6.785 6.785 0 0 0-4.187 6.28v10.476H6.803a3.569 3.569 0 0 1-3.565-3.564V20.517h90.068v26.891a1.62 1.62 0 0 0 3.238 0V6.803A6.81 6.81 0 0 0 89.742 0H6.803A6.81 6.81 0 0 0 0 6.803v69.115a6.81 6.81 0 0 0 6.803 6.803h54.32a1.62 1.62 0 0 0 0-3.238zM6.803 3.238h82.939c1.965 0 3.564 1.6 3.564 3.565v10.476H3.238V6.803c0-1.966 1.6-3.565 3.565-3.565zm8.996 62.478a3.555 3.555 0 0 0-2.193 3.29v10.477h38.231V69.007a3.555 3.555 0 0 0-2.193-3.29l-8.507-3.544a5.061 5.061 0 0 1-3.123-4.685v-.35c-.036.002-.071.007-.107.007a1.618 1.618 0 0 1-.812-3.022 8.783 8.783 0 0 0 4.374-7.578v-6.912c0-3.871-3.924-7.02-8.748-7.02-4.823 0-8.748 3.15-8.748 7.02v6.912a8.783 8.783 0 0 0 4.372 7.576 1.62 1.62 0 0 1-.916 3.016v.35a5.061 5.061 0 0 1-3.123 4.685l-8.507 3.544z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M8.548 11.878a1.62 1.62 0 1 0 0-3.238h-.017c-.894 0-1.61.725-1.61 1.62 0 .893.733 1.618 1.627 1.618zM18.898 11.878a1.62 1.62 0 0 0 0-3.238h-.017c-.894 0-1.61.725-1.61 1.62 0 .893.733 1.618 1.627 1.618zM13.714 11.878a1.62 1.62 0 1 0 0-3.238h-.017c-.894 0-1.61.725-1.61 1.62 0 .893.733 1.618 1.627 1.618zM25.81 11.878h62.204a1.62 1.62 0 0 0 0-3.238H25.81a1.62 1.62 0 0 0 0 3.238zM109.449 58.312l-23.326-8.114c-.345-.12-.72-.12-1.064 0l-16.775 5.835a1.62 1.62 0 0 0 1.064 3.059l16.243-5.65 21.629 7.523c-.188 2.068-.822 6.7-2.926 12.248-2.564 6.762-7.88 16.205-18.703 23.249C74.814 89.447 69.5 80.055 66.932 73.33c-2.877-7.53-3.048-13.467-3.05-13.526a1.62 1.62 0 0 0-3.238.07c.007.26.17 6.457 3.215 14.486 2.807 7.404 8.708 17.82 20.876 25.394a1.618 1.618 0 0 0 1.71 0c12.169-7.573 18.069-17.99 20.877-25.394 3.044-8.03 3.208-14.226 3.213-14.486a1.616 1.616 0 0 0-1.086-1.563z"
      />
      <path
        fill="#5C5C5C"
        d="M83.816 81.364c.414 0 .829-.158 1.145-.474l11.156-11.156a1.619 1.619 0 1 0-2.29-2.29L83.816 77.455l-3.951-3.95a1.619 1.619 0 1 0-2.29 2.289l5.096 5.096c.316.316.73.474 1.145.474z"
      />
      <path
        fill="#5C5C5C"
        fillRule="evenodd"
        d="M86.177 30.994v5.183a3.35 3.35 0 0 1-3.347 3.347H55.184a3.35 3.35 0 0 1-3.347-3.347v-5.183a3.35 3.35 0 0 1 3.347-3.347H82.83a3.35 3.35 0 0 1 3.347 3.347zm-31.102 0v5.183c0 .06.049.11.109.109H82.83c.06 0 .108-.049.108-.109v-5.183a.109.109 0 0 0-.108-.11H55.184a.109.109 0 0 0-.109.11z"
        clipRule="evenodd"
      />
      <path
        fill="#5C5C5C"
        d="M77.537 44.816a1.62 1.62 0 0 0-1.619-1.62H55.184a1.62 1.62 0 0 0 0 3.24h20.734a1.62 1.62 0 0 0 1.62-1.62zM55.184 50.11a1.62 1.62 0 0 0 0 3.238h12.095a1.62 1.62 0 0 0 0-3.239H55.184z"
      />
    </svg>
  );
}

export default CorrectUseInfoIcon;
