import React from 'react';

function TooltipLiveFreelanceIcon() {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.4569" cy="18.4569" r="17.4569" stroke="#3D3A62" strokeWidth="2" />
      <path
        d="M29.7543 28.9998H7.24573C7.11005 28.9998 7 28.8898 7 28.7541C7 28.6184 7.11005 28.5084 7.24573 28.5084H29.7543C29.8899 28.5084 30 28.6184 30 28.7541C30 28.8898 29.8901 28.9998 29.7543 28.9998Z"
        fill="#3D3A62"
      />
      <path
        d="M12.5695 21.9292C12.4914 21.9292 12.4179 21.892 12.3716 21.829L10.7484 19.618C10.668 19.5085 10.6917 19.3549 10.801 19.2745C10.9104 19.1943 11.0641 19.2178 11.1445 19.3272L12.5583 21.2529L13.7741 19.3996C13.8486 19.2863 14.0007 19.2545 14.1145 19.3289C14.228 19.4033 14.2596 19.5557 14.1852 19.6692L12.7753 21.8183C12.7312 21.8855 12.6568 21.9269 12.5762 21.929C12.5738 21.9292 12.5717 21.9292 12.5696 21.9292L12.5695 21.9292Z"
        fill="#3D3A62"
      />
      <path
        d="M12.5675 22.6023H12.567C12.4313 22.602 12.3216 22.4917 12.3218 22.3561L12.3234 21.6826C12.3237 21.5471 12.4336 21.4374 12.5691 21.4374H12.5696C12.7053 21.4378 12.815 21.548 12.8148 21.6837L12.8132 22.3571C12.8129 22.4926 12.703 22.6023 12.5675 22.6023Z"
        fill="#3D3A62"
      />
      <path
        d="M16.1519 16.5818C16.0931 16.5818 16.0341 16.5607 15.9871 16.5183L15.8525 16.3964C14.789 15.435 13.7728 14.401 12.8283 13.3201C12.026 14.2832 10.9171 14.9316 9.67455 15.1571L9.00213 15.2793C8.86892 15.3033 8.74061 15.2149 8.71639 15.0815C8.69217 14.9479 8.7808 14.8201 8.9142 14.7957L9.58661 14.6736C10.8061 14.4521 11.884 13.7825 12.6217 12.788C12.6661 12.7279 12.7356 12.6914 12.8102 12.6888C12.8867 12.6867 12.9567 12.7176 13.0054 12.7743C13.9911 13.9213 15.0598 15.0174 16.1819 16.0319L16.3165 16.1537C16.4171 16.2448 16.425 16.4001 16.3339 16.5009C16.2858 16.5546 16.2189 16.5818 16.1519 16.5818L16.1519 16.5818Z"
        fill="#3D3A62"
      />
      <path
        d="M12.5558 18.6195C11.1611 18.6195 10.0264 17.3428 10.0264 15.7735C10.0264 15.362 10.1032 14.9643 10.2547 14.5912C10.3058 14.4655 10.4488 14.405 10.5749 14.456C10.7005 14.5071 10.7611 14.6505 10.71 14.7762C10.5824 15.0902 10.5178 15.4258 10.5178 15.7735C10.5178 17.0718 11.4321 18.128 12.5557 18.128C13.6794 18.128 14.5937 17.0717 14.5937 15.7735C14.5937 15.5309 14.5617 15.2915 14.4989 15.0621C14.4631 14.9311 14.5399 14.796 14.6709 14.76C14.8018 14.7242 14.937 14.8011 14.9729 14.932C15.0475 15.2037 15.0853 15.4869 15.0853 15.7733C15.0851 17.3428 13.9504 18.6195 12.5557 18.6195L12.5558 18.6195Z"
        fill="#3D3A62"
      />
      <path
        d="M8.39434 28.9998C8.27726 28.9998 8.17353 28.9157 8.15265 28.7964L7.03198 22.4025C7.01952 22.331 7.03918 22.2577 7.08587 22.202C7.13256 22.1464 7.20136 22.1143 7.27402 22.1143H17.8381C17.9108 22.1143 17.9796 22.1464 18.0263 22.202C18.073 22.2577 18.0928 22.331 18.0802 22.4025L17.3744 26.4292C17.3509 26.5628 17.2235 26.6526 17.0899 26.6288C16.9561 26.6052 16.8668 26.478 16.8903 26.3443L17.5455 22.6057H7.56691L8.63702 28.7116C8.66054 28.8454 8.57102 28.9726 8.43746 28.9961C8.42307 28.9986 8.40867 28.9998 8.39463 28.9998L8.39434 28.9998Z"
        fill="#3D3A62"
      />
      <path
        d="M16.5915 22.6059C16.4558 22.6059 16.3458 22.4959 16.3458 22.3602V21.719C16.3458 21.0355 15.9119 20.4239 15.2662 20.1967L13.8663 19.7042C13.7678 19.6696 13.7021 19.5766 13.7021 19.4723V17.8339C13.7021 17.6982 13.8122 17.5882 13.9479 17.5882C14.0836 17.5882 14.1936 17.6982 14.1936 17.8339V19.2982L15.4294 19.733C16.2716 20.0293 16.8373 20.8273 16.8373 21.719V22.3602C16.8373 22.4958 16.7272 22.6059 16.5915 22.6059L16.5915 22.6059Z"
        fill="#3D3A62"
      />
      <path
        d="M8.51916 22.6059C8.38349 22.6059 8.27344 22.4959 8.27344 22.3602V21.6804C8.27344 20.8149 8.81703 20.0249 9.62613 19.7148L10.6987 19.3035V17.6166C10.6987 17.481 10.8088 17.3709 10.9444 17.3709C11.0801 17.3709 11.1902 17.4809 11.1902 17.6166V19.4726C11.1902 19.5744 11.1275 19.6656 11.0324 19.702L9.80199 20.1738C9.1817 20.4116 8.76486 21.0171 8.76486 21.6804V22.3602C8.76486 22.4959 8.65481 22.6059 8.51913 22.6059L8.51916 22.6059Z"
        fill="#3D3A62"
      />
      <path
        d="M14.2894 18.868H13.9476C13.812 18.868 13.7019 18.7579 13.7019 18.6222C13.7019 18.4866 13.812 18.3765 13.9476 18.3765H14.2894C15.181 18.3765 15.9062 17.6525 15.9062 16.7624V14.3285C15.9062 13.0378 14.9855 11.9197 13.7168 11.6703C13.6889 11.6649 13.6622 11.6545 13.6378 11.64C12.7207 11.0897 11.6174 11.0756 10.6865 11.6026C9.75816 12.1282 9.20388 13.0782 9.20388 14.1436V16.7623C9.20388 17.6523 9.92931 18.3763 10.8207 18.3763H10.9441C11.0798 18.3763 11.1898 18.4864 11.1898 18.6221C11.1898 18.7577 11.0798 18.8678 10.9441 18.8678H10.8207C9.6581 18.8678 8.7124 17.9233 8.7124 16.7623V14.1436C8.7124 12.8986 9.35988 11.7888 10.4442 11.1749C11.5188 10.5665 12.79 10.5755 13.8535 11.1965C15.3301 11.5073 16.3974 12.8175 16.3974 14.3282V16.7621C16.3978 17.9234 15.4519 18.868 14.2893 18.868L14.2894 18.868Z"
        fill="#3D3A62"
      />
      <path
        d="M24.3327 18.3975C21.2126 18.3975 18.6743 15.8595 18.6743 12.7401C18.6743 9.62057 21.2126 7.0826 24.3327 7.0826C27.4528 7.0826 29.991 9.62057 29.991 12.7401C29.991 15.8595 27.4527 18.3975 24.3327 18.3975ZM24.3327 7.57416C21.4835 7.57416 19.1654 9.89169 19.1654 12.7401C19.1654 15.5886 21.4833 17.906 24.3327 17.906C27.1819 17.906 29.4999 15.5884 29.4999 12.7401C29.4999 9.89156 27.182 7.57416 24.3327 7.57416Z"
        fill="#3D3A62"
      />
      <path
        d="M24.3324 13.7093C23.4852 13.7093 22.7959 13.0202 22.7959 12.173C22.7959 11.3259 23.4852 10.6367 24.3324 10.6367C25.1796 10.6367 25.8688 11.3258 25.8688 12.173C25.8688 13.0202 25.1796 13.7093 24.3324 13.7093ZM24.3324 11.1283C23.7562 11.1283 23.2873 11.5969 23.2873 12.1731C23.2873 12.7492 23.7562 13.218 24.3324 13.218C24.9086 13.218 25.3774 12.7494 25.3774 12.1731C25.3774 11.5971 24.9086 11.1283 24.3324 11.1283Z"
        fill="#3D3A62"
      />
      <path
        d="M23.1989 15.826H22.7627C22.4105 15.826 22.124 15.5394 22.124 15.1873V14.7889C22.124 13.9778 22.5672 13.2334 23.2807 12.8463C23.4002 12.7815 23.549 12.8257 23.6138 12.9451C23.6786 13.0644 23.6343 13.2134 23.515 13.2782C22.9602 13.5794 22.6154 14.1583 22.6154 14.7889V15.1873C22.6154 15.2686 22.6816 15.3346 22.7627 15.3346H23.1989C23.3345 15.3346 23.4446 15.4446 23.4446 15.5803C23.4446 15.716 23.3345 15.826 23.1989 15.826L23.1989 15.826Z"
        fill="#3D3A62"
      />
      <path
        d="M25.903 15.826H25.4298C25.2942 15.826 25.1841 15.716 25.1841 15.5803C25.1841 15.4446 25.2942 15.3346 25.4298 15.3346H25.903C25.9843 15.3346 26.0503 15.2684 26.0503 15.1873V14.7889C26.0503 14.1583 25.7056 13.5794 25.1506 13.2782C25.0313 13.2134 24.987 13.0643 25.0518 12.9451C25.1166 12.8259 25.2657 12.7815 25.3849 12.8463C26.0984 13.2335 26.5416 13.9778 26.5416 14.7889V15.1873C26.5418 15.5394 26.2551 15.826 25.903 15.826L25.903 15.826Z"
        fill="#3D3A62"
      />
      <path
        d="M22.3675 12.727C21.5203 12.727 20.8311 12.0379 20.8311 11.1907C20.8311 10.3437 21.5203 9.65444 22.3675 9.65444C23.1697 9.65444 23.8275 10.2564 23.8977 11.0545C23.9096 11.1897 23.8096 11.309 23.6744 11.3208C23.5389 11.3336 23.4199 11.2329 23.4082 11.0975C23.3611 10.5638 22.9041 10.1457 22.3675 10.1457C21.7913 10.1457 21.3225 10.6143 21.3225 11.1906C21.3225 11.7666 21.7913 12.2354 22.3675 12.2354C22.5625 12.2354 22.7528 12.1812 22.9178 12.0789C23.0331 12.0071 23.1846 12.0427 23.2562 12.158C23.3278 12.2733 23.2923 12.4248 23.177 12.4964C22.9341 12.6474 22.6541 12.727 22.3675 12.727L22.3675 12.727Z"
        fill="#3D3A62"
      />
      <path
        d="M26.2978 12.727C26.0111 12.727 25.7312 12.6473 25.4882 12.4966C25.3729 12.425 25.3376 12.2735 25.4091 12.1582C25.4807 12.0429 25.6322 12.0074 25.7475 12.079C25.9125 12.1815 26.1027 12.2358 26.2977 12.2358C26.8739 12.2358 27.3428 11.7671 27.3428 11.1909C27.3428 10.6149 26.8739 10.146 26.2977 10.146C25.7612 10.146 25.3041 10.5641 25.2571 11.0979C25.2453 11.2332 25.1262 11.3334 24.9908 11.3211C24.8557 11.3094 24.7556 11.19 24.7676 11.0549C24.8378 10.2566 25.4956 9.65478 26.2977 9.65478C27.145 9.65478 27.8342 10.3439 27.8342 11.1911C27.8342 12.038 27.145 12.727 26.2977 12.727L26.2978 12.727Z"
        fill="#3D3A62"
      />
      <path
        d="M22.3571 14.8433H20.7969C20.4447 14.8433 20.1582 14.5569 20.1582 14.2046V13.8062C20.1582 13.0073 20.5916 12.2682 21.2894 11.8776C21.4079 11.8113 21.5576 11.8536 21.6238 11.972C21.6901 12.0905 21.6478 12.2402 21.5294 12.3064C20.9867 12.6102 20.6495 13.1851 20.6495 13.8062V14.2046C20.6495 14.2859 20.7157 14.3519 20.7967 14.3519H22.3569C22.4926 14.3519 22.6027 14.4619 22.6027 14.5976C22.6028 14.7335 22.493 14.8433 22.3571 14.8433L22.3571 14.8433Z"
        fill="#3D3A62"
      />
      <path
        d="M27.8679 14.8433H26.2965C26.1608 14.8433 26.0508 14.7333 26.0508 14.5976C26.0508 14.4619 26.1608 14.3519 26.2965 14.3519H27.8681C27.9494 14.3519 28.0154 14.2857 28.0154 14.2046V13.8062C28.0154 13.1821 27.6759 12.606 27.1293 12.3029C27.0107 12.2371 26.9679 12.0875 27.0337 11.9689C27.0995 11.8502 27.2491 11.8074 27.3677 11.8732C28.0703 12.2629 28.5068 13.0036 28.5068 13.8064V14.2048C28.5068 14.5569 28.2202 14.8433 27.8679 14.8433V14.8433Z"
        fill="#3D3A62"
      />
      <path
        d="M24.6682 15.5326C24.6682 15.709 24.5251 15.8521 24.3487 15.8521C24.1723 15.8521 24.0293 15.709 24.0293 15.5326C24.0293 15.3562 24.1723 15.2132 24.3487 15.2132C24.5251 15.2132 24.6682 15.3562 24.6682 15.5326Z"
        fill="#3D3A62"
      />
      <path
        d="M15.9201 8.7996C15.9201 8.976 15.7771 9.11904 15.6007 9.11904C15.4243 9.11904 15.2812 8.976 15.2812 8.7996C15.2812 8.6232 15.4243 8.48016 15.6007 8.48016C15.7771 8.48016 15.9201 8.6232 15.9201 8.7996Z"
        fill="#3D3A62"
      />
      <path
        d="M18.1121 11.5727C18.0533 11.5727 17.9952 11.5516 17.9494 11.5111L16.6168 10.3346H11.7736C11.6379 10.3346 11.5278 10.2245 11.5278 10.0889V6.24573C11.5278 6.11005 11.6379 6 11.7736 6H18.1117C18.2474 6 18.3575 6.11005 18.3575 6.24573L18.3577 11.3272C18.3577 11.4237 18.301 11.5114 18.213 11.5511C18.1806 11.5659 18.1462 11.5729 18.1119 11.5729L18.1121 11.5727ZM12.0197 9.84334H16.7102C16.77 9.84334 16.8279 9.86528 16.8729 9.90495L17.8667 10.7823V6.49146H12.02L12.0197 9.84334Z"
        fill="#3D3A62"
      />
      <path
        d="M16.6394 7.81549H13.2472C13.1115 7.81549 13.0015 7.70544 13.0015 7.56976C13.0015 7.43409 13.1115 7.32403 13.2472 7.32403H16.6394C16.7751 7.32403 16.8851 7.43408 16.8851 7.56976C16.8851 7.70561 16.7753 7.81549 16.6394 7.81549Z"
        fill="#3D3A62"
      />
      <path
        d="M14.4918 9.04359H13.2467C13.111 9.04359 13.001 8.93354 13.001 8.79786C13.001 8.66218 13.111 8.55213 13.2467 8.55213H14.4918C14.6275 8.55213 14.7376 8.66218 14.7376 8.79786C14.7376 8.93354 14.6277 9.04359 14.4918 9.04359Z"
        fill="#3D3A62"
      />
      <path
        d="M27.6049 28.9998H21.8985C21.0463 28.9998 20.353 28.3065 20.353 27.4542C20.353 26.6021 21.0463 25.9088 21.8985 25.9088H27.6049C27.7406 25.9088 27.8506 26.0188 27.8506 26.1545V28.7541C27.8506 28.8897 27.7408 28.9998 27.6049 28.9998V28.9998ZM21.8985 26.4001C21.3173 26.4001 20.8445 26.8729 20.8445 27.454C20.8445 28.0354 21.3173 28.5082 21.8985 28.5082H27.3596V26.4001H21.8985Z"
        fill="#3D3A62"
      />
      <path
        d="M27.6053 27.6999H25.8541C25.7185 27.6999 25.6084 27.5899 25.6084 27.4542C25.6084 27.3185 25.7184 27.2085 25.8541 27.2085H27.6053C27.741 27.2085 27.851 27.3185 27.851 27.4542C27.851 27.5899 27.7411 27.6999 27.6053 27.6999Z"
        fill="#3D3A62"
      />
      <path
        d="M25.5703 26.4H19.8639C19.7282 26.4 19.6182 26.29 19.6182 26.1543V23.5547C19.6182 23.419 19.7282 23.309 19.8639 23.309H25.5703C26.4225 23.309 27.1158 24.0023 27.1158 24.8544C27.116 25.7068 26.4227 26.4 25.5703 26.4V26.4ZM20.1092 25.9085H25.5703C26.1515 25.9085 26.6243 25.4357 26.6243 24.8546C26.6243 24.2734 26.1515 23.8006 25.5703 23.8006L20.1092 23.8004V25.9085Z"
        fill="#3D3A62"
      />
      <path
        d="M21.6155 25.1001H19.8644C19.7287 25.1001 19.6187 24.9901 19.6187 24.8544C19.6187 24.7187 19.7287 24.6087 19.8644 24.6087H21.6155C21.7512 24.6087 21.8613 24.7187 21.8613 24.8544C21.8613 24.9901 21.7512 25.1001 21.6155 25.1001Z"
        fill="#3D3A62"
      />
    </svg>
  );
}

export default TooltipLiveFreelanceIcon;
