const PRICE_TITLE = 'Precio';
const PRICE_DESCRIPTION =
  'Si no te conseguimos ahorrar nada comparado con tu borrador, no te cobramos';

export const PRICE_BANNER_OPTIONS = {
  title: PRICE_TITLE,
  description: PRICE_DESCRIPTION,
  image: '/images/price_top_banner.jpg',
  imageMobile: '/images/price_mobile.jpg',
  noCenter: true,
};

const COOKIES_TITLE = 'Política de cookies';
const COOKIES_DESCRIPTION = '';

export const COOKIES_BANNER_OPTIONS = {
  title: COOKIES_TITLE,
  description: COOKIES_DESCRIPTION,
  image: '/images/cookies_banner.jpg',
  imageMobile: '/images/cookies_mobile.jpg',
};

const LEGAL_ADVISE_TITLE = 'Aviso legal';
const LEGAL_ADVISE_DESCRIPTION = '';

export const LEGAL_ADVISE_BANNER_OPTIONS = {
  title: LEGAL_ADVISE_TITLE,
  description: LEGAL_ADVISE_DESCRIPTION,
  image: '/images/legal_advise_banner.jpg',
  imageMobile: '/images/legal_advise_mobile.jpg',
};

const PRIVACY_TITLE = 'Política de privacidad';
const PRIVACY_DESCRIPTION = '';

export const PRIVACY_BANNER_OPTIONS = {
  title: PRIVACY_TITLE,
  description: PRIVACY_DESCRIPTION,
  image: '/images/privacy_banner.jpg',
  imageMobile: '/images/privacy_mobile.jpg',
};

export const TERMS_CONDITIONS_BANNER_OPTIONS = {
  title: 'TermsConditions.MainBanner.title',
  image: '/images/terms_banner.jpg',
  imageMobile: '/images/terms_mobile.jpg',
};

export const SMART_BANNER_OPTIONS = {
  title: 'TaxDown',
  author: 'TaxDown S.L',
  button: 'Obtener',
  theme: 'ios',
  icon: '/images/smart_banner_icon.png',
  store: {
    ios: 'On the App Store',
    android: 'In Google Play',
    windows: 'In Windows store',
  },
  price: {
    ios: '',
    android: '',
    windows: '',
  },
};
