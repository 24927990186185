import React from 'react';

function UserIcon() {
  return (
    <svg
      width="12px"
      height="15px"
      viewBox="0 0 12 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>5FA0973F-11FA-4A64-81BF-EEA649FFB357</title>
      <desc>Created with sketchtool.</desc>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Blog" transform="translate(-1292.000000, -40.000000)">
          <g id="Group-56">
            <g id="Group-54" transform="translate(1067.000000, 25.000000)">
              <g id="Group-4" transform="translate(190.000000, 0.000000)">
                <g id="Group-34" transform="translate(35.000000, 12.000000)">
                  <g id="man-user" transform="translate(0.000000, 3.000000)">
                    <path
                      d="M5.72649573,7.31508547 C7.38948718,7.31508547 8.73773504,5.6775641 8.73773504,3.6575641 C8.73773504,1.63752137 8.29508547,-1.13686838e-13 5.72649573,-1.13686838e-13 C3.15790598,-1.13686838e-13 2.71517094,1.63752137 2.71517094,3.6575641 C2.71517094,5.6775641 4.0634188,7.31508547 5.72649573,7.31508547 Z"
                      id="Path-1"
                      fill="#5C5C5C"
                    />
                    <path
                      d="M0.0932217859,13.0049104 C0.0914217859,12.7130319 0.0896217859,12.922674 0.0932217859,13.0049104 L0.0932217859,13.0049104 Z"
                      id="Path"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                    <path
                      d="M11.4665677,13.0525255 C11.4722677,13.0073176 11.4685177,12.7388776 11.4665677,13.0525255 L11.4665677,13.0525255 Z"
                      id="Path"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                    <path
                      d="M11.4076526,12.7705128 C11.3518834,9.25162393 10.8923108,8.24893162 7.37551589,7.61423077 C7.37551589,7.61423077 6.88047315,8.24504274 5.726627,8.24504274 C4.57278085,8.24504274 4.07765264,7.61423077 4.07765264,7.61423077 C0.599233837,8.24200855 0.11171247,9.22982906 0.0476099058,12.6561966 C0.0423534956,12.9359829 0.0399175982,12.9506838 0.0389774272,12.9182051 C0.0391911024,12.9790598 0.0394475127,13.0916239 0.0394475127,13.287906 C0.0394475127,13.287906 0.87671247,14.9757692 5.726627,14.9757692 C10.5764561,14.9757692 11.4138065,13.287906 11.4138065,13.287906 C11.4138065,13.1617949 11.413892,13.0741026 11.4140202,13.0144444 C11.41308,13.0345299 11.4111996,12.9955983 11.4076526,12.7705128 Z"
                      id="Path-2"
                      fill="#5C5C5C"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default UserIcon;
