import React from 'react';
import ProptTypes from 'prop-types';
import { StyledLegalText } from './styles';

const LegalText = ({ children }) => <StyledLegalText>{children} </StyledLegalText>;

LegalText.propTypes = {
  children: ProptTypes.node.isRequired,
};

export default LegalText;
