import React from 'react';

const LinesImage = () => {
  return (
    <svg
      width="499"
      height="87"
      viewBox="0 0 499 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="lines"
    >
      <path
        d="M-20 44.8781C35.7145 71.5447 131.41 111.545 244.476 58.2114C357.543 4.87817 459.936 -0.455146 497 3.54485"
        stroke="#3D3A62"
        strokeWidth="3.14713"
        strokeLinecap="round"
      />
      <path
        d="M-20 61.5C52.2368 29.1592 95.7754 -25.3869 244.476 29.1592C393.177 83.7054 458.82 29.1591 497 1.5"
        stroke="#99F1BD"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinesImage;
