import React from 'react';

import { Carousel, BannerSmall } from 'Components';
import withView from 'Hocs/withView';
import mainBannerOptions from 'Assets/json/about-us/main_banner.json';
import secondSectionOptions from 'Assets/json/about-us/second_section.json';
import { FirstSection, SecondSection } from './components';

function AboutUs() {
  return (
    <>
      <BannerSmall {...mainBannerOptions} />
      <FirstSection />
      <SecondSection {...secondSectionOptions} />
      <Carousel />
    </>
  );
}

export default withView(AboutUs);
