import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'Hooks';
import { validations } from 'Services/validations';

const View = ({ onSubmit, submitted }) => {
  const { t } = useTranslation('home');

  const [values, setValues] = useState({});
  const [hasNameError, setHasNameError] = useState(false);
  const [errorNameMsg, setErrorNameMsg] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);
  const [errorEmailMsg, setErrorEmailMsg] = useState('');
  const [hasPhoneError, setHasPhoneError] = useState(false);
  const [errorPhoneMsg, setErrorPhoneMsg] = useState('');
  const [hasCompanyError, setHasCompanyError] = useState(false);
  const [errorCompanyMsg, setErrorCompanyMsg] = useState('');
  const [hasEmployersError, setHasEmployersError] = useState(false);
  const [errorEmployersMsg, setErrorEmployersMsg] = useState('');

  const formModel = {
    name: ['required'],
    email: ['email', 'required'],
    phone: ['required'],
    company: ['required'],
    employers: ['required'],
  };

  const validateField = (val, name) => {
    const messages = [];
    const fieldModel = formModel[name];
    if (fieldModel) {
      fieldModel.forEach(rule => {
        const validation = validations[rule];
        if (validation) {
          const { message, validator } = validation;
          const isValid = validator(val);
          if (!isValid) {
            messages.push(message);
          }
        }
      });
    }
    return messages;
  };

  const handleName = name => {
    setHasNameError(false);
    const messages = validateField(name, 'name');
    if (messages.length > 0) {
      setHasNameError(true);
      setErrorNameMsg(t('PartnerContactForm.error.required'));
    } else {
      setValues({ ...values, name });
    }
  };

  const handleEmail = email => {
    setHasEmailError(false);
    const messages = validateField(email, 'email');
    if (messages.length) {
      setHasEmailError(true);
      setErrorEmailMsg(t('PartnerContactForm.error.email'));
    } else {
      setValues({ ...values, email });
    }
  };

  const handlePhone = phone => {
    setHasPhoneError(false);
    const messages = validateField(phone, 'phone');
    if (messages.length) {
      setHasPhoneError(true);
      setErrorPhoneMsg(t('PartnerContactForm.error.required'));
    } else {
      setValues({ ...values, phone: parseInt(phone, 10) });
    }
  };

  const handleCompany = company => {
    setHasCompanyError(false);
    const messages = validateField(company, 'company');
    if (messages.length) {
      setHasCompanyError(true);
      setErrorCompanyMsg(t('PartnerContactForm.error.required'));
    } else {
      setValues({ ...values, company });
    }
  };

  const handleEmployers = employers => {
    setHasEmployersError(false);
    const messages = validateField(employers, 'employers');
    if (messages.length) {
      setHasEmployersError(true);
      setErrorEmployersMsg(t('PartnerContactForm.error.required'));
    } else {
      setValues({ ...values, employers: parseInt(employers, 10) });
    }
  };

  const doSubmit = e => {
    e.preventDefault();
    onSubmit(values);
  };

  return (
    <>
      <form
        className="partners-contact-form"
        onSubmit={doSubmit}
        style={{ display: !submitted ? 'block' : 'none' }}
      >
        <div className="input-form">
          <input
            id="partners-contact-form-name"
            type="text"
            placeholder={t('PartnerContactForm.name.placeholder')}
            onBlur={e => handleName(e.target.value)}
            onKeyUp={e => handleName(e.target.value)}
            className={hasNameError ? 'has-error' : ''}
          />
          {hasNameError && errorNameMsg && <span className="error-msg">{errorNameMsg}</span>}
        </div>
        <div className="input-form">
          <input
            type="text"
            placeholder={t('PartnerContactForm.email.placeholder')}
            onBlur={e => handleEmail(e.target.value)}
            onKeyUp={e => handleEmail(e.target.value)}
            className={hasEmailError ? 'has-error' : ''}
          />
          {hasEmailError && errorEmailMsg && <span className="error-msg">{errorEmailMsg}</span>}
        </div>
        <div className="input-form">
          <input
            type="number"
            placeholder={t('PartnerContactForm.phone.placeholder')}
            onBlur={e => handlePhone(e.target.value)}
            onKeyUp={e => handlePhone(e.target.value)}
            className={hasPhoneError ? 'has-error' : ''}
          />
          {hasPhoneError && errorPhoneMsg && <span className="error-msg">{errorPhoneMsg}</span>}
        </div>
        <div className="input-form">
          <input
            type="text"
            placeholder={t('PartnerContactForm.company.placeholder')}
            onBlur={e => handleCompany(e.target.value)}
            onKeyUp={e => handleCompany(e.target.value)}
            className={hasCompanyError ? 'has-error' : ''}
          />
          {hasCompanyError && errorCompanyMsg && (
            <span className="error-msg">{errorCompanyMsg}</span>
          )}
        </div>
        <div className="input-form">
          <input
            type="number"
            placeholder={t('PartnerContactForm.employers.placeholder')}
            onBlur={e => handleEmployers(e.target.value)}
            onKeyUp={e => handleEmployers(e.target.value)}
            className={hasEmployersError ? 'has-error' : ''}
          />
          {hasEmployersError && errorEmployersMsg && (
            <span className="error-msg">{errorEmployersMsg}</span>
          )}
        </div>
        <div className="links">
          {t('PartnerContactForm.links.first')}
          <a href="/privacidad">{t('PartnerContactForm.links.second')}</a>
          {t('PartnerContactForm.links.third')}
          <a href="/terminos-condiciones">{t('PartnerContactForm.links.four')}</a>.
        </div>
        <div className="partners-contact-form-submit">
          <button type="submit" className="solid_btn green">
            {t('PartnerContactForm.send')}
          </button>
        </div>
      </form>
      <div
        className="partners-contact-form-submitted-panel"
        style={{ display: submitted ? 'flex' : 'none' }}
      >
        <div className="icon">
          <svg
            width="76"
            height="77"
            viewBox="0 0 76 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="38" cy="38.5" r="38" fill="#00DC5A" />
            <path
              d="M26 38.5L34.5 49L50 30"
              stroke="white"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="text">
          <p>{t('PartnerContactForm.sent')}</p>
          <p>{t('PartnerContactForm.thanks')}</p>
        </div>
      </div>
    </>
  );
};

View.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.isRequired,
};

export default View;
