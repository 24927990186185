import styled from 'styled-components';
import { pxToRem } from 'Styles/utils';
import { MOBILE_WIDTH, TABLET_WIDTH } from 'Styles/sizes';

export const MediaListWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  > h2 {
    font-size: ${pxToRem(32)};
    margin-bottom: ${pxToRem(50)};
  }
  @media (max-width: ${TABLET_WIDTH}) {
    > h2 {
      margin-bottom: ${pxToRem(40)};
    }
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    > h2 {
      margin-bottom: ${pxToRem(32)};
    }
  }
`;

export const MediaTwoColumns = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
  }
`;

export const MediaThreeColumns = styled.div`
  display: flex;
`;

export const MediaTwoRows = styled.div`
  flex: 0 1 calc(40% - 20px);

  @media (max-width: ${TABLET_WIDTH}) {
    flex: 0 1 calc(100% / 2 - 12px);
  }
`;
